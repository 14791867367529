import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import { ReactComponent as ArrowUp } from '../../assets/arrow_up.svg'

const useWindowScrollPosition = () => {
  const getPosition = () => ({
    x: window.pageXOffset,
    y: window.pageYOffset
  })

  const throttle = 100
  const [position, setPosition] = useState(getPosition())

  useEffect(() => {
    const handleScroll = _.throttle(() => {
      setPosition(getPosition())
    }, throttle)

    window.addEventListener(
      'scroll',
      handleScroll,
      false
    )

    return () => {
      handleScroll.cancel()
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return position
}

export const NavigateToTop = () => {
  const position = useWindowScrollPosition()
  const [isEnabled, setIsEnabled] = useState(true)

  useEffect(() => {
    setIsEnabled(position.y <= 0)
  }, [position])

  return <>
    <div className={classNames('toTop', isEnabled ? 'disabled' : '')} onClick={() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }}>
      <ArrowUp />
    </div></>
}
