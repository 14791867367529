import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { CheckLogin } from './checkLogin';

class EnsureLoggedIn extends Component {
  UNSAFE_componentWillMount() {
    const { tokenValidation, verifyToken } = this.props;

    if (!tokenValidation.result && !tokenValidation.requesting) {
      verifyToken();
    }
  }

  render() {
    const { children, tokenValidation } = this.props;

    if (!window.localStorage.accessToken) {
      return <Redirect to='/login' />;
    } else if (!tokenValidation.error && tokenValidation.result) {
      return (
        <div>
          <CheckLogin />
          {children}
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <br />
          <br />
          <Loader active size='large' inline='centered' />
        </div>
      );
    }
  }
}

const mapStateToProps = ({ tokenValidation }) => ({ tokenValidation });

const mapDispatchToProps = (dispatch) => ({
  verifyToken: () => dispatch({ type: 'TOKEN_VALIDATION_REQUESTED' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnsureLoggedIn);
