import React from 'react'

import Papa from 'papaparse'
import { zipObject, map } from 'lodash'

class CsvParse extends React.Component {
  handleFile = event => {
    const { keys, onDataUploaded, onError } = this.props

    const file = event.target.files[0]

    Papa.parse(file, {
      skipEmptyLines: true,
      error(err, fileInfo, inputElem, reason) {
        onError({ err, file: fileInfo, inputElem, reason })
      },
      complete(results) {
        const { data } = results

        // remove display headers
        data.shift()

        // convert arrays to objects
        const formatedResult = map(data, row => zipObject(keys, row))

        // send result to state
        onDataUploaded(formatedResult)
      }
    })
  }

  render() {
    const { render } = this.props
    return render(this.handleFile)
  }
}

export default CsvParse
