import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { Menu, Icon } from 'semantic-ui-react'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'

import { MenuHeader } from '../../common'
import Sidebar from '../../sidebar'
import GroupNavigation from './GroupNavigation'
import GroupActions from './GroupActions'

import './GroupDetails.scss'
import GroupTabs from './GroupTabs'
import GroupUsers from './GroupUsers'
import NewUser from './NewUser'
import BulkUsers from './BulkUsers'
import EditableHeader from './EditableHeader'

import { constructGroupHierarchy } from './GroupUtils'

const Content = ({ children }) => <div className='content'>{children}</div>
const Page = ({ children }) => <div className='groupDetails'>{children}</div>

const GoBack = ({ onClick }) => (
  <Menu.Item name='home' onClick={onClick}>
    <div className='menuItem'>
      <Icon name='chevron circle left' size='large' />
      <div className='marginLeft'>Dashboard</div>
    </div>
  </Menu.Item>
)

class GroupDetailsPage extends Component {
  componentDidMount() {
    const { match, getGroups, getGroupDetails } = this.props

    getGroupDetails(match.params.id)
    getGroups()
  }

  render() {
    const {
      match,
      groups,
      back,
      location: { pathname },
      switchTab,
      createUsers,
      onEditGroup,
      selectForDeletion
    } = this.props

    const { path, url } = match
    const { id } = match.params

    if (!groups) return null

    const selected = groups.find(g => g._id === id)
    const members = selected.members.filter(g => !g.deleted)
    const filtered = groups.filter(g => selected.archived || !g.archived)
    const groupMap = keyBy(filtered, '_id')
    const groupHierarchy = constructGroupHierarchy(filtered)

    return (
      <Page>
        <Sidebar>
          <Menu secondary vertical>
            <GoBack onClick={back} />
            <MenuHeader title='Groups' />
            <GroupNavigation selected={selected} groups={groupHierarchy} />
          </Menu>
        </Sidebar>
        <Content>
          <div className='groupPageHeader'>
            <EditableHeader group={selected} onEditGroup={onEditGroup} />
            <div className='groupHeaderActions'>
              <GroupActions group={selected} onDelete={selectForDeletion} />
            </div>
          </div>
          <GroupTabs onTabChange={switchTab} path={pathname} url={url} />
          <Route
            exact
            path={`${path}`}
            render={() => (
              <GroupUsers id={id} users={members} groupId={selected._id} />
            )}
          />
          <Route
            path={`${path}/new`}
            render={() => (
              <NewUser id={id} group={selected} allGroups={groupMap} />
            )}
          />
          <Route
            path={`${path}/bulk`}
            render={() => (
              <BulkUsers id={id} members={members} createUsers={createUsers} />
            )}
          />
        </Content>
      </Page>
    )
  }
}

const mapStateToProps = ({ groups }) => ({
  groups: get(groups, 'result.data', null)
})

const mapDispatchToProps = dispatch => ({
  back: () => dispatch({ type: 'REDIRECT', url: '/groups' }),
  switchTab: name => dispatch({ type: 'REDIRECT', url: `${name}` }),
  getGroups: () => dispatch({ type: 'FETCH_GROUPS_REQUESTED' }),
  selectForDeletion: (e, { value }) =>
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'deleteGroup',
      params: { group: value }
    }),
  createUsers: users =>
    dispatch({ type: 'CREATE_USERS_REQUESTED', payload: users }),
  onEditGroup: (group, id) =>
    dispatch({ type: 'UPDATE_GROUP_REQUESTED', payload: group, id }),
  getGroupDetails: id =>
    dispatch({ type: 'FETCH_GROUPS_DETAILS_REQUESTED', id }),
  getLoggedInUsers: () => dispatch({ type: 'FETCH_LOGGED_IN_USERS_REQUESTED' })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupDetailsPage)
