const defaultState = {
    requesting: false,
    error: false,
    result: null
  };

  export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'DUPLICATE_DIAGNOSTIC_REQUESTED':
        return {
          requesting: true,
          error: false,
          result: null
        }
      case 'DUPLICATE_DIAGNOSTIC_SUCCEEDED':
        return {
          requesting: false,
          error: false,
          result: action.payload
        }
      case 'DUPLICATE_DIAGNOSTIC_FAILED':
        return {
          requesting: false,
          error: true,
          result: action.payload
        }
      default:
        return state
    }
  }
