import React, { Component } from 'react'
import { Header, Label, Button } from 'semantic-ui-react'
import ContentEditable from 'react-contenteditable'

class EditableHeader extends Component {
  titleEditable = React.createRef()

  state = { focused: false }

  pasteAsPlainText = event => {
    event.preventDefault()

    const text = event.clipboardData.getData('text/plain')
    document.execCommand('insertHTML', false, text)
  }

  validateTitle = event => {
    const keyCode = event.keyCode || event.which

    if (keyCode === 13) {
      event.returnValue = false // eslint-disable-line no-param-reassign
      if (event.preventDefault) event.preventDefault()
    }
  }

  onSave = () => {
    const { group, onEditGroup } = this.props
    const name = this.titleEditable.current.innerText

    if (!name) return

    if (name !== group.name) {
      onEditGroup({ name }, group._id)
    }

    this.setState({ focused: false })
  }

  onCancel = event => {
    event.preventDefault()
    this.setState({ focused: false })
  }

  onFocus = () => {
    this.setState({ focused: true })
  }

  onBlur = target => {
    this.setState({ focused: false })
  }

  render() {
    const { group } = this.props
    const { focused } = this.state

    return (
      <div className='groupHeader'>
        <Header as='h4'>GROUPS</Header>
        <Header as='h1' style={{ display: 'inline', whiteSpace: 'no-wrap' }}>
          {group.parent ? `${group.parent.name} / ` : ''}
          <ContentEditable
            style={{
              display: 'inline',
              outline: '0px solid transparent'
            }}
            tagName='span'
            innerRef={this.titleEditable}
            html={group.name.toString()}
            data-column='item'
            className='content-editable'
            onPaste={this.pasteAsPlainText}
            onKeyPress={this.validateTitle}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />

          {group.archived && (
            <Label color='red' horizontal>
              Archived
            </Label>
          )}
        </Header>
        {focused && (
          <>
            <Button
              icon='check'
              primary
              onMouseDown={this.onSave}
              onClick={this.onSave}
              style={{
                lineHeight: '1rem',
                fontSize: '1rem',
                verticalAlign: 'middle',
                display: 'inline',
                margin: '-1rem 0 0rem 2rem'
              }}
            />
            <Button
              icon='cancel'
              onClick={this.onCancel}
              style={{
                lineHeight: '1rem',
                fontSize: '1rem',
                verticalAlign: 'middle',
                display: 'inline',
                margin: '-1rem 0 0rem 0.5rem'
              }}
            />
          </>
        )}
      </div>
    )
  }
}

export default EditableHeader
