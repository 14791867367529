const defaultState = {
  requesting: false,
  error: false,
  result: null,
  isCreate: false,
  isDelete: false
}

export default function counter(state = defaultState, { payload, type }) {
  switch (type) {
    case 'FETCH_SURVEYS_BY_DIAGNOSTIC_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false
      }
    case 'FETCH_SURVEYS_BY_DIAGNOSTIC_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isCreate: false
      }
    case 'FETCH_SURVEYS_BY_DIAGNOSTIC_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isCreate: false
      }
    default:
      return state
  }
}
