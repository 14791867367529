import React, { Component } from 'react'
import { DragSource, DropTarget } from 'react-dnd'


/**
 * Implements the drag source contract.
 */
const comparatorSource = {
  beginDrag(props) {
    return {
      compare: props.compare
    }
  }
}

/**
 * Specifies the props to inject into your component.
 */
function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

class Comparator extends Component {
  render() {
    const {
      connectDragSource,
      isDragging,
      compare,
      allComparatorsFromInventory
    } = this.props

    let comparatorName
    const comparator = allComparatorsFromInventory.find(
      comparator => comparator._id === compare.comparator
    )

    if (compare.isProduct) {
      comparatorName = compare.comparator || compare.description
    } else {
      comparatorName = comparator && comparator.name
    }

    if (!comparatorName || (comparator && comparator.deleted)) return null

    return connectDragSource(
      <div className='comparatorCard' secondary={isDragging}>
        {comparatorName}
      </div>
    )
  }
}

const ComparatorContainer = DragSource(
  'comparator',
  comparatorSource,
  collectSource
)(Comparator)

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}

const comparatorTarget = {
  drop(props, monitor) {
    const item = monitor.getItem()
    if (item.compare.description) {
      props.handleFactorRating(null, { value: props.item.number })
    } else {
      props.changeComparator(item.compare, props.item.number)
    }
  }
}


class Rating extends Component {
  render() {
    const { connectDropTarget, isOver, children } = this.props
    const style = isOver ? { backgroundColor: '#EEE' } : {}

    return connectDropTarget(
      <div style={style} className='compareTarget'>
        {children}
      </div>
    )
  }
}


class Unrated extends Component {
  render() {
    const { connectDropTarget, isOver, children } = this.props
    const style = isOver ? { backgroundColor: '#EEE' } : {}

    return connectDropTarget(
      <div style={style} className='unrated'>
        {children}
      </div>
    )
  }
}

const RatingContainer = DropTarget(
  'comparator',
  comparatorTarget,
  collectTarget
)(Rating)
const UnratedContainer = DropTarget(
  'comparator',
  comparatorTarget,
  collectTarget
)(Unrated)

export { RatingContainer, UnratedContainer, ComparatorContainer }