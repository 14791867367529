import { call, put, takeLatest } from 'redux-saga/effects'
import api from '../../services/api'
import {
  handleUnauthorizedError,
  handleSuccess,
  handleError
} from './helpers/saga-helpers'

function* createDiagnostic(action) {
  try {
    const result = yield call(api.createDiagnostic, action.payload)
    yield handleSuccess(
      'CREATE_DIAGNOSTIC_SUCCEEDED',
      result,
      'Diagnostic created successfully!'
    )
    yield put({ type: 'REDIRECT', url: '/diagnostic/' + result.data._id })
  } catch (e) {
    yield handleError({ type: 'CREATE_DIAGNOSTIC_FAILED', error: e })
  }
}

function* updateDiagnostic({ payload, id }) {
  try {
    const result = yield call(api.updateDiagnostic, payload, id)
    yield put({ type: 'UPDATE_DIAGNOSTIC_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Diagnostic updated successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'UPDATE_DIAGNOSTIC_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* archiveDiagnostic(action) {
  try {
    const result = yield call(api.archiveDiagnostic, action.id)
    yield put({ type: 'ARCHIVE_DIAGNOSTIC_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Diagnostic archived successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'ARCHIVE_DIAGNOSTIC_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* restoreDiagnostic({ id }) {
  try {
    const result = yield call(api.restoreDiagnostic, id)

    yield put({ type: 'RESTORE_DIAGNOSTIC_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Diagnostic restored successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'RESTORE_DIAGNOSTIC_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* duplicateDiagnostic(action) {
  try {
    const result = yield call(api.duplicateDiagnostic, action.id)
    yield put({ type: 'DUPLICATE_DIAGNOSTIC_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Diagnostic duplicated successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'DUPLICATE_DIAGNOSTIC_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* getDiagnostics(action) {
  try {
    const result = yield call(api.getDiagnostics, action.query)
    yield put({ type: 'FETCH_DIAGNOSTICS_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'FETCH_DIAGNOSTICS_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* getDiagnosticDetails(action) {
  try {
    const result = yield call(api.getDiagnosticDetails, action.id)
    yield put({ type: 'FETCH_DIAGNOSTIC_DETAILS_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'FETCH_DIAGNOSTIC_DETAILS_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* diagnostics() {
  yield takeLatest('CREATE_DIAGNOSTIC_REQUESTED', createDiagnostic)
  yield takeLatest('UPDATE_DIAGNOSTIC_REQUESTED', updateDiagnostic)
  yield takeLatest('FETCH_DIAGNOSTICS_REQUESTED', getDiagnostics)
  yield takeLatest('ARCHIVE_DIAGNOSTIC_REQUESTED', archiveDiagnostic)
  yield takeLatest('RESTORE_DIAGNOSTIC_REQUESTED', restoreDiagnostic)
  yield takeLatest('FETCH_DIAGNOSTIC_DETAILS_REQUESTED', getDiagnosticDetails)
  yield takeLatest('DUPLICATE_DIAGNOSTIC_REQUESTED', duplicateDiagnostic)
}

export default diagnostics
