import React, { Component } from 'react';
import Button from '../common/Button';

export default class NavigationButtons extends Component {
  render() {
    const { goTo, step, saveSurvey, isNewSurvey, isNewlyCreatedSurvey, validateSurveyBasicParams, handleNext, handlePrevious } = this.props;

    const isFinalStep = step === 'send';

    return (
      <div>
        {isNewSurvey() &&
          <div className='buttonsContainer'>
            <Button
              rounded
              shadowed
              color='white'
              onClick={() => goTo('/surveys')}>
              Cancel
            </Button>
            <Button
              color='black'
              rounded
              shadowed
              disabled={validateSurveyBasicParams()}
              onClick={() => saveSurvey(false)}>
              Next
            </Button>
          </div>
        }

        {!isNewSurvey() && isNewlyCreatedSurvey() &&
          <div className='buttonsContainer'>
            <Button
              rounded
              shadowed
              color='white'
              disabled={step === 'parameters'}
              onClick={() => { saveSurvey(false); handlePrevious() }}>
              Previous
            </Button>
            <Button
              color='black'
              rounded
              shadowed
              onClick={() => { isFinalStep ? saveSurvey(true) : saveSurvey(); handleNext() }}>
              {isFinalStep ? 'Save' : 'Next'}
            </Button>
          </div>
        }

        {!isNewSurvey() && !isNewlyCreatedSurvey() &&
          <div>
            <Button
              color='black'
              rounded
              shadowed
              onClick={() => saveSurvey(true)}
            >Save</Button>
          </div>
        }

      </div>
    );
  }
}
