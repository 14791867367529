import React from 'react'
import { matchPath } from 'react-router-dom'

import { Menu } from 'semantic-ui-react'

const GroupTabs = ({ onTabChange, path, url }) => (
  <Menu pointing secondary>
    <Menu.Item
      id='usersTab'
      content='Users'
      active={
        !!matchPath(path, {
          path: '/groups/:id',
          exact: true
        })
      }
      onClick={() => onTabChange(`${url}`)}
    />
    <Menu.Item
      id='newUserTab'
      content='Add User'
      active={
        !!matchPath(path, {
          path: '/groups/:id/new',
          exact: true
        })
      }
      onClick={() => onTabChange(`${url}/new`)}
    />
    <Menu.Item
      id='bulkUsersTab'
      content='Import / Export Users'
      active={
        !!matchPath(path, {
          path: '/groups/:id/bulk',
          exact: true
        })
      }
      onClick={() => onTabChange(`${url}/bulk`)}
    />
  </Menu>
)

export default GroupTabs
