import { Component } from 'react'
import { connect } from 'react-redux'
import { Header, Form, Input } from 'semantic-ui-react'
import { get } from 'lodash'

import { RoutePaths } from '../_routing/route-paths'
import SectorAdmin from '../admin-controls/sector-admin'
import Button from '../common/Button'
import { Breadcrumb } from '../page-layout/breadcrumb/Breadcrumb'

import './newWheel.scss'

class NewWheel extends Component {
  state = {
    step: 'wheel',
    title: '',
    titleError: false,
  }

  saveWheel = () => {
    const { match, createWheel, updateWheel } = this.props
    const { title } = this.state

    if (!title) {
      return this.setState({ titleError: true })
    }
    this.setState({ titleError: false })

    if (match.params.id) {
      updateWheel({ title }, match.params.id)
    } else {
      createWheel({ title })
    }
  }

  componentDidMount() {
    const { match, getWheel } = this.props

    if (match.params.id) {
      getWheel(match.params.id)
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { wheels, match, goTo } = props

    if (this.props.wheels !== wheels && !wheels.requesting && !wheels.error) {
      if (!wheels.isCreate && wheels.result) {
        this.setState({
          title: wheels.result.data.title,
        })
      }

      if (wheels.isCreate && wheels.result && !match.params.id) {
        goTo(`/new-wheel/${wheels.result.data._id}`)
      }
    }
  }

  render() {
    const { match, wheel } = this.props
    const editing = !!match.params.id

    return (
      <div className='newWheelComponent'>
        <Breadcrumb
          nodes={[
            { label: 'Dashboard', route: RoutePaths.Home },
            { label: 'Wheels', route: RoutePaths.Wheels },
          ]}
        />

        {!editing && (
          <>
            <Header as='h1'>New Wheel</Header>

            <Form>
              <Form.Field>
                <div className='fieldLabel'>
                  <label>Title</label>
                  <label>*REQUIRED</label>
                </div>
                <Input
                  placeholder='Title'
                  name='title'
                  error={this.state.titleError}
                  value={this.state.title}
                  onChange={(e, data) => this.setState({ title: data.value })}
                />
              </Form.Field>

              <div className='buttonsContainer'>
                <Button
                  color='white'
                  rounded
                  disabled={this.props.wheels.requesting}
                  onClick={() => this.props.goTo('/wheels')}
                >
                  Cancel
                </Button>
                <Button
                  color='black'
                  rounded
                  shadowed
                  disabled={this.props.wheels.requesting}
                  onClick={this.saveWheel}
                >
                  Save
                </Button>
              </div>
            </Form>
          </>
        )}
        {editing && (
          <>
            <Header as='h1'>{wheel.title}</Header>
            <SectorAdmin wheel={match.params.id} showSettings />
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ wheels }) => ({
  wheels,
  wheel: get(wheels, 'result.data', {}),
})

const mapDispatchToProps = (dispatch) => ({
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
  getWheel: (id) => dispatch({ type: 'FETCH_WHEEL_REQUESTED', id }),
  createWheel: (payload) =>
    dispatch({ type: 'CREATE_WHEEL_REQUESTED', payload }),
  updateWheel: (payload, id) =>
    dispatch({ type: 'UPDATE_WHEEL_REQUESTED', payload, id }),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewWheel)
