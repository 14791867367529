import React from 'react'
import { TextArea, Form } from 'semantic-ui-react'

import './Rationale.scss'

const Rationale = ({ onChange, rationale, compare, onBlur }) => (
  <div className='rationaleContainer'>
    <Form>
      <Form.Field>
        <label>Rationale</label>
        <TextArea
          placeholder='Add rationale here.'
          name='rationale'
          onChange={(e, data) => onChange(data.value, compare)}
          onBlur={onBlur}
          value={rationale || ''}
          rows='4'
        />
      </Form.Field>
    </Form>
  </div>
)

export default Rationale
