import { DragSource, DropTarget } from 'react-dnd'
import Rating from './Rating'
import Unrated from './Unrated'

import Comparator from './Comparator'
/**
 * Implements the drag source contract.
 */
const comparatorSource = {
  beginDrag(props) {
    return {
      compare: props.compare,
      allComparatorsFromInventory: props.allComparatorsFromInventory
    }
  }
}

/**
 * Specifies the props to inject into your component.
 */
function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}

const comparatorTarget = {
  drop(props, monitor) {
    const item = monitor.getItem()
    if (item.compare.description) {
      props.handleFactorRating(null, { value: props.item.number })
    } else {
      props.changeComparator(item.compare, props.item.number)
    }
  }
}

const RatingContainer = DropTarget(
  'comparator',
  comparatorTarget,
  collectTarget
)(Rating)
const UnratedContainer = DropTarget(
  'comparator',
  comparatorTarget,
  collectTarget
)(Unrated)

const ComparatorContainer = DragSource(
  'comparator',
  comparatorSource,
  collectSource
)(Comparator)

export { ComparatorContainer, RatingContainer, UnratedContainer }
