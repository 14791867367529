import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import get from 'lodash/get'
import { Loader, Header } from 'semantic-ui-react'
import Button from '../common/Button'

import Completion from './completion'
import Sector from './sector'
import TPP from './tpp'
import Welcome from './welcome'
import Introduction from './introduction'
import Importance from './importance'
import BackgroundQuestions from './background-questions'
import SurveyNavigation from './survey-navigation'
import Taskline from './task-line'
import AdditionalCoverage from './additional-coverage'
import TimeTracker from './timeTracker'
import { forEachFactor } from './utils'

import { RoutePaths } from '../_routing/route-paths'
import { Breadcrumb } from '../page-layout/breadcrumb/Breadcrumb'

import './styles.scss'

const Surveys = (props) => {
  const [sectorIndex, setSectorIndex] = useState(0)
  const [dimensionIndex, setDimensionIndex] = useState(0)
  const [factorIndex, setFactorIndex] = useState(0)
  const [sectors, setSectors] = useState([])
  const [factorMap, setFactorMap] = useState({})
  const [progressLoaded, setProgressLoaded] = useState(false)
  const [surveyNavigation, setSurveyNavigation] = useState(undefined)
  const [completed, setCompleted] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [navigationUpdate, setNavigationUpdate] = useState(undefined)

  const {
    getSurveyDetails,
    hideLogo,
    showLogo,
    survey,
    surveyResponse,
    match,
    getSurveyResponse,
    goTo,
    submitSurvey,
    getSurveyAssets,
  } = props

  useEffect(() => {
    getSurveyDetails(match.params.id)
    getSurveyAssets(match.params.id)
    getSurveyResponse(match.params.id)
    hideLogo()

    return () => showLogo()
  }, [])

  useEffect(() => {
    const { error, requesting, saving } = surveyResponse
    if (!error && !requesting && saving) {
      getSurveyResponse(match.params.id)
    }
  }, [surveyResponse])

  const updateSectors = (sectorsList) => {
    let newSectors = !sectorsList ? [...sectors] : _.cloneDeep(sectorsList)

    const maxSector = surveyNavigation
      ? surveyNavigation.getLatestSectorIndex()
      : 0
    const surveyFactors = survey.factors || {}

    let additionalFactorsArray = _.get(
      surveyResponse,
      'result.data.additionalFactors',
      []
    )
    let additionalFactors = _.keyBy(additionalFactorsArray, (item) => item)

    const factorsArray = _.get(surveyResponse, 'result.data.factors', [])
    const factorMap = _.keyBy(factorsArray, '_id')

    forEachFactor(newSectors, ({ sector, dimension, sectorIndex, factor }) => {
      if (factorMap[factor._id]) {
        factor.rating = factorMap[factor._id].rating.toString()
        factor.compare = factorMap[factor._id].compare
        if (factorMap[factor._id].importance) {
          factor.importance = factorMap[factor._id].importance.toString()
        }
      }

      if (maxSector >= sectorIndex) {
        factor.highlighted = surveyFactors[factor._id]
        factor.additionalFactor = !!additionalFactors[factor._id]
        sector.disabled = false
        sector.highlighted = true
        factor.disabled = false
      } else {
        sector.disabled = true
        factor.disabled = true
      }
      factor.dimension = dimension
      factor.sector = sector
    })

    return newSectors
  }

  const loadProgress = () => {
    const surveyResponseData = get(surveyResponse, 'result.data')

    if (!surveyResponseData) {
      return setProgressLoaded(true)
    }

    if (surveyResponseData.latestFulfilledStep) {
      surveyNavigation.setStepStatusesByLatestFullfiledStepId(
        surveyResponseData.latestFulfilledStep
      )
    }

    const newSectors = updateSectors()

    setSectors(_.sortBy(newSectors, 'order'))
    setProgressLoaded(true)
  }

  const setupNewSectorAfterNavigation = (newSectorIndex) => {
    const newSectors = updateSectors(sectors)
    setSectors(_.sortBy(newSectors, 'order'))
    setSectorIndex(newSectorIndex)
    setDimensionIndex(0)
    setFactorIndex(0)
  }

  useEffect(() => {
    if (!survey) return

    loadSectors(survey)
  }, [survey])

  useEffect(() => {
    if (!surveyNavigation && sectors.length && survey) {
      setupSurveyNavigation()
    }
  }, [surveyNavigation, sectors, survey])

  useEffect(() => {
    if (
      surveyResponse.error ||
      surveyResponse.requesting ||
      !surveyResponse.result ||
      !surveyNavigation
    )
      return

    if (completed) {
      goTo('/')
    }

    if (!progressLoaded) {
      loadProgress()
    } else {
      setSectors(_.sortBy(updateSectors(sectors), 'order'))
    }
  }, [surveyResponse, surveyNavigation])

  const addFactor = (acum, val, survey) => {
    const sectorId = val._id.dimension.sector._id
    if (!acum[sectorId]) {
      acum[sectorId] = Object.assign({}, val._id.dimension.sector, {
        dimensionsMap: {},
      })
    }
    const dimensionId = val._id.dimension._id
    if (!acum[sectorId].dimensionsMap[dimensionId]) {
      acum[sectorId].dimensionsMap[dimensionId] = Object.assign(
        {},
        val._id.dimension,
        {
          factors: [],
        }
      )
    }

    const factor = {
      _id: val._id._id,
      name: val._id.name,
      description: val._id.description,
      rating: -1,
      compare: [],
      flipRatings: val._id.flipRatings || false,
      competitorsQuestion: val._id.competitorsQuestion,
      competitorsOptions: val._id.competitorsOptions,
      ratingQuestion: val._id.ratingQuestion,
      ratingOptions: val._id.ratingOptions,
    }

    if (val._id.dimension.sector.compare) {
      factor.importance = 0
      factor.compare = survey.comparators.map((comparator) => ({
        comparator,
        rating: '0',
      }))
    }
    acum[sectorId].dimensionsMap[dimensionId].factors.push(factor)
  }

  const mapSectors = (sectorsMap) => {
    return Object.keys(sectorsMap).map((sectorKey) => {
      sectorsMap[sectorKey].dimensions = Object.keys(
        sectorsMap[sectorKey].dimensionsMap
      ).map((dimensionKey) => {
        return sectorsMap[sectorKey].dimensionsMap[dimensionKey]
      })
      delete sectorsMap[sectorKey].dimensionsMap
      return sectorsMap[sectorKey]
    })
  }

  const loadSectors = (survey) => {
    const factors = get(survey, 'diagnostic.factors', [])
    const sectorsMap = factors.reduce((acum, val) => {
      if (val.include) {
        addFactor(acum, val, survey)
      }

      return acum
    }, {})

    const newSectors = updateSectors(mapSectors(sectorsMap))

    setSectors(_.sortBy(newSectors, 'order'))
  }

  const previousFactor = () => {
    const { found, sectorIndex, factorIndex, dimensionIndex } =
      getPreviousFactor()

    if (found) {
      setSectorIndex(sectorIndex)
      setFactorIndex(factorIndex)
      setDimensionIndex(dimensionIndex)
    }
  }

  const getPreviousFactor = () => {
    const sectorSubStep = surveyNavigation.getCurrentStepSectorSubStep()

    let found = false
    let factor
    let previousDimension = dimensionIndex
    let factorIdx = factorIndex
    let dimensionIdx = dimensionIndex
    let sectorIdx = sectorIndex

    while (!found) {
      factorIdx--
      if (factorIdx < 0) {
        dimensionIdx--
      }
      if (dimensionIdx < 0) {
        if (sectorSubStep === 'otherfactors') {
          if (sectorIdx - 1 >= 0) {
            sectorIdx--
          } else {
            sectorIdx = sectors.length - 1
          }
          dimensionIdx = sectors[sectorIdx].dimensions.length - 1
          factorIdx = sectors[sectorIdx].dimensions[dimensionIdx].length - 1
          continue
        } else {
          dimensionIdx = sectors[sectorIdx].dimensions.length - 1
        }
      }

      const dimension = sectors[sectorIdx].dimensions[dimensionIdx]

      if (dimensionIdx !== previousDimension) {
        factorIdx = dimension.factors.length - 1
        previousDimension = dimensionIdx
      }

      factor = dimension.factors[factorIdx]
      if (factor.highlighted && sectorSubStep !== 'otherfactors') {
        found = true
      } else if (
        sectorSubStep === 'otherfactors' &&
        factorMap[factor._id] &&
        factorMap[factor._id].onClick
      ) {
        found = true
      }
    }

    return {
      found,
      sectorIndex: sectorIdx,
      factorIndex: factorIdx,
      dimensionIndex: dimensionIdx,
    }
  }

  const nextFactor = () => {
    const { found, sectorIndex, factorIndex, dimensionIndex } = getNextFactor()

    if (found) {
      setSectorIndex(sectorIndex)
      setFactorIndex(factorIndex)
      setDimensionIndex(dimensionIndex)
    }
  }

  const getNextFactor = () => {
    const sectorSubStep = surveyNavigation.getCurrentStepSectorSubStep()

    let found = false
    let factor
    let factorIdx = factorIndex
    let dimensionIdx = dimensionIndex
    let sectorIdx = sectorIndex

    while (!found) {
      factorIdx++
      if (
        factorIdx >= sectors[sectorIdx].dimensions[dimensionIdx].factors.length
      ) {
        factorIdx = 0
        dimensionIdx++
      }
      if (dimensionIdx >= sectors[sectorIdx].dimensions.length) {
        dimensionIdx = 0

        if (sectorSubStep === 'otherfactors') {
          if (sectorIdx + 1 < sectors.length) {
            sectorIdx++
          } else {
            sectorIdx = 0
          }
          dimensionIdx = 0
          factorIdx = -1
          continue
        }
      }

      factor = sectors[sectorIdx].dimensions[dimensionIdx].factors[factorIdx]
      if (factor.highlighted && sectorSubStep !== 'otherfactors') {
        found = true
      } else if (
        sectorSubStep === 'otherfactors' &&
        factorMap[factor._id] &&
        factorMap[factor._id].onClick
      ) {
        found = true
      }
    }

    return {
      found,
      sectorIndex: sectorIdx,
      factorIndex: factorIdx,
      dimensionIndex: dimensionIdx,
    }
  }

  const updateSurveyNavigationState = () => {
    // Value is not important, we just need to force an update
    setNavigationUpdate(Math.random())
  }

  const setupSurveyNavigation = () => {
    const surveyNavigation = new SurveyNavigation(
      updateSurveyNavigationState,
      completeSurvey,
      setupNewSectorAfterNavigation,
      saveLatestFulfilledStep,
      sectors,
      survey
    )
    setSurveyNavigation(surveyNavigation)
  }

  const saveImportance = (factorImportance) => {
    const payload = {
      survey: survey._id,
      shortSurvey: true,
      factorImportance,
    }

    submitSurvey(payload)
  }

  const saveCoverage = (coverage) => {
    const payload = {
      survey: survey._id,
      shortSurvey: true,
      coverage,
    }

    submitSurvey(payload)
  }

  const saveCoverageAnswers = (coverageAnswers) => {
    const payload = {
      survey: props.survey._id,
      shortSurvey: true,
      coverageAnswers,
    }

    submitSurvey(payload)
  }

  const saveBackgroundQuestions = (backgroundQuestions) => {
    const payload = {
      survey: props.survey._id,
      shortSurvey: true,
      backgroundQuestions,
    }
    submitSurvey(payload)
  }

  const saveSectorRating = (value, hold) => {
    const newSectors = sectors.slice()
    let factor =
      newSectors[sectorIndex].dimensions[dimensionIndex].factors[factorIndex]

    newSectors[sectorIndex].dimensions[dimensionIndex].factors[factorIndex] =
      Object.assign({}, factor, value)

    factor =
      newSectors[sectorIndex].dimensions[dimensionIndex].factors[factorIndex]

    setSectors(_.sortBy(newSectors, 'order'))

    if (!hold) {
      const payload = {
        survey: survey._id,
        shortSurvey: true,
        factor: {
          _id: factor._id,
          rating: parseInt(factor.rating, 10),
          importance: parseInt(factor.importance, 10),
          compare: factor.compare
            ? factor.compare.map((compare) => ({
                comparator: compare.comparator,
                rating: parseInt(compare.rating, 10),
              }))
            : null,
          timeToComplete: factor.timeToComplete,
        },
      }

      submitSurvey(payload)
    }
  }

  const saveAdditionalFactors = () => {
    const additionalFactors = Object.keys(factorMap).reduce((acum, key) => {
      if (factorMap[key].active) {
        acum.push(key)
      }

      return acum
    }, [])

    const payload = {
      survey: survey._id,
      shortSurvey: true,
      additionalFactors,
    }

    submitSurvey(payload)
  }

  const saveLatestFulfilledStep = (step, additionalData) => {
    const payload = {
      survey: props.survey._id,
      shortSurvey: true,
      latestFulfilledStep: step,
      ...additionalData,
    }

    submitSurvey(payload)
  }

  const completeSurvey = () => {
    const payload = {
      survey: survey._id,
      completed: true,
    }

    delete window.localStorage[survey._id]
    setCompleted(true)
    submitSurvey(payload)
    goTo('/')
  }

  if (!progressLoaded || !surveyNavigation) {
    return (
      <div className='surveyV2Component'>
        <Loader active inline='centered' />
      </div>
    )
  }

  let acceptedTos = false
  if (surveyResponse.result && surveyResponse.result.data) {
    acceptedTos = surveyResponse.result.data.acceptedTos
  }

  return (
    <div className='surveyV2Component'>
      <div className='parent'>
        <Breadcrumb
          nodes={[
            { label: 'Dashboard', route: RoutePaths.Home },
            { label: 'Survey', route: RoutePaths.Survey },
          ]}
        />

        <Header as='h1'>{survey.title}</Header>

        <div className='actionsMenu'>
          <Button
            padded
            color='black'
            size='huge'
            onClick={() => goTo('/surveys')}
          >
            Exit Survey
          </Button>
        </div>
      </div>

      {surveyNavigation.checkIfCurrentStepShouldShowTaskline() && (
        <Taskline surveyNavigation={surveyNavigation} />
      )}

      {surveyNavigation.getCurrentStepId() === 'welcome' && (
        <TimeTracker surveyNavigation={surveyNavigation} field='tosTime'>
          <Welcome
            surveyNavigation={surveyNavigation}
            acceptedTos={acceptedTos}
            dueDate={survey.dueDate}
          />
        </TimeTracker>
      )}

      {surveyNavigation.getCurrentStepId() === 'background' && (
        <BackgroundQuestions
          surveyNavigation={surveyNavigation}
          saveBackgroundQuestions={saveBackgroundQuestions}
        />
      )}

      {surveyNavigation.getCurrentStepId() === 'introduction' && (
        <TimeTracker
          surveyNavigation={surveyNavigation}
          field='introductionTime'
        >
          <Introduction surveyNavigation={surveyNavigation} />
        </TimeTracker>
      )}

      {surveyNavigation.getCurrentStepId() ===
        'product-and-disease-information' && (
        <TimeTracker surveyNavigation={surveyNavigation} field='pddTime'>
          <TPP
            surveyNavigation={surveyNavigation}
            standalone={false}
            surveyId={survey._id}
          />
        </TimeTracker>
      )}

      {surveyNavigation.checkIfSector() &&
        surveyNavigation.getCurrentStepSectorSubStep() !== 'coverage' && (
          <TimeTracker surveyNavigation={surveyNavigation} field='summaryTime'>
            <Sector
              surveyNavigation={surveyNavigation}
              onSave={saveSectorRating}
              saveAdditionalFactors={saveAdditionalFactors}
              saveSummaryTime={() => {}}
              sectors={sectors}
              sector={sectors[sectorIndex]}
              sectorIndex={sectorIndex}
              dimension={dimensionIndex}
              activeFactor={factorIndex}
              setActiveFactor={({
                sectorIndex,
                dimensionIndex,
                factorIndex,
              }) => {
                setSectorIndex(sectorIndex)
                setDimensionIndex(dimensionIndex)
                setFactorIndex(factorIndex)
              }}
              factorMap={factorMap}
              updateFactorMap={(newFactorMap) => setFactorMap(newFactorMap)}
              survey={survey}
              nextFactor={nextFactor}
              previousFactor={previousFactor}
            />
          </TimeTracker>
        )}

      {surveyNavigation.getCurrentStepId() === 'importance' && (
        <TimeTracker surveyNavigation={surveyNavigation} field='importanceTime'>
          <Importance
            surveyNavigation={surveyNavigation}
            sectors={sectors}
            saveImportance={saveImportance}
          />
        </TimeTracker>
      )}

      {surveyNavigation.checkIfSector() &&
        surveyNavigation.getCurrentStepSectorSubStep() === 'coverage' && (
          <AdditionalCoverage
            surveyNavigation={surveyNavigation}
            saveCoverage={saveCoverage}
            saveCoverageAnswers={saveCoverageAnswers}
          />
        )}

      {surveyNavigation.getCurrentStepId() === 'completion' && (
        <TimeTracker surveyNavigation={surveyNavigation} field='submitTime'>
          <Completion surveyNavigation={surveyNavigation} />
        </TimeTracker>
      )}
    </div>
  )
}

const mapStateToProps = ({
  tokenValidation,
  survey,
  surveyResponse,
  assets,
}) => ({
  user: get(tokenValidation, 'result.data', {}),
  survey: get(survey, 'result.data'),
  surveyResponse,
  surveyAssetsRequest: assets,
})

const mapDispatchToProps = (dispatch) => ({
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
  getSurveyDetails: (id) =>
    dispatch({ type: 'FETCH_SURVEY_DETAILS_REQUESTED', id }),
  getSurveyResponse: (id) =>
    dispatch({ type: 'FETCH_SURVEY_RESPONSE_REQUESTED', id }),
  submitSurvey: (payload) =>
    dispatch({ type: 'SUBMIT_SURVEY_REQUESTED', payload }),
  hideLogo: () => dispatch({ type: 'HIDE_LOGO' }),
  showLogo: () => dispatch({ type: 'SHOW_LOGO' }),
  getSurveyAssets: (objectId) =>
    dispatch({ type: 'FETCH_ASSETS_REQUESTED', objectId, assetType: 'survey' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Surveys)
