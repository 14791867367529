import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import AdminDashboard from './adminDashboard'
import ViewerDashboard from './viewerDashboard'

import './dashboard.scss'

class Dashboard extends Component {
  render() {
    const { user, location, match } = this.props

    const showAdmin =
      user.type === 'A' || user.type === 'I' || user.type === 'C'

    return (
      <div className='dashboardComponent'>
        {showAdmin && <AdminDashboard location={location} match={match} />}
        {!showAdmin && <ViewerDashboard location={location} match={match} />}
      </div>
    )
  }
}

const mapStateToProps = ({ tokenValidation }) => ({
  user: get(tokenValidation, 'result.data')
})

export default connect(mapStateToProps)(Dashboard)
