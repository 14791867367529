import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Dropdown, TextArea } from 'semantic-ui-react'
import get from 'lodash/get'

import { getArray } from '../../lib/utils'
import EmailList from './emailList'
import SurveySender from './surveySender'
import VariableManager from '../variable-manager'

class Send extends Component {
  state = {
    showEmailListModal: false,
    editEmailList: null,
    showSurveySenderModal: false,
    editSender: null,
  }

  render() {
    const {
      emailLists,
      surveySenders,
      survey,
      onValueChange,
      email,
      emailList,
      sender,
      emailTemplate,
    } = this.props
    let { subject, message } = this.props

    if (!subject) {
      subject = '[DISEASE] VOLT Payer Survey'

      onValueChange({ field: 'subject', value: subject })
    }

    if (!message && emailTemplate) {
      message = emailTemplate.value

      onValueChange({ field: 'message', value: message })
    }

    const errors = {}
    if (
      survey.error &&
      survey.result.response &&
      survey.result.response.data.validation
    ) {
      survey.result.response.data.validation.keys.forEach(
        (key) => (errors[key] = true)
      )
    }

    return (
      <div>
        <EmailList
          onClose={() => this.setState({ showEmailListModal: false })}
          show={this.state.showEmailListModal}
          list={this.state.editEmailList}
        />
        <SurveySender
          onClose={() => this.setState({ showSurveySenderModal: false })}
          show={this.state.showSurveySenderModal}
          sender={this.state.editSender}
        />

        <Form>
          <Form.Field>
            <label>SEND TO AN INDIVIDUAL RESPONDENT</label>
            <Input
              placeholder='Email Address'
              name='email'
              value={email}
              onChange={(e, data) =>
                onValueChange({ field: 'email', value: data.value })
              }
              error={errors.email}
            />
          </Form.Field>
          <Form.Field>
            <label>SEND TO MULTIPLE RESPONDENTS</label>
            <Dropdown
              placeholder='Select an email list'
              fluid
              search
              selection
              onChange={(e, data) =>
                onValueChange({ field: 'emailList', value: data.value })
              }
              value={emailList}
              error={errors.emailList}
              options={emailLists.map((item) => ({
                key: item._id,
                value: item._id,
                text: (
                  <span>
                    {item.name} |{' '}
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.setState({
                          showEmailListModal: true,
                          editEmailList: item,
                        })
                      }
                    >
                      Edit
                    </a>
                  </span>
                ),
              }))}
            />
            <br />
            <a
              style={{ cursor: 'pointer' }}
              onClick={() =>
                this.setState({ showEmailListModal: true, editEmailList: null })
              }
            >
              CREATE NEW EMAIL LIST
            </a>
          </Form.Field>
          <Form.Field>
            <label>SURVEY SENDER</label>
            <Dropdown
              placeholder='Select a sender'
              fluid
              search
              selection
              onChange={(e, data) =>
                onValueChange({ field: 'sender', value: data.value })
              }
              value={sender}
              error={errors.sender}
              options={surveySenders.map((item) => ({
                key: item._id,
                value: item._id,
                text: (
                  <span>
                    {item.name} |{' '}
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.setState({
                          showSurveySenderModal: true,
                          editSender: item,
                        })
                      }
                    >
                      Edit
                    </a>
                  </span>
                ),
              }))}
            />
            <br />
            <a
              style={{ cursor: 'pointer' }}
              onClick={() =>
                this.setState({ showSurveySenderModal: true, editSender: null })
              }
            >
              CREATE NEW SENDER
            </a>
          </Form.Field>
          <Form.Field>
            <label>SUBJECT</label>
            <Input
              placeholder='Subject'
              name='subject'
              value={subject}
              onChange={(e, data) =>
                onValueChange({ field: 'subject', value: data.value })
              }
              error={errors.subject}
            />
          </Form.Field>
          <VariableManager
            variables={['[FIRST NAME]', '[LINK]', '[DUE DATE]']}
            onAddVariable={(variable) =>
              onValueChange({
                field: 'message',
                value:
                  message.slice(0, this.surveyTxtArea.selectionStart) +
                  variable +
                  message.slice(this.surveyTxtArea.selectionStart),
              })
            }
          />
          <Form.Field>
            <TextArea
              placeholder='Message'
              style={{ minHeight: 150 }}
              autoHeight
              name='message'
              value={message}
              error={errors.message}
              onChange={(e, data) =>
                onValueChange({ field: 'message', value: data.value })
              }
              ref={(elem) => (this.surveyTxtArea = elem && elem.ref)}
            />
          </Form.Field>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = ({ appSettings, survey, emailList, surveySender }) => ({
  emailTemplate: get(appSettings, 'result.data', []).find(
    (val) => val.key === 'surveyEmailTemplate'
  ),
  survey,
  emailLists: getArray(emailList.result, 'result.data', []),
  surveySenders: getArray(surveySender, 'result.data', []),
})

export default connect(mapStateToProps)(Send)
