const defaultState = null

export default function counter(state = defaultState, action) {
  const { payload } = action

  switch (action.type) {
    case 'SEND_NOTIFICATION':
      return {
        ...payload
      }
    case 'RESET_NOTIFICATION':
      return defaultState
    default:
      return state
  }
}
