import React from 'react'
import { Loader, Message } from 'semantic-ui-react'

const ExpiringLoader = ({ items, message, children }) => {
  if (!items) {
    return <Loader active inline='centered' />
  }

  if (!items.length) {
    return <Message>{message}</Message>
  }

  return <>{children}</>
}

export default ExpiringLoader
