import React from 'react'
import FactorDragSource from './FactorDragSource'
import FactorDragTarget from './FactorDragTarget'

const Factor = ({ factor, onClick, onFactorDrag, onIncludeChange }) => (
  <FactorDragTarget factor={factor} onFactorDrag={onFactorDrag}>
    <FactorDragSource
      factor={factor}
      onClick={onClick}
      onIncludeChange={onIncludeChange}
    />
  </FactorDragTarget>
)

export default Factor
