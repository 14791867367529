import React from 'react'
import { Header, Icon } from 'semantic-ui-react'

import './styles.scss'

export default () => (
  <div className='notFound'>
    <Header as='h1' icon textAlign='center'>
      <Icon name='question circle' circular />
      <Header.Content>Page not found</Header.Content>
    </Header>
    <a href='/'>Go Back</a>
  </div>
)
