import React from 'react'
import { connect } from 'react-redux'

import madeByPsLogo from '../../assets/made_by_ps.svg'

const Logo = ({ pslogo }) =>
  pslogo.logoVisible ? (
    <img src={madeByPsLogo} className='madeByPsLogo' alt='PayerSciences' />
  ) : null

const mapStateToProps = state => {
  return {
    pslogo: state.pslogo
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logo)
