import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../services/api';
import { handleUnauthorizedError } from './helpers/saga-helpers';
import { copyToClipboard } from '../../helpers/copy.helpers';

function* getUsers(action) {
  try {
    const result = yield call(api.getUsers, action.query);
    yield put({ type: 'FETCH_USERS_SUCCEEDED', payload: result });
  } catch (e) {
    yield put({ type: 'FETCH_USERS_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* getLoggedInUsers() {
  try {
    const result = yield call(api.getLoggedInUsers);
    yield put({ type: 'FETCH_LOGGED_IN_USERS_SUCCEEDED', payload: result });
  } catch (e) {
    yield put({ type: 'FETCH_LOGGED_IN_USERS_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* getUserDetails(action) {
  try {
    const result = yield call(api.getUserDetails, action.id);
    yield put({ type: 'FETCH_USER_DETAILS_SUCCEEDED', payload: result });
  } catch (e) {
    yield put({ type: 'FETCH_USER_DETAILS_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* getUserByToken(action) {
  try {
    const result = yield call(api.getUserByToken, action.token);
    yield put({ type: 'FETCH_USER_BY_TOKEN_SUCCEEDED', payload: result });
  } catch (e) {
    yield put({ type: 'FETCH_USER_BY_TOKEN_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* createUser(action) {
  try {
    const result = yield call(api.createUser, action.payload);
    yield put({ type: 'CREATE_USER_SUCCEEDED', payload: result });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'User created successfully',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'CREATE_USER_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* createUsers(action) {
  try {
    const result = yield call(api.createUsers, action.payload);
    yield put({ type: 'CREATE_USERS_SUCCEEDED', payload: result });
  } catch (e) {
    yield put({ type: 'CREATE_USER_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* createGroupUser(action) {
  try {
    const result = yield call(api.createUser, action.payload);
    yield put({ type: 'CREATE_USER_SUCCEEDED', payload: result });
    yield put({ type: 'FETCH_GROUPS_REQUESTED' });
  } catch (e) {
    yield put({ type: 'CREATE_USER_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* updateUser(action) {
  try {
    const result = yield call(api.updateUser, action.payload, action.id);
    yield put({ type: 'UPDATE_USER_SUCCEEDED', payload: result });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'User updated successfully',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'UPDATE_USER_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* deleteUser(action) {
  yield updateUser({
    ...action,
    payload: {
      deleted: true
    }
  });
}

function* forceLogOutUser(action) {
  try {
    const result = yield call(api.forceLogOutUser, action.userId);
    yield put({ type: 'FORCE_LOG_OUT_USER_SUCCEEDED', payload: result });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'User logged out successfully',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'FORCE_LOG_OUT_USER_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* inviteUser(action) {
  try {
    const result = yield call(api.inviteUser, action.userId);
    yield put({ type: 'INVITE_USER_SUCCEEDED', payload: result });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'User was invited',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'INVITE_USER_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* getResetLink(action) {
  try {
    const result = yield call(api.getResetLink, action.userId);
    yield put({ type: 'GET_RESET_LINK_SUCCEEDED', payload: result });

    if (result.data && result.data.resetLink) {
      copyToClipboard(result.data.resetLink);
      yield put({
        type: 'SEND_NOTIFICATION',
        payload: {
          message: 'Copied reset link to clipboard',
          success: true
        }
      });
    }
  } catch (e) {
    yield put({ type: 'GET_RESET_LINK_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* getAppMaintenance() {
  try {
    const result = yield call(api.getAppMaintenance);
    yield put({ type: 'FETCH_APP_MAINTENANCE_SUCCEEDED', payload: result });
  } catch (e) {
    yield put({ type: 'FETCH_APP_MAINTENANCE_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* getAppSettings() {
  try {
    const result = yield call(api.getAppSettings);
    yield put({ type: 'FETCH_APP_SETTINGS_SUCCEEDED', payload: result });
  } catch (e) {
    yield put({ type: 'FETCH_APP_SETTINGS_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* activateAppMaintenance(action) {
  try {
    const result = yield call(api.activateAppMaintenance, action.payload);
    yield put({ type: 'ACTIVATE_APP_MAINTENANCE_SUCCEEDED', payload: result });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'App maintenance is active',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'ACTIVATE_APP_MAINTENANCE_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* deactivateAppMaintenance(action) {
  try {
    yield call(api.deactivateAppMaintenance, action.payload);
    yield put({ type: 'DEACTIVATE_APP_MAINTENANCE_SUCCEEDED' });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'App maintenance is inactive',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'DEACTIVATE_APP_MAINTENANCE_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* getEmailTemplates() {
  try {
    const result = yield call(api.getEmailTemplates);
    yield put({ type: 'FETCH_EMAIL_TEMPLATES_SUCCEEDED', payload: result });
  } catch (e) {
    yield put({ type: 'FETCH_EMAIL_TEMPLATES_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* updateEmailTemplates(action) {
  try {
    yield call(api.updateEmailTemplates, action.payload);
    yield put({ type: 'UPDATE_EMAIL_TEMPLATES_SUCCEEDED' });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Email templates were updated',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'UPDATE_EMAIL_TEMPLATES_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* updateDiagnosticRelationNames(action) {
  try {
    const { wheel, inventory, diagnostic } = action.payload;
    yield call(api.updateWheel, wheel, wheel._id);
    const newInventory = {
      ...inventory
    };
    const inventoryId = inventory._id;
    delete newInventory._id;
    yield call(api.updateInventory, newInventory, inventoryId);
    const newDiagnostic = {
      ...diagnostic
    };
    const diagnosticId = diagnostic._id;
    delete newDiagnostic._id;
    yield call(api.updateDiagnostic, newDiagnostic, diagnosticId);
    yield put({ type: 'UPDATE_DIAGNOSTIC_RELATION_SUCCEEDED' });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Renamed successfully',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'UPDATE_DIAGNOSTIC_RELATION_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* deleteWheel(action) {
  try {
    const result = yield call(api.deleteWheel, action.id);
    yield put({ type: 'DELETE_WHEEL_SUCCEEDED', payload: result });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Wheel was deleted',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'DELETE_WHEEL_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* admin() {
  yield takeLatest('FETCH_USERS_REQUESTED', getUsers);
  yield takeLatest('FETCH_LOGGED_IN_USERS_REQUESTED', getLoggedInUsers);
  yield takeLatest('FETCH_USER_DETAILS_REQUESTED', getUserDetails);
  yield takeLatest('FETCH_USER_BY_TOKEN_REQUESTED', getUserByToken);
  yield takeLatest('CREATE_USER_REQUESTED', createUser);
  yield takeLatest('CREATE_USERS_REQUESTED', createUsers);
  yield takeLatest('CREATE_GROUP_USER_REQUESTED', createGroupUser);
  yield takeLatest('UPDATE_USER_REQUESTED', updateUser);
  yield takeLatest('DELETE_USER_REQUESTED', deleteUser);
  yield takeLatest('FORCE_LOG_OUT_USER_REQUESTED', forceLogOutUser);
  yield takeLatest('INVITE_USER_REQUESTED', inviteUser);
  yield takeLatest('GET_RESET_LINK_REQUESTED', getResetLink);
  yield takeLatest('FETCH_APP_MAINTENANCE_REQUESTED', getAppMaintenance);
  yield takeLatest('FETCH_APP_SETTINGS_REQUESTED', getAppSettings);
  yield takeLatest(
    'ACTIVATE_APP_MAINTENANCE_REQUESTED',
    activateAppMaintenance
  );
  yield takeLatest(
    'DEACTIVATE_APP_MAINTENANCE_REQUESTED',
    deactivateAppMaintenance
  );
  yield takeLatest('FETCH_EMAIL_TEMPLATES_REQUESTED', getEmailTemplates);
  yield takeLatest('UPDATE_EMAIL_TEMPLATES_REQUESTED', updateEmailTemplates);
  yield takeLatest(
    'UPDATE_DIAGNOSTIC_RELATION_REQUESTED',
    updateDiagnosticRelationNames
  );
  yield takeLatest('DELETE_WHEEL_REQUESTED', deleteWheel);
}

export default admin;
