import { Menu, Tab, Header } from 'semantic-ui-react'

import { RoutePaths } from '../../_routing/route-paths'
import { Breadcrumb } from '../../page-layout/breadcrumb/Breadcrumb'

import './CreateDiagnostic.scss'
import DiagnosticSettings from '../DiagnosticSettings'

const tabSettings = { secondary: true, pointing: true }

const Content = ({ children }) => (
  <div className='createDiagnostic'>{children}</div>
)

const CreateDiagnostic = () => (
  <Content>
    <Breadcrumb
      nodes={[
        { label: 'Dashboard', route: RoutePaths.Home },
        { label: 'Diagnostics', route: RoutePaths.Diagnostics },
      ]}
    />
    <Header as='h1'>New Diagnostic</Header>
    <Tab
      menu={tabSettings}
      panes={[
        {
          menuItem: <Menu.Item key='settings'>Settings</Menu.Item>,
          render: () => <DiagnosticSettings isNewDiagnostic />,
        },
      ]}
    />
  </Content>
)

export default CreateDiagnostic
