import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'semantic-ui-react'
import png from 'save-svg-as-png'
import { get } from 'lodash'

import './styles.scss'
import Wheel from '../wheel'
import Button from '../common/Button'
import {
  filterParitiesAndMidpoint,
  loadSectors,
  getComparators
} from './previewUtils'

import DownloadImageIcon from '../../assets/volt_download_image.png'
import FavorabilityLegend from './FavorabilityLegend'

class Favorability extends Component {
  state = {
    sectors: [],
    comparatorFilter: null,
    viewFilter: '',
    surveySelectOptions: [],
    allComparatorsFromInventory: null
  }

  componentDidMount() {
    this.createSurveySelectionOptions()
    this.getAllComparatorsFromInventory()
    this.initSurvey()
  }

  componentDidUpdate(prevProps) {
    const { survey } = this.props

    if (prevProps.survey._id !== survey._id) {
      this.initSurvey()
    }

    if (survey.type === 'external') {
      this.loadSectorsForExternalSurvey(prevProps)
    }
  }

  initSurvey() {
    const { survey, getSurveyResponsesSummary } = this.props

    if (survey.type === 'external') {
      getSurveyResponsesSummary(survey._id)
      this.loadSectorsForExternalSurvey({})
    } else if (survey.type === 'internal') {
      this.loadSectorsForInternalSurvey({})
    }
  }

  loadSectorsForExternalSurvey(prevProps) {
    const { survey, surveyResponsesSummary } = this.props

    if (
      survey &&
      survey.diagnostic &&
      Array.isArray(surveyResponsesSummary) &&
      (survey !== prevProps.survey ||
        surveyResponsesSummary !== prevProps.surveyResponsesSummary)
    ) {
      const externalSurvey = {
        ...survey,
        factors: survey.diagnostic.factors.filter(
          factor => survey.factors[factor._id._id]
        )
      }
      const sectors = loadSectors(
        this.filterIncludedFactors(externalSurvey),
        surveyResponsesSummary
      )
      const comparators = getComparators(survey)

      this.setState({
        sectors,
        comparatorFilter: comparators[0]
      })
    }
  }

  filterIncludedFactors(survey) {
    return {
      ...survey,
      factors: survey.factors.filter(
        factor => factor.include && factor._id && factor._id.include
      )
    }
  }

  loadSectorsForInternalSurvey() {
    const { survey } = this.props

    const sectors = loadSectors(this.filterIncludedFactors(survey))
    const comparators = getComparators(survey)

    this.setState({
      sectors,
      comparatorFilter: comparators[0]
    })
  }

  getNameOfTheComparatorById(comparatorId) {
    const { allComparatorsFromInventory } = this.state

    return (
      comparatorId &&
      allComparatorsFromInventory &&
      get(
        allComparatorsFromInventory.find(
          comparator => comparator._id === comparatorId
        ),
        'name',
        'N/A'
      )
    )
  }

  createSurveySelectionOptions() {
    const { surveys, diagnostic } = this.props

    const options = [
      { text: 'Diagnostic', key: 'diagnostic', value: diagnostic._id }
    ]

    surveys.forEach(survey => {
      options.push({
        text: survey.title,
        key: survey._id,
        value: survey._id
      })
    })

    this.setState({ surveySelectOptions: options })
  }

  getComparatorFilterOptions() {
    const { survey } = this.props
    const comparators = getComparators(survey)
    const comparatorFilterOptions = []

    if (comparators.length > 0) {
      comparators.forEach(comparatorId => {
        comparatorFilterOptions.push({
          text: this.getNameOfTheComparatorById(comparatorId),
          key: comparatorId,
          value: comparatorId
        })
      })
    }

    return comparatorFilterOptions
  }

  getAllComparatorsFromInventory() {
    const { diagnostic } = this.props

    this.setState({
      allComparatorsFromInventory: diagnostic.inventory.comparators
    })
  }

  getViewFilterMeta(sectors) {
    const { comparatorFilter } = this.state
    let hasAdvantages = false
    let hasDisadvantages = false
    let hasParities = false
    for (const sector of sectors) {
      for (const dimension of sector.dimensions) {
        for (const factor of dimension.factors) {
          if (!sector.compare) {
            if (factor.score > 3) {
              hasAdvantages = true
            } else {
              hasDisadvantages = true
            }
          } else {
            const comparators = get(factor, 'compareScore', [])
            const comparatorValues = Object.values(comparators)
            let avg = 0
            if (comparatorValues.length) {
              if (comparatorFilter) {
                if (comparators[comparatorFilter]) {
                  avg = comparators[comparatorFilter]
                } else {
                  avg = 0
                }
              } else {
                avg = Math.floor(
                  comparatorValues.reduce((sum, val) => sum + val, 0) /
                    comparatorValues.length
                )
              }
            }
            if (factor.score > avg) {
              hasAdvantages = true
            } else if (factor.score < avg) {
              hasDisadvantages = true
            } else if (factor.score === avg && factor.score) {
              hasParities = true
            }
          }
        }
      }
    }

    return { hasAdvantages, hasDisadvantages, hasParities }
  }

  getViewFilterOptions(sectors) {
    const {
      hasAdvantages,
      hasDisadvantages,
      hasParities
    } = this.getViewFilterMeta(sectors)
    return [
      { text: 'All Ratings', key: '', value: '' },
      {
        text: 'Advantages',
        key: 'advantages',
        value: 'advantages',
        disabled: !hasAdvantages
      },
      {
        text: 'Disadvantages',
        key: 'disadvantages',
        value: 'disadvantages',
        disabled: !hasDisadvantages
      },
      {
        text: 'Parities',
        key: 'parities',
        value: 'parities',
        disabled: !hasParities
      },
      {
        text: 'Sector Review',
        key: 'detailed',
        value: 'detailed'
      },
      { text: 'Summary', key: 'summaries', value: 'summaries' }
    ]
  }

  onSvgChange = el => {
    this.wheel = el
  }

  exportAsPng = () => {
    const { survey } = this.props

    png.saveSvgAsPng(this.wheel, `${survey.title}.png`, {
      backgroundColor: '#FFFFFF'
    })
  }

  render() {
    const {
      viewFilter,
      comparatorFilter,
      sectors,
      surveySelectOptions
    } = this.state
    const { survey, onSurveySelected, diagnostic } = this.props

    const comparatorFilterOptions = this.getComparatorFilterOptions()
    const filteredSectors = filterParitiesAndMidpoint(
      sectors,
      viewFilter,
      comparatorFilter
    )
    const viewFilterOptions = this.getViewFilterOptions(filteredSectors)
    return (
      <div className='voltFavorability'>
        <div className='voltFavorabilityNavigation'>
          <Form>
            <div className='voltFavorabilityNavigationItem'>
              <Form.Select
                label='Survey'
                value={survey._id}
                options={surveySelectOptions}
                onChange={(e, data) => onSurveySelected(data.value)}
              />
            </div>
            <div className='voltFavorabilityNavigationItem'>
              <Form.Select
                label='Comparator'
                placeholder='All'
                value={comparatorFilter}
                options={comparatorFilterOptions}
                onChange={(e, data) => {
                  if (comparatorFilter !== data.value) {
                    this.setState({ comparatorFilter: data.value })
                  }
                }}
              />
            </div>
            <div className='voltFavorabilityNavigationItem'>
              <Form.Select
                label='View'
                placeholder='All Ratings'
                options={viewFilterOptions}
                onChange={(e, data) => {
                  if (viewFilter !== data.value) {
                    this.setState({ viewFilter: data.value })
                  }
                }}
              />
            </div>
          </Form>
          <div className='voltFavorabilityNavigationItem'>
            <Button
              onClick={this.exportAsPng}
              className='voltFavorabilityDownloadImage'
              size='small'
              rounded
              shadowed
            >
              Download Image{' '}
              <img
                alt=''
                src={DownloadImageIcon}
                className='voltFavorabilityDownloadImageIcon'
              />
            </Button>
          </div>
        </div>

        <FavorabilityLegend
          comparator={this.getNameOfTheComparatorById(comparatorFilter)}
        />

        <div className='voltPreviewWheelWrapper'>
          <Wheel
            sectors={filteredSectors}
            compare={comparatorFilter}
            filter={viewFilter}
            type={survey.type}
            onSvgChange={this.onSvgChange}
            inventory={get(diagnostic, 'inventory')}
            preview
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ surveyResponsesSummary }) => ({
  surveyResponsesSummary:
    surveyResponsesSummary.result &&
    Array.isArray(surveyResponsesSummary.result.data)
      ? surveyResponsesSummary.result.data
      : []
})

const mapDispatchToProps = dispatch => ({
  goTo: url => dispatch({ type: 'REDIRECT', url }),
  getSurveyResponsesSummary: id =>
    dispatch({ type: 'FETCH_SURVEY_RESPONSES_SUMMARY_REQUESTED', id })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Favorability)
