import React from 'react'
import { DropTarget } from 'react-dnd'

const factorTarget = {
  drop(props, monitor) {
    const { factor } = monitor.getItem()
    const { factor: targetFactor, onFactorDrag } = props
    onFactorDrag(factor, targetFactor)
  }
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}

const FactorDragTarget = ({ connectDropTarget, isOver, children }) => {
  let className = ''

  if (isOver) {
    className = 'hover'
  }

  return connectDropTarget(
    <div className={`factorDragTarget ${className}`}>{children}</div>
  )
}

const DragEnd = DropTarget('factor', factorTarget, collectTarget)(
  FactorDragTarget
)

export default DragEnd
