import { call, put, takeLatest } from 'redux-saga/effects'
import api from '../../services/groupsApi'
import { handleError, handleSuccess } from './helpers/saga-helpers'

function* getGroups() {
  try {
    const result = yield call(api.getGroups)
    yield put({ type: 'FETCH_GROUPS_SUCCEEDED', payload: result })
  } catch (e) {
    yield handleError({ error: e, type: 'FETCH_GROUPS_FAILED' })
  }
}

function* createGroup(action) {
  try {
    yield call(api.createGroup, action.payload)
    const result = yield call(api.getGroups)
    yield handleSuccess('CREATE_GROUP_SUCCEEDED', result, 'Group created!')
  } catch (e) {
    yield handleError({ error: e, type: 'CREATE_GROUP_FAILED' })
  }
}

function* updateGroup(action) {
  try {
    yield call(api.updateGroup, action.payload, action.id)
    const result = yield call(api.getGroups)
    yield handleSuccess('UPDATE_GROUP_SUCCEEDED', result, 'Group updated!')
  } catch (e) {
    yield handleError({ error: e, type: 'UPDATE_GROUP_FAILED' })
  }
}

function* deleteGroup(action) {
  try {
    yield call(api.deleteGroup, action.id)
    const result = yield call(api.getGroups)
    yield handleSuccess('DELETE_GROUP_SUCCEEDED', result, 'Group deleted!')
  } catch (e) {
    yield handleError({ error: e, type: 'DELETE_GROUP_FAILED' })
  }
}

function* addUserToGroup(action) {
  try {
    const result = yield call(
      api.addUserToGroup,
      action.userIds,
      action.groupId
    )
    yield handleSuccess(
      'ADD_USER_GROUP_SUCCEEDED',
      result,
      'User added to group!'
    )
    yield put({ type: 'FETCH_GROUPS_REQUESTED' })
  } catch (e) {
    yield handleError({ error: e, type: 'ADD_USER_GROUP_FAILED' })
  }
}

function* deleteUserFromGroup(action) {
  try {
    const result = yield call(
      api.deleteUserFromGroup,
      action.userId,
      action.groupId
    )

    yield handleSuccess(
      'DELETE_USER_FROM_GROUP_SUCCEEDED',
      result,
      'User deleted from group!'
    )

    yield put({ type: 'FETCH_GROUPS_REQUESTED' })
  } catch (e) {
    yield handleError({ error: e, type: 'DELETE_USER_FROM_GROUP_FAILED' })
  }
}

function* archiveGroup(action) {
  yield call(updateGroup, { ...action, payload: { archived: true } })
}

function* restoreGroup(action) {
  yield call(updateGroup, { ...action, payload: { archived: false } })
}

function* refreshGroups(action) {
  yield call(getGroups, action)
}

function* groups() {
  yield takeLatest('UPDATE_USER_SUCCEEDED', refreshGroups)
  yield takeLatest('FETCH_GROUPS_REQUESTED', getGroups)
  yield takeLatest('CREATE_GROUP_REQUESTED', createGroup)
  yield takeLatest('UPDATE_GROUP_REQUESTED', updateGroup)
  yield takeLatest('DELETE_GROUP_REQUESTED', deleteGroup)
  yield takeLatest('ARCHIVE_GROUP_REQUESTED', archiveGroup)
  yield takeLatest('RESTORE_GROUP_REQUESTED', restoreGroup)
  yield takeLatest('ADD_USER_GROUP_REQUESTED', addUserToGroup)
  yield takeLatest('DELETE_USER_FROM_GROUP_REQUESTED', deleteUserFromGroup)
}

export default groups
