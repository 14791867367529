import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Header, Button } from 'semantic-ui-react'

import './styles.scss'

class AssetUploader extends Component {
  state = {
    fileError: false,
    orderError: false,
    order: 1
  }

  handleUpload = () => {
    const { showOrder, createAsset, type, objectId, additional } = this.props
    const { file, order } = this.state

    if (!file) {
      return this.setState({ fileError: true })
    }
    if (!order && showOrder) {
      return this.setState({ orderError: true })
    }

    createAsset(
      {
        objectId,
        order,
        type,
        additional
      },
      file
    )

    this.setState({
      fileError: false,
      orderError: false
    })
  }

  render() {
    const { fileError, orderError, order } = this.state
    const { assetsRequest, showOrder } = this.props
    const requesting = assetsRequest.requesting && assetsRequest.isCreate

    return (
      <div className='asset-uploader'>
        <Header as='h3'>Add new file</Header>
        <Form>
          <Form.Field>
            <Input
              placeholder='Image'
              name='image'
              type='file'
              error={fileError}
              size='small'
              onChange={e => this.setState({ file: e.target.files[0] })}
            />
          </Form.Field>
          {showOrder && (
            <Form.Field>
              <label>Order</label>
              <Input
                placeholder='Order'
                name='order'
                type='number'
                size='small'
                error={orderError}
                onChange={(e, data) => this.setState({ order: data.value })}
                value={order}
              />
            </Form.Field>
          )}
          <Button
            basic
            size='big'
            disabled={requesting}
            loading={requesting}
            onClick={() => this.handleUpload()}
          >
            Upload
          </Button>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = ({ assets }) => {
  return {
    assetsRequest: assets
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createAsset: (payload, file) =>
      dispatch({ type: 'CREATE_ASSET_REQUESTED', payload, file })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetUploader)
