import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Tab } from 'semantic-ui-react'

import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import moment from 'moment'

import TPP from '../tpp'
import EvaluateFactor from './EvaluateFactor'
import { getDefaultValueForFactor } from '../../admin-controls/sector-admin/config/defaultValuesForFactors'
import EntryList from '../../inventory/EntryList'
import { mapEntriesToFactorIds } from '../../inventory/inventoryUtils'

class Factor extends Component {
  state = {
    selectedComparator: null,
    showError: false,
    showSuccess: false,
    startTime: null
  }

  componentDidMount() {
    const { getEntries, survey } = this.props
    this.resetFactor()
    getEntries(_.get(survey, 'diagnostic.inventory._id'))
  }

  componentDidUpdate(prevProps) {
    const { factor, compare } = this.props
    let showError = false,
      showSuccess = false
    if (prevProps.factor !== factor) {
      if (!prevProps.factor || factor._id !== prevProps.factor._id) {
        this.resetFactor()
      }

      let shouldSave = true
      if (compare) {
        shouldSave = factor.compare.reduce((acum, compare) => {
          return acum && compare.rating && compare.rating !== '0'
        }, factor.rating && factor.rating !== -1)
      }

      const isDifferent =
        factor.rating !== prevProps.factor.rating ||
        factor.compare.length !== prevProps.factor.compare.length ||
        !Object.keys(factor.compare).reduce((acum, key) => {
          return (
            acum &&
            factor.compare[key].rating === prevProps.factor.compare[key].rating
          )
        }, true)

      if (shouldSave && isDifferent) {
        this.props.onSave(factor)
        showSuccess = true
        this.setState({ showError, showSuccess })
      } else if (isDifferent && !shouldSave) {
        showError = true
        this.setState({ showError, showSuccess })
      }
    }
  }

  resetFactor = () => {
    const { factor, onSave } = this.props
    this.setState({ showError: false, showSuccess: false, startTime: moment() })
    const isRated =
      factor.rating &&
      factor.rating !== -1 &&
      factor.rating !== '0' &&
      factor.compare.reduce((acum, compare) => {
        return acum && compare.rating && compare.rating !== '0'
      }, true)

    if (!isRated) {
      const newFactor = Object.assign({}, factor, {
        rating: -1,
        compare: factor.compare.map(val =>
          Object.assign({}, val, { rating: 0 })
        )
      })

      onSave(newFactor, true)
    }
  }

  handleFactorRating = (e, data) => {
    const { factor, onSave } = this.props
    const { startTime } = this.state

    const timeToComplete = factor.timeToComplete
      ? factor.timeToComplete
      : moment().diff(startTime)
    const payload = {
      rating: data.value
    }
    if (
      !factor.sector.compare ||
      (factor.sector.compare &&
        factor.compare.reduce((acum, val) => acum && val.rating > 0, true))
    ) {
      payload.timeToComplete = timeToComplete
    }

    onSave(payload, true)
  }

  handleFactorImportance = (e, data) => {
    this.props.onSave({
      importance: data.value
    })
  }

  changeComparator = (selected, value) => {
    const { factor } = this.props
    const { startTime } = this.state

    const compare = factor.compare.map(item => {
      if (selected.comparator !== item.comparator) return item
      return {
        comparator: item.comparator,
        rating: value.toString()
      }
    })

    const timeToComplete = factor.timeToComplete
      ? factor.timeToComplete
      : moment().diff(startTime)
    const payload = {
      compare
    }
    if (
      parseInt(factor.rating, 10) > 0 &&
      compare.reduce((acum, val) => acum && val.rating > 0, true)
    ) {
      payload.timeToComplete = timeToComplete
    }

    this.props.onSave(payload, true)
  }

  render() {
    const {
      factor = {},
      compare,
      survey,
      sectors,
      sector,
      disease,
      nextFactor,
      previousFactor,
      factorMap,
      inventoryEntries
    } = this.props

    const { showError, showSuccess } = this.state

    const productName =
      survey && survey.diagnostic && survey.diagnostic.inventory.product

    const allRated = !!sector.dimensions.reduce((dimensionResult, dim) => {
      return (
        dimensionResult &&
        dim.factors.reduce((factorResult, fac) => {
          if (!factorMap[fac._id] || !factorMap[fac._id].onClick) {
            return factorResult
          }
          return (
            factorResult &&
            fac.rating &&
            fac.rating !== -1 &&
            fac.rating !== '0' &&
            fac.compare.reduce((acum, compare) => {
              return acum && compare.rating && compare.rating !== '0'
            }, true)
          )
        }, dimensionResult)
      )
    }, true)

    const comparisonOptions = getDefaultValueForFactor(
      sector.name,
      'competitors',
      'options'
    )

    const comparison = [
      {
        number: '1',
        label: comparisonOptions[0]
      },
      {
        number: '2',
        label: comparisonOptions[1]
      },
      {
        number: '3',
        label: comparisonOptions[2]
      },
      {
        number: '4',
        label: comparisonOptions[3]
      },
      {
        number: '5',
        label: comparisonOptions[4]
      }
    ]

    const ratingQuestion =
      factor.ratingQuestion ||
      getDefaultValueForFactor(sector.name, 'rating', 'question')

    const ratingOptions = getDefaultValueForFactor(
      sector.name,
      'rating',
      'options'
    )

    // override default rating options values
    if (factor.ratingOptions && factor.ratingOptions.length) {
      factor.ratingOptions.forEach((option, index) => {
        if (option) {
          ratingOptions[index] = option
        }
      })
    }

    const competitorsQuestion =
      factor.competitorsQuestion ||
      getDefaultValueForFactor(sector.name, 'competitors', 'question')

    // override default comparison options labels
    if (factor.competitorsOptions && factor.competitorsOptions.length) {
      factor.competitorsOptions.forEach((option, index) => {
        if (option) {
          comparison[index].label = option
        }
      })
    }

    const { dimension } = factor
    const entryMap = {
      factor,
      dimension: factor.dimension,
      entryMap: inventoryEntries.reduce(mapEntriesToFactorIds, {})
    }

    const tabItems = [
      {
        menuItem: 'Your rating on this factor',
        render: () => (
          <EvaluateFactor
            allRated={allRated}
            changeComparator={this.changeComparator}
            compare={compare}
            comparison={comparison}
            competitorsQuestion={competitorsQuestion}
            disease={disease}
            factor={factor}
            handleFactorImportance={this.handleFactorImportance}
            handleFactorRating={this.handleFactorRating}
            nextFactor={nextFactor}
            previousFactor={previousFactor}
            productName={productName}
            ratingOptions={ratingOptions}
            ratingQuestion={ratingQuestion}
            showError={showError}
            showSuccess={showSuccess}
            survey={survey}
          />
        )
      }
    ]

    if (!survey.hideProductDiseaseInfo) {
      tabItems.push({
        menuItem: 'Product and / or Disease Information',
        render: () => (
          <TPP sectors={sectors} standalone surveyId={survey._id} />
        )
      })
    }

    tabItems.push({
      menuItem: 'View Asset Inventory',
      render: () => (
        <EntryList
          factor={factor}
          dimension={dimension}
          entryMap={entryMap.entryMap}
          inModal={true}
          hideBackButton
          hideRationale
        />
      )
    })

    return (
      <div className='surveyDimension'>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={tabItems}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ survey, inventoryEntry }) => ({
  survey: survey.result && survey.result.data ? survey.result.data : {},
  inventoryEntries: _.get(inventoryEntry, 'result.data', [])
})

const mapDispatchToProps = dispatch => ({
  getEntries: id => dispatch({ type: 'FETCH_INVENTORY_ENTRIES_REQUESTED', id })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragDropContext(HTML5Backend)(Factor))
