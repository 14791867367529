import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Header, Form, Radio, TextArea } from 'semantic-ui-react'
import Button from '../common/Button'
import moment from 'moment'
import get from 'lodash/get'
import Prompt from './prompt'

class AdditionalCoverage extends Component {
  state = {
    cover: null,
    questions: [],
    startTime: null
  }

  componentDidMount() {
    const { surveyResponse, survey } = this.props
    const { additionalCoverage = {} } = survey
    const { questions = [] } = additionalCoverage
    const answers = surveyResponse.coverageAnswers || {}
    const newQuestions = questions.slice(0).map((question, index) => {
      if (answers[index]) {
        question.answer = answers[index].answer
        question.description = answers[index].description
      }
      return question
    })

    this.setState({
      cover: surveyResponse.coverage,
      questions: newQuestions,
      startTime: moment()
    })
  }

  next = () => {
    const {
      saveCoverageAnswers,
      surveyResponse: { coverageAnswers }
    } = this.props
    const { questions, cover, startTime } = this.state

    const answersMap = questions.reduce((acum, val, index) => {
      if ((cover && !val.showOnYes) || (!cover && val.showOnYes)) return acum
      acum[index] = {
        answer: val.answer,
        description: val.description
      }
      return acum
    }, {})

    if (!coverageAnswers || !coverageAnswers.timeToComplete) {
      answersMap.timeToComplete = moment().diff(startTime)
    }

    saveCoverageAnswers(answersMap)

    this.handleSubmit()
  }

  previous = () => {
    const { surveyNavigation } = this.props
    surveyNavigation.navigateToPrevious()
  }

  saveCoverage = value => {
    const { saveCoverage } = this.props
    const cover = value === 'true'
    this.setState({ cover })
    saveCoverage(cover)
  }

  setChecked = (questionIndex, optionIndex) => {
    const { questions } = this.state
    const newQuestions = questions.slice(0)
    newQuestions[questionIndex].answer = optionIndex
    this.setState({ questions: newQuestions })
  }

  setDescription = (questionIndex, value) => {
    const { questions } = this.state
    const newQuestions = questions.slice(0)
    newQuestions[questionIndex].description = value
    this.setState({ questions: newQuestions })
  }

  handleSubmit() {
    const { surveyNavigation } = this.props

    surveyNavigation.setCurrentStepToDone()
    surveyNavigation.navigateToNext()
  }

  render() {
    const { survey } = this.props
    const { additionalCoverage = {} } = survey
    const { questions, cover } = this.state

    const canGoNext =
      questions.reduce((acum, val) => {
        if (cover && !val.showOnYes) return acum
        return acum && val.answer >= 0
      }, true) || cover === false

    return (
      <div className='surveySectorComponent'>
        <div className='surveyPaddedContainer'>
          <Header as='h1'>Additional Coverage Questions</Header>
          <div className='coverageQuestion'>
            <div>
              Would you expect to cover{' '}
              <span className='coverageProductName'>
                {survey.diagnostic.inventory.product}
              </span>
              ?
            </div>
            <div>
              <Form>
                <Form.Field>
                  <Radio
                    label='Yes'
                    name='expectCover'
                    value='true'
                    checked={this.state.cover}
                    onChange={(e, data) => this.saveCoverage(data.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='No'
                    name='expectCover'
                    value='false'
                    checked={this.state.cover === false}
                    onChange={(e, data) => this.saveCoverage(data.value)}
                  />
                </Form.Field>
              </Form>
            </div>
          </div>
          {cover !== undefined &&
            questions.map(
              (question, index) =>
                ((question.showOnYes && cover) ||
                  (!question.showOnYes && !cover)) && (
                  <div key={`question_${index}`} className='coverageQuestion'>
                    <div>{question.text}</div>
                    <div>
                      <Form>
                        {question.options &&
                          question.options.map((option, optionIndex) => (
                            <div
                              key={`option_${index}_${optionIndex}`}
                              className='coverageOption'
                            >
                              <Form.Field>
                                <Radio
                                  label={option.text}
                                  name={`form_${index}`}
                                  value={option.text}
                                  checked={question.answer === optionIndex}
                                  onChange={(e, data) =>
                                    this.setChecked(index, optionIndex)
                                  }
                                />
                              </Form.Field>
                              {option.showDescribe && (
                                <TextArea
                                  placeholder='Describe...'
                                  value={
                                    question.answer !== optionIndex
                                      ? ''
                                      : question.description
                                  }
                                  rows='7'
                                  disabled={question.answer !== optionIndex}
                                  onChange={(e, data) =>
                                    this.setDescription(index, data.value)
                                  }
                                />
                              )}
                            </div>
                          ))}
                      </Form>
                    </div>
                  </div>
                )
            )}
        </div>

        <Prompt>
          <div className='promptInstructions additional-coverage'>
            <div>{additionalCoverage.instructions}</div>
            <div>
              Once you have completed this section, the Next button will be
              active and you can move on to the next step.
            </div>
          </div>
          <div className='buttonsContainer'>
            <Button
              color={canGoNext ? 'pink' : 'gray'}
              size='large'
              onClick={this.next}
              disabled={!canGoNext}
            >
              Next
            </Button>
          </div>
        </Prompt>
      </div>
    )
  }
}

const mapStateToProps = ({ survey, surveyResponse }) => ({
  survey: get(survey, 'result.data', {}),
  surveyResponse: get(surveyResponse, 'result.data', {})
})

export default connect(mapStateToProps)(AdditionalCoverage)
