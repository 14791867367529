``;
import { Editor } from '@tinymce/tinymce-react';
import React, { useState } from 'react';
import { Form, Input, TextArea } from 'semantic-ui-react';

import ActionsBar from './ActionsBar';
import { IDescriptionProps } from './Description.types';

export const Description: React.FC<IDescriptionProps> = props => {
  const { onCancel, onChange, descriptionProperties, isSaving } = props;
  const [titleError, setTitleError] = useState<boolean>(false);

  const onActionsBarSave = () => {
    const { onSave } = props;
    const { title, value, citation } = descriptionProperties;
    onSave({ title, value, citation });
    setTitleError(false);
  };

  return (
    <div className='entryDescription'>
      <Form>
        <label>Title (80 characters max.)</label>
        <Input
          fluid
          value={descriptionProperties?.title}
          onChange={(e, data) => {
            onChange({ field: 'title', value: data.value });
            setTitleError(false);
          }}
          placeholder='New Data Entry'
          error={titleError}
          maxLength='80'
        />

        <label>Description</label>
        <Editor
          initialValue={descriptionProperties?.value}
          apiKey={'xxr5h4hhanq4vtksd2mfrda6hv5x5s53lb5qgkz3jkzh5u7v'}
          init={{
            selector: 'textarea' as any,
            paste_data_images: true,
            powerpaste_allow_local_images: true,
            menubar: false,
            height: 500,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'print',
              'preview',
              'anchor',
              'help',
              'searchreplace',
              'visualblocks',
              'code',
              'insertdatetime',
              'media',
              'table',
              'wordcount',
              'textcolor',
              'powerpaste'
            ],
            toolbar:
              'undo redo | bold strikethrough underline italic | forecolor backcolor fontselect fontsizeselect | \
              superscript subscript | bullist numlist | outdent indent | font fontsize brush paragraph | \
              image table link | alignleft aligncenter alignright | help'
          }}
          onEditorChange={(newValue, editor) => {
            onChange({ field: 'editorValue', value: newValue });
          }}
        />

        <label>Citation / Source</label>
        <TextArea
          rows={5}
          value={descriptionProperties?.citation}
          onChange={(e, data) => {
            onChange({ field: 'citation', value: data.value as any });
          }}
        />

        <ActionsBar
          isSaving={isSaving}
          onSave={onActionsBarSave}
          onCancel={onCancel}
        />
      </Form>
    </div>
  );
};
