import { connect } from 'react-redux'
import get from 'lodash/get'
import FactorDetails from './FactorDetails'
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'

const mapStateToProps = ({ factors, diagnostic, inventoryEntry }) => ({
  factor: get(factors, 'result.data', {}),
  diagnostic: get(diagnostic, 'result.data', {}),
  inventoryEntries: get(inventoryEntry, 'result.data', [])
})

const mapDispatchToProps = dispatch => ({
  getFactor: id => dispatch({ type: 'FETCH_FACTOR_REQUESTED', id }),
  updateFactor: (payload, id) =>
    dispatch({ type: 'UPDATE_FACTOR_REQUESTED', payload, id }),
  getEntries: id => dispatch({ type: 'FETCH_INVENTORY_ENTRIES_REQUESTED', id })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragDropContext(HTML5Backend)(FactorDetails))
