import get from 'lodash/get'
import { connect } from 'react-redux'

import DiagnosticDetails from './DiagnosticDetails'

const mapStateToProps = ({ token, sectors, diagnostic }) => ({
  sectors: get(sectors, 'result.data', []),
  diagnostic: get(diagnostic, 'result.data'),
  token: get(token, 'result.data.token', '')
})

const mapDispatchToProps = dispatch => ({
  goTo: url => dispatch({ type: 'REDIRECT', url }),
  getSectors: query => dispatch({ type: 'FETCH_SECTORS_REQUESTED', query }),
  getToken: payload => dispatch({ type: 'GET_TOKEN_REQUESTED', payload }),
  getDiagnosticDetails: id =>
    dispatch({ type: 'FETCH_DIAGNOSTIC_DETAILS_REQUESTED', id })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiagnosticDetails)
