import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import {
  Table,
  Button,
  Checkbox,
  Label,
  Dropdown,
  Input
} from 'semantic-ui-react'

import TimeDisplay from './timeDisplay'

const Results = props => {
  const [surveyResponses, setSurveyResponses] = useState({})
  const [exportMap, setExportMap] = useState({})
  const [filter, setFilter] = useState('')
  const [search, setSearch] = useState('')
  const [payload, setPayload] = useState(undefined)

  const {
    getSurveyInvites,
    survey,
    getSurveyResponses,
    surveyResponses: surveyResponsesProp,
    token,
    surveyInvites,
    getTokenRequest,
    onRemoveSurveyInvite,
    updateSurveyInvite
  } = props

  const form = useRef()

  useEffect(() => {
    getSurveyInvites({ survey: survey._id, all: true })
    getSurveyResponses(survey._id, { all: 1 })
  }, [])

  useEffect(() => {
    if (surveyResponsesProp) {
      const responseMap = surveyResponsesProp.reduce((acum, val) => {
        acum[val.user.email] = val
        return acum
      }, {})

      setSurveyResponses(responseMap)
    }
  }, [surveyResponsesProp])

  useEffect(() => {
    if (surveyInvites && Array.isArray(surveyInvites)) {
      const exportMap = surveyInvites.reduce((acum, val) => {
        acum[val.email] = true
        return acum
      }, {})

      setExportMap(exportMap)
    } else if (surveyInvites === 'OK') {
      getSurveyInvites({ survey: survey._id, all: true })
    }
  }, [surveyInvites])

  useEffect(() => {
    if (token && token.token) {
      form.current.submit()
    }
  }, [token])

  const getSurveyStatus = survey => {
    if (survey.answered) {
      return 'Completed'
    }
    if (survey.started) {
      return 'In Progress'
    }
    return 'Not Started'
  }

  const updateExportMap = (email, checked) => {
    const newExportMap = { ...exportMap }
    if (checked) newExportMap[email] = true
    else delete newExportMap[email]
    setExportMap(newExportMap)
  }

  const getToken = () => {
    const emails = Object.keys(exportMap)

    if (emails.length) {
      setPayload(
        JSON.stringify({
          surveyId: survey._id,
          emails,
          filter
        })
      )
      getTokenRequest({})
    }

    if (filter === 'internal') {
      getTokenRequest({})
    }
  }

  const filterInvites = invites => {
    if (!search) return invites
    return invites.filter(invite => {
      return invite.email.includes(search)
    })
  }

  const getInviteColor = invite => {
    if (invite.answered) return 'green'
    if (invite.started) return 'yellow'
    return 'red'
  }

  return (
    <div className='surveyExport'>
      <div className='menuBar'>
        <div>
          <Dropdown
            placeholder='Data to export'
            selection
            style={{ marginRight: '10px' }}
            options={[
              { key: 'rating', value: 'rating', text: 'Factor Ratings' },
              {
                key: 'background',
                value: 'background',
                text: 'Background Questions'
              },
              {
                key: 'coverage',
                value: 'coverage',
                text: 'Additional Coverage Questions'
              },
              {
                key: 'internal',
                value: 'internal',
                text: 'Internal Factor Ratings'
              }
            ]}
            onChange={(e, data) => setFilter(data.value)}
          />
          <Button primary onClick={getToken}>
            Export
          </Button>
        </div>

        <Input
          action={{ icon: 'search' }}
          placeholder='Search...'
          onChange={(e, data) => setSearch(data.value)}
        />
      </div>

      <form
        target='_blank'
        action={`/api/v1/${
          filter === 'internal'
            ? `diagnostic/${get(survey, 'diagnostic._id', '0')}/csv`
            : 'survey-response/csv'
        }?token=${get(token, 'token')}`}
        method='POST'
        ref={form}
      >
        <input type='hidden' name='payload' value={payload} />
      </form>

      <Table padded='very' basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Respondent</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Time taken</Table.HeaderCell>
            <Table.HeaderCell>Include in Export</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Array.isArray(surveyInvites) &&
            filterInvites(surveyInvites).map(val => (
              <Table.Row key={val._id}>
                <Table.Cell>
                  <Checkbox
                    slider
                    checked={!val.disabled}
                    title={`${val.disabled ? 'Grant' : 'Remove'} access`}
                    style={{ marginRight: '10px' }}
                    onChange={(e, data) =>
                      updateSurveyInvite(val._id, { disabled: !data.checked })
                    }
                  />
                  <Button
                    circular
                    icon='trash'
                    onClick={() => onRemoveSurveyInvite(val)}
                    title='Remove access and data'
                    alt='Remove access and data'
                  />
                </Table.Cell>
                <Table.Cell>{val.email}</Table.Cell>
                <Table.Cell>
                  <Label color={getInviteColor(val)}>
                    {getSurveyStatus(val)}
                  </Label>
                </Table.Cell>
                <Table.Cell>
                  <TimeDisplay surveyResponse={surveyResponses[val.email]} />
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={exportMap[val.email]}
                    onChange={(e, data) =>
                      updateExportMap(val.email, data.checked)
                    }
                  />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </div>
  )
}

const mapStateToProps = ({ token, surveyInvites, surveyResponse, survey }) => ({
  token: get(token, 'result.data', {}),
  survey: get(survey, 'result.data', {}),
  surveyInvites: get(surveyInvites, 'result.data', []),
  surveyResponses: get(surveyResponse, 'result.data', [])
})

const mapDispatchToProps = dispatch => ({
  updateSurveyInvite: (id, payload) =>
    dispatch({ type: 'UPDATE_SURVEY_INVITE_REQUESTED', id, payload }),
  getSurveyResponses: (id, query) =>
    dispatch({ type: 'FETCH_SURVEY_RESPONSE_REQUESTED', id, query }),
  getSurveyInvites: (query = {}) =>
    dispatch({ type: 'FETCH_SURVEY_INVITES_REQUESTED', query }),
  getTokenRequest: payload => dispatch({ type: 'GET_TOKEN_REQUESTED', payload })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Results)
