import React from 'react';
import { IWheelDto } from 'types/dto/wheel.dto';

export interface IRootContext {
  wheelsWithInventories: IWheelDto[];
  setWheelsWithInventories: (updatedWheels: IWheelDto[]) => void;
}

export const RootContext = React.createContext<IRootContext>({
  wheelsWithInventories: [],
  setWheelsWithInventories: (updatedWheels: IWheelDto[]) => undefined,
});