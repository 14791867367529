import React from 'react'
import { Table, Dropdown, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import LastUpdated from './LastUpdated'
import ItemStatus from '../common/item-status'

const { Row, Cell } = Table

const GroupListItem = ({
  root,
  expanded,
  item,
  addItem,
  archiveItem,
  restoreItem,
  deleteItem,
  editItem,
  createSubGroup,
  manageItems,
  onClick,
  goTo
}) => (
    <Row onClick={onClick}>
      <Cell className='tableTitle'>
        {root && !!item.children.length && (
          <Icon name={'caret ' + (expanded ? 'down' : 'right')} />
        )}
        <Link to={`/groups/${item._id}`}>{item.name}</Link>
      </Cell>
      <Cell>
        <ItemStatus archived={item.archived} />
      </Cell>
      <Cell>
        <LastUpdated item={item} />
      </Cell>
      <Cell className='actionsCell'>
        <Dropdown text='Actions' item>
          <Dropdown.Menu>
            <Dropdown.Item
              disabled={item.parent}
              value={item._id}
              onClick={() => goTo(`/groups/${item._id}`)}
            >
              Edit
          </Dropdown.Item>

            <Dropdown.Item
              disabled={item.parent}
              value={item._id}
              onClick={createSubGroup}
              content=''
            >
              Add Group
          </Dropdown.Item>
            <Dropdown.Item value={item._id} onClick={addItem}>
              Add User
          </Dropdown.Item>
            <Dropdown.Item value={item._id} onClick={manageItems}>
              Import / Export Users
          </Dropdown.Item>
            {item.archived ? (
              <Dropdown.Item
                value={item._id}
                onClick={restoreItem}
                content='Restore'
              />
            ) : (
                <Dropdown.Item value={item._id} onClick={archiveItem}>
                  Archive
            </Dropdown.Item>
              )}
            <Dropdown.Item
              value={item}
              onClick={deleteItem}
              className='voltActionDelete'
            >
              Delete
          </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Cell>
    </Row>
  )

export default GroupListItem
