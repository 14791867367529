import React, { Component } from 'react'
import {
  Form,
  Input,
  TextArea,
  Button,
  Divider,
  Confirm,
  Checkbox
} from 'semantic-ui-react'
import _ from 'lodash'

import VariableManager from '../variable-manager'

class Disclaimer extends Component {
  state = {
    showRemoveEntry: false
  }

  disclaimers = {}

  setDisclaimer = (index, field, elem) => {
    if (!elem) return
    this.disclaimers[`${field}_${index}`] = elem.ref || elem.inputRef
  }

  addDisclaimer = () => {
    const { onValueChange, disclaimer = [] } = this.props
    const newDisclaimer = _.cloneDeep(disclaimer)
    newDisclaimer.push({ title: '', content: '' })
    onValueChange({ field: 'disclaimer', value: newDisclaimer })
  }

  removeEntry = index => {
    const { onValueChange, disclaimer = [] } = this.props
    const newDisclaimer = _.cloneDeep(disclaimer)
    newDisclaimer.splice(index, 1)
    onValueChange({ field: 'disclaimer', value: newDisclaimer })
  }

  updateDisclaimer = (index, field, value) => {
    const disclaimer = _.cloneDeep(this.props.disclaimer)
    disclaimer[index][field] = value
    return disclaimer
  }

  getNewValue = (index, field, variable) => {
    const { disclaimer } = this.props
    const key = `${field}_${index}`
    const { selectionStart } = this.disclaimers[key]
    const content = disclaimer[index][field]
    return (
      content.slice(0, selectionStart) +
      variable +
      content.slice(selectionStart)
    )
  }

  render() {
    const { showRemoveEntry } = this.state
    const { onValueChange, disclaimer = [], hideDisclaimer } = this.props
    const variables = [
      '[First Name]',
      '[Last Name]',
      '[Disease Name]',
      '[Due Date]'
    ]

    return (
      <div>
        <Confirm
          open={Number.isInteger(showRemoveEntry)}
          header='Remove entry'
          content={
            <div className='content'>
              Are you sure you want to remove this entry?
            </div>
          }
          onCancel={() => this.setState({ showRemoveEntry: false })}
          onConfirm={() => {
            this.removeEntry(showRemoveEntry)
            this.setState({ showRemoveEntry: false })
          }}
        />
        <div className='hideDisclaimerField'>
          <Checkbox
            checked={hideDisclaimer}
            onChange={(event, { checked }) =>
              onValueChange({ field: 'hideDisclaimer', value: checked })
            }
            label='Do not include in this survey'
          />
        </div>
        <div>
          <Button onClick={this.addDisclaimer} primary>
            Add Entry
          </Button>
        </div>
        <Form>
          {disclaimer.map((value, index) => (
            <div key={`disclaimer_${index}`} className='disclaimerEntry'>
              <VariableManager
                variables={variables}
                onAddVariable={variable =>
                  onValueChange({
                    field: 'disclaimer',
                    value: this.updateDisclaimer(
                      index,
                      'title',
                      this.getNewValue(index, 'title', variable)
                    )
                  })
                }
              />
              <Form.Field>
                <Input
                  placeholder='Title'
                  value={value.title}
                  onChange={(e, data) =>
                    onValueChange({
                      field: 'disclaimer',
                      value: this.updateDisclaimer(index, 'title', data.value)
                    })
                  }
                  ref={elem => this.setDisclaimer(index, 'title', elem)}
                />
              </Form.Field>
              <VariableManager
                variables={variables}
                onAddVariable={variable =>
                  onValueChange({
                    field: 'disclaimer',
                    value: this.updateDisclaimer(
                      index,
                      'content',
                      this.getNewValue(index, 'content', variable)
                    )
                  })
                }
              />
              <Form.Field>
                <TextArea
                  placeholder='Message'
                  style={{ minHeight: 150 }}
                  autoHeight
                  value={value.content}
                  onChange={(e, data) =>
                    onValueChange({
                      field: 'disclaimer',
                      value: this.updateDisclaimer(index, 'content', data.value)
                    })
                  }
                  ref={elem => this.setDisclaimer(index, 'content', elem)}
                />
              </Form.Field>
              <Button
                content='Remove Entry'
                icon='trash'
                labelPosition='left'
                onClick={() => this.setState({ showRemoveEntry: index })}
              />
              <Divider />
            </div>
          ))}
        </Form>
      </div>
    )
  }
}

export default Disclaimer
