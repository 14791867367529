const defaultState = {
  all: [],
  isError: null,
  message: '',
  requesting: false
}

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'FETCH_WHEEL_SECTORS_REQUESTED':
      return {
        ...state,
        requesting: true
      }
    case 'FETCH_WHEEL_SECTORS_SUCCEEDED':
      return {
        ...defaultState,
        all: action.payload.data,
        requesting: false
      }
    case 'FETCH_WHEEL_SECTORS_FAILED':
      return {
        ...state,
        isError: true,
        message: 'Fetch wheel sectors failed',
        requesting: false
      }
    default:
      return state
  }
}
