import { IDefaultReducerState } from "redux/types/reducer.types";

const defaultState: IDefaultReducerState = {
  requesting: false,
  error: false,
  result: null,
  isFetch: false,
  isCreate: false,
  isDelete: false,
  loaded: false,
};

export default function counter(state = defaultState, { type, payload }) {
  switch (type) {
    case 'FETCH_INVENTORY_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: false
      }
    case 'FETCH_INVENTORY_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: true
      }
    case 'FETCH_INVENTORY_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: false
      }
    case 'FETCH_INVENTORY_DETAILS_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: false
      }
    case 'FETCH_INVENTORY_DETAILS_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: true
      }
    case 'FETCH_INVENTORY_DETAILS_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: false
      }
    case 'CREATE_INVENTORY_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: false
      }
    case 'CREATE_INVENTORY_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: true
      }
    case 'CREATE_INVENTORY_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: false
      }
    case 'UPDATE_INVENTORY_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: false
      }
    case 'UPDATE_INVENTORY_PROPS_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false
      }
    case 'UPDATE_INVENTORY_PROPS_SUCCEEDED':
      return {
        ...state,
        requesting: false,
        result: payload
      }
    case 'UPDATE_INVENTORY_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: true
      }
    case 'UPDATE_INVENTORY_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: false
      }
    case 'ARCHIVE_INVENTORY_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false,
        isDelete: true
      }
    case 'ARCHIVE_INVENTORY_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isCreate: false,
        isDelete: true
      }
    case 'ARCHIVE_INVENTORY_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isCreate: false,
        isDelete: true
      }
    case 'RESTORE_INVENTORY_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        isCreate: false,
        isDelete: false
      }
    case 'RESTORE_INVENTORY_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isCreate: true,
        isDelete: true
      }
    case 'RESTORE_INVENTORY_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isCreate: true,
        isDelete: true
      }
    default:
      return state
  }
}
