import React, { useEffect, useState } from 'react'
import { DragSource } from 'react-dnd'
import { Icon, Checkbox } from 'semantic-ui-react'

/**
 * Implements the drag source contract.
 */
const factorSource = {
  beginDrag(props) {
    return {
      factor: props.factor
    }
  }
}

/**
 * Specifies the props to inject into your component.
 */
function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

const FactorDragSource = ({factor, connectDragSource, onClick, onIncludeChange}) => {
  const [checked, setChecked] = useState();
  const [disabledFactor, setDisabledFactor] = useState(!factor.include);

  const onChange = (e, data) => {
    onIncludeChange(e, data)
    setChecked(data.checked);
  }

  useEffect(() => {
    let disabled = !factor.include;
    if (typeof checked !== 'undefined') {
      disabled = !checked;
      setChecked(undefined);
    }
    setDisabledFactor(disabled)
  }, [checked, factor.include]);

  return connectDragSource(
    <div
      key={factor._id}
      className={`wheelFactor ${disabledFactor ? 'disabled' : ''}`}
    >
      <Icon name='ellipsis vertical' />
      <Checkbox onChange={onChange} checked={factor.include} />
      <div onClick={onClick}>{factor.name}</div>
    </div>
  );
}

const DragStart = DragSource('factor', factorSource, collectSource)(
  FactorDragSource
)

export default DragStart
