import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

function* handleRedirect(action: { url: string }) {
  yield put(push(action.url));
}

function* redirect() {
  yield takeLatest('REDIRECT' as any, handleRedirect);
}

export default redirect;
