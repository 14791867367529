import React, { Component } from 'react'
import GroupListItem from './GroupListItem'

class GroupListRootItem extends Component {
  state = {
    expanded: false
  }

  toggle = event => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { expanded } = this.state
    const { item } = this.props

    return (
      <>
        <GroupListItem
          key={item._id}
          {...this.props}
          item={item}
          onClick={this.toggle}
          expanded={expanded}
          root
        />
        {expanded &&
          item &&
          item.children.map(child => (
            <GroupListItem
              active={true}
              key={child._id}
              {...this.props}
              item={child}
              onClick={this.toggle}
            />
          ))}
      </>
    )
  }
}

export default GroupListRootItem
