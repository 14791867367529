import React from 'react'
import { Checkbox } from 'semantic-ui-react'

const Dimension = ({ dimension, onFactorChanged, factors }) => (
  <div className='dimension' key={dimension._id}>
    <div className='name'>{dimension.name}</div>
    <div className='factors'>
      {dimension.factors.map(factor => (
        <div
          className={`factor ${!!factors[factor._id] ? '' : 'inactive'}`}
          key={factor._id}
        >
          <Checkbox
            checked={!!factors[factor._id]}
            onChange={(e, data) => onFactorChanged(factor._id, data.checked)}
          />
          {factor.name}
        </div>
      ))}
    </div>
  </div>
)

export default Dimension
