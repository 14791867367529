import React from 'react'
import { Message } from 'semantic-ui-react'

import './alert.scss'

const Alert = ({ children, ...rest }) => (
  <Message {...rest} className='flickerAlert'>
    {children}
  </Message>
)

export default Alert
