import React from 'react'

import Alert from '../alert'

import { Rating as SRating, Header } from 'semantic-ui-react'

import {
  ComparatorContainer,
  UnratedContainer,
  RatingContainer
} from './Containers'

const getProduct = (factor, productName, allComparatorsFromInventory) => {
  if (factor.rating.toString() === '-1' || factor.rating.toString() === '0') {
    return (
      <ComparatorContainer
        compare={{
          description: productName,
          comparator: productName,
          isProduct: true
        }}
        allComparatorsFromInventory={allComparatorsFromInventory}
      />
    )
  }

  return null
}

const CompareFactor = ({
  competitorsQuestion,
  factor,
  changeComparator,
  productName,
  allRated,
  comparison,
  ratingQuestion,
  survey,
  ratingOptions,
  showError,
  showSuccess,
  handleFactorRating,
  handleFactorImportance,
  allComparatorsFromInventory
}) => {
  if (factor.flipRatings) comparison.reverse()

  return (
    <div>
      <div className='factorRating'>
        <div className='title'>{competitorsQuestion}</div>
        <br />
        <br />
        <div className='showSmallOnly'>
          <div className='comparator'>
            <div>{productName}</div>
            <SRating
              rating={parseInt(factor.rating, 10)}
              maxRating={5}
              icon='star'
              size='massive'
              onRate={(e, data) =>
                handleFactorRating(e, { value: data.rating.toString() })
              }
            />
          </div>
          {factor.compare.map(item => (
            <div className='comparator' key={item.comparator}>
              <div>{item.comparator}</div>
              <SRating
                rating={parseInt(item.rating, 10)}
                maxRating={5}
                icon='star'
                size='massive'
                onRate={(e, data) => changeComparator(item, data.rating)}
              />
            </div>
          ))}
        </div>
        <div className='showBigOnly'>
          <Header style={{ fontSize: '16px' }}>
            Drag and drop each drug below to its place on the scale
          </Header>
          {showError && (
            <Alert error>
              You must drag and drop all products below to continue. Otherwise
              your answers for this factor will not be saved.
            </Alert>
          )}
          {showSuccess && !allRated && (
            <Alert positive>
              You have rated this factor. Click the Next or Previous Factor
              buttons to rate other factors.
            </Alert>
          )}
          {allRated && (
            <Alert positive>
              You have rated all factors in this sector. Click the Close Window
              button to continue.
            </Alert>
          )}
          <UnratedContainer
            item={{ number: '0' }}
            changeComparator={changeComparator}
            handleFactorRating={handleFactorRating}
          >
            {factor.compare
              .filter(compare => {
                return compare.rating.toString() === '0'
              })
              .map(compare => (
                <ComparatorContainer
                  compare={compare}
                  key={compare.comparator}
                  allComparatorsFromInventory={allComparatorsFromInventory}
                />
              ))}

            {getProduct(factor, productName, allComparatorsFromInventory)}
          </UnratedContainer>

          <div className='comparison'>
            {comparison.map((item, index) => (
              <div key={item.number} className='comparisonItem'>
                <RatingContainer
                  item={item}
                  changeComparator={changeComparator}
                  handleFactorRating={handleFactorRating}
                >
                  {factor.compare
                    .filter(compare => {
                      return compare.rating.toString() === item.number
                    })
                    .map(compare => (
                      <ComparatorContainer
                        compare={compare}
                        key={compare.comparator}
                        allComparatorsFromInventory={
                          allComparatorsFromInventory
                        }
                      />
                    ))}
                  {factor.rating.toString() === item.number && (
                    <ComparatorContainer
                      compare={{
                        description: productName,
                        comparator: productName,
                        isProduct: true
                      }}
                      allComparatorsFromInventory={allComparatorsFromInventory}
                    />
                  )}
                </RatingContainer>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompareFactor
