import { put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import get from 'lodash/get'

export function* handleUnauthorizedError(e) {
  const status = get(e, 'response.status')

  if (status !== 401) {
    return
  }

  delete window.localStorage.clear()

  yield put({
    type: 'SEND_NOTIFICATION',
    payload: {
      message: 'You are not authenticated, please log in again',
      success: false,
    },
  })

  yield put(push('/login'))
}

export function* handleError({ type, error }) {
  yield put({ type, payload: error })
  yield put({
    type: 'SEND_NOTIFICATION',
    payload: {
      success: false,
      message: get(error, 'response.data.message', 'Something went wrong!'),
    },
  })

  yield handleUnauthorizedError(error)
}

export function* handleSuccess(type, result, message) {
  yield put({ type, payload: result })

  if (!message) {
    return
  }

  yield put({
    type: 'SEND_NOTIFICATION',
    payload: {
      success: true,
      message,
    },
  })
}
