import React from 'react'

import { Icon } from 'semantic-ui-react'
import Button from '../common/Button'

import './SideNavigation.scss'

const PreviewSection = ({ id, goTo }) => (
  <div className='previewSection'>
    <Button
      size='small'
      onClick={() => window.open(`/diagnostic-preview/${id}`)}
      className='voltExternalButton'
      rounded
      shadowed
    >
      Preview
      <Icon name='external' className='voltExternalIcon' />
    </Button>
  </div>
)

const SideNavigation = ({ sector, selected, select, id, goTo }) => (
  <div className='sideNavigation'>
    {sector.dimensions.map(
      dimension =>
        dimension.factors.length > 0 && (
          <DimensionMenu
            dimension={dimension}
            selected={selected}
            select={select}
            key={dimension._id}
          />
        )
    )}
    <PreviewSection id={id} goTo={goTo} />
  </div>
)

const DimensionMenu = ({ dimension, selected, select }) => (
  <>
    <div
      className='dimensionItem'
      key={dimension._id}
      onClick={e => select(dimension._id, dimension.factors[0]._id)}
    >
      <Icon
        name={`angle ${
          selected.dimension === dimension._id ? 'down' : 'right'
        }`}
      />
      <span className='dimensionTitle'>{dimension.name}</span>
    </div>
    {selected.dimension === dimension._id && (
      <div className='factorMenu'>
        {dimension.factors.map(factor => (
          <div
            className={[
              'factorItem',
              selected.factor === factor._id ? 'active' : ''
            ].join(' ')}
            key={factor._id}
            onClick={e => select(dimension._id, factor._id)}
          >
            {factor.name}
          </div>
        ))}
      </div>
    )}
  </>
)

export default SideNavigation
