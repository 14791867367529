import { isMatch } from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Tab, Header } from 'semantic-ui-react';

import { IInventoryCreateAction, IInventoryCreatePayload } from 'redux/types/inventories.types';

import './inventory.scss';
import { INewInventoryProps } from './inventory.types';
import InventoryForm from './inventoryForm';

import { RoutePaths } from '../_routing/route-paths';
import { usePreviousProps } from '../common/use-previous-props/usePreviousProps';
import { Breadcrumb } from '../page-layout/breadcrumb/Breadcrumb';

const NewInventory: React.FC<INewInventoryProps> = props => { 
  const { createAssetInventory, inventory, goTo, match } = props;
  const previousProps = usePreviousProps(props);

  const saveParameters = (parameters: IInventoryCreatePayload) => {
    createAssetInventory(parameters);
  };

  useEffect(() => {
    if (
      inventory &&
      !inventory?.requesting &&
      inventory?.result &&
      isMatch(inventory, previousProps?.inventory) &&
      inventory?.isCreate &&
      !inventory?.error
    ) {
      goTo(`/inventory/${inventory?.result.data._id}`);
    }
  }, [inventory]);

  return (
    <div className='newInventoryComponent'>
      <Breadcrumb
        nodes={[
          { label: 'Dashboard', route: RoutePaths.Home },
          { label: 'Value Asset Inventories', route: RoutePaths.Inventories },
        ]}
      />

      <Header as='h1'>New Value Asset Inventory</Header>

      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={[
          {
            menuItem: 'Settings',
            render: () => (
              <InventoryForm
                onSave={saveParameters}
                match={match}
                isNewInventory={true}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

const mapStateToProps = ({ inventory }) => ({
  inventory,
});

const mapDispatchToProps = (dispatch: Function) => ({
  goTo: (url: string) => dispatch({ type: 'REDIRECT', url }),
  createAssetInventory: (payload: IInventoryCreateAction) =>
    dispatch({ type: 'CREATE_INVENTORY_REQUESTED', payload }),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type InventoryFormProps = ConnectedProps<typeof connector>;

export default connector(NewInventory as any);