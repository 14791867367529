import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import { Table, Dropdown, Confirm, Icon } from 'semantic-ui-react';

import UpdatedAt from './updatedAt';
import TopBar from './TopBar';
import ItemStatus from '../common/item-status';
import ContentLoader from '../common/content-loader';

class Inventories extends Component {
  state = {
    inventory: {},
    search: '',
    includeArchived: false,
    searchTimeout: undefined,
  };

  UNSAFE_componentWillReceiveProps(props) {
    const { inventoryResult, getInventory, duplicateInventoryResult } = props;

    const { search, includeArchived } = this.state;

    if (
      this.props.inventoryResult !== inventoryResult &&
      inventoryResult.isDelete &&
      inventoryResult.result &&
      !inventoryResult.error
    ) {
      getInventory({ search, includeArchived });
    }

    if (
      this.props.duplicateInventoryResult.requesting &&
      !duplicateInventoryResult.requesting
    ) {
      getInventory({ search, includeArchived });
    }
  }

  componentDidMount() {
    const { search, includeArchived, getInventory, inventory } = this.props;
    if (!inventory || search !== '') {
      getInventory({ search, includeArchived });
    }
  }

  onSearch = (newSearch) => {
    const { search, includeArchived, searchTimeout } = this.state;
    const { getInventory } = this.props;

    if (newSearch !== search) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      const newTimeout = setTimeout(() => {
        getInventory({ search: newSearch, includeArchived });
      }, 500);
      this.setState({
        ...this.state,
        search: newSearch,
        searchTimeout: newTimeout,
      });
    }
  };

  toggleArchived = (checked) => {
    const { search, includeArchived } = this.state;
    const { getInventory } = this.props;

    if (checked !== includeArchived) {
      this.setState({ includeArchived: checked });
      getInventory({ search, includeArchived: checked });
    }
  };

  rowActionClick = (event, data) => {
    this.setState({ showArchiveConfirmation: true, inventoryId: data.value });
  };

  finishAction = () => {
    const { inventoryId } = this.state;
    const { restore, archive, inventory } = this.props;

    const selected = inventory.find((i) => i._id === inventoryId);

    selected && selected.archived
      ? restore(selected._id)
      : archive(selected._id);

    this.setState({ showArchiveConfirmation: false });
  };

  content = () => {
    const { inventory } = this.props;
    const { inventoryId } = this.state;

    const selected = inventory && inventory.find((i) => i._id === inventoryId);

    if (!selected) return {};

    return {
      header: (selected.archived ? 'Restore' : 'Archive') + ' inventory',
      content: (
        <div className='content'>
          Are you sure you want to {selected.archived ? 'restore' : 'archive'}{' '}
          <strong>{selected.title}</strong>?
        </div>
      ),
    };
  };

  render() {
    const { inventory, goTo, inventoryResult, duplicateInventoryResult, user } =
      this.props;

    const { showArchiveConfirmation, search, includeArchived } = this.state;

    const { header, content } = this.content();

    return (
      <>
        <Confirm
          open={showArchiveConfirmation}
          header={header}
          content={content}
          onCancel={() => this.setState({ showArchiveConfirmation: false })}
          onConfirm={this.finishAction}
        />
        <TopBar
          search={search}
          includeArchived={includeArchived}
          onSearch={this.onSearch}
          toggleArchived={this.toggleArchived}
          onNew={() => goTo('/new-inventory')}
          newText='New Inventory'
          hideArchiveToggle={user.type === 'C'}
          hideNewButton={user.type === 'C'}
        />
        <ContentLoader
          loading={
            inventoryResult.requesting || duplicateInventoryResult.requesting
          }
          empty={!inventory.length}
        >
          <Table padded='very' basic='very' id='inventoriesList'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Wheel</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Last Edited By</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {inventory.map((row) => {
                return (
                  <Table.Row key={row._id}>
                    <Table.Cell className='tableTitle'>
                      <Link to={`/inventory/${row._id}`}>{row.title}</Link>
                    </Table.Cell>
                    <Table.Cell>{get(row, 'wheel.title', '')}</Table.Cell>
                    <Table.Cell>
                      <ItemStatus archived={row.archived} />
                    </Table.Cell>
                    <Table.Cell>
                      {row.lastEditedBy &&
                        `${row.lastEditedBy.firstName} ${row.lastEditedBy.lastName}`}
                      <UpdatedAt timestamp={row.updatedAt} />
                    </Table.Cell>
                    <Table.Cell className='actionsCell'>
                      <Dropdown text='Actions' item>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => goTo(`/inventory/${row._id}`)}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              window.open(`/inventory-preview/${row._id}`)
                            }
                          >
                            Preview{' '}
                            <Icon
                              name='external'
                              size='small'
                              className='voltActionMenuPreviewIcon'
                            />
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={this.rowActionClick}
                            value={row._id}
                          >
                            {row.archived ? 'Restore' : 'Archive'}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </ContentLoader>
      </>
    );
  }
}

const mapStateToProps = ({
  tokenValidation,
  inventory,
  duplicateInventory,
}) => ({
  inventory:
    inventory.result && Array.isArray(inventory.result.data)
      ? inventory.result.data
      : [],
  inventoryResult: inventory,
  duplicateInventoryResult: duplicateInventory,
  user: get(tokenValidation, 'result.data'),
});

const mapDispatchToProps = (dispatch) => ({
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
  getInventory: (query) =>
    dispatch({ type: 'FETCH_INVENTORY_REQUESTED', query }),
  archive: (id) => dispatch({ type: 'ARCHIVE_INVENTORY_REQUESTED', id }),
  restore: (id) => dispatch({ type: 'RESTORE_INVENTORY_REQUESTED', id }),
  duplicateInventory: (id) =>
    dispatch({ type: 'DUPLICATE_INVENTORY_REQUESTED', id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventories);
