import React from 'react'

import { Segment } from 'semantic-ui-react'

const Comparator = ({
  connectDragSource,
  isDragging,
  compare,
  allComparatorsFromInventory
}) => {
  let comparatorName

  if (compare.isProduct) {
    comparatorName = compare.comparator || compare.description
  } else {
    comparatorName = allComparatorsFromInventory.find(
      comparator => comparator._id === compare.comparator
    ).name
  }

  return connectDragSource(
    <div className='comparatorCard'>
      <Segment style={{ cursor: 'pointer' }} secondary={isDragging}>
        {comparatorName}
      </Segment>
    </div>
  )
}

export default Comparator
