import React, { Component } from 'react';
import moment from 'moment';

class TimeTracker extends Component {
  state = {}

  componentDidMount() {
    this.setState({ startTime: moment() });
  }

  saveTime = (additionalData = {}) => {
    const { surveyNavigation, field } = this.props;
    const { startTime } = this.state;
    const time = moment().diff(startTime);
    surveyNavigation.setCurrentStepToDone(Object.assign({}, additionalData, { [field]: time }));
  }

  render() {
    const children = React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        index,
        saveTime: this.saveTime
      });
    });

    return (
      <div>{children}</div>
    )
  }
}

export default TimeTracker;
