import React from 'react'
import { Table as SemanticTable } from 'semantic-ui-react'
import './Table.scss'

const { Header, Row, Body, HeaderCell } = SemanticTable

const Table = ({ headings, children }) => {
  return (
    <SemanticTable padded='very' basic='very'>
      <Header>
        <Row>
          {headings &&
            headings.map((item, index) => (
              <HeaderCell key={index.toString()}>{item}</HeaderCell>
            ))}
        </Row>
      </Header>
      <Body>{children}</Body>
    </SemanticTable>
  )
}

export default Table
