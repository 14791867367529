import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Message } from 'semantic-ui-react'

class Notifications extends Component {
  UNSAFE_componentWillReceiveProps(props) {
    if (props.notifications) {
      setTimeout(this.props.clearNotifications, 4000)
    }
  }

  render() {
    const { notifications } = this.props

    if (!notifications) {
      return null
    }

    return (
      <div className='notificationsComponent'>
        <div className='notificationsContainer'>
          <Message
            positive={notifications.success}
            negative={!notifications.success}
          >
            {notifications.message}
          </Message>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ notifications }) => ({ notifications })

const mapDispatchToProps = (dispatch) => ({
  clearNotifications: () => dispatch({ type: 'RESET_NOTIFICATION' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
