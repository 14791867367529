import React, { useEffect, useState } from 'react'
// import parse from 'html-react-parser'
import { loadAssetsFromEntry } from './entryPreview.helpers'
import { EntryAssetImage } from './entry-asset-image/EntryAssetImage'

export const EntryPreview = (props) => {
  const { entry } = props

  const [images, setImages] = useState([])

  useEffect(() => {
    loadAssetsFromEntry(entry._id, setImages)
  }, [entry])

  return (
    <div className='entryPreview'>
      <div>
        <a
          href={`/entry/${entry._id}?inventory=${entry.inventory}`}
          target={'_blank'}
          className='title'
        >
          {entry.title}
        </a>
        <div className='jodit_wysiwyg'
          dangerouslySetInnerHTML={{
            __html: entry.description ? entry.description : ''
          }}
        />
        <div className={'entryAssetList'}>
          {images &&
            images.map((imgUrl) => <EntryAssetImage imageUrl={imgUrl} />)}
        </div>
        <div className='citation'>{entry.citation}</div>
      </div>
    </div>
  )
}

export default EntryPreview
