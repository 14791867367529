import React from 'react'

const Rating = ({ connectDropTarget, isOver, item, children }) => {
  const style = { padding: '5px' }

  if (isOver) {
    style.backgroundColor = '#EEE'
  }

  return connectDropTarget(
    <div style={style} className='compareTarget'>
      <div>{children}</div>
      <div className='comparisonNumber'>{item.number}</div>
      <div>{item.label}</div>
    </div>
  )
}

export default Rating
