import { call, put, takeLatest } from 'redux-saga/effects'
import api from '../../services/api'
import { handleUnauthorizedError } from './helpers/saga-helpers'

function* getSurveysByDiagnostic(action) {
  try {
    const result = yield call(api.getSurveysByDiagnostic, action.diagnosticId)
    yield put({
      type: 'FETCH_SURVEYS_BY_DIAGNOSTIC_SUCCEEDED',
      payload: result
    })
  } catch (e) {
    yield put({ type: 'FETCH_SURVEYS_BY_DIAGNOSTIC_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* surveysByDiagnostic() {
  yield takeLatest(
    'FETCH_SURVEYS_BY_DIAGNOSTIC_REQUESTED',
    getSurveysByDiagnostic
  )
}

export default surveysByDiagnostic
