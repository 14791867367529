import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { RoutePaths } from './route-paths';

import EnsureLoggedIn from '../app/ensureLoggedIn';
import ForgotPassword from '../forgot-password';
import { Home } from '../home';
import InventoryPreview from '../inventory-preview';
import Login from '../login';
import NotFound from '../not-found';
import { ResetPasswordPage } from '../reset-password';
import SignUp from '../sign-up';

export const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path={RoutePaths.NotFound} component={NotFound} />
        <Route exact path={RoutePaths.Login} component={Login} />
        <Route exact path={RoutePaths.SignUp} component={SignUp} />
        <Route
          exact
          path={RoutePaths.ForgotPassword}
          component={ForgotPassword}
        />
        <Route
          exact
          path={RoutePaths.ResetPassword}
          component={ResetPasswordPage}
        />

        <EnsureLoggedIn>
          <Switch>
            <Route
              path={`${RoutePaths.InventoryPreview}/:id`}
              component={InventoryPreview}
            />
            <Route path={RoutePaths.Home} component={Home} />
          </Switch>
        </EnsureLoggedIn>
      </Switch>
    </>
  );
};
