import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon, Label, Menu, Tab, Input, Checkbox } from 'semantic-ui-react'
import { get, orderBy } from 'lodash'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import AddSector from './addSector'
import AddDimension from './addDimension'
import AddFactor from './addFactor'
import Factor from './Factor'
import Button from '../../common/Button'
import ContentLoader from '../../common/content-loader'
import ConfirmDeleteModal from '../../modals/ConfirmDeleteModal'
import WheelSettings from './WheelSettings'

import './styles.scss'

class SectorAdmin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sectorIndex: 0,
      dimensionIndex: 0,
      showSectorModal: false,
      editingSector: {},
      showDimensionModal: false,
      editingDimension: null,
      showFactorModal: false,
      editingFactor: null,
      dimensionToDelete: null,
      search: '',
    }
  }

  componentDidMount() {
    this.getSectors()
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { dimensions, factors, multiFactors } = props
    const {
      dimensions: currentDimensions,
      factors: currentFactors,
      multiFactors: currentMultiFactors,
    } = this.props

    if (
      dimensions !== currentDimensions &&
      dimensions.isDelete &&
      !dimensions.error &&
      dimensions.result
    ) {
      this.setState({ dimensionIndex: 0 })
      this.getSectors()
    }

    if (
      factors !== currentFactors &&
      factors.isDelete &&
      !factors.error &&
      factors.result
    ) {
      this.getSectors()
    }

    if (multiFactors && multiFactors !== currentMultiFactors) {
      this.getSectors()
    }
  }

  componentDidUpdate(prevProps) {
    const { token, dimensions, factors } = this.props

    if (token !== prevProps.token && token.token) {
      this.form.submit()
    }

    if (
      (dimensions !== prevProps.dimensions &&
        dimensions.isCreate &&
        dimensions.loaded) ||
      (factors !== prevProps.factors && factors.isCreate && factors.loaded)
    ) {
      this.getSectors()
    }
  }

  getSectors = () => {
    const { getSectors, wheel } = this.props
    const query = { all: true }
    if (wheel) {
      query.wheel = wheel
    }
    getSectors(query)
  }

  updateFactorOrder = (sourceFactor, destFactor, dimensionIndex) => {
    const { sectors, updateFactors } = this.props
    const { sectorIndex } = this.state

    const factors = orderBy(
      sectors[sectorIndex].dimensions[dimensionIndex].factors,
      ['order'],
      ['asc']
    )

    const sourceIndex = factors.findIndex((item) => item === sourceFactor)
    factors.splice(sourceIndex, 1)
    const destIndex = factors.findIndex((item) => item === destFactor)
    const newFactors = factors
      .slice(0, destIndex)
      .concat([sourceFactor])
      .concat(factors.slice(destIndex))
      .map((item, index) => {
        item.order = index + 1
        return item
      })

    updateFactors(
      newFactors.map((factor) => ({ _id: factor._id, order: factor.order }))
    )
    sectors[sectorIndex].dimensions[dimensionIndex].factors = newFactors
    this.setState({ factors: newFactors })
  }

  toggleDimension = (factors, checked) => {
    const { updateFactors } = this.props

    const newFactors = factors.map((f) => ({ ...f, include: checked }))
    updateFactors(newFactors)
    this.setState({ search: '', factors: newFactors })
  }

  onDimensionDelete = (dimensionToDelete) => {
    this.setState({ dimensionToDelete, showDimensionModal: false })
  }

  showDeleteDimension = (e, dimensionToDelete) => {
    e.stopPropagation()
    this.setState({ dimensionToDelete })
  }

  render() {
    const {
      sectors,
      wheel,
      getToken,
      token,
      updateFactors,
      showSettings,
      user,
    } = this.props

    const {
      sectorIndex,
      dimensionIndex,
      showSectorModal,
      showDimensionModal,
      editingDimension,
      showFactorModal,
      editingFactor,
      dimensionToDelete,
      search,
    } = this.state

    let sector = { dimensions: [] }
    let dimension = { factors: [] }

    if (sectors.length) {
      sector = sectors[sectorIndex]
      dimension = sector.dimensions[dimensionIndex] || { factors: [] }
    }

    const panes = sectors.map((item, index) => ({
      menuItem: (
        <Menu.Item key={item._id}>
          {item.name}
          <Label color='black'>
            {item.dimensions.reduce((acum, dimension) => {
              return acum + dimension.factors.length
            }, 0)}
          </Label>
        </Menu.Item>
      ),
      render: () => (
        <div>
          <div className='sectorMenu'>
            <Button className='largeButton' onClick={() => getToken({})}>
              Export as CSV
            </Button>

            <Button
              className='largeButton'
              onClick={() =>
                window.open(`/wheel-preview/${wheel || 'default'}`)
              }
              rounded
              shadowed
            >
              Preview <Icon name='external' className='voltWheelPreview' />
            </Button>

            <Input
              icon='search'
              placeholder='Search in sector'
              onChange={(e, data) => this.setState({ search: data.value })}
            />
          </div>

          <Button
            color='clear'
            className='addDimensionButton'
            onClick={() =>
              this.setState({
                sectorIndex: index,
                showDimensionModal: true,
                editingDimension: null,
              })
            }
          >
            <Icon name='plus circle' color='green' size='large' />
            Add Dimension
          </Button>

          {item.dimensions.map((dimension, dIndex) => (
            <div key={dimension._id} className='wheelDimension'>
              <Checkbox
                indeterminate={
                  dimension.factors.reduce((a, f) => a || f.include, false) &&
                  !dimension.factors.reduce((a, f) => a && f.include, true)
                }
                onChange={(e, data) =>
                  this.toggleDimension(dimension.factors, data.checked)
                }
                checked={dimension.factors.reduce(
                  (a, f) => a && f.include,
                  true
                )}
                className='selectAllFactorsCheckbox'
              />
              <span
                className='dimensionName'
                onClick={() =>
                  this.setState({
                    editingSector: item,
                    sectorIndex: index,
                    showDimensionModal: true,
                    editingDimension: dimension,
                  })
                }
              >
                {dimension.name}{' '}
                {user.type === 'A' && (
                  <div className='deleteDimension'>
                    <div>|</div>
                    <div
                      onClick={(e) => this.showDeleteDimension(e, dimension)}
                    >
                      DELETE
                    </div>
                  </div>
                )}
              </span>
              <div className='wheelFactors'>
                {orderBy(dimension.factors, ['order'], ['asc']).map(
                  (factor) => {
                    if (
                      !search ||
                      factor.name
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      dimension.name
                        .toUpperCase()
                        .includes(search.toUpperCase())
                    ) {
                      return (
                        <Factor
                          key={factor._id}
                          onFactorDrag={(sourceFactor, destFactor) =>
                            this.updateFactorOrder(
                              sourceFactor,
                              destFactor,
                              dIndex
                            )
                          }
                          factor={factor}
                          onClick={() =>
                            this.setState({
                              editingSector: item,
                              sectorIndex: index,
                              dimensionIndex: dIndex,
                              showFactorModal: true,
                              editingFactor: factor,
                            })
                          }
                          onIncludeChange={(e, data) => {
                            updateFactors([
                              {
                                _id: factor._id,
                                include: data.checked,
                              },
                            ])
                          }}
                        />
                      )
                    }

                    return null
                  }
                )}
                <Button
                  onClick={() =>
                    this.setState({
                      editingSector: item,
                      sectorIndex: index,
                      dimensionIndex: dIndex,
                      showFactorModal: true,
                      editingFactor: null,
                    })
                  }
                  color='clear'
                >
                  <Icon name='plus circle' color='green' size='large' />
                  Add Factor
                </Button>
              </div>
            </div>
          ))}
        </div>
      ),
    }))

    if (showSettings) {
      panes.push({
        menuItem: 'Settings',
        render: () => <WheelSettings wheel={wheel} />,
      })
    }

    return (
      <div className='sectorAdminComponent'>
        {dimensionToDelete && (
          <ConfirmDeleteModal
            onClose={() => this.setState({ dimensionToDelete: null })}
            name={dimensionToDelete.name}
            item={dimensionToDelete}
            type='dimension'
          />
        )}
        <AddSector
          onClose={() => this.setState({ showSectorModal: false })}
          show={showSectorModal}
          sector={sector}
          wheel={wheel}
        />
        <AddDimension
          onClose={() => this.setState({ showDimensionModal: false })}
          show={showDimensionModal}
          sector={sector}
          dimension={editingDimension}
          wheel={wheel}
          onDelete={this.onDimensionDelete}
        />
        <AddFactor
          onClose={() => this.setState({ showFactorModal: false })}
          show={showFactorModal}
          dimension={dimension}
          factor={editingFactor}
          sector={sector}
          wheel={wheel}
        />

        <ContentLoader empty={!sectors.length}>
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </ContentLoader>

        <form
          target='_blank'
          action={`/api/v1/wheels/${wheel}/csv?token=${get(token, 'token')}`}
          method='POST'
          ref={(el) => {
            this.form = el
            return null
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  wheelSectors,
  dimensions,
  factors,
  token,
  tokenValidation,
  multiFactors,
}) => ({
  sectors: get(wheelSectors, 'all'),
  dimensions: dimensions,
  factors: factors,
  token: get(token, 'result.data', {}),
  user: get(tokenValidation, 'result.data'),
  multiFactors: get(multiFactors, 'result.data', null),
})

const mapDispatchToProps = (dispatch) => ({
  updateFactors: (payload) =>
    dispatch({ type: 'UPDATE_FACTORS_REQUESTED', payload }),
  getSectors: (query) =>
    dispatch({ type: 'FETCH_WHEEL_SECTORS_REQUESTED', query }),
  deleteDimension: (id) => dispatch({ type: 'DELETE_DIMENSION_REQUESTED', id }),
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
  getToken: (payload) => dispatch({ type: 'GET_TOKEN_REQUESTED', payload }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragDropContext(HTML5Backend)(SectorAdmin))
