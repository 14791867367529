import React, { Component } from 'react'

class FavorabilityLegend extends Component {
  render() {
    const { comparator } = this.props
    return (
      <div className='voltFavorabilityInfo'>
        <div className='voltFavorabilityInfoKey'>Key</div>
        <div className='voltFavorabilityInfoProduct'>Product X Ratings</div>
        <div>
          <div className='voltFavorabilityInfoOval color1' />
          <div className='voltFavorabilityInfoOval color2' />
          <div className='voltFavorabilityInfoOval color3' />
          <div className='voltFavorabilityInfoOval color4' />
          <div className='voltFavorabilityInfoOval color5' />
          <div className='voltFavorabilityInfoOval color6' />
          <div className='voltFavorabilityInfoOval color7' />
          <div className='voltFavorabilityInfoOval color8 noMargin' />
        </div>
        <div className='voltFavorabilityInfoLow'>
          Lowest
          <br /> favorability
        </div>
        <div className='voltFavorabilityInfoHigh'>
          Highest
          <br /> favorability
        </div>
        <div className='voltFavorabilityInfoComparator'>
          <div className='voltFavorabilityInfoRectangle' />
          {comparator}
        </div>
        <div className='voltFavorabilityInfoComparator'>
          <div className='voltFavorabilityInfoRectanglePink' />
          Most relevant factors
        </div>
      </div>
    )
  }
}

export default FavorabilityLegend
