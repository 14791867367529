import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loader, Form, TextArea } from 'semantic-ui-react'
import get from 'lodash/get'
import Button from '../../common/Button'

import './styles.scss'

class AppMaintenance extends Component {
  state = {
    userInviteEmailTemplate: undefined,
    surveyEmailTemplate: undefined,
  }

  componentDidMount() {
    this.props.getEmailTemplates()
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.updateEmailTemplatesRequest.requesting) {
      this.props.getEmailTemplates()
    }

    if (this.props.emailTemplates !== props.emailTemplates) {
      const userInviteEmailTemplate = get(
        props.emailTemplates,
        'userInviteEmailTemplate.value',
        undefined
      )

      const surveyEmailTemplate = get(
        props.emailTemplates,
        'surveyEmailTemplate.value',
        undefined
      )

      this.setState({
        userInviteEmailTemplate,
        surveyEmailTemplate,
      })
    }
  }

  saveEmailTemplates = () => {
    const { userInviteEmailTemplate, surveyEmailTemplate } = this.state

    this.props.updateEmailTemplates({
      userInviteEmailTemplate,
      surveyEmailTemplate,
    })
  }

  render() {
    const { emailTemplates, goTo } = this.props
    let { userInviteEmailTemplate, surveyEmailTemplate } = this.state

    if (!emailTemplates) {
      return <Loader active inline='centered' />
    }

    return (
      <div className='emailTemplateComponent'>
        <Form>
          <Form.Field>
            <h5>New User Email Template</h5>

            <TextArea
              className='emailTemplate'
              name='userInviteEmailTemplate'
              onChange={(e, data) =>
                this.setState({ userInviteEmailTemplate: data.value })
              }
              value={userInviteEmailTemplate}
            />
          </Form.Field>

          <Form.Field>
            <h5 className='secondFieldHeader'>Survey Email Template</h5>

            <TextArea
              className='emailTemplate'
              name='surveyEmailTemplate'
              onChange={(e, data) =>
                this.setState({ surveyEmailTemplate: data.value })
              }
              value={surveyEmailTemplate}
            />
          </Form.Field>

          <div className='buttons'>
            <Button
              className='cancelButton'
              rounded
              color='white'
              onClick={() => goTo(`/`)}
            >
              Cancel
            </Button>

            <Button
              rounded
              shadowed
              color='black'
              onClick={this.saveEmailTemplates}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = ({ emailTemplates, updateEmailTemplates }) => ({
  emailTemplates: emailTemplates.result && emailTemplates.result.data,
  updateEmailTemplatesRequest: updateEmailTemplates,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getEmailTemplates: () =>
    dispatch({ type: 'FETCH_EMAIL_TEMPLATES_REQUESTED' }),
  updateEmailTemplates: (payload) =>
    dispatch({ type: 'UPDATE_EMAIL_TEMPLATES_REQUESTED', payload }),
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppMaintenance)
