import React from 'react';

import constants from './constants';

const calculateDX = (x, ang, v) => x + Math.cos(ang * Math.PI / 180) * v;
const calculateDY = (y, ang, v) => y + Math.sin(ang * Math.PI / 180) * v;
const getRectPath = ({ sector, id, angle, radius, meta, stroke, fill, style, onMouseOver, onMouseOut, width = 140, onClick, strokeWidth }) => {
  while (angle >= 360) angle -= 360;
  while (angle < 0) angle += 360;

  const cx = meta.width / 2;
  const cy = meta.height / 2;

  const rectWidth = 1.2;
  const rectRadius = radius + (sector.compare ? 7 : 9) * constants.scaleStep;
  const x1 = calculateDX(cx, angle - rectWidth, rectRadius);
  const y1 = calculateDY(cy, angle - rectWidth, rectRadius);
  const x2 = calculateDX(cx, angle + rectWidth, rectRadius);
  const y2 = calculateDY(cy, angle + rectWidth, rectRadius);
  const x3 = calculateDX(cx, angle - rectWidth, rectRadius + width);
  const y3 = calculateDY(cy, angle - rectWidth, rectRadius + width);
  const x4 = calculateDX(cx, angle + rectWidth, rectRadius + width);
  const y4 = calculateDY(cy, angle + rectWidth, rectRadius + width);

  let lx1 = calculateDX(cx, angle + rectWidth / 2, rectRadius);
  let ly1 = calculateDY(cy, angle + rectWidth / 2, rectRadius);
  let lx2 = calculateDX(cx, angle + rectWidth / 2, rectRadius + width);
  let ly2 = calculateDY(cy, angle + rectWidth / 2, rectRadius + width);
  if (angle < 270 && angle > 90) {
    lx1 = calculateDX(cx, angle - rectWidth / 2, rectRadius + width);
    ly1 = calculateDY(cy, angle - rectWidth / 2, rectRadius + width);
    lx2 = calculateDX(cx, angle - rectWidth / 2, rectRadius);
    ly2 = calculateDY(cy, angle - rectWidth / 2, rectRadius);
  }

  if (!x1 || !y1 || !x2 || !y2) {
    return null
  }

  return (
    <g>
      <path
        d={`M ${x1} ${y1} A 5 5 0 1 0 ${x2} ${y2} L ${x4} ${y4} A 5 5 0 1 0 ${x3} ${y3} z`}
        stroke={stroke || '#DDD'}
        strokeWidth={strokeWidth || '1'}
        fill={fill || '#fff'}
        style={style}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onClick}
      />
      <path
        id={id}
        d={`M ${lx1} ${ly1} L ${lx2} ${ly2}`}
        stroke='none'
        strokeWidth='1'
        fill='none'
      />
    </g>

  );
}

const utils = {
  calculateDX,
  calculateDY,
  getRectPath
};

export default utils;
