import React, { Component } from 'react';

import { Label } from 'semantic-ui-react';

const parseTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  let time = '';
  if (minutes > 0) {
    time += `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
  }
  if (seconds > 0) {
    time += ` ${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
  }

  return time;
}

class TimeDisplay extends Component {
  render() {
    const { surveyResponse } = this.props;
    if (!surveyResponse) return <Label>Total: <Label.Detail>0 seconds</Label.Detail></Label>;

    const questions = surveyResponse.backgroundQuestions;
    const factors = surveyResponse.factors;
    const coverage = surveyResponse.coverageAnswers;

    let questionsTime = 0;
    if (questions && Number.isInteger(questions.timeToComplete)) {
      questionsTime = Math.floor(questions.timeToComplete / 1000);
    }

    let factorsTime = 0;
    if (factors && factors.length) {
      factorsTime = Math.floor(factors.reduce((acum, val) => {
        if (Number.isInteger(val.timeToComplete)) {
          acum += val.timeToComplete;
        }
        return acum;
      }, 0) / 1000);
    }

    let coverageTime = 0;
    if (coverage && Number.isInteger(coverage.timeToComplete)) {
      coverageTime = Math.floor(coverage.timeToComplete / 1000);
    }

    let importanceTime = 0;
    if (Number.isInteger(surveyResponse.importanceTime)) {
      importanceTime = Math.floor(surveyResponse.importanceTime / 1000);
    }

    let introductionTime = 0;
    if (Number.isInteger(surveyResponse.introductionTime)) {
      introductionTime = Math.floor(surveyResponse.introductionTime / 1000);
    }

    let pddTime = 0;
    if (Number.isInteger(surveyResponse.pddTime)) {
      pddTime = Math.floor(surveyResponse.pddTime / 1000);
    }

    let submitTime = 0;
    if (Number.isInteger(surveyResponse.submitTime)) {
      submitTime = Math.floor(surveyResponse.submitTime / 1000);
    }

    let summaryTime = 0;
    if (Number.isInteger(surveyResponse.summaryTime)) {
      summaryTime = Math.floor(surveyResponse.summaryTime / 1000);
    }

    let tosTime = 0;
    if (Number.isInteger(surveyResponse.tosTime)) {
      tosTime = Math.floor(surveyResponse.tosTime / 1000);
    }

    const totalTime = questionsTime + factorsTime + coverageTime + importanceTime + introductionTime +
      pddTime + submitTime + summaryTime + tosTime;

    return (
      <div className='surveyTimes'>
        {totalTime ? (
          <div>
            <Label>Total: <Label.Detail>{parseTime(totalTime)}</Label.Detail></Label>
          </div>
        ) : (
          <Label>Total: <Label.Detail>0 seconds</Label.Detail></Label>
        )}
      </div>
    );
  }
}

export default TimeDisplay;
