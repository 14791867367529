import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Table, Dropdown, Confirm, Icon } from 'semantic-ui-react'

import UpdatedAt from './updatedAt'
import TopBar from './TopBar'
import ItemStatus from '../common/item-status'
import ContentLoader from '../common/content-loader'

class Surveys extends Component {
  state = {
    survey: {},
    surveyInvitesMap: {},
    search: '',
    includeArchived: false,
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {
      surveyResult,
      getSurveys,
      surveyInvites,
      duplicateSurveyResult,
      getSurveyInvites,
    } = props

    const { search, includeArchived } = this.state

    if (
      this.props.surveyResult !== surveyResult &&
      (surveyResult.isDelete || surveyResult.isCreate) &&
      surveyResult.result &&
      !surveyResult.error
    ) {
      getSurveys({ search, includeArchived })
      getSurveyInvites()
    }

    if (this.props.surveyInvites !== surveyInvites && surveyInvites.length) {
      const surveyInvitesMap = surveyInvites.reduce((acum, val) => {
        acum[val.survey._id] = val
        return acum
      }, {})
      this.setState({ surveyInvitesMap })
    }

    if (
      this.props.duplicateSurveyResult.requesting &&
      !duplicateSurveyResult.requesting
    ) {
      getSurveys({ search, includeArchived })
    }
  }

  componentDidMount() {
    const { search, getSurveyInvites, getSurveys, includeArchived } = this.props
    getSurveys({ search, includeArchived })
    getSurveyInvites()
  }

  componentDidUpdate(prevProps) {
    const { surveyResponses } = this.props

    if (surveyResponses !== prevProps.surveyResponses) {
      const responseMap = surveyResponses.reduce((acum, val) => {
        acum[val.user.email] = val
        return acum
      }, {})

      this.setState({ surveyResponses: responseMap })
    }
  }

  onSearch = (newSearch) => {
    const { search, includeArchived } = this.state
    const { getSurveys } = this.props

    if (newSearch !== search) {
      this.setState({ search: newSearch })
      getSurveys({ search: newSearch, includeArchived })
    }
  }

  toggleArchived = (checked) => {
    const { search, includeArchived } = this.state
    const { getSurveys } = this.props

    if (checked !== includeArchived) {
      this.setState({ includeArchived: checked })
      getSurveys({ search, includeArchived: checked })
    }
  }

  rowActionClick = (event, data) => {
    this.setState({ showArchiveConfirmation: true, surveyId: data.value })
  }

  finishAction = () => {
    const { surveyId } = this.state
    const { restore, archive, surveys } = this.props

    const selected = surveys.find((i) => i._id === surveyId)

    selected && selected.archived
      ? restore(selected._id)
      : archive(selected._id)

    this.setState({ showArchiveConfirmation: false })
  }

  content = () => {
    const { surveys } = this.props
    const { surveyId } = this.state

    const selected = surveys && surveys.find((i) => i._id === surveyId)

    if (!selected) return {}

    return {
      header: (selected.archived ? 'Restore' : 'Archive') + ' survey',
      content: (
        <div className='content'>
          Are you sure you want to {selected.archived ? 'restore' : 'archive'}{' '}
          <strong>{selected.title}</strong>?
        </div>
      ),
    }
  }

  showExport = (survey) => {
    const { getSurveyInvites, getSurveyResponses } = this.props
    getSurveyInvites({
      survey: survey._id,
      all: true,
    })
    getSurveyResponses(survey._id, { all: 1 })
    this.setState({ survey, showExport: true })
  }

  hideExport = () => {
    const { getSurveyInvites } = this.props
    getSurveyInvites()
    this.setState({ showExport: false })
  }

  getSurveyStatus = (survey) => {
    if (survey.answered) {
      return 'Completed'
    }
    if (survey.started) {
      return 'In Progress'
    }
    return 'Not Started'
  }

  render() {
    const {
      surveys,
      goTo,

      surveyResult,
      surveyInvitesResult,
      toggleDisable,
      duplicateSurveyResult,
    } = this.props
    const {
      showArchiveConfirmation,
      surveyInvitesMap,
      search,
      includeArchived,
    } = this.state

    const { header, content } = this.content()

    return (
      <>
        <Confirm
          open={showArchiveConfirmation}
          header={header}
          content={content}
          onCancel={() => this.setState({ showArchiveConfirmation: false })}
          onConfirm={this.finishAction}
        />
        <TopBar
          search={search}
          includeArchived={includeArchived}
          onSearch={this.onSearch}
          toggleArchived={this.toggleArchived}
          onNew={() => goTo('/new-survey')}
          newText='New Survey'
        />
        <ContentLoader
          loading={
            surveyResult.requesting ||
            surveyInvitesResult.requesting ||
            duplicateSurveyResult.requesting
          }
          empty={!surveys.length}
        >
          <Table padded='very' basic='very' id='surveysList'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Diagnostic</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Last Edited By</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {surveys.map((row) => (
                <Table.Row key={row._id}>
                  <Table.Cell className='tableTitle'>
                    <Link to={`/new-survey/${row._id}`}>{row.title}</Link>
                  </Table.Cell>
                  <Table.Cell>
                    {row.diagnostic && row.diagnostic.title}
                  </Table.Cell>
                  <Table.Cell>
                    <ItemStatus
                      archived={row.archived}
                      inactive={row.disabled}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {row.lastEditedBy &&
                      `${row.lastEditedBy.firstName} ${row.lastEditedBy.lastName}`}
                    <UpdatedAt timestamp={row.updatedAt} />
                  </Table.Cell>
                  <Table.Cell className='actionsCell'>
                    <Dropdown text='Actions' item>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => goTo(`/new-survey/${row._id}`)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            window.open(`/survey-preview/${row._id}`)
                          }
                        >
                          Preview
                          <Icon
                            name='external'
                            size='small'
                            className='voltActionMenuPreviewIcon'
                          />
                        </Dropdown.Item>

                        {surveyInvitesMap[row._id] &&
                          !surveyInvitesMap[row._id].answered && (
                            <Dropdown.Item
                              onClick={() => window.open(`/survey/${row._id}`)}
                            >
                              Open Survey
                              <Icon
                                name='external'
                                size='small'
                                className='voltActionMenuPreviewIcon'
                              />
                            </Dropdown.Item>
                          )}

                        <Dropdown.Item onClick={() => toggleDisable(row)}>
                          {row.disabled ? 'Resume Survey' : 'Stop Survey'}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            goTo(`/new-survey/${row._id}?results=1`)
                          }
                        >
                          Export Data
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={this.rowActionClick}
                          value={row._id}
                        >
                          {row.archived ? 'Restore' : 'Archive'}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ContentLoader>
      </>
    )
  }
}

const mapStateToProps = ({
  surveyInvites,
  survey,
  surveyResponse,
  duplicateSurvey,
}) => ({
  surveyInvitesResult: surveyInvites,
  surveyInvites:
    surveyInvites.result && Array.isArray(surveyInvites.result.data)
      ? surveyInvites.result.data
      : [],
  surveyResult: survey,
  surveys:
    survey.result && Array.isArray(survey.result.data)
      ? survey.result.data
      : [],
  surveyResponses:
    surveyResponse.result && Array.isArray(surveyResponse.result.data)
      ? surveyResponse.result.data
      : [],
  duplicateSurveyResult: duplicateSurvey,
})

const mapDispatchToProps = (dispatch) => ({
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
  getSurveys: (query) => dispatch({ type: 'FETCH_SURVEYS_REQUESTED', query }),
  getSurveyResponses: (id, query) =>
    dispatch({ type: 'FETCH_SURVEY_RESPONSE_REQUESTED', id, query }),
  getSurveyInvites: (query = {}) =>
    dispatch({ type: 'FETCH_SURVEY_INVITES_REQUESTED', query }),
  archive: (id) => dispatch({ type: 'ARCHIVE_SURVEY_REQUESTED', id }),
  restore: (id) => dispatch({ type: 'RESTORE_SURVEY_REQUESTED', id }),
  duplicateSurvey: (id) => dispatch({ type: 'DUPLICATE_SURVEY_REQUESTED', id }),
  toggleDisable: (survey) =>
    dispatch({
      type: 'UPDATE_SURVEY_REQUESTED',
      payload: {
        ...survey,
        disabled: !survey.disabled,
        emailList: survey.emailList ? survey.emailList._id : null,
      },
      id: survey._id,
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Surveys)
