import React from 'react'
import { Input, Checkbox } from 'semantic-ui-react'

import Button from '../common/Button'

const TopBar = ({
  onSearch = () => true,
  toggleArchived = () => true,
  search,
  includeArchived,
  onNew,
  newText,
  hideArchiveToggle,
  hideNewButton
}) => (
    <div className='topBar'>
      {!hideNewButton && <Button onClick={onNew} rounded padded shadowed>
        {newText}
      </Button>}
      <Input
        value={search}
        icon={{ name: 'search', link: true }}
        placeholder='Search...'
        onChange={(e, data) => onSearch(data.value)}
      />
      {!hideArchiveToggle && (
        <Checkbox
          toggle
          label='See archived'
          checked={includeArchived}
          onChange={(e, data) => toggleArchived(data.checked)}
        />
      )}
    </div>
  )

export default TopBar
