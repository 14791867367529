import { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { Menu, Header, Tab, Label } from 'semantic-ui-react';
import { get } from 'lodash';

import InventoryForm from './inventoryForm';
import EntryList from './EntryList';
import TopBar from '../common/TopBar';
import SectorEntries from './SectorEntries';
import { mapEntriesToFactorIds, calculateActiveTab } from './inventoryUtils';

import { RoutePaths } from '../_routing/route-paths';
import { Breadcrumb } from '../page-layout/breadcrumb/Breadcrumb';

import './inventory.scss';

class Inventory extends Component {
  state = {
    entryMap: {},
    search: '',
    factor: null,
  };

  componentDidMount() {
    const { match, getInventoryDetails, getWheels, getEntries } = this.props;
    getEntries(match.params.id);
    getInventoryDetails(match.params.id);
    getWheels();
  }

  saveParameters = (parameters, inventoryId) => {
    const { updateInventory } = this.props;

    updateInventory(inventoryId, parameters);
  };

  goToSection = (section) => {
    const { match, history } = this.props;
    history.push(`/inventory/${match.params.id}/${section}`);
  };

  changeDimension = (sectorId, dimensionId) => {
    const { match, history } = this.props;
    this.setState({ factor: null });
    history.push(
      `/inventory/${match.params.id}/sector/${sectorId}/dimension/${dimensionId}`
    );
  };

  showParameters = () => {
    const { match, goTo } = this.props;
    goTo(`/inventory/${match.params.id}`);
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { inventory, sectors } = nextProps;
    const { getSectors, inventory: currentInventory } = this.props;

    if (!sectors.length && inventory && inventory !== currentInventory) {
      getSectors({ wheel: inventory.wheel });
    }
  };

  componentDidUpdate = (prevProps) => {
    const {
      inventoryResult,
      inventoryEntriesResult,
      inventoryEntries,
      getEntries,
      match,
      getInventoryDetails,
    } = this.props;

    if (
      inventoryResult !== prevProps.inventoryResult &&
      inventoryResult.result &&
      inventoryResult.isCreate
    ) {
      getInventoryDetails(match.params.id);
    }

    if (
      inventoryEntriesResult !== prevProps.inventoryEntriesResult &&
      inventoryEntriesResult.loaded &&
      inventoryEntriesResult.isFetch
    ) {
      const entryMap = inventoryEntries.reduce(mapEntriesToFactorIds, {});
      this.setState({ entryMap });
    }

    if (
      inventoryEntriesResult !== prevProps.inventoryEntriesResult &&
      inventoryEntriesResult.isCreate &&
      inventoryEntriesResult.loaded
    ) {
      getEntries(match.params.id);
    }
  };

  getParams = (history, match) => {
    let result = matchPath(history.location.pathname, {
      path: `${match.path}/sector/:sectorId/dimension/:dimensionId`,
    });

    if (result === null) {
      result = matchPath(history.location.pathname, {
        path: `${match.path}/:section`,
      });

      return {
        section: get(result, 'params.section', 'all'),
        sectorId: -1,
        dimensionId: -1,
      };
    }

    return {
      sectorId: parseInt(result.params.sectorId, 10),
      dimensionId: parseInt(result.params.dimensionId, 10),
    };
  };

  getTotalEntries = (sector) => {
    const { inventoryEntries } = this.props;
    const { entryMap } = this.state;

    if (!sector) {
      return inventoryEntries.length;
    }

    return sector.dimensions.reduce((dimensionEntries, dimension) => {
      return (
        dimensionEntries +
        dimension.factors.reduce((factorEntries, factor) => {
          let entries = 0;
          if (entryMap[factor._id]) {
            entries = entryMap[factor._id].length;
          }
          return factorEntries + entries;
        }, 0)
      );
    }, 0);
  };

  onAddEntry = () => {
    const { goTo, inventory } = this.props;
    goTo(`/entry?inventory=${inventory._id}`);
  };

  getTab = ({ label, sector, onClick }) => {
    const totalEntries = this.getTotalEntries(sector);

    return (
      <Menu.Item onClick={onClick} key={get(sector, '_id', 'all')}>
        {label}
        <Label color={totalEntries ? 'black' : 'grey'}>{totalEntries}</Label>
      </Menu.Item>
    );
  };

  onSearchChange = (search) => {
    this.setState({ search });
  };

  onSelectFactor = ({ factor, dimension }) => {
    this.setState({ factor, dimension });
  };

  render() {
    const { match, history, sectors, inventory } = this.props;
    const { section, sectorId } = this.getParams(history, match);
    const { search, entryMap, factor, dimension } = this.state;

    if (!inventory) return null;

    const activeTab = calculateActiveTab(sectorId, section, sectors);

    return (
      <div className='newInventoryComponent'>
        <Breadcrumb
          nodes={[
            { label: 'Dashboard', route: RoutePaths.Home },
            { label: 'Value Asset Inventories', route: RoutePaths.Inventories },
          ]}
        />

        <Header as='h1'>{inventory.title}</Header>

        <Tab
          activeIndex={activeTab}
          menu={{ secondary: true, pointing: true }}
          panes={[
            {
              menuItem: this.getTab({
                onClick: () => this.goToSection('all'),
                label: 'All Entries',
              }),
              render: () => (
                <div>
                  <TopBar
                    searchValue={this.state.search}
                    onPress={this.onAddEntry}
                    onSearchChange={this.onSearchChange}
                  />

                  <EntryList search={search} />
                </div>
              ),
            },
          ]
            .concat(
              sectors.map((sector, sectorIndex) => ({
                menuItem: this.getTab({
                  onClick: () => this.changeDimension(sectorIndex, 0),
                  label: sector.name,
                  sector,
                }),
                render: () => (
                  <div>
                    <TopBar
                      searchValue={this.state.search}
                      onPress={this.onAddEntry}
                      onSearchChange={this.onSearchChange}
                    />

                    {factor ? (
                      <EntryList
                        search={search}
                        factor={factor}
                        dimension={dimension}
                        entryMap={entryMap}
                        onSelectFactor={this.onSelectFactor}
                      />
                    ) : (
                      <SectorEntries
                        sector={sector}
                        entryMap={entryMap}
                        onSelectFactor={this.onSelectFactor}
                        search={search}
                      />
                    )}
                  </div>
                ),
              }))
            )
            .concat([
              {
                menuItem: (
                  <Menu.Item
                    onClick={() => this.goToSection('settings')}
                    key='settings'
                  >
                    Settings
                  </Menu.Item>
                ),
                render: () => (
                  <InventoryForm onSave={this.saveParameters} match={match} />
                ),
              },
              {
                menuItem: (
                  <Menu.Item
                    onClick={() => this.goToSection('files')}
                    key='files'
                    disabled
                  >
                    Files
                  </Menu.Item>
                ),
                render: () => <div>Files</div>,
              },
            ])}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ inventory, sectors, inventoryEntry }) => ({
  inventoryResult: inventory,
  inventory: get(inventory, 'result.data', null),
  sectors: get(sectors, 'result.data', []),
  inventoryEntries: get(inventoryEntry, 'result.data', []),
  inventoryEntriesResult: inventoryEntry,
});

const mapDispatchToProps = (dispatch) => ({
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
  getSectors: (query) => dispatch({ type: 'FETCH_SECTORS_REQUESTED', query }),
  getWheels: () => dispatch({ type: 'FETCH_WHEELS_REQUESTED' }),
  getInventoryDetails: (id) =>
    dispatch({ type: 'FETCH_INVENTORY_DETAILS_REQUESTED', id }),
  updateInventory: (id, payload) =>
    dispatch({ type: 'UPDATE_INVENTORY_REQUESTED', id, payload }),
  getEntries: (id, query) =>
    dispatch({ type: 'FETCH_INVENTORY_ENTRIES_REQUESTED', id, query }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
