import React from 'react'
import { Table, Label } from 'semantic-ui-react'

import UserActions from './UsersActions'
import { USER_TYPES } from '../../../types/user.types'

const { Row, Cell } = Table

const Role = ({ type }) => {
  return <span>{USER_TYPES[type] || ''}</span>
}

const Status = ({ item }) => {
  return <>{item.disabled && <Label color='red'>Disabled</Label>}</>
}

const UserListItem = ({ item, groupId, loggedInUsers }) => (
  <Row>
    <Cell>
      {item.firstName} {item.lastName}
    </Cell>
    <Cell>{item.email}</Cell>
    <Cell>
      <Role type={item.type} />
    </Cell>
    <Cell>
      <Status item={item} loggedInUsers={loggedInUsers} />
    </Cell>
    <Cell>
      <UserActions user={item} groupId={groupId} />
    </Cell>
  </Row>
)

export default UserListItem
