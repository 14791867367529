const defaultState = {
  requesting: false,
  error: false,
  result: null,
  fetchResult: null,
  isCreate: false,
  isDelete: false
}

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'FETCH_DIAGNOSTICS_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        isCreate: false,
        fetchResult: null,
        isDelete: false
      }
    case 'FETCH_DIAGNOSTICS_SUCCEEDED':
      return {
        ...state,
        requesting: false,
        error: false,
        isCreate: false,
        fetchResult: action.payload,
        isDelete: false
      }
    case 'FETCH_DIAGNOSTICS_FAILED':
      return {
        ...state,
        requesting: false,
        error: true,
        fetchResult: action.payload,
        isCreate: false,
        isDelete: false
      }
    case 'FETCH_DIAGNOSTIC_DETAILS_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        result: null,
        isCreate: false,
        isDelete: false
      }
    case 'FETCH_DIAGNOSTIC_DETAILS_SUCCEEDED':
      return {
        ...state,
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false,
        isDelete: false
      }
    case 'FETCH_DIAGNOSTIC_DETAILS_FAILED':
      return {
        ...state,
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false,
        isDelete: false
      }
    case 'CREATE_DIAGNOSTIC_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        result: null,
        isCreate: true,
        isDelete: false
      }
    case 'CREATE_DIAGNOSTIC_SUCCEEDED':
      return {
        ...state,
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true,
        isDelete: false
      }
    case 'CREATE_DIAGNOSTIC_FAILED':
      return {
        ...state,
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true,
        isDelete: false
      }
    case 'UPDATE_DIAGNOSTIC_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        isCreate: true,
        isDelete: false
      }
    case 'UPDATE_DIAGNOSTIC_SUCCEEDED':
      return {
        ...state,
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true,
        isDelete: false
      }
    case 'UPDATE_DIAGNOSTIC_FAILED':
      return {
        ...state,
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true,
        isDelete: false
      }
    case 'ARCHIVE_DIAGNOSTIC_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        result: null,
        isCreate: false,
        isDelete: true,
      }
    case 'ARCHIVE_DIAGNOSTIC_SUCCEEDED':
      return {
        ...state,
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false,
        isDelete: true
      }
    case 'ARCHIVE_DIAGNOSTIC_FAILED':
      return {
        ...state,
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false,
        isDelete: true
      }
    case 'RESTORE_DIAGNOSTIC_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        isCreate: false,
        isDelete: false
      }
    case 'RESTORE_DIAGNOSTIC_SUCCEEDED':
      return {
        ...state,
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true,
        isDelete: true
      }
    case 'RESTORE_DIAGNOSTIC_FAILED':
      return {
        ...state,
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true,
        isDelete: true
      }
    default:
      return state
  }
}
