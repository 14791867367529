import { getDefaultValueForFactor } from '../../admin-controls/sector-admin/config/defaultValuesForFactors'

const getDefaultLabelsForFactor = (sectorName, factor) => {
  const comparisonOptions = getDefaultValueForFactor(sectorName, 'competitors', 'options')

  return {
    comparisonOptions,
    competitorsQuestion: factor.competitorsQuestion || getDefaultValueForFactor(sectorName, 'competitors', 'question'),
    ratingQuestion: factor.ratingQuestion || getDefaultValueForFactor(sectorName, 'rating', 'question'),
    ratingOptions: getDefaultValueForFactor(sectorName, 'rating', 'options'),
    comparison: [
      {
        number: '1',
        label: comparisonOptions[0]
      },
      {
        number: '2',
        label: comparisonOptions[1]
      },
      {
        number: '3',
        label: comparisonOptions[2]
      },
      {
        number: '4',
        label: comparisonOptions[3]
      },
      {
        number: '5',
        label: comparisonOptions[4]
      }
    ]
  }
}

export { getDefaultLabelsForFactor }