import { call, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import api from '../../services/api'

function* validateToken(action) {
  try {
    const result = yield call(api.validateToken)
    yield put({ type: 'TOKEN_VALIDATION_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'TOKEN_VALIDATION_FAILED', payload: e })
    delete window.localStorage.clear()
    yield put(push('/login'))
  }
}

function* tokenValidation() {
  yield takeLatest('TOKEN_VALIDATION_REQUESTED', validateToken)
}

export default tokenValidation
