import React from 'react'
import { Message } from 'semantic-ui-react'

const parentPlurals = {
  inventory: 'inventories',
  wheel: 'wheels'
}

const DropdownMessage = ({
  items,
  type = 'diagnostic',
  parent = 'inventory'
}) => {
  const length = items.filter(i => !i.used && !i.archived).length
  return (
    <Message>
      <Message.Header>
        {length
          ? `You can only choose an active ${parent} at this time (not greyed out).`
          : `There are no ${parentPlurals[parent]} available for this ${type}.`}
      </Message.Header>

      <p>
        {!length && (
          <>
            Click here to add a <a href={`/new-${parent}`}>new {parent}</a>{' '}
            first.{' '}
          </>
        )}
        If you need to select an unavailable {parent} in your {type}, contact{' '}
        <a href='mailto:VOLTSupport@payersciences.com'>VOLT support</a> and we
        can provide additional help.
      </p>
    </Message>
  )
}

export default DropdownMessage
