import axios from 'axios'

function getInstance() {
  return axios.create({
    baseURL: `${process.env.REACT_APP_API}/api/v1`,
    headers: {
      'x-access-token': window.localStorage.accessToken || ''
    }
  })
}

export { getInstance }
