import { find } from 'lodash';

import { IInventoryDto } from "types/dto/inventory.dto";
import { IWheelDto } from "types/dto/wheel.dto";

export const getUpdatedWheels = (wheels: IWheelDto[], inventories: IInventoryDto[]): IWheelDto[] => {
  return wheels.map(wheel => {
    const foundInventory: IInventoryDto = find(inventories, (inventory: IInventoryDto) => {
      return wheel._id === inventory?.wheel?._id;
    });
    if (foundInventory || wheel.archived) {
      wheel.used = true;
    }
    return wheel;
  });
};

export const getUpdatedWheelsWithInventory = (wheels: IWheelDto[], inventory: IInventoryDto): IWheelDto[] => {
  return wheels.map(wheel => {
    if (wheel._id === (inventory?.wheel || inventory?.wheel?._id)) {
      // If we are on an existing inventory, make pre-selected wheel selectable
      wheel.used = false;
    }
    return wheel;
  });
};
