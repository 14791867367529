import { lookup } from 'mime-types'
import api from '../../services/api'

// const mime = require('mime-types')

// export const mimeTypes = new Mime(require('mime/types.json'))
// mimeTypes.define(require('mime/types/other.json'))

export const filterImages = (assetUrl) => {
  const mimetype = lookup(assetUrl)
  return !!(mimetype && mimetype.match(/image\/(.+)/g))
}

export const loadAssetsFromEntry = async (entryId, setImages) => {
  const { data } = await api.getAssets(entryId, 'entry')
  if (!data) return
  setImages(data.map((obj) => obj.imageUrl).filter(filterImages))
}
