import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, TextArea, Modal } from 'semantic-ui-react'

import Button from '../common/Button'

class EmailList extends Component {
  state = {
    name: '',
    emailList: '',
  }

  saveEmailList = () => {
    if (this.props.list) {
      this.props.updateEmailList(
        {
          name: this.state.name,
          emails: this.state.emailList.split('\n'),
        },
        this.props.list._id
      )
    } else {
      this.props.createEmailList({
        name: this.state.name,
        emails: this.state.emailList.split('\n'),
      })
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.show && !this.props.show) {
      if (props.list) {
        this.setState({
          name: props.list.name,
          emailList: props.list.emails.join('\n'),
        })
      } else {
        this.setState({
          name: '',
          emailList: '',
        })
      }
    }

    if (
      props.emailList !== this.props.emailList &&
      props.emailList.isCreate &&
      !props.emailList.error
    ) {
      this.props.getEmailLists()
      this.props.onClose()
    }
  }

  render() {
    const { onClose, show, list } = this.props

    return (
      <Modal open={show} onClose={onClose} centered={false}>
        <Modal.Header>{list ? 'Update' : 'Create new'} email list</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>NAME</label>
              <Input
                placeholder='Name'
                name='name'
                onChange={(e, data) => this.setState({ name: data.value })}
                value={this.state.name}
              />
            </Form.Field>
            <Form.Field>
              <label>EMAIL LIST</label>
              <TextArea
                placeholder='Email list (each on a new line)'
                name='emailList'
                onChange={(e, data) => this.setState({ emailList: data.value })}
                value={this.state.emailList}
                rows='4'
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button rounded shadowed onClick={this.saveEmailList}>
            Save
          </Button>
          <Button rounded color='white' onClick={onClose}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const mapStateToProps = ({ emailList }) => ({
  emailList,
})

const mapDispatchToProps = (dispatch) => ({
  updateEmailList: (payload, id) =>
    dispatch({ type: 'UPDATE_EMAIL_LIST_REQUESTED', payload, id }),
  createEmailList: (payload) =>
    dispatch({ type: 'CREATE_EMAIL_LIST_REQUESTED', payload }),
  getEmailLists: () => dispatch({ type: 'FETCH_EMAIL_LISTS_REQUESTED' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailList)
