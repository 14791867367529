import React, { Component } from 'react'
import { Label, Button, Header } from 'semantic-ui-react'
import ContentEditable from 'react-contenteditable'

const buttonStyle = {
  lineHeight: '1rem',
  fontSize: '1rem',
  verticalAlign: 'middle',
  display: 'inline',
  margin: '-1rem 0 0rem 0.5rem'
}

const editableComponentStyle = {
  display: 'inline',
  outline: '0px solid transparent'
}

const componentStyle = {
  display: 'inline',
  whiteSpace: 'no-wrap'
}

class EditableHeader extends Component {
  editableText = React.createRef()

  state = { focused: false }

  pasteAsPlainText = event => {
    event.preventDefault()

    const text = event.clipboardData.getData('text/plain')
    document.execCommand('insertHTML', false, text)
  }

  validateTitle = event => {
    const keyCode = event.keyCode || event.which

    // prevent entering new-lines
    if (keyCode === 13) {
      event.returnValue = false // eslint-disable-line no-param-reassign
      if (event.preventDefault) event.preventDefault()
    }
  }

  onSave = () => {
    const { item, onSave, property } = this.props
    const name = this.editableText.current.innerText

    if (!name) return

    if (name !== item[property]) {
      onSave({ [property]: name }, item._id)
    }

    this.setState({ focused: false })
  }

  onCancel = event => {
    event.preventDefault()
    this.setState({ focused: false })
  }

  onFocus = () => {
    this.setState({ focused: true })
  }

  onBlur = target => {
    this.setState({ focused: false })
  }

  render() {
    const { property, item } = this.props
    const { focused } = this.state

    return (
      <>
        <Header as='h1' style={componentStyle}>
          <ContentEditable
            style={editableComponentStyle}
            tagName='span'
            innerRef={this.editableText}
            html={item[property]}
            data-column='item'
            className='content-editable'
            onPaste={this.pasteAsPlainText}
            onKeyPress={this.validateTitle}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
        </Header>
        {focused && (
          <>
            <Button
              primary
              icon='check'
              onMouseDown={this.onSave}
              onClick={this.onSave}
              style={buttonStyle}
            />
            <Button icon='cancel' onClick={this.onCancel} style={buttonStyle} />
          </>
        )}
        {item.archived && (
          <Label color='red' horizontal>
            Archived
          </Label>
        )}
        {item.deleted && (
          <Label color='red' horizontal>
            Deleted
          </Label>
        )}
      </>
    )
  }
}

export default EditableHeader
