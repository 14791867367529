import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import Button from '../common/Button';

import Carousel from '../carousel';
import Prompt from './prompt';
import intro1 from './assets/intro1.png';
import intro2 from './assets/intro2.png';
import intro3 from './assets/intro3.png';
import intro4 from './assets/intro4.png';
import intro5 from './assets/intro5.png';

class Introduction extends Component {
  state = {
    step: 1,
    preIntro: true
  }

  handleSubmit() {
    const { surveyNavigation, saveTime } = this.props;

    saveTime();
    surveyNavigation.navigateToNext();
  }

  validateIntroduction() {
    const { surveyNavigation } = this.props;

    if (!surveyNavigation.checkIfCurrentStepIsDoneOrCompleted()) {
      if (this.state.step >= 5) {
        surveyNavigation.setCurrentStepToCompleted();
      }
    }
  }

  render() {
    const { surveyNavigation } = this.props;
    const { step, preIntro } = this.state;

    const navigationButton = (
      <div className='introductionContainer'>
        {step < 5 && (
          <Button
            color='black'
            size='big'
            onClick={() => this.setState({ step: step + 1 })}
          >Continue</Button>
        )}
        {step >= 5 && (
          <Button
            color='black'
            size='big'
            onClick={() => this.handleSubmit()}
          >Done</Button>
        )}
      </div>
    );

    return (
      <div className='surveySectorComponent'>
        <div className='surveyIntroductionComponent'>
          {!preIntro && (
            <div>
              <Header as='h1'>Introduction</Header>
              <Segment className='carouselContainer'>
                {this.validateIntroduction()}
                <Carousel
                  activeSlide={step}
                  onChange={({ slide }) => this.setState({ step: slide })}
                  slides={[
                    <div>
                      <div className='introductionStep'>
                        <div>
                          <p>
                            The image to the right is a visual construct used to organize information for this survey.  It represents 5 “value domains” or wheel sectors that decision makers, like yourself, may consider when making coverage decisions for pharmaceutical products.
                          </p>
                          <p>
                            For this survey, when we talk about coverage decisions, we mean decisions about whether and how products are covered, including decisions about tier placement, step edits, prior authorization, and any other management criteria designed to influence how many and which patients will have access to the treatment.
                          </p>
                        </div>
                        <div>
                          <img src={intro1} alt='intro1' />
                        </div>
                      </div>
                      {navigationButton}
                    </div>,
                    <div>
                      <div className='introductionStep'>
                        <div>
                          <p>
                            You will be rating a particular disease or condition, and treatments for that disease or condition, across a range of factors that make up each of the 5 value sectors shown on the prior screen.
                          </p>
                        </div>
                        <div>
                          <img src={intro2} alt='intro2' />
                        </div>
                      </div>
                      {navigationButton}
                    </div>,
                    <div>
                      <div className='introductionStep'>
                        <div>
                          <p>
                            Later you’ll be providing feedback on the relative importance of these factors in your coverage decisions.
                          </p>
                        </div>
                        <div>
                          <img src={intro3} alt='intro3' />
                        </div>
                      </div>
                      {navigationButton}
                    </div>,
                    <div>
                      <div className='introductionStep'>
                        <div>
                          <p>
                            The wheel will also serve as a visual frame to feed back to you, your responses.
                          </p>
                        </div>
                        <div>
                          <img src={intro4} alt='intro4' />
                        </div>
                      </div>
                      {navigationButton}
                    </div>,
                    <div>
                      <div className='introductionStep'>
                        <div>
                          <p>
                            User-friendly prompts will be provided to guide you all along the way.  The total estimated time to complete this survey is 1 hour.
                          </p>
                        </div>
                        <div>
                          <img src={intro5} alt='intro5' />
                        </div>
                      </div>
                      {navigationButton}
                    </div>
                  ]}
                />
              </Segment>
            </div>
          )}
        </div>

        {preIntro && (
          <Prompt>
            <div className='promptInstructions introduction'>
              <div>
                Let’s begin by introducing one of our main features…
              </div>
              <div>
                At the top of the screen, you’ll see your "Task Line" which shows you where you are in the survey, in the context of all of the <br />tasks you will be completing.
              </div>
              <div>
                When you’re ready, click Next to continue.
              </div>
            </div>
            <div className='buttonsContainer'>
              {/*  TODO: enable previous buttons
              <Button
                size='large'
                onClick={() => surveyNavigation.navigateToPrevious()}
              >Previous</Button> */}
              <Button
                color='pink'
                size='large'
                onClick={() => this.setState({ preIntro: false })}
              >Next</Button>
            </div>
          </Prompt>
        )}
        {!preIntro && (
          <Prompt>
            {step !== 5 && (
              <div className='promptInstructions introduction'>
                <div />
                <div>
                  This survey works a little differently from other surveys. <br />
                  To become familiar with this approach, take a moment to review the information in the window above. <br />
                  Click the arrow buttons to navigate content.
                </div>
                <div>
                  Once you have completed this section, the Next button will be active and you can move on to the next step.
                </div>
              </div>
            )}
            {step === 5 && (
              <div className='promptInstructions introduction'>
                <div>
                  You have completed this step.
                </div>
                <div>
                  When you’re ready, click Next to continue.
                </div>
              </div>
            )}
            <div className='buttonsContainer'>
              {/*  TODO: enable previous buttons
              <Button
                size='large'
                onClick={() => this.setState({ preIntro: true })}
              >Previous</Button> */}
              <Button
                color={surveyNavigation.checkIfCurrentStepIsDoneOrCompleted() ? 'pink' : 'gray'}
                disabled={!surveyNavigation.checkIfCurrentStepIsDoneOrCompleted()}
                size='large'
                onClick={() => this.handleSubmit()}
              >Next</Button>
            </div>
          </Prompt>
        )}

      </div>
    )
  }
}

export default Introduction;
