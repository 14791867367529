import React from 'react'
import { Label } from 'semantic-ui-react'

const getTotalEntries = (factor, entryMap) => {
  return entryMap[factor._id] ? entryMap[factor._id].length : 0
}

const filterFactors = (factors, search) => {
  if (!search) {
    return factors
  }

  return factors.filter(factor => factor.name.toUpperCase().includes(search.toUpperCase()))
}

const SectorEntries = ({ sector, entryMap, onSelectFactor = () => true, search }) => (
  <div className='sectorEntries'>
    {sector.dimensions.map(dimension => (
      <div
        key={dimension._id}
        className='dimension'>
        <div className='title'>{dimension.name}</div>
        <div className='factors'>
          {filterFactors(dimension.factors, search).map(factor => (
            <div
              className={`factor ${getTotalEntries(factor, entryMap) ? '' : 'disabled'}`}
              key={factor._id}
              onClick={() => onSelectFactor({ factor, dimension })}
            >
              {factor.name}
              <Label color={getTotalEntries(factor, entryMap) ? 'black' : 'grey'}>{getTotalEntries(factor, entryMap)}</Label>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
)

export default SectorEntries
