const defaultState = {
  requesting: false,
  error: false,
  result: null,
  isCreate: false
}

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'INVITE_USER_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false
      }
    case 'INVITE_USER_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: null,
        isCreate: false
      }
    case 'INVITE_USER_FAILED':
      return {
        requesting: false,
        error: true,
        result: null,
        isCreate: false
      }
    default:
      return state
  }
}
