const defaultState = {
  requesting: false,
  error: false,
  result: null,
  isCreate: false,
  isDelete: false
}

export default function counter(state = defaultState, { payload, type }) {
  switch (type) {
    case 'FETCH_SURVEYS_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false
      }
    case 'FETCH_SURVEYS_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isCreate: false
      }
    case 'FETCH_SURVEYS_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isCreate: false
      }
    case 'FETCH_SURVEY_DETAILS_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false
      }
    case 'FETCH_SURVEY_DETAILS_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isCreate: false
      }
    case 'FETCH_SURVEY_DETAILS_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isCreate: false
      }
    case 'CREATE_SURVEY_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: true
      }
    case 'CREATE_SURVEY_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isCreate: true
      }
    case 'CREATE_SURVEY_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isCreate: true
      }
    case 'UPDATE_SURVEY_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: true
      }
    case 'UPDATE_SURVEY_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isCreate: true
      }
    case 'UPDATE_SURVEY_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isCreate: true
      }
    case 'UPDATE_SURVEY_PROPS_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        isCreate: false
      }
    case 'UPDATE_SURVEY_PROPS_SUCCEEDED':
      return {
        ...state,
        requesting: false,
        result: {
          data: {
            ...payload.data,
            diagnostic: {
              ...state.result.data.diagnostic
            }
          }
        }
      }
    case 'ARCHIVE_SURVEY_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false,
        isDelete: true
      }
    case 'ARCHIVE_SURVEY_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isCreate: false,
        isDelete: true
      }
    case 'ARCHIVE_SURVEY_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isCreate: false,
        isDelete: true
      }
    case 'RESTORE_SURVEY_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        isCreate: false,
        isDelete: false
      }
    case 'RESTORE_SURVEY_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: payload,
        isCreate: true,
        isDelete: true
      }
    case 'RESTORE_SURVEY_FAILED':
      return {
        requesting: false,
        error: true,
        result: payload,
        isCreate: true,
        isDelete: true
      }
    default:
      return state
  }
}
