import owaspPasswordStrengthTest from 'owasp-password-strength-test'

const passwordHelpers = {
  validatePasswordStrength(password) {
    const passwordStrength = owaspPasswordStrengthTest.test(password)

    if (passwordStrength.errors.length !== 0) {
      return passwordStrength.errors[0]
    }

    return true
  }
}

export default passwordHelpers
