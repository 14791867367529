import { call, put, takeLatest } from 'redux-saga/effects'
import api from '../../services/api'
import { handleError, handleSuccess } from './helpers/saga-helpers'

function* getFactor(action) {
  try {
    const result = yield call(api.getFactor, action.id)
    yield put({ type: 'FETCH_FACTOR_SUCCEEDED', payload: result })
  } catch (e) {
    yield handleError({ error: e, type: 'FETCH_FACTOR_FAILED' })
  }
}

function* updateFactors(action) {
  try {
    const result = yield call(api.updateFactors, action.payload)
    yield handleSuccess('UPDATE_FACTORS_SUCCEEDED', result, 'Factors updated!')
  } catch (e) {
    yield handleError({ error: e, type: 'UPDATE_FACTORS_FAILED' })
  }
}

function* createFactor(action) {
  try {
    const result = yield call(api.createFactor, action.payload)
    yield handleSuccess(
      'CREATE_FACTOR_SUCCEEDED',
      result,
      'Factor created successfully!'
    )
  } catch (e) {
    yield handleError({ error: e, type: 'CREATE_FACTOR_FAILED' })
  }
}

function* updateFactor(action) {
  try {
    const result = yield call(api.updateFactor, action.payload, action.id)
    yield handleSuccess(
      'UPDATE_FACTOR_SUCCEEDED',
      result,
      'Factor updated successfully!'
    )
  } catch (e) {
    yield handleError({ error: e, type: 'UPDATE_FACTOR_FAILED' })
  }
}

function* deleteFactor(action) {
  try {
    const result = yield call(api.deleteFactor, action.id)
    yield handleSuccess(
      'DELETE_FACTOR_SUCCEEDED',
      result,
      'Factor deleted successfully!'
    )
  } catch (e) {
    yield handleError({ error: e, type: 'DELETE_FACTOR_FAILED' })
  }
}

function* factors() {
  yield takeLatest('FETCH_FACTOR_REQUESTED', getFactor)
  yield takeLatest('CREATE_FACTOR_REQUESTED', createFactor)
  yield takeLatest('UPDATE_FACTOR_REQUESTED', updateFactor)
  yield takeLatest('UPDATE_FACTORS_REQUESTED', updateFactors)
  yield takeLatest('DELETE_FACTOR_REQUESTED', deleteFactor)
}

export default factors
