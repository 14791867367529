import { get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Header, Menu, Segment } from 'semantic-ui-react';

import Diagnostics from './diagnostics';
import Inventories from './inventories';
import Surveys from './surveys';
import Users from './users';
import Wheels from './wheels';

import { RoutePaths } from '../_routing/route-paths';
import { GroupsPage } from '../group';
import { Breadcrumb } from '../page-layout/breadcrumb/Breadcrumb';

import { RootContext } from '../../contexts/RootContext.types';
import { getUpdatedWheels } from '../../helpers/wheels.helper';
import { getArray } from '../../lib/utils';
import { TABS } from '../../types/dashboard.types';
import { IUserDto } from 'types/dto/user.dto';
import { IInventoryDto } from 'types/dto/inventory.dto';
import { IWheelDto } from 'types/dto/wheel.dto';
import { IDiagnosticDto } from 'types/dto/diagnostic.dto';

interface IAdminDashboardProps {
  diagnostics: IDiagnosticDto[];
  inventories: IInventoryDto[];
  user: IUserDto;
  wheels: IWheelDto[];
  getDiagnostics: () => void;
  getInventory: () => void;
  goTo: (url: string) => void;
  openCreateGroupModal: () => void;
  location: any;
  match: any;
};

const AdminDashboard: React.FC<IAdminDashboardProps> = props => {
  const [search, setSearch] = useState('');
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const { setWheelsWithInventories } = useContext(RootContext);
  const { getDiagnostics, getInventory, goTo, location, user, inventories, wheels } = props;
  const isAdmin = user.type === 'A';
  const isClient = user.type === 'C';

  useEffect(() => {
    getInventory();
    getDiagnostics();
  }, []);

  useEffect(() => {
    const updatedWheels = getUpdatedWheels(wheels, inventories);
    setWheelsWithInventories(updatedWheels);
  }, [wheels, inventories]);

  const shouldSeeTab = (tab: TABS) => {
    if (!isClient) return true;
    if (tab === TABS.SURVEYS) return false;
    return user?.enabledTabs?.includes(tab);
  };

  const getHomePage = () => {
    if (shouldSeeTab(TABS.WHEELS)) return <Redirect to={RoutePaths.Wheels} />;
    if (shouldSeeTab(TABS.INVENTORIES))
      return <Redirect to={RoutePaths.Inventories} />;
    if (shouldSeeTab(TABS.DIAGNOSTICS))
      return <Redirect to={RoutePaths.Diagnostics} />;
    if (shouldSeeTab(TABS.SURVEYS))
      return <Redirect to={RoutePaths.Surveys} />;
    return <></>;
  };

  return (
    <>
      <Breadcrumb nodes={[{ label: 'Dashboard', route: RoutePaths.Home }]} />

      <Header as='h1'>Dashboard</Header>

      <Menu attached='top' tabular pointing secondary>
        {shouldSeeTab(TABS.WHEELS) && (
          <Menu.Item
            id='wheelsMenu'
            name='Wheels'
            active={location.pathname === RoutePaths.Wheels}
            onClick={() => {
              setSearch('');
              goTo(RoutePaths.Wheels);
            }}
          />
        )}
        {shouldSeeTab(TABS.INVENTORIES) && (
          <Menu.Item
            id='inventoriesMenu'
            name='Asset Inventories'
            active={location.pathname === RoutePaths.Inventories}
            onClick={() => {
              setSearch('');
              goTo(RoutePaths.Inventories);
            }}
          />
        )}
        {shouldSeeTab(TABS.DIAGNOSTICS) && (
          <Menu.Item
            id='diagnosticsMenu'
            name='Diagnostics'
            active={location.pathname === RoutePaths.Diagnostics}
            onClick={() => {
              setSearch('');
              goTo(RoutePaths.Diagnostics);
            }}
          />
        )}
        {shouldSeeTab(TABS.SURVEYS) && (
          <Menu.Item
            id='surveysMenu'
            name='Surveys'
            active={location.pathname === RoutePaths.Surveys}
            onClick={() => {
              setSearch('');
              goTo(RoutePaths.Surveys);
            }}
          />
        )}
        {isAdmin && (
          <Menu.Item
            id='usersMenu'
            name='Users'
            active={location.pathname === RoutePaths.Users}
            onClick={() => {
              setSearch('');
              goTo(RoutePaths.Users);
            }}
          />
        )}
        {isAdmin && (
          <Menu.Item
            id='groupsMenu'
            name='groups'
            active={location.pathname.includes(RoutePaths.Groups)}
            onClick={() => {
              setSearch('');
              goTo(RoutePaths.Groups);
            }}
          />
        )}
      </Menu>

      <Segment className='dashboardSegment' attached='bottom'>
        <Switch>
          <Route exact path={RoutePaths.Home} render={getHomePage} />
          {shouldSeeTab(TABS.WHEELS) && (
            <Route
              path={RoutePaths.Wheels}
              render={(props) => (
                <Wheels
                  {...props}
                  search={search}
                  includeArchived={includeArchived}
                />
              )}
            />
          )}
          {shouldSeeTab(TABS.INVENTORIES) && (
            <Route
              path={RoutePaths.Inventories}
              render={(props) => (
                <Inventories
                  {...props}
                  search={search}
                  includeArchived={includeArchived}
                />
              )}
            />
          )}
          {shouldSeeTab(TABS.DIAGNOSTICS) && (
            <Route
              path={RoutePaths.Diagnostics}
              render={(props) => (
                <Diagnostics
                  {...props}
                  search={search}
                  includeArchived={includeArchived}
                />
              )}
            />
          )}
          {shouldSeeTab(TABS.SURVEYS) && (
            <Route
              path={RoutePaths.Surveys}
              render={(props) => (
                <Surveys
                  {...props}
                  search={search}
                  includeArchived={includeArchived}
                />
              )}
            />
          )}
          {isAdmin && (
            <Route
              path={RoutePaths.Users}
              render={(props) => (
                <Users
                  {...props}
                  search={search}
                  includeArchived={includeArchived}
                />
              )}
            />
          )}
          {isAdmin && (
            <Route
              exact
              path={RoutePaths.Groups}
              render={(props) => (
                <GroupsPage
                  {...props}
                  search={search}
                  includeArchived={includeArchived}
                />
              )}
            />
          )}
          <Route render={() => <Redirect to={RoutePaths.NotFound} />} />
        </Switch>
      </Segment>
    </>
  );
};

const mapStateToProps = ({
  tokenValidation,
  inventory: inventoryRequest,
  diagnostic,
  wheels: wheelsRequest,
}) => {
  const inventories = getArray(inventoryRequest, 'result.data');
  const wheels = getArray(wheelsRequest, 'result.data');
  return {
    user: get(tokenValidation, 'result.data'),
    inventories,
    diagnostics: getArray(diagnostic, 'fetchResult.data'),
    wheels
  };
};

const mapDispatchToProps = (dispatch) => ({
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
  openCreateGroupModal: () =>
    dispatch({ type: 'OPEN_MODAL', modal: 'createGroup' }),
  getInventory: () => dispatch({ type: 'FETCH_INVENTORY_REQUESTED' }),
  getDiagnostics: () => dispatch({ type: 'FETCH_DIAGNOSTICS_REQUESTED' }),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type AdminDashboardProps = ConnectedProps<typeof connector>;

export default connector(AdminDashboard);