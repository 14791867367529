const defaultValue = {
  open: false,
  params: null
}

const defaultState = {
  createGroup: {
    ...defaultValue
  }
}

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'CLOSE_MODAL':
      return {
        ...state,
        [action.modal]: {
          ...defaultValue,
          open: false
        }
      }
    case 'OPEN_MODAL':
      return {
        ...state,
        [action.modal]: {
          open: true,
          params: action.params || null
        }
      }
    default:
      return state
  }
}
