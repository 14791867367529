import React from 'react'
import { Loader, Message } from 'semantic-ui-react'

const ContentLoader = ({ loading, empty, children }) => {
  if (loading) {
    return <Loader active inline='centered' />
  }

  if (empty) {
    return <Message>Your dashboard is empty.</Message>
  }

  return children
}

export default ContentLoader
