import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { Form, Input, Modal, TextArea } from 'semantic-ui-react'

import Button from '../../common/Button'

class AddDimension extends Component {
  state = {}

  saveDimension = () => {
    const { wheel } = this.props

    if (this.props.dimension) {
      this.props.updateDimension(
        {
          name: this.state.name,
          description: this.state.description,
        },
        this.props.dimension._id
      )
    } else {
      this.props.createDimension({
        name: this.state.name,
        description: this.state.description,
        sector: this.props.sector._id,
        wheel,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.show && !this.props.show) {
      if (props.dimension) {
        this.setState({
          name: props.dimension.name,
          description: props.dimension.description,
        })
      } else {
        this.setState({
          name: '',
          description: '',
        })
      }
    }

    if (
      props.dimensions !== this.props.dimensions &&
      props.dimensions.isCreate &&
      !props.dimensions.error &&
      props.dimensions.result
    ) {
      this.props.getSectors({ wheel: this.props.wheel })
      this.props.onClose()
    }
  }

  render() {
    const { onClose, show, onDelete, dimension, user } = this.props

    return (
      <Modal open={show} onClose={onClose} centered={false}>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>NAME</label>
              <Input
                placeholder='Name'
                name='name'
                onChange={(e, data) => this.setState({ name: data.value })}
                value={this.state.name}
              />
            </Form.Field>
            <Form.Field>
              <label>DESCRIPTION</label>
              <TextArea
                placeholder='Description'
                name='description'
                onChange={(e, data) =>
                  this.setState({ description: data.value })
                }
                value={this.state.description}
              />
            </Form.Field>
          </Form>

          <div className='adminModal actionButtons'>
            <div>
              {!isEmpty(dimension) && user.type === 'A' && (
                <Button rounded color='red' onClick={() => onDelete(dimension)}>
                  Delete
                </Button>
              )}
            </div>
            <div>
              <Button rounded onClick={onClose} color='white'>
                Cancel
              </Button>
              <Button rounded shadowed onClick={this.saveDimension}>
                Save
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    )
  }
}

const mapStateToProps = ({ dimensions, tokenValidation }) => ({
  dimensions,
  user: get(tokenValidation, 'result.data'),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getSectors: (query) => dispatch({ type: 'FETCH_SECTORS_REQUESTED', query }),
  updateDimension: (payload, id) =>
    dispatch({ type: 'UPDATE_DIMENSION_REQUESTED', payload, id }),
  createDimension: (payload) =>
    dispatch({ type: 'CREATE_DIMENSION_REQUESTED', payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddDimension)
