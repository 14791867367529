import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Input,
  List,
  Modal,
  Button,
  Checkbox,
  TextArea
} from 'semantic-ui-react'

class AdditionalCoverage extends Component {
  state = {
    activeQuestion: -1,
    question: {},
    showQuestionModal: false,
    option: '',
    showDescribe: false
  }

  updateInstructions = value => {
    const { onValueChange, additionalCoverage } = this.props
    const newData = Object.assign({}, additionalCoverage, {
      instructions: value
    })
    onValueChange({ field: 'additionalCoverage', value: newData })
  }

  createQuestion = () => {
    this.setState({
      activeQuestion: -1,
      showQuestionModal: true,
      question: {},
      option: '',
      showDescribe: false
    })
  }

  openQuestion = index => {
    const { additionalCoverage } = this.props
    const { questions = [] } = additionalCoverage

    this.setState({
      activeQuestion: index,
      showQuestionModal: true,
      question: Object.assign({}, questions[index]),
      option: '',
      showDescribe: false
    })
  }

  updateQuestion = ({ field, value }) => {
    const { question } = this.state
    const newQuestion = Object.assign({}, question)
    newQuestion[field] = value
    this.setState({ question: newQuestion })
  }

  addOption = () => {
    const { option, showDescribe, question } = this.state

    if (!option) return

    const newQuestion = Object.assign({}, question)

    if (!newQuestion.options) newQuestion.options = []
    newQuestion.options.push({
      text: option,
      showDescribe
    })

    this.setState({
      question: newQuestion,
      option: '',
      showDescribe: false
    })
  }

  updateOption = (field, data, index) => {
    const { question } = this.state

    const newQuestion = Object.assign({}, question)

    newQuestion.options[index][field] = data

    this.setState({ question: newQuestion })
  }

  removeOption = index => {
    const { question } = this.state

    const newQuestion = Object.assign({}, question)

    newQuestion.options.splice(index, 1)

    this.setState({ question: newQuestion })
  }

  saveQuestion = () => {
    const { question, activeQuestion } = this.state
    const { additionalCoverage, onValueChange } = this.props
    const { questions = [] } = additionalCoverage

    if (!question.text) return

    const newAdditionalCoverage = Object.assign({}, additionalCoverage)
    if (activeQuestion === -1) {
      questions.push(question)
    } else {
      questions[activeQuestion] = question
    }

    newAdditionalCoverage.questions = questions

    onValueChange({ field: 'additionalCoverage', value: newAdditionalCoverage })
    this.setState({ showQuestionModal: false })
  }

  removeQuestion = index => {
    const { additionalCoverage, onValueChange } = this.props
    const newAdditionalCoverage = Object.assign({}, additionalCoverage)
    newAdditionalCoverage.questions.splice(index, 1)
    onValueChange({ field: 'additionalCoverage', value: newAdditionalCoverage })
  }

  render() {
    const {
      showQuestionModal,
      activeQuestion,
      question,
      option,
      showDescribe
    } = this.state
    const {
      additionalCoverage,
      hideAdditionalCoverage,
      onValueChange
    } = this.props
    const { instructions = '', questions = [] } = additionalCoverage
    const { options = [] } = question

    return (
      <div>
        <Modal
          open={showQuestionModal}
          onClose={() => this.setState({ showQuestionModal: false })}
          closeIcon
        >
          <Modal.Header>
            {activeQuestion === -1 ? 'New' : 'Edit'} Question
          </Modal.Header>
          <Modal.Content scrolling>
            <Checkbox
              label='Show when user selects "Yes"'
              checked={question.showOnYes}
              onChange={(e, data) =>
                this.updateQuestion({ field: 'showOnYes', value: data.checked })
              }
            />
            <br />
            <br />
            <Input
              value={question.text}
              fluid
              placeholder='Question text'
              onChange={(e, data) =>
                this.updateQuestion({ field: 'text', value: data.value })
              }
            />

            <List divided relaxed>
              <List.Item className='questionOption'>
                <Form onSubmit={this.addOption}>
                  <Input
                    placeholder='Option'
                    value={option}
                    onChange={(e, data) =>
                      this.setState({ option: data.value })
                    }
                  />
                  <Checkbox
                    label='Show describe'
                    checked={showDescribe}
                    onChange={(e, data) =>
                      this.setState({ showDescribe: data.checked })
                    }
                  />
                  <Button primary onClick={this.addOption}>
                    Add Option
                  </Button>
                </Form>
              </List.Item>
              {options.map((option, index) => (
                <List.Item key={`option_${index}`} className='questionOption'>
                  <Input
                    placeholder='Option'
                    value={option.text}
                    onChange={(e, data) =>
                      this.updateOption('text', data.value, index)
                    }
                  />
                  <Checkbox
                    label='Show describe'
                    checked={option.showDescribe}
                    onChange={(e, data) =>
                      this.updateOption('showDescribe', data.checked, index)
                    }
                  />
                  <Button onClick={() => this.removeOption(index)} color='red'>
                    Remove
                  </Button>
                </List.Item>
              ))}
            </List>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.saveQuestion} primary>
              Ok
            </Button>
            <Button onClick={() => this.setState({ showQuestionModal: false })}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Form>
          <Form.Field>
            <Checkbox
              checked={hideAdditionalCoverage}
              onChange={(event, { checked }) =>
                onValueChange({
                  field: 'hideAdditionalCoverage',
                  value: checked
                })
              }
              label='Do not include in this survey'
            />
          </Form.Field>
          <Form.Field>
            <label>Instructions</label>
            <TextArea
              placeholder='Answer a few questions regarding coverage of Product X and other hematology/oncology therapies.'
              value={instructions}
              onChange={(e, data) => this.updateInstructions(data.value)}
            />
          </Form.Field>
        </Form>
        <br />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              marginRight: '10px'
            }}
          >
            Questions
          </div>
          <Button
            circular
            icon='add'
            title='Add Question'
            onClick={this.createQuestion}
          />
        </div>

        <List divided relaxed>
          {questions.map((question, index) => (
            <List.Item key={`question_${index}`}>
              <List.Icon
                name='question circle outline'
                size='large'
                verticalAlign='middle'
              />
              <List.Content>
                <List.Header as='a' onClick={() => this.openQuestion(index)}>
                  {question.text}
                </List.Header>
                <List.Description
                  as='a'
                  onClick={() => this.removeQuestion(index)}
                >
                  Remove
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </div>
    )
  }
}

const mapStateToProps = ({ survey }) => ({ survey })

export default connect(mapStateToProps)(AdditionalCoverage)
