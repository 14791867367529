import { get, isEmpty } from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Modal, TextArea, Checkbox } from 'semantic-ui-react'

import { getDefaultValueForFactor } from './config/defaultValuesForFactors'

import Button from '../../common/Button'

class AddFactor extends Component {
  state = {
    competitorsOptions: [],
    ratingOptions: [],
    competitorsOptionsEnabled: [],
    ratingOptionsEnabled: [],
    nameError: false,
  }

  saveFactor = () => {
    const {
      name,
      description,
      flipRatings,
      competitorsQuestion,
      competitorsOptions,
      ratingQuestion,
      ratingOptions,
      nameError,
    } = this.state

    const { wheel, factor, createFactor, dimension, updateFactor } = this.props

    if (nameError) {
      return
    }

    if (factor) {
      updateFactor(
        {
          name,
          description,
          flipRatings,
          competitorsQuestion,
          competitorsOptions,
          ratingQuestion,
          ratingOptions,
        },
        factor._id
      )
      factor.name = name
    } else {
      createFactor({
        name,
        description,
        flipRatings,
        competitorsQuestion,
        competitorsOptions,
        ratingQuestion,
        ratingOptions,
        dimension: dimension._id,
        wheel,
      })
    }
  }

  deleteFactor = (id) => {
    const { onClose, onDelete } = this.props
    onDelete(id)
    onClose()
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { factor, show, factors, getSectors, onClose, sector } = props
    const { wheel } = this.props

    if (show && !this.props.show) {
      let newState
      if (factor) {
        newState = {
          name: factor.name,
          description: factor.description,
          flipRatings: factor.flipRatings || false,
          competitorsQuestion:
            factor.competitorsQuestion ||
            getDefaultValueForFactor(sector.name, 'competitors', 'question'),
          competitorsOptions:
            factor.competitorsOptions && factor.competitorsOptions.length
              ? factor.competitorsOptions
              : getDefaultValueForFactor(sector.name, 'competitors', 'options'),
          ratingQuestion:
            factor.ratingQuestion ||
            getDefaultValueForFactor(sector.name, 'rating', 'question'),
          ratingOptions:
            factor.ratingOptions && factor.ratingOptions.length
              ? factor.ratingOptions
              : getDefaultValueForFactor(sector.name, 'rating', 'options'),
        }
      } else {
        newState = {
          name: '',
          description: '',
          flipRatings: false,
          competitorsQuestion: getDefaultValueForFactor(
            sector.name,
            'competitors',
            'question'
          ),
          competitorsOptions: getDefaultValueForFactor(
            sector.name,
            'competitors',
            'options'
          ),
          ratingQuestion: getDefaultValueForFactor(
            sector.name,
            'rating',
            'question'
          ),
          ratingOptions: getDefaultValueForFactor(
            sector.name,
            'rating',
            'options'
          ),
        }
      }

      newState.competitorsOptionsEnabled = newState.competitorsOptions.map(
        (val) => (val ? true : false)
      )
      newState.ratingOptionsEnabled = newState.ratingOptions.map((val) =>
        val ? true : false
      )

      this.setState(newState)
    }

    if (
      factors !== this.props.factors &&
      factors.isCreate &&
      !factors.error &&
      factors.result
    ) {
      if (wheel) {
        getSectors({ wheel, all: true })
      }
      onClose()
    }
  }

  setOption = (index, field, value) => {
    const newOptions = this.state[field].slice()
    newOptions[index] = value
    const newState = {}
    newState[field] = newOptions
    this.setState(newState)
  }

  getDisabled = (index) => {
    if (this.state.ratingOptions.length === 5) {
      return index !== 0 && index !== 2 && index !== 4
    }
    return index !== 0 && index !== 3 && index !== 6
  }

  handleChange = (e, data) => {
    let nextState = {}
    nextState = { nameError: data.name === 'name' && !data.value }

    const value = data.type === 'checkbox' ? data.checked : data.value
    nextState[data.name] = value

    this.setState(nextState)
  }

  calculateLabel = (position, total) => {
    if (position === total - 1) {
      return 'HIGH'
    } else if (position === Math.floor(total / 2)) {
      return 'MID'
    } else if (position === 0) {
      return 'LOW'
    } else {
      return null
    }
  }

  render() {
    const { onClose, show, sector, factor, user } = this.props
    const {
      name,
      description,
      competitorsQuestion,
      competitorsOptions,
      ratingQuestion,
      ratingOptions,
      flipRatings,
      competitorsOptionsEnabled,
      ratingOptionsEnabled,
      nameError,
    } = this.state

    const flipRatingsRadio = (
      <Form.Field>
        <Checkbox
          slider
          label='Flip ratings scale in external survey (payers)'
          className='flipRatingsToggle'
          name='flipRatings'
          checked={flipRatings}
          onChange={this.handleChange}
        />
      </Form.Field>
    )

    return (
      <Modal
        className='addFactorModal'
        open={show}
        closeIcon
        onClose={onClose}
        centered={false}
      >
        <Modal.Content scrolling>
          <Form>
            <Form.Field>
              <label>Title</label>
              <Input
                placeholder='Name'
                name='name'
                error={nameError}
                onChange={this.handleChange}
                value={name}
              />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <TextArea
                placeholder='Description'
                name='description'
                onChange={this.handleChange}
                value={description}
              />
            </Form.Field>
            {sector.compare && (
              <>
                <Form.Field className='question'>
                  <label>Question</label>
                  <Input
                    placeholder='Competitors question'
                    name='competitorsQuestion'
                    onChange={this.handleChange}
                    value={competitorsQuestion}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Ratings (5-point Likert Scale)</label>

                  {flipRatingsRadio}

                  {competitorsOptions.map((option, index) => {
                    const valueIndex = flipRatings
                      ? competitorsOptions.length - 1 - index
                      : index

                    return (
                      <div className='ratingOption' key={index}>
                        <div className='factorInputLabel'>
                          {this.calculateLabel(index, ratingOptions.length)}
                        </div>
                        <Checkbox
                          checked={competitorsOptionsEnabled[valueIndex]}
                          onChange={(e, data) => {
                            this.setOption(
                              valueIndex,
                              'competitorsOptionsEnabled',
                              data.checked
                            )
                            if (!data.checked) {
                              this.setOption(
                                valueIndex,
                                'competitorsOptions',
                                ''
                              )
                            }
                          }}
                        />
                        <Input
                          placeholder={`Option ${index + 1}`}
                          name='competitorsOption'
                          onChange={(e, data) =>
                            this.setOption(
                              valueIndex,
                              'competitorsOptions',
                              data.value
                            )
                          }
                          value={competitorsOptions[valueIndex]}
                          disabled={!competitorsOptionsEnabled[valueIndex]}
                        >
                          <input />
                        </Input>
                      </div>
                    )
                  })}
                </Form.Field>
              </>
            )}
            {!sector.compare && (
              <>
                <Form.Field className='question'>
                  <label>Question</label>
                  <Input
                    placeholder='Question'
                    name='ratingQuestion'
                    onChange={this.handleChange}
                    value={ratingQuestion}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Ratings (7-point Likert Scale)</label>

                  {flipRatingsRadio}

                  {ratingOptions.map((option, index) => {
                    const valueIndex = flipRatings
                      ? ratingOptions.length - 1 - index
                      : index

                    return (
                      <div className='ratingOption' key={index}>
                        <div className='factorInputLabel'>
                          {this.calculateLabel(index, ratingOptions.length)}
                        </div>
                        <Checkbox
                          checked={ratingOptionsEnabled[valueIndex]}
                          onChange={(e, data) => {
                            this.setOption(
                              valueIndex,
                              'ratingOptionsEnabled',
                              data.checked
                            )
                            if (!data.checked) {
                              this.setOption(valueIndex, 'ratingOptions', '')
                            }
                          }}
                        />
                        <Input
                          className='optionInput'
                          placeholder={`Option ${index + 1}`}
                          name='ratingOption'
                          onChange={(e, data) =>
                            this.setOption(
                              valueIndex,
                              'ratingOptions',
                              data.value
                            )
                          }
                          value={ratingOptions[valueIndex]}
                          disabled={!ratingOptionsEnabled[valueIndex]}
                        >
                          <input />
                        </Input>
                      </div>
                    )
                  })}
                </Form.Field>
              </>
            )}
          </Form>

          <div className='adminModal actionButtons'>
            <div>
              {!isEmpty(factor) && user.type === 'A' && (
                <Button
                  rounded
                  color='red'
                  onClick={() => this.deleteFactor(factor._id)}
                >
                  Delete
                </Button>
              )}
            </div>
            <div>
              <Button rounded color='white' onClick={onClose}>
                Cancel
              </Button>
              <Button rounded shadowed onClick={this.saveFactor}>
                Save
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    )
  }
}

const mapStateToProps = ({ factors, tokenValidation }) => ({
  factors,
  user: get(tokenValidation, 'result.data'),
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (id) => dispatch({ type: 'DELETE_FACTOR_REQUESTED', id }),
  getSectors: (query) => dispatch({ type: 'FETCH_SECTORS_REQUESTED', query }),
  updateFactor: (payload, id) =>
    dispatch({ type: 'UPDATE_FACTOR_REQUESTED', payload, id }),
  createFactor: (payload) =>
    dispatch({ type: 'CREATE_FACTOR_REQUESTED', payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddFactor)
