import React from 'react'
import { connect } from 'react-redux'

import CreateGroupModal from '../group/CreateGroupModal'
import DeleteGroupModal from '../group/DeleteGroupModal'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import EditUserModal from './EditUserModal'
import TechnicalSupportModal from './TechnicalSupportModal'

const Modals = ({
  createGroup,
  deleteGroup,
  confirmDelete,
  editUser,
  closeModal,
  technicalSupport
}) => (
    <>
      {createGroup && createGroup.open && (
        <CreateGroupModal
          onClose={() => closeModal('createGroup')}
          {...createGroup.params}
        />
      )}
      {deleteGroup && deleteGroup.open && (
        <DeleteGroupModal
          onClose={() => closeModal('deleteGroup')}
          {...deleteGroup.params}
        />
      )}
      {confirmDelete && confirmDelete.open && (
        <ConfirmDeleteModal
          onClose={() => closeModal('confirmDelete')}
          {...confirmDelete.params}
        />
      )}
      {editUser && editUser.open && (
        <EditUserModal
          onClose={() => closeModal('editUser')}
          {...editUser.params}
        />
      )}
      {technicalSupport && technicalSupport.open && (
        <TechnicalSupportModal
          onClose={() => closeModal('technicalSupport')}
        />
      )}
    </>
  )

const mapStateToProps = ({ modals }) => ({
  createGroup: modals.createGroup,
  deleteGroup: modals.deleteGroup,
  confirmDelete: modals.confirmDelete,
  technicalSupport: modals.technicalSupport,
  editUser: modals.editUser
})

const mapDispatchToProps = dispatch => ({
  closeModal: id => dispatch({ type: 'CLOSE_MODAL', modal: id })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modals)
