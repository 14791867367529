import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Modal } from 'semantic-ui-react'

import Wheelv2 from './wheelv2'
import EntryList from '../inventory/EntryList'
import { mapEntriesToFactorIds } from '../inventory/inventoryUtils'

import './styles.scss'

class Wheel extends Component {
  state = {
    sectors: [],
    showEntriesModal: false,
    entryData: {}
  }

  componentDidMount() {
    this.loadSectors()
    const { inventory, getEntries } = this.props;
    if (inventory) {
      getEntries(inventory._id);
    }
  }

  componentDidUpdate(prevProps) {
    const { sectors, filter, compare, type } = this.props
    if (
      (prevProps.sectors !== sectors ||
        prevProps.filter !== filter ||
        prevProps.compare !== compare ||
        prevProps.type !== type) &&
      sectors.length
    ) {
      this.loadSectors()
    }
  }

  loadSectors = () => {
    const {
      sectors,
      filter,
      compare,
      order,
      ratingsCalculated = false
    } = this.props

    const sec = _.cloneDeep(sectors)
    const factorMap = {}

    sec.forEach(sector => {
      sector.dimensions.forEach(dimension => {
        dimension.factors.forEach(factor => {
          factor.rating =
            (ratingsCalculated ? factor.rating : factor.score) || 0

          if (!ratingsCalculated) {
            if (factor.compareScore) {
              factor.compare = Object.keys(factor.compareScore).map(
                compare => ({
                  comparator: compare,
                  rating: factor.compareScore[compare]
                })
              )
            } else {
              factor.compare = []
            }
          }

          factor.sector = sector
          factor.dimension = dimension

          factor.compareAvg = Math.floor(
            factor.compare.reduce(
              (sum, val) => sum + parseInt(val.rating, 10),
              0
            ) / (factor.compare.length || 1)
          )

          // if we need to compare with specific comparator, modify avg value to be equal of compared item
          if (compare) {
            const comparator = factor.compare.find(
              elem => elem.comparator === compare
            )
            if (comparator) {
              factor.compareAvg = comparator.rating
            } else {
              factor.compareAvg = 0
            }
          }

          // for sector that shouldCompare
          // calc relative rating (rating - avg)
          // for others it's just rating

          factor.realRating = sector.compare
            ? Math.floor(factor.rating - factor.compareAvg)
            : Math.floor(factor.rating)

          factorMap[factor._id] = factor
        })
      })
    })

    if (filter !== 'detailed') {
      sec.forEach(sector => {
        const dimension = sector.dimensions.reduce(
          (acum, dimension) => {
            acum.factors = acum.factors.concat(dimension.factors)
            return acum
          },
          { _id: sector._id, factors: [] }
        )

        sector.dimensions = [dimension]
      })
    }

    if (order !== 'none') {
      sec.forEach(sector => {
        sector.dimensions.forEach(dimension => {
          dimension.factors = dimension.factors.sort((a, b) => {
            if (sector.compare) {
              if (a.realRating === 0 && b.realRating !== 0) return 1
              if (b.realRating === 0 && a.realRating !== 0) return -1
              if (a.realRating === b.realRating) {
                if (a.rating < b.rating) return 1
                if (a.rating > b.rating) return -1
                return 0
              }
            }

            if (a.rating > a.compareAvg && b.rating < b.compareAvg) return -1
            if (b.rating > b.compareAvg && a.rating < a.compareAvg) return 1
            if (a.rating < a.compareAvg && b.rating < b.compareAvg) {
              if (a.realRating < b.realRating) return -1
              if (a.realRating > b.realRating) return 1
            } else {
              if (a.realRating < b.realRating) return 1
              if (a.realRating > b.realRating) return -1
            }
            return 0
          })
        })
      })
    }

    this.setState({
      sectors: sec,
      factorMap
    })
  }

  render() {
    const {
      filter,
      comparator,
      hidePopup,
      factorMap: factorMapProp,
      showTags,
      onSvgChange,
      preview,
      inventoryEntries,
      showChildComponents,
      children,
    } = this.props

    const { sectors, factorMap: factorMapState, entryData, showEntriesModal } = this.state

    const factorMap = factorMapProp || factorMapState;

    if (factorMap && !showChildComponents) {
      Object.values(factorMap).forEach(factor => {
        factor.onClick = () => {
          this.setState({
            entryData: {
              factor,
              dimension: factor.dimension,
              entryMap: inventoryEntries.reduce(mapEntriesToFactorIds, {}),
            },
            showEntriesModal: true,
          })
        }
      });
    }

    return (
      <div className='wheelContainer'>
        <Wheelv2
          sectors={sectors}
          selectableSectors={filter === 'detailed'}
          showDimensions={filter === 'detailed'}
          filter={filter}
          preview={preview}
          comparator={comparator}
          hidePopup={hidePopup}
          factorMap={factorMap}
          showTags={showTags}
          onSvgChange={onSvgChange}
        />
        {showChildComponents ?
          children :
          (<Modal
            open={showEntriesModal}
            onClose={() => this.setState({ showEntriesModal: false })}
            centered={false}
            closeIcon={true}
            size='large'
          >
            <Modal.Header>Factor entries</Modal.Header>
            <Modal.Content scrolling>
              <EntryList
                factor={entryData.factor}
                dimension={entryData.dimension}
                entryMap={entryData.entryMap}
                onSelectFactor={this.onSelectFactor}
                inModal={true}
                hideBackButton
              />
            </Modal.Content>
          </Modal>)}
      </div>
    )
  }
}

const mapStateToProps = ({ inventoryEntry }) => ({
  inventoryEntries: _.get(inventoryEntry, 'result.data', []),
})

const mapDispatchToProps = dispatch => ({
  getEntries: id => dispatch({ type: 'FETCH_INVENTORY_ENTRIES_REQUESTED', id }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wheel)
