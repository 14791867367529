import React from 'react'

const Unrated = ({ connectDropTarget, isOver, children }) => {
  const style = { padding: '5px' }

  if (isOver) {
    style.backgroundColor = '#EEE'
  }

  return connectDropTarget(
    <div style={style} className='unrated'>
      {children}
    </div>
  )
}

export default Unrated
