/**
 * Creates object that represents group hierarchy and is easy to render inside a component
 * @param {array} groups - Array of all available groups. It should be filtered before using this method.
 * @returns object
 */
const constructGroupHierarchy = groups => {
  return groups.reduce((groupsMap, group) => {
    // if group doesn't have parent, it's root group
    if (!group.parent) {
      // it should be added to map only if not already added
      // there is no else - we are not doing anything if it is already added to map
      if (!groupsMap[group._id]) {
        groupsMap[group._id] = group
        groupsMap[group._id].children = []
      }
      // group is a child, so we add it to its parent if he exists
    } else if (groupsMap[group.parent._id]) {
      groupsMap[group.parent._id].children.push(group)
    } else {
      // if it doesn't exist in the map object, we create it and add current group
      // to parents children
      groupsMap[group.parent._id] = group.parent
      groupsMap[group.parent._id].children = [group]
    }
    return groupsMap
  }, {})
}

export { constructGroupHierarchy }
