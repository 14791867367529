const defaultState = {
  requesting: false,
  success: false
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'UPDATE_DIAGNOSTIC_RELATION_REQUESTED':
      return {
        requesting: true,
        success: false
      }
    case 'UPDATE_DIAGNOSTIC_RELATION_SUCCEEDED':
      return {
        requesting: false,
        success: true
      }
    default:
      return state
  }
}
