import get from 'lodash/get'

const compareSectors = (a, b) => {
  if (a.order < b.order) return -1
  if (a.order > b.order) return 1
  return 0
}

const getArray = (object, path) => {
  const fetched = get(object, path, [])
  return Array.isArray(fetched) ? fetched : []
}

const utils = {
  compareSectors,
  getArray
}

export default utils

export { getArray, compareSectors }
