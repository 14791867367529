import { call, put, takeLatest } from 'redux-saga/effects'
import api, { convertSurveyToSchemaSurvey } from '../../services/api'
import {
  handleUnauthorizedError,
  handleSuccess,
  handleError
} from './helpers/saga-helpers'

function* getSectors(action) {
  try {
    const result = yield call(api.getSectors, action.query)
    yield put({ type: 'FETCH_SECTORS_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'FETCH_SECTORS_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* getWheelSectors(action) {
  try {
    const result = yield call(api.getSectors, action.query)
    yield put({ type: 'FETCH_WHEEL_SECTORS_SUCCEEDED', payload: result })
  } catch (e) {
    yield handleError({ error: e, type: 'FETCH_WHEEL_SECTORS_FAILED' })
  }
}

function* createSector(action) {
  try {
    const result = yield call(api.createSector, action.payload)
    yield put({ type: 'CREATE_SECTOR_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Sector created successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'CREATE_SECTOR_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* updateSector(action) {
  try {
    const result = yield call(api.updateSector, action.payload, action.id)
    yield put({ type: 'UPDATE_SECTOR_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Sector updated successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'UPDATE_SECTOR_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* createDimension(action) {
  try {
    const result = yield call(api.createDimension, action.payload)
    yield put({ type: 'CREATE_DIMENSION_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Dimension created successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'CREATE_DIMENSION_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* updateDimension(action) {
  try {
    const result = yield call(api.updateDimension, action.payload, action.id)
    yield put({ type: 'UPDATE_DIMENSION_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Dimension updated successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'UPDATE_DIMENSION_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* deleteDimension(action) {
  try {
    const result = yield call(api.deleteDimension, action.id)
    yield put({ type: 'DELETE_DIMENSION_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Dimension deleted successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'DELETE_DIMENSION_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* createSurvey(action) {
  try {
    const result = yield call(api.createSurvey, action.payload)
    yield put({ type: 'CREATE_SURVEY_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Survey created successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'CREATE_SURVEY_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* getSurveys(action) {
  try {
    const result = yield call(api.getSurveys, action.query)
    yield put({ type: 'FETCH_SURVEYS_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'FETCH_SURVEYS_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* getSurveyDetails(action) {
  try {
    const result = yield call(api.getSurveyDetails, action.id)
    yield put({ type: 'FETCH_SURVEY_DETAILS_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'FETCH_SURVEY_DETAILS_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* updateSurvey({ payload, id }) {
  try {
    const survey = convertSurveyToSchemaSurvey(payload)
    const result = yield call(api.updateSurvey, survey, id)
    yield put({ type: 'UPDATE_SURVEY_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Survey updated successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'UPDATE_SURVEY_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* updateSurveyProps({ payload, id }) {
  try {
    const result = yield call(api.updateSurveyProps, payload, id)
    yield handleSuccess(
      'UPDATE_SURVEY_PROPS_SUCCEEDED',
      result,
      'Survey properties updated!'
    )
  } catch (e) {
    yield handleError({ error: e, type: 'UPDATE_SURVEY_PROPS_FAILED' })
  }
}

function* archiveSurvey(action) {
  try {
    const result = yield call(api.archiveSurvey, action.id)
    yield put({ type: 'ARCHIVE_SURVEY_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Survey archived successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'ARCHIVE_SURVEY_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* restoreSurvey({ id }) {
  try {
    const result = yield call(api.restoreSurvey, id)

    yield put({ type: 'RESTORE_SURVEY_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Survey restored successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'RESTORE_SURVEY_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* getSurveyInvites(action) {
  try {
    const result = yield call(api.getSurveyInvites, action.query)
    yield put({ type: 'FETCH_SURVEY_INVITES_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'FETCH_SURVEY_INVITES_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* updateSurveyInvite(action) {
  try {
    const result = yield call(api.updateSurveyInvite, action.payload, action.id)
    yield put({ type: 'UPDATE_SURVEY_INVITE_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'UPDATE_SURVEY_INVITE_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* deleteSurveyInvite(action) {
  try {
    const result = yield call(api.deleteSurveyInvite, action.id)
    yield put({ type: 'DELETE_SURVEY_INVITE_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'DELETE_SURVEY_INVITE_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* submitSurvey(action) {
  try {
    const result = yield call(api.submitSurvey, action.payload)
    yield put({ type: 'SUBMIT_SURVEY_SUCCEEDED', payload: result })
    if (action.payload.completed) {
      yield put({
        type: 'SEND_NOTIFICATION',
        payload: {
          message: 'Survey submitted successfully',
          success: true
        }
      })
    }
  } catch (e) {
    yield put({ type: 'SUBMIT_SURVEY_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* getSurveyResponse(action) {
  try {
    const result = yield call(api.getSurveyResponse, action.id, action.query)
    yield put({ type: 'FETCH_SURVEY_RESPONSE_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'FETCH_SURVEY_RESPONSE_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* getSurveyResponsesSummary(action) {
  try {
    const result = yield call(api.getSurveyResponsesSummary, action.id)
    yield put({
      type: 'FETCH_SURVEY_RESPONSES_SUMMARY_SUCCEEDED',
      payload: result
    })
  } catch (e) {
    yield put({ type: 'FETCH_SURVEY_RESPONSES_SUMMARY_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* duplicateSurvey(action) {
  try {
    const result = yield call(api.duplicateSurvey, action.id)
    yield put({ type: 'DUPLICATE_SURVEY_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Survey duplicated successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'DUPLICATE_SURVEY_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* createEmailList(action) {
  try {
    const result = yield call(api.createEmailList, action.payload)
    yield put({ type: 'CREATE_EMAIL_LIST_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Email list created successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'CREATE_EMAIL_LIST_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* updateEmailList(action) {
  try {
    const result = yield call(api.updateEmailList, action.payload, action.id)
    yield put({ type: 'UPDATE_EMAIL_LIST_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Email list updated successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'UPDATE_EMAIL_LIST_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* getEmailLists(action) {
  try {
    const result = yield call(api.getEmailLists)
    yield put({ type: 'FETCH_EMAIL_LISTS_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'FETCH_EMAIL_LISTS_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* createSurveySender(action) {
  try {
    const result = yield call(api.createSurveySender, action.payload)
    yield put({ type: 'CREATE_SURVEY_SENDER_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Survey sender created successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'CREATE_SURVEY_SENDER_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* updateSurveySender(action) {
  try {
    const result = yield call(api.updateSurveySender, action.payload, action.id)
    yield put({ type: 'UPDATE_SURVEY_SENDER_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Survey sender updated successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'UPDATE_SURVEY_SENDER_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* getSurveySenders(action) {
  try {
    const result = yield call(api.getSurveySenders)
    yield put({ type: 'FETCH_SURVEY_SENDERS_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'FETCH_SURVEY_SENDERS_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* getWheels(action) {
  try {
    const result = yield call(api.getWheels, action.query)
    yield put({ type: 'FETCH_WHEELS_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'FETCH_WHEELS_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* getWheel(action) {
  try {
    const result = yield call(api.getWheel, action.id)
    yield put({ type: 'FETCH_WHEEL_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'FETCH_WHEEL_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* createWheel(action) {
  try {
    const result = yield call(api.createWheel, action.payload)
    yield put({ type: 'CREATE_WHEEL_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Wheel created successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'CREATE_WHEEL_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* updateWheel(action) {
  try {
    const result = yield call(api.updateWheel, action.payload, action.id)
    yield put({ type: 'UPDATE_WHEEL_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Wheel updated successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'UPDATE_WHEEL_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* archiveWheel(action) {
  try {
    const result = yield call(api.archiveWheel, action.id)
    yield put({ type: 'ARCHIVE_WHEEL_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Wheel archived successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'ARCHIVE_WHEEL_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* restoreWheel({ id }) {
  try {
    const result = yield call(api.restoreWheel, id)

    yield put({ type: 'RESTORE_WHEEL_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Wheel restored successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'RESTORE_WHEEL_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* duplicateWheel(action) {
  try {
    const result = yield call(api.duplicateWheel, action.id)
    yield put({ type: 'DUPLICATE_WHEEL_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Wheel duplicated successfully',
        success: true
      }
    })
  } catch (e) {
    yield put({ type: 'DUPLICATE_WHEEL_FAILED', payload: e })
    yield handleUnauthorizedError(e)
  }
}

function* surveys() {
  yield takeLatest('FETCH_SECTORS_REQUESTED', getSectors)
  yield takeLatest('CREATE_SECTOR_REQUESTED', createSector)
  yield takeLatest('UPDATE_SECTOR_REQUESTED', updateSector)
  yield takeLatest('FETCH_WHEEL_SECTORS_REQUESTED', getWheelSectors)

  yield takeLatest('CREATE_DIMENSION_REQUESTED', createDimension)
  yield takeLatest('UPDATE_DIMENSION_REQUESTED', updateDimension)
  yield takeLatest('DELETE_DIMENSION_REQUESTED', deleteDimension)

  yield takeLatest('CREATE_SURVEY_REQUESTED', createSurvey)
  yield takeLatest('FETCH_SURVEYS_REQUESTED', getSurveys)
  yield takeLatest('FETCH_SURVEY_DETAILS_REQUESTED', getSurveyDetails)
  yield takeLatest('ARCHIVE_SURVEY_REQUESTED', archiveSurvey)
  yield takeLatest('RESTORE_SURVEY_REQUESTED', restoreSurvey)
  yield takeLatest('UPDATE_SURVEY_REQUESTED', updateSurvey)
  yield takeLatest('UPDATE_SURVEY_PROPS_REQUESTED', updateSurveyProps)
  yield takeLatest('DUPLICATE_SURVEY_REQUESTED', duplicateSurvey)

  yield takeLatest('FETCH_SURVEY_INVITES_REQUESTED', getSurveyInvites)
  yield takeLatest('DELETE_SURVEY_INVITE_REQUESTED', deleteSurveyInvite)
  yield takeLatest('UPDATE_SURVEY_INVITE_REQUESTED', updateSurveyInvite)

  yield takeLatest('SUBMIT_SURVEY_REQUESTED', submitSurvey)

  yield takeLatest('FETCH_SURVEY_RESPONSE_REQUESTED', getSurveyResponse)

  yield takeLatest(
    'FETCH_SURVEY_RESPONSES_SUMMARY_REQUESTED',
    getSurveyResponsesSummary
  )

  yield takeLatest('CREATE_EMAIL_LIST_REQUESTED', createEmailList)
  yield takeLatest('UPDATE_EMAIL_LIST_REQUESTED', updateEmailList)
  yield takeLatest('FETCH_EMAIL_LISTS_REQUESTED', getEmailLists)

  yield takeLatest('CREATE_SURVEY_SENDER_REQUESTED', createSurveySender)
  yield takeLatest('UPDATE_SURVEY_SENDER_REQUESTED', updateSurveySender)
  yield takeLatest('FETCH_SURVEY_SENDERS_REQUESTED', getSurveySenders)

  yield takeLatest('FETCH_WHEELS_REQUESTED', getWheels)
  yield takeLatest('FETCH_WHEEL_REQUESTED', getWheel)
  yield takeLatest('CREATE_WHEEL_REQUESTED', createWheel)
  yield takeLatest('UPDATE_WHEEL_REQUESTED', updateWheel)
  yield takeLatest('ARCHIVE_WHEEL_REQUESTED', archiveWheel)
  yield takeLatest('RESTORE_WHEEL_REQUESTED', restoreWheel)
  yield takeLatest('DUPLICATE_WHEEL_REQUESTED', duplicateWheel)
}

export default surveys
