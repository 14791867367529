const defaultState = {
  requesting: false,
  error: false,
  result: null,
  isCreate: false,
  isDelete: false,
  loaded: false
}

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'FETCH_FACTOR_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        isCreate: false,
        isDelete: false,
        loaded: false
      }
    case 'FETCH_FACTOR_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true,
        isDelete: false,
        loaded: true
      }
    case 'FETCH_FACTOR_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true,
        isDelete: false,
        loaded: false
      }

    case 'CREATE_FACTOR_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: true,
        isDelete: false,
        loaded: false
      }
    case 'CREATE_FACTOR_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true,
        isDelete: false,
        loaded: true
      }
    case 'CREATE_FACTOR_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true,
        isDelete: false,
        loaded: false
      }
    case 'UPDATE_FACTOR_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        isCreate: true,
        isDelete: false,
        loaded: false
      }
    case 'UPDATE_FACTOR_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true,
        isDelete: false,
        loaded: true
      }
    case 'UPDATE_FACTOR_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true,
        isDelete: false,
        loaded: false
      }
    case 'DELETE_FACTOR_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false,
        isDelete: true,
        loaded: false
      }
    case 'DELETE_FACTOR_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false,
        isDelete: true,
        loaded: true
      }
    case 'DELETE_FACTOR_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false,
        isDelete: true,
        loaded: false
      }
    default:
      return state
  }
}
