const defaultState = {
  requesting: false,
  error: false,
  result: null,
  isCreate: false,
  isDelete: false
};

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'FETCH_ASSETS_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false,
        isDelete: false
      }
    case 'FETCH_ASSETS_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false,
        isDelete: false
      }
    case 'FETCH_ASSETS_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false,
        isDelete: false
      }
    case 'CREATE_ASSET_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: true,
        isDelete: false
      }
    case 'CREATE_ASSET_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true,
        isDelete: false
      }
    case 'CREATE_ASSET_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true,
        isDelete: false
      }
    case 'UPDATE_ASSET_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: true,
        isDelete: false
      }
    case 'UPDATE_ASSET_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true,
        isDelete: false
      }
    case 'UPDATE_ASSET_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true,
        isDelete: false
      }
    case 'DELETE_ASSET_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false,
        isDelete: true
      }
    case 'DELETE_ASSET_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false,
        isDelete: true
      }
    case 'DELETE_ASSET_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false,
        isDelete: true
      }
    default:
      return state
  }
}
