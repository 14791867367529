import { call, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import api from '../../services/api'
import { handleUnauthorizedError, handleError } from './helpers/saga-helpers'

function* login(action) {
  try {
    const result = yield call(api.login, action.payload)
    yield put({ type: 'LOGIN_SUCCEEDED', payload: result })
    window.localStorage.accessToken = result.data.token
    yield put(push('/'))
  } catch (e) {
    yield put({ type: 'LOGIN_FAILED', payload: e })
    yield put({ type: 'GET_TOKEN_REQUESTED', payload: e })
  }
}

function* signUp(action) {
  try {
    const result = yield call(api.signUp, action.payload)
    yield put({ type: 'SIGNUP_SUCCEEDED', payload: result })
    window.localStorage.accessToken = result.data.token
    yield put(push('/'))
  } catch (e) {
    yield put({ type: 'SIGNUP_FAILED', payload: e })
  }
}

function* getToken(action) {
  try {
    const result = yield call(api.getToken, action.payload)
    yield put({ type: 'GET_TOKEN_SUCCEEDED', payload: result })
  } catch (e) {
    yield put({ type: 'GET_TOKEN_FAILED', payload: e })
  }
}

function* logout(action) {
  try {
    const result = yield call(api.logout, action.payload)
    yield put({ type: 'LOGOUT_SUCCEEDED', payload: result })
  } catch (e) {
    yield handleError({ error: e, type: 'LOGOUT_FAILED' })
  } finally {
    delete window.localStorage.clear()
    yield put({ type: 'TOKEN_VALIDATION_RESET' })
    yield put(push('/login'))
  }
}

function* sendForgotPassword(action) {
  try {
    const result = yield call(api.sendForgotPassword, action.payload)
    yield put({ type: 'FORGOT_PASSWORD_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message:
          'An email has been sent with the instructions to reset your password',
        success: true,
      },
    })
  } catch (e) {
    yield put({ type: 'FORGOT_PASSWORD_FAILED', payload: e })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'There was an error while sending the email',
        success: false,
      },
    })
  }
}

function* resetPassword(action) {
  try {
    const result = yield call(api.resetPassword, action.payload)
    yield put({ type: 'RESET_PASSWORD_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'The password has been reset',
        success: true,
      },
    })
  } catch (e) {
    yield put({ type: 'RESET_PASSWORD_FAILED', payload: e })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message:
          (e.response && e.response.data.msg) ||
          'There was an error when resetting the password',
        success: false,
      },
    })
  }
}

function* changePassword(action) {
  try {
    const result = yield call(api.changePassword, action.payload)
    yield put({ type: 'CHANGE_PASSWORD_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'The password has been changed',
        success: true,
      },
    })
  } catch (e) {
    yield put({ type: 'CHANGE_PASSWORD_FAILED', payload: e })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message:
          (e.response && e.response.data.msg) ||
          'There was an error when changing the password',
        success: false,
      },
    })
    yield handleUnauthorizedError(e)
  }
}

function* auth() {
  yield takeLatest('LOGIN_REQUESTED', login)
  yield takeLatest('SIGNUP_REQUESTED', signUp)
  yield takeLatest('LOGOUT', logout)
  yield takeLatest('FORGOT_PASSWORD_REQUESTED', sendForgotPassword)
  yield takeLatest('RESET_PASSWORD_REQUESTED', resetPassword)
  yield takeLatest('CHANGE_PASSWORD_REQUESTED', changePassword)
  yield takeLatest('GET_TOKEN_REQUESTED', getToken)
}

export default auth
