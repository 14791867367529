import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Header, Loader, Button, Segment } from 'semantic-ui-react'

import Carousel from '../carousel'
import Prompt from './prompt'

class TPP extends Component {
  constructor(props) {
    super(props)

    this.state = {
      surveyAssets: [],
      step: 1,
    }
  }

  componentDidMount() {
    this.props.getSurveyAssets(this.props.surveyId)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { surveyAssetsRequest } = nextProps

    if (!surveyAssetsRequest.requesting && surveyAssetsRequest.result) {
      this.setState({
        surveyAssets: surveyAssetsRequest.result.data || [],
        stepsCount: surveyAssetsRequest.result.data.length,
      })
    }

    if (
      !surveyAssetsRequest.requesting &&
      (surveyAssetsRequest.isCreate || surveyAssetsRequest.isDelete)
    ) {
      this.props.getSurveyAssets(this.props.surveyId)
    }
  }

  handleSubmit() {
    const { surveyNavigation, saveTime } = this.props
    saveTime()
    surveyNavigation.navigateToNext()
  }

  validateTPP() {
    const { standalone, surveyNavigation } = this.props

    if (
      !standalone &&
      !surveyNavigation.checkIfCurrentStepIsDoneOrCompleted()
    ) {
      if (this.state.step >= this.state.stepsCount) {
        surveyNavigation.setCurrentStepToCompleted()
      }
    }
  }

  render() {
    const { surveyNavigation, standalone } = this.props
    const { step, stepsCount } = this.state
    const requesting = this.props.surveyAssetsRequest.requesting

    const navigationButton = (
      <div className='introductionContainer'>
        {step < stepsCount && (
          <Button
            primary
            size='big'
            onClick={() => this.setState({ step: step + 1 })}
          >
            Continue
          </Button>
        )}
        {step >= stepsCount && !standalone && (
          <Button primary size='big' onClick={() => this.handleSubmit()}>
            Done
          </Button>
        )}
      </div>
    )

    const images = this.state.surveyAssets.map((asset) => (
      <div>
        <div>
          <img src={asset.imageUrl} alt='asset' />
        </div>
        {navigationButton}
      </div>
    ))

    return (
      <div>
        {requesting && <Loader active inline='centered' />}

        {!requesting && (
          <div>
            <div className='surveyIntroductionComponent'>
              <Header as='h1'>Product and / or Disease Information</Header>
              <Segment className='carouselContainer productDisease'>
                {this.validateTPP()}

                <Carousel
                  activeSlide={step}
                  onChange={({ slide }) => this.setState({ step: slide })}
                  slides={images}
                />
              </Segment>
            </div>

            {!standalone && (
              <Prompt>
                <div className='promptInstructions tpp'>
                  <div />
                  <div>
                    The information above is directly relevant to this survey,
                    so it is very important that you take some time to review
                    it.
                    <br />
                    You will also have access to this information throughout the
                    survey via a clickable tab within each factor rating window,
                    should you need to refer to it again in order to answer any
                    question. Click the arrow buttons to navigate content.
                  </div>
                  <div>
                    Once you have completed this section, the Next button will
                    be active and you can move on to the next step.
                  </div>
                </div>
                <div className='buttonsContainer'>
                  {/*  TODO: enable previous buttons
                <Button
                  size='large'
                  onClick={() => surveyNavigation.navigateToPrevious()}>
                  Previous
                </Button> */}
                  <Button
                    primary={surveyNavigation.checkIfCurrentStepIsDoneOrCompleted()}
                    disabled={
                      !surveyNavigation.checkIfCurrentStepIsDoneOrCompleted()
                    }
                    size='large'
                    onClick={() => this.handleSubmit()}
                  >
                    Next
                  </Button>
                </div>
              </Prompt>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ assets }) => ({
  surveyAssetsRequest: assets,
})

const mapDispatchToProps = (dispatch) => ({
  getSurveyAssets: (objectId) =>
    dispatch({ type: 'FETCH_ASSETS_REQUESTED', objectId, assetType: 'survey' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(TPP)
