import React, { Component } from 'react'
import get from 'lodash/get'
import { Loader, Divider } from 'semantic-ui-react'

import ActionsButton from './ActionsButton'
import RateFactor from './RateFactor'
import CompareFactor from './CompareFactor'
import Rationale from './Rationale'
import Modals from './Modals'
import { getDefaultLabelsForFactor } from './FactorDetailsUtils'

import './FactorDetails.scss'

class FactorDetails extends Component {
  state = {
    showFactorModal: false,
    showEntriesModal: false
  }

  componentDidMount() {
    this.initFactor({})
  }

  componentDidUpdate(prevProps) {
    this.initFactor(prevProps)
  }

  initFactor = prevProps => {
    const { factorId, factor, getEntries, getFactor, diagnostic } = this.props

    if (factorId && factorId !== prevProps.factorId) {
      getFactor(factorId)

      const inventoryId = get(diagnostic, 'inventory._id', null)

      if (inventoryId) {
        getEntries(get(diagnostic, 'inventory._id', null))
      }
    }

    if (factor && factor !== prevProps.factor) {
      this.setState({ rationale: factor.rationale || '' })
    }
  }

  handleActionButtonSelection = (e, { value }) => {
    const { factor } = this.props

    if (value === 'edit') {
      this.setState({ showFactorModal: true })
    } else if (value === 'view') {
      this.setState({ showEntriesModal: true })
    } else if (value === 'highlighted') {
      this.handleFactorChange('highlighted', !factor.highlighted)
    }
  }

  updateRationale = () => {
    this.handleFactorChange('rationale', this.state.rationale)
  }

  handleFactorChange = (field, value) => {
    const { updateFactor, factor } = this.props

    updateFactor({ [field]: value }, factor._id)
  }

  changeComparator = (selected, value) => {
    const { updateFactor, factor } = this.props

    factor.compareScore[selected.comparator].value = parseInt(value, 10)

    const mappedCompareScore = {}

    Object.keys(factor.compareScore).forEach(key => {
      mappedCompareScore[key] = factor.compareScore[key].value
    })

    updateFactor({ compareScore: mappedCompareScore }, factor._id)
  }

  render() {
    const {
      diagnostic,
      dimension,
      factor,
      factorIndex,
      inventoryEntries,
      sector,
      shouldCompare,
      updateFactor
    } = this.props
    const { showFactorModal, showEntriesModal, rationale } = this.state

    if (!diagnostic || !diagnostic.inventory || !factor)
      return <Loader active size='large' inline='centered' />

    const sectorName = sector.name

    const {
      competitorsQuestion,
      ratingQuestion,
      ratingOptions,
      comparison
    } = getDefaultLabelsForFactor(sectorName, factor)

    // TODO add to factor rating component
    // set custom rating options
    if (factor.ratingOptions && factor.ratingOptions.length) {
      factor.ratingOptions.forEach((option, index) => {
        if (option) {
          ratingOptions[index] = option
        }
      })
    }

    // TODO move to compareFactor component
    if (factor.competitorsOptions && factor.competitorsOptions.length) {
      factor.competitorsOptions.forEach((option, index) => {
        if (option) {
          comparison[index].label = option
        }
      })
    }

    let comparators = factor.compareScore
    if (comparators) {
      Object.keys(comparators).forEach(key => {
        if (comparators[key] === 0) {
          delete comparators[key]
        } else {
          if (!comparators[key].comparator) {
            comparators[key] = {
              comparator: key,
              value: comparators[key]
            }
          }
        }
      })
    }
    if (diagnostic.inventory) {
      if (!comparators) comparators = {}
      diagnostic.inventory.comparators.forEach(item => {
        if (!comparators[item._id]) {
          comparators[item._id] = {
            comparator: item._id,
            value: 0
          }
        }
      })
      factor.compareScore = comparators
    }

    return (
      <div className='factorDetails'>
        <Modals
          factor={factor}
          sector={sector}
          inventoryEntries={inventoryEntries}
          showEntriesModal={showEntriesModal}
          dimension={dimension}
          onCloseEntriesModal={() => this.setState({ showEntriesModal: false })}
          showFactorModal={showFactorModal}
          onCloseEditFactor={() => this.setState({ showFactorModal: false })}
          onSelectFactor={this.onSelectFactor}
        />
        <span className='factorPosition'>
          FACTOR {factorIndex + 1} OF {dimension.factors.length}
        </span>
        <div className='factorHeader'>
          <span className='factorName'>{factor.name}</span>
          <ActionsButton
            highlighted={factor.highlighted || false}
            handleOnChange={this.handleActionButtonSelection}
          />
        </div>
        <div className='factorDescription'>
          <span>{factor.description}</span>
        </div>

        {shouldCompare ? (
          <CompareFactor
            changeComparator={this.changeComparator}
            comparators={comparators}
            comparison={comparison}
            competitorsQuestion={competitorsQuestion}
            diagnostic={diagnostic}
            factor={factor}
            updateFactor={updateFactor}
          />
        ) : (
            <RateFactor
              factor={factor}
              ratingOptions={ratingOptions}
              ratingQuestion={ratingQuestion}
              updateFactor={updateFactor}
            />
          )}

        <Divider />

        <Rationale
          onChange={rationale => this.setState({ rationale })}
          onBlur={() => this.updateRationale()}
          rationale={rationale}
          compare={shouldCompare}
        />
      </div>
    )
  }
}

export default FactorDetails
