import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Modal, Dropdown } from 'semantic-ui-react'

import Button from '../common/Button'
import { USER_TYPES } from '../../types/user.types'

class EditUserModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props._id,
      firstName: props.firstName,
      lastName: props.lastName,
      email: props.email,
      type: props.type
    }
  }

  handleChange = (e, { type, name, checked, value }) => {
    const nextValue = type === 'checkbox' ? checked : value
    this.setState({ [name]: nextValue })
  }

  save = () => {
    const { id, firstName, lastName, email, type } = this.state
    const { updateUser, onClose } = this.props

    updateUser({ firstName, lastName, email, type }, id)
    onClose()
  }

  render() {
    const { onClose } = this.props
    const { firstName, lastName, email, type } = this.state

    const editing = true

    const userTypeOptions = Object.keys(USER_TYPES).map(key => {
      return {
        value: key,
        key,
        text: USER_TYPES[key]
      }
    })

    return (
      <Modal scrolling open={true} size='tiny' onClose={onClose}>
        <Modal.Header>Edit User</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description />
          <Form>
            <Form.Field>
              <label htmlFor='firstName'>FIRST NAME</label>
              <Input
                placeholder='First Name'
                name='firstName'
                value={firstName}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor='lastName'>LAST NAME</label>
              <Input
                placeholder='Last Name'
                name='lastName'
                value={lastName}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor='type'>TYPE</label>
              <Dropdown
                placeholder='User Type'
                name='type'
                fluid
                search
                selection
                onChange={this.handleChange}
                value={type}
                options={userTypeOptions}
              />
            </Form.Field>

            <Form.Field>
              <label htmlFor='email'>EMAIL</label>
              <Input
                placeholder='Email'
                name='email'
                onChange={this.handleChange}
                value={email}
                readOnly={editing}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button rounded color='white' onClick={onClose}>
            Cancel
          </Button>
          <Button rounded shadowed onClick={this.save}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  updateUser: (payload, id) =>
    dispatch({ type: 'UPDATE_USER_REQUESTED', payload, id })
})

export default connect(
  null,
  mapDispatchToProps
)(EditUserModal)
