import React from 'react'

import { Header, Divider, Button } from 'semantic-ui-react'

import RateFactor from './RateFactor'
import CompareFactor from './CompareFactor'

const EvaluateFactor = ({
  allRated,
  changeComparator,
  compare,
  comparison,
  competitorsQuestion,
  disease,
  factor,
  handleFactorImportance,
  handleFactorRating,
  nextFactor,
  previousFactor,
  productName,
  ratingOptions,
  ratingQuestion,
  showError,
  showSuccess,
  survey
}) => {
  return (
    <div style={{ marginTop: '30px', position: 'relative' }}>
      <Header as='h2'>Disease: {disease}</Header>

      <Divider />

      <div className='factorDefinition'>
        <div>
          <div>
            Factor: <strong>{factor.name}</strong>
          </div>
          <div>
            Definition: <strong>{factor.description}</strong>
          </div>
        </div>
        <div>
          <Button onClick={previousFactor}>Previous Factor</Button>
          <Button primary onClick={nextFactor}>
            Next Factor
          </Button>
        </div>
      </div>

      <Divider />

      {compare ? (
        <CompareFactor
          allRated={allRated}
          changeComparator={changeComparator}
          comparison={comparison}
          competitorsQuestion={competitorsQuestion}
          factor={factor}
          handleFactorImportance={handleFactorImportance}
          handleFactorRating={handleFactorRating}
          productName={productName}
          ratingOptions={ratingOptions}
          ratingQuestion={ratingQuestion}
          showError={showError}
          showSuccess={showSuccess}
          survey={survey}
          allComparatorsFromInventory={survey.diagnostic.inventory.comparators}
        />
      ) : (
        <RateFactor
          allRated={allRated}
          factor={factor}
          handleFactorRating={handleFactorRating}
          ratingOptions={ratingOptions}
          ratingQuestion={ratingQuestion}
        />
      )}
    </div>
  )
}

export default EvaluateFactor
