import React, { Component } from 'react'

import Factor from './factor.js'
import utils from './utils'
import constants from './constants'

class Dimension extends Component {
  isDimensionSelected = () => {
    const { selectedDimensions, dimension } = this.props
    return selectedDimensions[dimension._id]
  }

  dimensionClick = () => {
    const { sector, selectedSector, dimension, onDimensionSelect } = this.props

    if (selectedSector === sector._id) {
      onDimensionSelect(dimension, this.isDimensionSelected() ? false : true)
    }
  }

  render() {
    const {
      sector,
      dimension,
      radius,
      angle,
      meta,
      selectedSector,
      onFactorSelect
    } = this.props

    const cx = meta.width / 2
    const cy = meta.height / 2

    let currentAngle = angle
    while (currentAngle >= 360) currentAngle -= 360
    while (currentAngle < 0) currentAngle += 360
    const straight = currentAngle < 270 && currentAngle > 90 ? 1 : 0

    function getDimensionLine() {
      const endRadius = radius + (sector.compare ? 6 : 8) * constants.scaleStep

      const x1 = utils.calculateDX(cx, angle, radius)
      const y1 = utils.calculateDY(cy, angle, radius)
      const x2 = utils.calculateDX(cx, angle, endRadius)
      const y2 = utils.calculateDY(cy, angle, endRadius)

      return (
        <line
          key={`DL_${dimension._id}`}
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          style={{ stroke: '#4e4d4d', strokeWidth: '1' }}
        />
      )
    }

    function getTextWidth(text) {
      const ctx = document.createElement('canvas').getContext('2d')
      ctx.font = '12px Roboto'
      return ctx.measureText(text).width
    }

    const getDimension = () => {
      let dim = null
      if (meta.showDimensions && dimension) {
        dim = (
          <g>
            {utils.getRectPath({
              sector,
              id: `DT_${dimension._id}`,
              angle: currentAngle,
              radius,
              meta,
              stroke: 'transparent',
              width: getTextWidth(dimension.name),
              fill: 'none',
            })}
            <text
              fill='#000'
              className={`factorName ${
                this.isDimensionSelected() ? 'dimensionSelected' : ''
                }`}
              onClick={this.dimensionClick}
              textAnchor={straight ? 'end' : ''}
            >
              <textPath
                startOffset={straight ? '100%' : '0%'}
                href={`#DT_${dimension._id}`}
                style={{
                  fontFamily: 'Roboto, Arial'
                }}
              >
                {dimension.name}
              </textPath>
            </text>
            {selectedSector === sector._id && getDimensionLine()}
          </g>
        )
        currentAngle += meta.factorStep
      }
      return dim
    }

    return (
      <g>
        {getDimension()}
        {dimension.factors.map(factor => {
          const elem = (
            <Factor
              key={factor._id}
              sector={sector}
              dimension={dimension}
              factor={factor}
              radius={radius}
              angle={currentAngle}
              meta={meta}
              onFactorSelect={onFactorSelect}
              dimensionSelected={this.isDimensionSelected()}
            />
          )
          currentAngle += meta.factorStep
          return elem
        })}
      </g>
    )
  }
}

export default Dimension
