import { get } from 'lodash';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  handleUnauthorizedError,
  handleSuccess,
  handleError
} from './helpers/saga-helpers';
// import redirect from './redirectSaga';

import {
  IInventoryCreateAction,
  IInventoryCreateEntryAction,
  IInventoryGetEntryAction,
  IInventoryResponse
} from '../types/inventories.types';

import api from '../../services/api';
import { IInventoryEntryDto } from 'types/dto/inventory.dto';

function* fetchInventoryEntry(action: IInventoryGetEntryAction) {
  try {
    const result: IInventoryResponse = yield call(
      api.getInventoryEntry,
      action.id
    );
    yield put({ type: 'FETCH_INVENTORY_ENTRY_SUCCEEDED', payload: result });
  } catch (e) {
    yield handleError({ type: 'FETCH_INVENTORY_ENTRY_FAILED', error: e });
  }
}

function* fetchInventoryEntries(action) {
  try {
    const result = yield call(api.getInventoryEntries, action.id, action.query);
    yield put({ type: 'FETCH_INVENTORY_ENTRIES_SUCCEEDED', payload: result });
  } catch (e) {
    yield handleError({ type: 'FETCH_INVENTORY_ENTRIES_FAILED', error: e });
  }
}

function* updateEntry(action) {
  try {
    yield call(api.updateEntry, action.id, action.payload);
    const result = yield call(api.getInventoryEntries, action.id, action.query);
    yield put({ type: 'UPDATE_ENTRY_SUCCEEDED', payload: result });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Entry updated',
        success: true
      }
    });
  } catch (e) {
    yield handleError({ type: 'UPDATE_ENTRY_FAILED', error: e });
  }
}

function* createInventoryEntry(action: IInventoryCreateEntryAction) {
  try {
    const response: IInventoryResponse = yield call(
      api.createInventoryEntry,
      action.payload
    );
    const responseData = get(response, 'data') as IInventoryEntryDto;
    const result = yield call(
      api.getInventoryEntries,
      action.payload.inventory,
      { entryId: responseData._id }
    );

    yield put({ type: 'CREATE_INVENTORY_ENTRY_SUCCEEDED', payload: result });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Entry saved successfully',
        success: true
      }
    });
  } catch (e) {
    yield handleError({ type: 'CREATE_INVENTORY_ENTRY_FAILED', error: e });
  }
}

function* deleteEntry(action) {
  try {
    yield call(
      api.deleteEntry,
      action.inventoryId,
      action.factorId,
      action.entryIndex
    );
    const result = yield call(api.getInventoryEntries, action.id, action.query);
    yield put({ type: 'DELETE_ENTRY_SUCCEEDED', payload: result });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Entry deleted',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'DELETE_ENTRY_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* createAssetInventory(action: IInventoryCreateAction) {
  try {
    const result = yield call(api.createAssetInventory, action.payload);
    yield put({ type: 'CREATE_INVENTORY_SUCCEEDED', payload: result });
    yield put(push(`/inventory/${result.data._id}`));
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Asset inventory created successfully',
        success: true
      }
    });
  } catch (e) {
    yield handleError({ type: 'CREATE_INVENTORY_FAILED', error: e });
  }
}

function* archiveInventory(action) {
  try {
    const result = yield call(api.archiveInventory, action.id);
    yield put({ type: 'ARCHIVE_INVENTORY_SUCCEEDED', payload: result });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Asset inventory archived successfully',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'ARCHIVE_INVENTORY_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* restoreInventory({ id }) {
  try {
    const result = yield call(api.restoreInventory, id);

    yield put({ type: 'RESTORE_INVENTORY_SUCCEEDED', payload: result });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Inventory restored successfully',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'RESTORE_INVENTORY_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* duplicateInventory(action) {
  try {
    const result = yield call(api.duplicateInventory, action.id);
    yield put({ type: 'DUPLICATE_INVENTORY_SUCCEEDED', payload: result });
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Inventory duplicated successfully',
        success: true
      }
    });
  } catch (e) {
    yield put({ type: 'DUPLICATE_INVENTORY_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* getAssetInventory(action) {
  try {
    const result = yield call(api.getAssetInventories, action.query);
    yield put({ type: 'FETCH_INVENTORY_SUCCEEDED', payload: result });
  } catch (e) {
    yield put({ type: 'FETCH_INVENTORY_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* getInventoryDetails(action) {
  try {
    const result = yield call(api.getInventoryDetails, action.id);
    yield put({ type: 'FETCH_INVENTORY_DETAILS_SUCCEEDED', payload: result });
  } catch (e) {
    yield put({ type: 'FETCH_INVENTORY_DETAILS_FAILED', payload: e });
    yield handleUnauthorizedError(e);
  }
}

function* updateInventory(action) {
  try {
    const result = yield call(api.updateInventory, action.payload, action.id);

    yield handleSuccess(
      'UPDATE_INVENTORY_SUCCEEDED',
      result,
      'Asset inventory updated!'
    );
  } catch (e) {
    yield handleError({ error: e, type: 'UPDATE_INVENTORY_FAILED' });
  }
}

function* updateInventoryProps({ payload, id }) {
  try {
    const result = yield call(api.updateInventoryProps, payload, id);

    yield handleSuccess(
      'UPDATE_INVENTORY_PROPS_SUCCEEDED',
      result,
      'Asset inventory title updated!'
    );
  } catch (e) {
    yield handleError({ error: e, type: 'UPDATE_INVENTORY_PROPS_FAILED' });
  }
}

function* inventories() {
  yield takeLatest('CREATE_INVENTORY_REQUESTED', createAssetInventory);
  yield takeLatest('FETCH_INVENTORY_DETAILS_REQUESTED', getInventoryDetails);
  yield takeLatest('FETCH_INVENTORY_REQUESTED', getAssetInventory);
  yield takeLatest('UPDATE_INVENTORY_REQUESTED', updateInventory);
  yield takeLatest(
    'UPDATE_INVENTORY_PROPS_REQUESTED' as any,
    updateInventoryProps
  );
  yield takeLatest('ARCHIVE_INVENTORY_REQUESTED', archiveInventory);
  yield takeLatest('RESTORE_INVENTORY_REQUESTED' as any, restoreInventory);

  yield takeLatest('FETCH_INVENTORY_ENTRY_REQUESTED', fetchInventoryEntry);
  yield takeLatest('FETCH_INVENTORY_ENTRIES_REQUESTED', fetchInventoryEntries);
  yield takeLatest('UPDATE_ENTRY_REQUESTED', updateEntry);
  yield takeLatest('CREATE_INVENTORY_ENTRY_REQUESTED', createInventoryEntry);
  yield takeLatest('DUPLICATE_INVENTORY_REQUESTED', duplicateInventory);
  yield takeLatest('DELETE_ENTRY_REQUESTED', deleteEntry);
}

export default inventories;
