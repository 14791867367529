import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Input, Image, Button, Loader, Checkbox } from 'semantic-ui-react'

import AssetUploader from '../asset-uploader'
import './productDiseaseInfo.scss'

class ProductDiseaseInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order: 5,
      surveyAssets: [],
      assetOrders: {},
    }
  }

  componentDidMount() {
    this.props.getSurveyAssets(this.props.surveyId)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.surveyId !== nextProps.surveyId) {
      this.props.getSurveyAssets(nextProps.surveyId)
    }

    const { surveyAssetsRequest } = nextProps

    if (
      !surveyAssetsRequest.requesting &&
      surveyAssetsRequest.result &&
      !surveyAssetsRequest.isCreate &&
      !surveyAssetsRequest.isDelete
    ) {
      const surveyAssets = surveyAssetsRequest.result.data
      const assetOrders = this.getAssetOrders(surveyAssets)

      this.setState({
        surveyAssets: surveyAssets || [],
        assetOrders,
      })
    }

    if (
      !surveyAssetsRequest.requesting &&
      (surveyAssetsRequest.isCreate || surveyAssetsRequest.isDelete)
    ) {
      this.props.getSurveyAssets(this.props.surveyId)
    }
  }

  getAssetOrders(surveyAssets) {
    const assetOrders = {}

    surveyAssets.forEach((asset) => {
      assetOrders[asset._id] = asset.order
    })

    return assetOrders
  }

  handleDeleteImage(assetId) {
    this.props.deleteSurveyAsset(assetId)
  }

  handleUpdateImageOrder(assetId) {
    const order = this.state.assetOrders[assetId]
    this.props.updateSurveyAsset(assetId, { order })
  }

  render() {
    const {
      surveyId,
      hideProductDiseaseInfo,
      surveyAssetsRequest,
      onValueChange,
    } = this.props
    const { assetOrders } = this.state
    const requesting =
      surveyAssetsRequest.requesting && !surveyAssetsRequest.isCreate

    return (
      <div className='volt-product-disease-info'>
        {requesting && <Loader active inline='centered' />}
        {!requesting && (
          <div>
            <Checkbox
              checked={hideProductDiseaseInfo}
              onChange={(event, { checked }) =>
                onValueChange({
                  field: 'hideProductDiseaseInfo',
                  value: checked,
                })
              }
              label='Do not include in this survey'
            />
            <div className='volt-images'>
              {this.state.surveyAssets.map((asset) => (
                <div className='volt-image-wrapper'>
                  <Image src={asset.imageUrl} size='medium' bordered />
                  <div className='volt-image-detail'>
                    <div className='volt-update-image-order'>
                      Order{' '}
                      <Input
                        placeholder='Order'
                        name='order'
                        type='number'
                        size='small'
                        id={asset._id}
                        action={{
                          icon: 'save',
                          onClick: () => {
                            this.handleUpdateImageOrder(asset._id)
                          },
                        }}
                        onFocus={(event) => event.target.select()}
                        onChange={(e, data) =>
                          this.setState({
                            assetOrders: { [asset._id]: data.value },
                          })
                        }
                        value={assetOrders[asset._id]}
                      />
                    </div>
                    <div className='volt-delete-image'>
                      <Button
                        size='mini'
                        basic
                        color='red'
                        onClick={() => this.handleDeleteImage(asset._id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <AssetUploader objectId={surveyId} type='survey' showOrder />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ assets }) => ({
  surveyAssetsRequest: assets,
})

const mapDispatchToProps = (dispatch) => ({
  getSurveyAssets: (objectId) =>
    dispatch({ type: 'FETCH_ASSETS_REQUESTED', objectId, assetType: 'survey' }),
  deleteSurveyAsset: (id) => dispatch({ type: 'DELETE_ASSET_REQUESTED', id }),
  updateSurveyAsset: (id, payload) =>
    dispatch({ type: 'UPDATE_ASSET_REQUESTED', id, payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductDiseaseInfo)
