import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Header,
  Menu,
  Icon,
  Divider,
  Confirm,
  Button,
  Search,
} from 'semantic-ui-react'
import qs from 'query-string'
import _ from 'lodash'
import debounce from 'lodash/debounce'
import moment from 'moment'

import { getArray } from '../../lib/utils'
import Sidebar from '../sidebar'
import SurveyContent from '../survey-content'
import Send from './send'
import Parameters from './parameters'
import Sector from './sector'
import AdditionalCoverage from './additionalCoverage'
import ProductDiseaseInfo from './productDiseaseInfo'
import NavigationButtons from './navigationButtons'
import Disclaimer from './disclaimer'
import disclaimerData from '../survey/disclaimerData'
import Results from './results'

import { RoutePaths } from '../_routing/route-paths'
import { Breadcrumb } from '../page-layout/breadcrumb/Breadcrumb'

import './styles.scss'

const resultRenderer = ({ factor }) => (
  <div className='message empty'>
    <div className='header'>{factor.name}</div>
    <div className='description'>{factor.description}</div>
  </div>
)

const NewSurvey = (props) => {
  const [step, setStep] = useState('parameters')
  const [sectorIndex, setSectorIndex] = useState(-1)
  const [dimensionIndex, setDimensionIndex] = useState(0)
  const [factorsMap, setFactorsMap] = useState({})
  const [comparators, setComparators] = useState([])
  const [hideProductDiseaseInfo, setHideProductDiseaseInfo] = useState(false)
  const [hideBackgroundQuestions, setHideBackgroundQuestions] = useState(false)
  const [hideAdditionalCoverage, setHideAdditionalCoverage] = useState(false)
  const [hideDisclaimer, setHideDisclaimer] = useState(false)
  const [additionalCoverage, setAdditionalCoverage] = useState({})
  const [dueDate, setDueDate] = useState(moment().format())
  const [disclaimer, setDisclaimer] = useState(disclaimerData)
  const [redirectTo, setRedirectTo] = useState('')
  const [justCreated, setJustCreated] = useState(true)
  const [diagnostic, setDiagnostic] = useState(undefined)
  const [surveyId, setSurveyId] = useState(undefined)
  const [title, setTitle] = useState('')
  const [email, setEmail] = useState(undefined)
  const [emailList, setEmailList] = useState(undefined)
  const [sender, setSender] = useState(undefined)
  const [subject, setSubject] = useState(undefined)
  const [message, setMessage] = useState(undefined)
  const [
    showRemoveSurveyInviteConfirmation,
    setShowRemoveSurveyInviteConfirmation,
  ] = useState(false)
  const [surveyToRemove, setSurveyToRemove] = useState(undefined)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const {
    match,
    location,
    getDiagnostics,
    getEmailLists,
    getSurveySenders,
    getSurveyDetails,
    getSurveyInvites,
    getSectors,
    getAppSettings,
    survey,
    goTo,
    surveyInvites,
    diagnostics,
    sectors,
    createSurvey,
    updateSurvey,
    deleteSurveyInvite,
  } = props

  useEffect(() => {
    getDiagnostics()
    getEmailLists()
    getSurveySenders()
    getAppSettings()

    if (qs.parse(location.search).results) {
      setRedirectTo('results')
    }
    if (match.params.id) {
      getSurveyDetails(match.params.id)
      getSurveyInvites(match.params.id)
    } else {
      getSectors({})
    }
  }, [])

  useEffect(() => {
    if (match.params.id) {
      getSurveyDetails(match.params.id)
      getSurveyInvites(match.params.id)
    }
  }, [match.params.id])

  useEffect(() => {
    if (
      isNewSurvey() &&
      !survey.requesting &&
      survey.result &&
      survey.isCreate &&
      !survey.error
    ) {
      goTo(`/new-survey/${survey.result.data._id}#new`)
    }

    if (isNewlyCreatedSurvey() && justCreated) {
      setStep('product-disease-info')
      setJustCreated(false)
    }

    if (
      !survey.requesting &&
      survey.result &&
      !survey.isCreate &&
      survey.result.data &&
      !Array.isArray(survey.result.data)
    ) {
      const {
        _id,
        title,
        dueDate: surveyDueDate,
        hideProductDiseaseInfo,
        hideAdditionalCoverage,
        hideBackgroundQuestions,
        hideDisclaimer,
        diagnostic,
        sender,
        subject,
        message,
        factors,
        comparators,
        additionalCoverage,
        disclaimer: surveyDisclaimer,
      } = survey.result.data

      setSurveyId(_id)
      setTitle(title)
      setDueDate(surveyDueDate || dueDate)
      setHideProductDiseaseInfo(hideProductDiseaseInfo)
      setHideAdditionalCoverage(hideAdditionalCoverage)
      setHideBackgroundQuestions(hideBackgroundQuestions)
      setHideDisclaimer(hideDisclaimer)
      setDiagnostic(diagnostic)
      setSender(sender)
      setSubject(subject)
      setMessage(message)
      setComparators(comparators)
      setSectorIndex(0)
      setFactorsMap(factors || {})
      setAdditionalCoverage(additionalCoverage || {})
      setDisclaimer(surveyDisclaimer || disclaimer)

      if (redirectTo) {
        setStep(redirectTo)
        setRedirectTo('')
      }
    }
  }, [survey])

  useEffect(() => {
    if (diagnostic) {
      getSectors({ wheel: diagnostic.inventory.wheel })
    }
  }, [diagnostic])

  useEffect(() => {
    if (diagnostics.length && location.search) {
      const search = qs.parse(location.search)
      if (search.diagnostic) {
        setDiagnostic(search.diagnostic)
      }
    }
  }, [diagnostics])

  const getTitle = () => {
    const titles = {
      parameters: 'Parameters',
      'product-disease-info': 'Product / Disease info',
      coverage: 'Additional Coverage',
      disclaimer: 'Disclaimer',
      send: 'Send Survey',
      results: 'Results',
      sectors: _.get(
        sectors,
        `[${sectorIndex}].dimensions[${dimensionIndex}].name`,
        ''
      ),
    }

    return <Header as='h1'>{titles[step] || step}</Header>
  }

  const saveSurvey = (redirect) => {
    const payload = {
      title,
      dueDate,
      hideProductDiseaseInfo,
      hideAdditionalCoverage,
      hideBackgroundQuestions,
      hideDisclaimer,
      diagnostic: diagnostic._id,
      email,
      emailList,
      sender,
      subject,
      message,
      comparators,
      factors: factorsMap,
      additionalCoverage,
      disclaimer,
    }

    if (surveyId) {
      updateSurvey(payload, surveyId)
    } else {
      createSurvey(payload)
    }

    if (redirect) {
      goTo('/surveys')
    }
  }

  const handleNext = () => {
    switch (step) {
      case 'parameters':
        setStep('product-disease-info')
        break
      case 'product-disease-info':
        setStep('sectors')
        break
      case 'sectors':
        if (sectors[sectorIndex].dimensions[dimensionIndex + 1]) {
          setDimensionIndex(dimensionIndex + 1)
        } else if (sectors[sectorIndex + 1]) {
          setSectorIndex(sectorIndex + 1)
          setDimensionIndex(0)
        } else {
          goToAdditionalCoverage()
        }
        break
      case 'coverage':
        goToDisclaimer()
        break
      case 'disclaimer':
        goToSend()
        break
      case 'send':
        goToResults()
        break
      // results step
      default:
        break
    }
  }

  const handlePrevious = () => {
    let lastSectorIndex
    let lastDimensionOfLastSector

    switch (step) {
      case 'results':
        goToSend()
        break
      case 'send':
        goToDisclaimer()
        break
      case 'disclaimer':
        goToAdditionalCoverage()
        break
      case 'coverage':
        lastSectorIndex = sectors.length - 1
        lastDimensionOfLastSector =
          sectors[lastSectorIndex].dimensions.length - 1
        setStep('sectors')
        setSectorIndex(lastSectorIndex)
        setDimensionIndex(lastDimensionOfLastSector)
        break
      case 'sectors':
        if (sectorIndex === 0 && dimensionIndex === 0) {
          setStep('product-disease-info')
        } else if (dimensionIndex !== 0) {
          const previousDimensionIndex = dimensionIndex - 1
          setDimensionIndex(previousDimensionIndex)
        } else if (sectorIndex !== 0 && dimensionIndex === 0) {
          const previousSectorIndex = sectorIndex - 1
          const lastDimensionOfPreviousSector =
            sectors[previousSectorIndex].dimensions.length - 1
          setSectorIndex(previousSectorIndex)
          setDimensionIndex(lastDimensionOfPreviousSector)
        }
        break
      case 'product-disease-info':
        setStep('parameters')
        break
      default:
        break
    }
  }

  const isNewSurvey = () => {
    return !match.params.id
  }

  const isNewlyCreatedSurvey = () => {
    return location.hash === '#new'
  }

  const validateSurveyBasicParams = () => {
    if (step === 'parameters' && (!title || !dueDate || !diagnostic)) {
      return true
    }

    return false
  }

  const onValueChange = ({ field, value }) => {
    const valueFunctions = {
      title: setTitle,
      dueDate: setDueDate,
      hideProductDiseaseInfo: setHideProductDiseaseInfo,
      hideAdditionalCoverage: setHideAdditionalCoverage,
      hideBackgroundQuestions: setHideBackgroundQuestions,
      hideDisclaimer: setHideDisclaimer,
      diagnostic: setDiagnostic,
      email: setEmail,
      emailList: setEmailList,
      sender: setSender,
      subject: setSubject,
      message: setMessage,
      comparators: setComparators,
      factorsMap: setFactorsMap,
      additionalCoverage: setAdditionalCoverage,
      disclaimer: setDisclaimer,
    }
    valueFunctions[field](value)
  }

  const selectDiagnostic = (id) => {
    const diagnostic = diagnostics.find((item) => item._id === id)
    const comparators = diagnostic.inventory.comparators.map(
      (comparator) => comparator._id
    )
    setDiagnostic(diagnostic)
    setComparators(comparators)
    getSectors({ wheel: diagnostic.inventory.wheel })
  }

  const updateComparator = (checked, comparator) => {
    if (checked) {
      setComparators([...comparators, comparator])
    } else {
      const newComparators = _.clone(comparators)
      const index = newComparators.indexOf(comparator)
      if (index !== -1) {
        newComparators.splice(index, 1)
      }
      setComparators(newComparators)
    }
  }

  const selectSector = (newSectorIndex) => {
    if (sectorIndex > -1) {
      setStep('sectors')
      setSectorIndex(newSectorIndex)
      setDimensionIndex(0)
    }
  }

  const setSectorsToDefault = () => {
    setSectorIndex(0)
    setDimensionIndex(0)
  }

  const goToSend = () => {
    if (sectorIndex > -1) {
      setStep('send')
      setSectorsToDefault()
    }
  }

  const goToResults = () => {
    if (sectorIndex > -1) {
      setStep('results')
      setSectorsToDefault()
    }
  }

  const goToAdditionalCoverage = () => {
    if (sectorIndex > -1) {
      setStep('coverage')
      setSectorsToDefault()
    }
  }

  const goToDisclaimer = () => {
    if (sectorIndex > -1) {
      setStep('disclaimer')
      setSectorsToDefault()
    }
  }

  const removeSurveyInvite = (survey) => {
    setShowRemoveSurveyInviteConfirmation(true)
    setSurveyToRemove(survey)
  }

  const surveyInvitesData =
    match.params.id &&
    surveyInvites.result &&
    Array.isArray(surveyInvites.result.data)
      ? surveyInvites.result.data
      : []

  const errors = {}

  if (
    survey.error &&
    survey.result.response &&
    survey.result.response.data.validation
  ) {
    survey.result.response.data.validation.keys.forEach((key) => {
      errors[key] = true
      return true
    })
  }

  const surveyTitle = _.get(survey, 'result.data.title', 'Survey')

  const handleSearchChange = (e, { value }) => {
    const result = []
    const re = new RegExp(_.escapeRegExp(value), 'i')
    for (const sIdx in sectors) {
      const sector = sectors[sIdx]
      for (const dIdx in sector.dimensions) {
        const dimension = sector.dimensions[dIdx]
        for (const factor of dimension.factors) {
          if (re.test(factor.name)) {
            result.push({
              title: '',
              sector: {
                ...sector,
                index: sIdx,
              },
              dimension: {
                ...dimension,
                index: dIdx,
              },
              factor,
              key: factor._id,
            })
          }
        }
      }
    }
    setSearchResults(result.slice(0, 6))
    setSearchQuery(value)
  }

  const onFactorSelect = (e, { result }) => {
    const { sector, dimension } = result
    selectSector(parseInt(sector.index))
    setDimensionIndex(parseInt(dimension.index))
  }

  return (
    <div className='newSurveyContainer'>
      <div className='surveyBreadcrumb'>
        <Breadcrumb
          nodes={[
            { label: 'Dashboard', route: RoutePaths.Home },
            { label: 'Surveys', route: RoutePaths.Surveys },
          ]}
        />

        <Header as='h1' className='breadcrumbHeader'>
          {surveyTitle}
        </Header>
      </div>

      {!isNewSurvey() && (
        <div className='actionBar surveySearchBar'>
          <Search
            placeholder='Search'
            className='searchInput'
            loading={false}
            value={searchQuery}
            onResultSelect={onFactorSelect}
            onSearchChange={debounce(handleSearchChange, 500, {
              leading: true,
            })}
            results={searchResults}
            resultRenderer={resultRenderer}
            noResultsMessage={'Change search query'}
            noResultsDescription={
              'For current search query, no factors are found.'
            }
          />
        </div>
      )}

      <div className='newSurveyComponent'>
        <Sidebar>
          <Menu secondary vertical className='surveySideMenu'>
            <Menu.Item
              name='parameters'
              onClick={() => {
                setStep('parameters')
                setSectorsToDefault()
              }}
              active={step === 'parameters'}
            >
              <div className='menuItem'>
                <Icon name='sliders horizontal' size='large' />
                <div className='surveySectorMenu'>Parameters</div>
              </div>
            </Menu.Item>

            <Menu.Item
              name='product-disease-info'
              onClick={() => {
                setStep('product-disease-info')
                setSectorsToDefault()
              }}
              disabled={isNewSurvey()}
              active={step === 'product-disease-info'}
            >
              <div className='menuItem'>
                <Icon name='info' size='large' />
                <div className='surveySectorMenu'>Product / disease info</div>
              </div>
            </Menu.Item>

            {sectors &&
              sectors.map((sector, sectIndex) => (
                <div
                  className={`menuAccordion ${
                    step === 'sectors' && sectIndex === sectorIndex
                      ? 'active'
                      : ''
                  }`}
                  key={sector.name}
                >
                  <Menu.Item
                    key={sector.name}
                    name='sector'
                    onClick={() => selectSector(sectIndex)}
                    disabled={isNewSurvey()}
                    active={step === 'sectors' && sectIndex === sectorIndex}
                    className='menuAccordion'
                  >
                    <div className='menuItem'>
                      <Icon
                        name={`angle ${
                          step === 'sectors' && sectIndex === sectorIndex
                            ? 'down'
                            : 'right'
                        }`}
                      />
                      <div className='surveySectorMenu'>{sector.name}</div>
                    </div>
                  </Menu.Item>
                  {step === 'sectors' && sectIndex === sectorIndex && (
                    <Menu secondary vertical>
                      {sector.dimensions.map((dimension, dimIndex) => (
                        <Menu.Item
                          key={dimension._id}
                          active={dimIndex === dimensionIndex}
                          onClick={(e) => {
                            e.stopPropagation()
                            setDimensionIndex(dimIndex)
                          }}
                          className='innerItem'
                        >
                          <div className='surveySectorMenu'>
                            {dimension.name}
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu>
                  )}
                </div>
              ))}
            <Menu.Item
              name='coverage'
              onClick={goToAdditionalCoverage}
              disabled={isNewSurvey()}
              active={step === 'coverage'}
            >
              <div className='menuItem'>
                <Icon name='tasks' size='large' />
                <div className='surveySectorMenu'>Additional Coverage</div>
              </div>
            </Menu.Item>
            <Menu.Item
              name='coverage'
              onClick={goToDisclaimer}
              disabled={isNewSurvey()}
              active={step === 'disclaimer'}
            >
              <div className='menuItem'>
                <Icon name='exclamation triangle' size='large' />
                <div className='surveySectorMenu'>Disclaimer</div>
              </div>
            </Menu.Item>
            <Menu.Item
              name='send'
              onClick={goToSend}
              disabled={isNewSurvey()}
              active={step === 'send'}
            >
              <div className='menuItem'>
                <Icon name='send' size='large' />
                <div className='surveySectorMenu'>Send Survey</div>
              </div>
            </Menu.Item>
            <Menu.Item
              name='results'
              onClick={goToResults}
              disabled={isNewSurvey()}
              active={step === 'results'}
            >
              <div className='menuItem'>
                <Icon name='table' size='large' />
                <div className='surveySectorMenu'>Results</div>
              </div>
            </Menu.Item>
          </Menu>
        </Sidebar>
        <SurveyContent>
          <div className='parent'>{getTitle()}</div>

          <br />
          <Divider />

          {step === 'parameters' && (
            <Parameters
              selectDiagnostic={selectDiagnostic}
              hideBackgroundQuestions={hideBackgroundQuestions}
              onValueChange={onValueChange}
              title={title}
              dueDate={dueDate}
              surveyId={surveyId}
              diagnostic={diagnostic}
              surveyInvites={surveyInvitesData}
              comparators={comparators}
              updateComparator={updateComparator}
            />
          )}

          {step === 'product-disease-info' && (
            <ProductDiseaseInfo
              hideProductDiseaseInfo={hideProductDiseaseInfo}
              onValueChange={onValueChange}
              surveyId={surveyId}
            />
          )}
          {step === 'sectors' && (
            <Sector
              onValueChange={onValueChange}
              sectors={sectors}
              sector={sectors[sectorIndex]}
              sectorIndex={sectorIndex}
              dimensionIndex={dimensionIndex}
              factorsMap={factorsMap}
              onDimensionChange={(val) => setDimensionIndex(val)}
              selectSector={(sectorIndex) => selectSector(sectorIndex)}
            />
          )}
          {step === 'coverage' && (
            <AdditionalCoverage
              onValueChange={onValueChange}
              hideAdditionalCoverage={hideAdditionalCoverage}
              additionalCoverage={additionalCoverage}
            />
          )}
          {step === 'disclaimer' && (
            <Disclaimer
              onValueChange={onValueChange}
              disclaimer={disclaimer}
              hideDisclaimer={hideDisclaimer}
            />
          )}
          {step === 'send' && (
            <Send
              onValueChange={onValueChange}
              email={email}
              emailList={emailList}
              sender={sender}
              subject={subject}
              message={message}
            />
          )}
          {step === 'results' && (
            <Results
              survey={_.get(survey, 'result.data')}
              onRemoveSurveyInvite={removeSurveyInvite}
            />
          )}

          <div className='parent marginTop'>
            <div className='actionsMenu'>
              <NavigationButtons
                goTo={(url) => goTo(url)}
                step={step}
                saveSurvey={(redirect) => saveSurvey(redirect)}
                validateSurveyBasicParams={validateSurveyBasicParams}
                isNewSurvey={isNewSurvey}
                isNewlyCreatedSurvey={isNewlyCreatedSurvey}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            </div>
          </div>
        </SurveyContent>
      </div>

      <Confirm
        open={showRemoveSurveyInviteConfirmation}
        header='Delete Survey Participant Data'
        content={
          <div className='content'>
            <p>
              You are about to remove this user from your survey and delete all
              survey responses. This action is permanent and you cannot undo.
            </p>
            <p>
              If you need to include this participant in this survey again, you
              will need to resend an invite via Surveys.
            </p>
          </div>
        }
        onCancel={() =>
          this.setState({
            showRemoveSurveyInviteConfirmation: false,
            surveyToRemove: undefined,
          })
        }
        onConfirm={() => {
          deleteSurveyInvite(surveyToRemove)
          this.setState({
            showRemoveSurveyInviteConfirmation: false,
            surveyToRemove: undefined,
          })
        }}
        confirmButton={
          <Button className='deleteSurveyInviteBtn' color='red'>
            Delete
          </Button>
        }
      />

      <Confirm
        open={showRemoveSurveyInviteConfirmation}
        header='Delete Survey Participant Data'
        content={
          <div className='content'>
            <p>
              You are about to remove this user from your survey and delete all
              survey responses. This action is permanent and you cannot undo.
            </p>
            <p>
              If you need to include this participant in this survey again, you
              will need to resend an invite via Surveys.
            </p>
          </div>
        }
        onCancel={() => {
          setShowRemoveSurveyInviteConfirmation(false)
          setSurveyToRemove(undefined)
        }}
        onConfirm={() => {
          deleteSurveyInvite(surveyToRemove)
          setShowRemoveSurveyInviteConfirmation(false)
          setSurveyToRemove(undefined)
        }}
        confirmButton={
          <Button className='deleteSurveyInviteBtn' color='red'>
            Delete
          </Button>
        }
      />
    </div>
  )
}

const mapStateToProps = ({
  sectors,
  diagnostic,
  survey,
  surveyInvites,
  emailList,
  surveySender,
}) => ({
  sectors: sectors.result ? sectors.result.data : [],
  diagnostics: getArray(diagnostic, 'fetchResult.data', 'diagnostic'),
  survey,
  surveyInvites,
  emailLists: getArray(emailList, 'result.data'),
  surveySenders: getArray(surveySender, 'result.data'),
})

const mapDispatchToProps = (dispatch) => ({
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
  getDiagnostics: () => dispatch({ type: 'FETCH_DIAGNOSTICS_REQUESTED' }),
  getSectors: (query) => dispatch({ type: 'FETCH_SECTORS_REQUESTED', query }),
  getSurveyDetails: (id) =>
    dispatch({ type: 'FETCH_SURVEY_DETAILS_REQUESTED', id }),
  updateSurvey: (payload, id) =>
    dispatch({ type: 'UPDATE_SURVEY_REQUESTED', payload, id }),
  updateSurveyProps: (payload, id) =>
    dispatch({ type: 'UPDATE_SURVEY_PROPS_REQUESTED', payload, id }),
  createSurvey: (payload) =>
    dispatch({ type: 'CREATE_SURVEY_REQUESTED', payload }),
  getEmailLists: () => dispatch({ type: 'FETCH_EMAIL_LISTS_REQUESTED' }),
  getSurveyInvites: (survey) =>
    dispatch({
      type: 'FETCH_SURVEY_INVITES_REQUESTED',
      query: {
        survey,
        all: true,
      },
    }),
  getSurveySenders: () => dispatch({ type: 'FETCH_SURVEY_SENDERS_REQUESTED' }),
  deleteSurveyInvite: (surveyInvite) =>
    dispatch({
      type: 'DELETE_SURVEY_INVITE_REQUESTED',
      id: surveyInvite._id,
    }),
  getAppSettings: () => dispatch({ type: 'FETCH_APP_SETTINGS_REQUESTED' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewSurvey)
