import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Menu, Loader } from 'semantic-ui-react'
import get from 'lodash/get'

import Favorability from './Favorability'
import Importance from './Importance'
import { isInRequestingState } from '../common/request-helpers'

import './styles.scss'

class DiagnosticAndSurveyPreview extends Component {
  state = {
    activeSubPage: 'favorability',
    diagnostic: null,
    survey: null,
    surveys: null
  }

  componentDidMount() {
    const { match, getInitialSurvey, getDiagnostic } = this.props

    if (match.params.surveyId) {
      getInitialSurvey(match.params.surveyId)
    }

    if (match.params.diagnosticId) {
      getDiagnostic(match.params.diagnosticId)
    }
  }

  componentDidUpdate() {
    const {
      diagnostic,
      diagnosticRequest,
      initialSurvey,
      surveys,
      surveysRequest,
      getSurveysByDiagnostic,
      initialSurveyRequest,
      getDiagnostic
    } = this.props
    const { survey } = this.state

    if (isInRequestingState(initialSurveyRequest)) {
      return
    }

    if (initialSurvey && !survey) {
      this.setSurvey(initialSurvey)
    }

    if (
      initialSurvey &&
      !diagnostic &&
      !isInRequestingState(diagnosticRequest)
    ) {
      getDiagnostic(initialSurvey.diagnostic._id)
    }

    if (diagnostic && !surveys && !isInRequestingState(surveysRequest)) {
      getSurveysByDiagnostic(diagnostic._id)
    }

    if (diagnostic && !survey) {
      this.setDiagnosticToSurvey(diagnostic)
    }
  }

  setDiagnosticToSurvey(diagnostic) {
    this.setState({ survey: { ...diagnostic, type: 'internal' } })
  }

  setSurvey(survey) {
    this.setState({ survey: { ...survey, type: 'external' } })
  }

  onSurveySelected(selectedSurveyId) {
    const { goTo, diagnostic, surveys } = this.props

    if (selectedSurveyId === diagnostic._id) {
      this.setDiagnosticToSurvey(diagnostic)
      goTo(`/diagnostic-preview/${selectedSurveyId}`)
    } else {
      const selectedSurvey = surveys.find(survey => {
        return survey._id === selectedSurveyId
      })
      this.setSurvey(selectedSurvey)
      goTo(`/survey-preview/${selectedSurveyId}`)
    }
  }

  showLoading() {
    const {
      initialSurveyRequest,
      diagnosticRequest,
      surveysRequest,
      diagnostic,
      surveys
    } = this.props
    const { survey } = this.state

    return (
      isInRequestingState(initialSurveyRequest) ||
      isInRequestingState(diagnosticRequest) ||
      isInRequestingState(surveysRequest) ||
      !diagnostic ||
      !surveys ||
      !survey
    )
  }

  render() {
    const { diagnostic } = this.props

    if (this.showLoading()) {
      return <Loader active />
    }

    return (
      <div className='voltPreviewComponent'>
        <div className='voltPreview-navigation'>
          <div className='voltPreview-navigation-disease'>
            {diagnostic.inventory.indicatedPopulation}
          </div>
          <div className='voltPreview-navigation-product'>
            {diagnostic.inventory.product}
          </div>

          {this.renderSubPageMenu()}
        </div>

        {this.renderSubPage()}
      </div>
    )
  }

  renderSubPageMenu() {
    const { activeSubPage } = this.state

    return (
      <Menu attached='top' tabular pointing secondary>
        <Menu.Item
          id='favorability'
          name='Favorability'
          active={activeSubPage === 'favorability'}
          onClick={() => {
            this.setState({ activeSubPage: 'favorability' })
          }}
        />
        <Menu.Item
          disabled
          id='importance'
          name='Importance'
          active={activeSubPage === 'importance'}
          onClick={() => {
            this.setState({ activeSubPage: 'importance' })
          }}
        />
      </Menu>
    )
  }

  renderSubPage() {
    const { surveys, diagnostic } = this.props
    const { activeSubPage, survey } = this.state

    switch (activeSubPage) {
      case 'favorability':
        return (
          <Favorability
            surveys={surveys}
            survey={survey}
            diagnostic={diagnostic}
            onSurveySelected={this.onSurveySelected.bind(this)}
          />
        )
      case 'importance':
        return <Importance />
      default:
        return null
    }
  }
}

const mapStateToProps = ({ survey, surveysByDiagnostic, diagnostic }) => ({
  initialSurveyRequest: survey,
  initialSurvey: get(survey, 'result.data', null),
  diagnosticRequest: diagnostic,
  diagnostic: get(diagnostic, 'result.data', null),
  surveysRequest: surveysByDiagnostic,
  surveys: surveysByDiagnostic.result && get(surveysByDiagnostic, 'result.data')
})

const mapDispatchToProps = dispatch => ({
  goTo: url => dispatch({ type: 'REDIRECT', url }),
  getInitialSurvey: id =>
    dispatch({ type: 'FETCH_SURVEY_DETAILS_REQUESTED', id }),
  getDiagnostic: id =>
    dispatch({ type: 'FETCH_DIAGNOSTIC_DETAILS_REQUESTED', id }),
  getSurveysByDiagnostic: diagnosticId =>
    dispatch({ type: 'FETCH_SURVEYS_BY_DIAGNOSTIC_REQUESTED', diagnosticId })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiagnosticAndSurveyPreview)
