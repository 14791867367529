const defaultState = {
  requesting: false,
  error: false,
  result: null,
  isFetch: false,
  isCreate: false,
  isDelete: false,
  loaded: false
}

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'FETCH_SECTORS_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: false
      }
    case 'FETCH_SECTORS_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: true
      }
    case 'FETCH_SECTORS_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: false
      }
    case 'CREATE_SECTOR_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: false
      }
    case 'CREATE_SECTOR_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: true
      }
    case 'CREATE_SECTOR_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: false
      }
    case 'UPDATE_SECTOR_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: false
      }
    case 'UPDATE_SECTOR_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: true
      }
    case 'UPDATE_SECTOR_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: false
      }
    default:
      return state
  }
}
