const mapEntriesToFactorIds = (entries, entry) => {
  entry.factors.forEach(factor => {
    if (!entries[factor._id]) {
      entries[factor._id] = []
    }
    entries[factor._id].push(entry)
  })
  return entries
}

const calculateActiveTab = (sectorId, section, sectors) => {
  const defaultTabIndex = 0

  if (sectorId > -1) {
    return sectorId + 1
  } else if (section) {
    if (section === 'settings') {
      return sectors.length + 1
    }
    if (section === 'files') {
      return sectors.length + 2
    }
    return defaultTabIndex
  }
}

export { mapEntriesToFactorIds, calculateActiveTab }