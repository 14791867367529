import React, { Component } from 'react'
import range from 'lodash/range'
import { Form, Radio } from 'semantic-ui-react'

import './RateFactor.scss'

class RateFactor extends Component {
  handleFactorRating = (e, data) => {
    const { updateFactor, factor } = this.props
    const value = parseInt(data.value, 10)
    updateFactor({ score: value }, factor._id)
  }

  render() {
    const { factor, ratingQuestion, ratingOptions } = this.props
    const score = (factor.score || 0).toString()

    return (
      <div className='rateFactor'>
        <div className='title'>{ratingQuestion}</div>
        <Form>
          {range(1, 8).map(number => (
            <Form.Field
              key={number.toString()}
              className='ratingItem'>
              <div className='score'>{number}</div>
              <Radio
                name='factorRating'
                value={number.toString()}
                checked={score === number.toString()}
                onChange={this.handleFactorRating}
              />
            </Form.Field>
          ))}
        </Form>
        <div className='description'>
          {range(1, 8).map(number => (
            <div
              key={number.toString()}
              className='descriptionItem'>
              {ratingOptions[number - 1]}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default RateFactor
