import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Header, Divider, Input, Form, Radio } from 'semantic-ui-react'
import Button from '../common/Button'
import moment from 'moment'
import get from 'lodash/get'

import Prompt from './prompt'

class BackgroundQuestions extends Component {
  state = {
    role: null,
    roleOther: '',
    organization: null,
    organizationOther: '',
    lives: {},
    timeToComplete: null
  }

  lives = [
    { label: 'Commercial', key: 'commercial' },
    { label: 'Medicare Part D', key: 'medicarePartD' },
    { label: 'Managed Medicaid', key: 'managedMedicaid' },
    { label: 'Health Exchange', key: 'healthExchange' }
  ]

  roles = ['Pharmacy Director', 'Medical Director', 'Other (specify)']

  organizations = [
    'National Health Plan',
    'Regional Health Plan',
    'PBM',
    'Integrated Delivery Network',
    'Other (specify)'
  ]

  handleSubmit() {
    const { surveyNavigation, saveBackgroundQuestions } = this.props
    const {
      role,
      roleOther,
      organization,
      organizationOther,
      lives,
      timeToComplete,
      startTime
    } = this.state

    saveBackgroundQuestions({
      role,
      roleOther,
      organization,
      organizationOther,
      lives,
      timeToComplete: timeToComplete ? timeToComplete : moment().diff(startTime)
    })

    surveyNavigation.setCurrentStepToDone()
    surveyNavigation.navigateToNext()
  }

  validateForm() {
    const { surveyNavigation } = this.props

    let valid = true
    if (!this.state.role || !this.state.organization) {
      valid = false
    } else if (this.state.role === 'Other (specify)' && !this.state.roleOther) {
      valid = false
    } else if (
      this.state.organization === 'Other (specify)' &&
      !this.state.organizationOther
    ) {
      valid = false
    } else {
      valid = this.lives.reduce((acum, item) => {
        return (
          acum ||
          this.state.lives[`total_${item.key}`] ||
          this.state.lives[`pharmacy_${item.key}`]
        )
      }, false)
    }

    if (!surveyNavigation.checkIfCurrentStepIsDone()) {
      if (valid) {
        surveyNavigation.setCurrentStepToCompleted()
      } else {
        surveyNavigation.setCurrentStepToPending()
      }
    }
  }

  changeLives = (key, value) => {
    const newState = Object.assign({}, this.state.lives)
    newState[key] = value
    this.setState({ lives: newState })
  }

  componentDidMount() {
    this.setState({ startTime: moment() })
    this.loadForm()
  }

  componentDidUpdate(prevProps) {
    const { surveyResponse } = this.props

    if (surveyResponse !== prevProps.surveyResponse) {
      this.loadForm()
    }
  }

  loadForm = () => {
    const { surveyResponse } = this.props

    if (surveyResponse.backgroundQuestions) {
      const {
        role,
        roleOther,
        organization,
        organizationOther,
        lives,
        timeToComplete
      } = surveyResponse.backgroundQuestions

      this.setState({
        role,
        roleOther,
        organization,
        organizationOther,
        lives,
        timeToComplete
      })
    }
  }

  render() {
    const { surveyNavigation } = this.props

    const getForm = (category, element) => (
      <Form.Field key={`${category}_${element}`}>
        <Radio
          label={element}
          name={category}
          value={element}
          checked={this.state[category] === element}
          onChange={(e, { value }) => this.setState({ [category]: value })}
        />
      </Form.Field>
    )

    return (
      <div style={{ padding: '30px', marginBottom: '50px' }}>
        <Header as='h1' style={{ marginBottom: '56px' }}>
          Background Questions
        </Header>

        <Header as='h2'>
          1. How would you best characterize your role within your organization?
        </Header>

        <Form style={{ padding: '25px 40px' }}>
          {this.roles.map(role => getForm('role', role))}
          <Input
            value={this.state.roleOther}
            onChange={(e, { value }) => this.setState({ roleOther: value })}
            style={{ marginLeft: '25px' }}
          />
        </Form>

        <Divider />

        <Header as='h2'>
          2. Which of the following best characterizes your organization?
        </Header>

        {this.validateForm()}
        <Form style={{ padding: '25px 40px' }}>
          {this.organizations.map(organization =>
            getForm('organization', organization)
          )}
          <Input
            value={this.state.organizationOther}
            fluid={false}
            onChange={(e, { value }) =>
              this.setState({ organizationOther: value })
            }
            style={{ marginLeft: '25px' }}
          />
        </Form>

        <Divider />

        <Header as='h2'>
          3. How many lives does your organization currently manage in each of
          the following lines of business?
        </Header>

        <table
          width='100%'
          style={{ maxWidth: '700px' }}
          border='0'
          cellPadding='10'
        >
          <tbody>
            <tr
              style={{
                fontWeight: 'bold',
                textDecoration: 'underline',
                textAlign: 'center'
              }}
            >
              <td />
              <td>TOTAL LIVES</td>
              <td>PHARMACY LIVES</td>
            </tr>
            {this.lives.map(live => (
              <tr key={live.key}>
                <td style={{ textAlign: 'right' }}>{live.label}</td>
                <td style={{ textAlign: 'center' }}>
                  <Input
                    value={this.state.lives[`total_${live.key}`]}
                    onChange={(e, { value }) =>
                      this.changeLives(`total_${live.key}`, value)
                    }
                  />
                </td>
                <td style={{ textAlign: 'center' }}>
                  <Input
                    value={this.state.lives[`pharmacy_${live.key}`]}
                    onChange={(e, { value }) =>
                      this.changeLives(`pharmacy_${live.key}`, value)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Prompt>
          <div className='promptInstructions background-questions'>
            <div>
              Please answer a few quick background questions about your role and
              your organization.
            </div>
            <div>
              Once you have completed this section, the Next button will be
              active and you can move on to the next step.
            </div>
          </div>
          <div className='buttonsContainer'>
            {/*  TODO: enable previous buttons
            <Button
              size='large'
              onClick={() => surveyNavigation.navigateToPrevious()}
            >Previous</Button> */}

            <Button
              color={surveyNavigation.checkIfCurrentStepIsDoneOrCompleted() ? 'pink' : 'gray'}
              size='large'
              onClick={() => this.handleSubmit()}
              disabled={!surveyNavigation.checkIfCurrentStepIsDoneOrCompleted()}
            >
              Next
            </Button>
          </div>
        </Prompt>
      </div>
    )
  }
}

const mapStateToProps = ({ surveyResponse }) => ({
  surveyResponse: get(surveyResponse, 'result.data', {})
})

export default connect(mapStateToProps)(BackgroundQuestions)
