import React from 'react'
import { Input } from 'semantic-ui-react'
import Button from './Button'

import './TopBar.scss'

const TopBar = ({
  label = 'Add Data Entry',
  onSearchChange = () => false,
  onPress = () => false,
  searchValue = ''
}) => (
  <div className='topBar'>
    <Button className='largeButton' onClick={() => onPress()}>
      {label}
    </Button>
    <Input
      value={searchValue}
      icon='search'
      placeholder='Search'
      onChange={(e, data) => onSearchChange(data.value)}
    />
  </div>
)

export default TopBar
