import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import './App.scss';

import { Routes } from '../_routing/routes';
import { AppData } from '../common';

import { RootContextProvider } from '../../contexts/RootContext';
import store from '../../redux/store';
import history from '../../redux/history';

const packageInfo = require('../../volt-package.json');

const getVersion = () => {
  if (packageInfo.version === 'local') {
    return packageInfo.version;
  }
  return `v${packageInfo.version}`;
};

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <RootContextProvider>
            <div className='appComponent'>
              <Routes />
              <AppData appVersion={getVersion()} />
            </div>
          </RootContextProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
