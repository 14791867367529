import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Confirm } from 'semantic-ui-react'
import get from 'lodash/get'

class DeleteGroupModal extends Component {
  remove = () => {
    const { remove, group, onClose } = this.props

    remove(group)
    onClose()
  }

  render() {
    const { group, onClose } = this.props

    return (
      <Confirm
        open={!!group}
        header='Delete Group'
        content={
          <div className='modalContent'>
            Are you sure you want to delete{' '}
            <strong>{get(group, 'name')}</strong>?
          </div>
        }
        onCancel={onClose}
        onConfirm={this.remove}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  remove: group => {
    dispatch({ type: 'DELETE_GROUP_REQUESTED', id: group._id })
    dispatch({
      type: 'REDIRECT',
      url: `/groups` + (group.parent ? '/' + group.parent._id : '')
    })
  }
})

export default connect(
  null,
  mapDispatchToProps
)(DeleteGroupModal)
