import React from 'react';

const Prompt = ({ children }) => (
  <div className='surveyPromptContainer'>
    <div className='surveyPrompt'>
      {children}
    </div>
  </div>
);

export default Prompt;
