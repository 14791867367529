import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Header,
  Form,
  Input,
  Divider,
  Dropdown,
  Checkbox
} from 'semantic-ui-react'

import Button from '../common/Button'

import './styles.scss'
import { USER_TYPES } from '../../types/user.types'
import _ from 'lodash'
import { PermissionsTable } from './permissionsTable'
import { TABS } from '../../types/dashboard.types'

const TAB_PERMISSIONS = [TABS.WHEELS, TABS.INVENTORIES, TABS.DIAGNOSTICS]

const NewUser = ({
  match,
  getUserDetails,
  user,
  goTo,
  updateUser,
  createUser,
  diagnostics,
  wheels,
  getDiagnostics,
  getWheels
}) => {
  const [editedUser, setEditedUser] = useState({})

  useEffect(() => {
    if (match.params.id) {
      getUserDetails(match.params.id)
    }
    getDiagnostics({})
    getWheels({})
  }, [])

  useEffect(() => {
    if (!user.requesting && user.result && user.isCreate) {
      goTo('/users')
    }

    if (!user.requesting && user.result && !user.isCreate) {
      const editing = !!((match.params.id || match.params.id === 0) && match.params.id !== 'new');

      setEditedUser({
        ...user.result.data,
        editing
      })
    }
  }, [user, goTo])

  const save = () => {
    const {
      email,
      type,
      firstName,
      lastName,
      editing,
      _id,
      enabledTabs,
      diagnosticIds
    } = editedUser

    if (editing) {
      return updateUser(_id, {
        email,
        type,
        firstName,
        lastName,
        enabledTabs,
        diagnosticIds
      })
    }

    return createUser({
      email,
      type,
      firstName,
      lastName,
      enabledTabs,
      diagnosticIds
    })
  }

  const userTypeOptions = Object.keys(USER_TYPES).map(key => {
    return {
      value: key,
      key,
      text: USER_TYPES[key]
    }
  })

  return (
    <div className='newUserComponent'>
      <Header as='h1'>{editedUser.editing ? 'Edit' : 'New'} User</Header>
      <br />
      <Divider />

      <Form>
        <Form.Field>
          <label htmlFor='firstName'>FIRST NAME</label>
          <Input
            placeholder='First Name'
            name='firstName'
            value={editedUser.firstName}
            onChange={(e, data) =>
              setEditedUser(oldUser => ({ ...oldUser, firstName: data.value }))
            }
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor='lastName'>LAST NAME</label>
          <Input
            placeholder='Last Name'
            name='lastName'
            value={editedUser.lastName}
            onChange={(e, data) =>
              setEditedUser(oldUser => ({ ...oldUser, lastName: data.value }))
            }
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor='email'>EMAIL</label>
          <Input
            placeholder='Email'
            name='email'
            onChange={(e, data) =>
              setEditedUser(oldUser => ({ ...oldUser, email: data.value }))
            }
            value={editedUser.email}
            readOnly={editedUser.editing}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor='type'>TYPE</label>
          <Dropdown
            placeholder='User Type'
            fluid
            search
            selection
            onChange={(e, data) =>
              setEditedUser(oldUser => ({ ...oldUser, type: data.value }))
            }
            value={editedUser.type}
            options={userTypeOptions}
          />
        </Form.Field>
        {editedUser.type === 'C' && (
          <>
            <Form.Field className={'tabsFormField'}>
              <label htmlFor='permissions'>TABS</label>
              <div className='tabsContainer'>
                {TAB_PERMISSIONS.map(permission => {
                  const enabledTabs = _.cloneDeep(editedUser.enabledTabs) || []
                  return (
                    <div
                      className={`${
                        !enabledTabs.includes(permission) ? 'grayText' : ''
                      } tab`}
                    >
                      <Checkbox
                        label={permission}
                        checked={enabledTabs.includes(permission)}
                        onChange={() => {
                          const permissionIndex = enabledTabs.indexOf(
                            permission
                          )
                          if (permissionIndex >= 0) {
                            enabledTabs.splice(permissionIndex, 1)
                            return setEditedUser(oldUser => ({
                              ...oldUser,
                              enabledTabs
                            }))
                          }
                          return setEditedUser(oldUser => ({
                            ...oldUser,
                            enabledTabs: [...enabledTabs, permission]
                          }))
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </Form.Field>
            <Form.Field className={'permissionsFormField'}>
              <label htmlFor='permissions'>PERMISSIONS</label>
            </Form.Field>
            <PermissionsTable
              diagnostics={diagnostics}
              wheels={wheels}
              diagnosticIds={editedUser.diagnosticIds}
              setDiagnosticIds={diagnosticIds =>
                setEditedUser(oldUser => ({
                  ...oldUser,
                  diagnosticIds
                }))
              }
              enabledTabs={editedUser.enabledTabs}
            />
          </>
        )}
      </Form>

      <div className='parent marginTop'>
        <div className='actionsMenu'>
          <Button rounded color='white' onClick={() => goTo('/users')}>
            Cancel
          </Button>
          <Button rounded shadowed onClick={save}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ diagnostic, wheels, users }) => {
  const diagnosticData = _.get(diagnostic, 'fetchResult.data', [])
  const wheelData = _.get(wheels, 'result.data', [])
  return {
    diagnostics: Array.isArray(diagnosticData) ? diagnosticData : [],
    user: users,
    wheels: Array.isArray(wheelData) ? wheelData : []
  }
}

const mapDispatchToProps = dispatch => ({
  goTo: url => dispatch({ type: 'REDIRECT', url }),
  getUserDetails: id => dispatch({ type: 'FETCH_USER_DETAILS_REQUESTED', id }),
  createUser: payload => dispatch({ type: 'CREATE_USER_REQUESTED', payload }),
  updateUser: (id, payload) =>
    dispatch({ type: 'UPDATE_USER_REQUESTED', id, payload }),
  getWheels: query => dispatch({ type: 'FETCH_WHEELS_REQUESTED', query }),
  getDiagnostics: query =>
    dispatch({ type: 'FETCH_DIAGNOSTICS_REQUESTED', query })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUser)
