import React, { Component } from 'react'
import { Header, Menu, Tab } from 'semantic-ui-react'

import SectorAdmin from './sector-admin'
import { AppMaintenance } from './app-maintenance'
import { EmailTemplates } from './email-templates'
import { DataDuplication } from './data-duplication'

import './styles.scss'

class AdminControls extends Component {
  render() {
    return (
      <div className='adminControlsComponent'>
        <Header as='h1'>Admin Controls</Header>

        <Tab
          className='adminControlsMenu'
          menu={{ secondary: true, pointing: true }}
          panes={[
            {
              menuItem: (
                <Menu.Item key='wheel-settings'>
                  Default Wheel Settings
                </Menu.Item>
              ),
              render: () => <SectorAdmin />,
            },
            {
              menuItem: (
                <Menu.Item key='app-maintenance'>App Maintenance</Menu.Item>
              ),
              render: () => <AppMaintenance />,
            },
            {
              menuItem: (
                <Menu.Item key='email-templates'>Email Templates</Menu.Item>
              ),
              render: () => <EmailTemplates />,
            },
            {
              menuItem: (
                <Menu.Item key='data-duplication'>Data Duplication</Menu.Item>
              ),
              render: () => <DataDuplication />,
            },
          ]}
        />
      </div>
    )
  }
}

export default AdminControls
