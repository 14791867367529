import React from 'react'
import { Form, Radio } from 'semantic-ui-react'
import range from 'lodash/range'

import Alert from '../alert'

const RateFactor = ({
  allRated,
  factor,
  handleFactorRating,
  ratingOptions,
  ratingQuestion
}) => {
  const rating = parseInt(factor.rating)
  const ratingOrder = factor.flipRatings ? range(7, 0) : range(1, 8)

  return (
    <div className='factorRating'>
      <div className='title'>{ratingQuestion}</div>
      {rating <= 0 && (
        <Alert positive>
          Click a rating below. Remember you may access additional product and /
          or disease information by clicking the tab at the top.
        </Alert>
      )}
      {rating > 0 && !allRated && (
        <Alert positive>
          You have rated this factor. Click the Next or Previous Factor buttons
          to rate other factors.
        </Alert>
      )}
      {allRated && (
        <Alert positive>
          You have rated all factors in this sector. Click the Close Window
          button to continue.
        </Alert>
      )}
      <br />
      <div>
        <Form>
          {Array.isArray(ratingOrder) && ratingOrder.map(number => (
            <Form.Field
              key={number.toString()}
              className='comparisonItem7'>
              <Radio
                name='factorRating'
                value={number.toString()}
                checked={factor.rating.toString() === number.toString()}
                onChange={handleFactorRating}
              />
              <div>{ratingOptions[number - 1]}</div>
            </Form.Field>
          )
          )}
        </Form>
      </div>
    </div>
  )
}

export default RateFactor
