import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loader, Form, Button, TextArea } from 'semantic-ui-react'

import './styles.scss'

class AppMaintenance extends Component {
  state = {
    notes: undefined,
  }

  componentDidMount() {
    this.props.getMaintenance()
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      this.props.activateAppMaintenanceResult.requesting ||
      this.props.deactivateAppMaintenanceResult.requesting
    ) {
      this.props.getMaintenance()
    }

    if (this.props.appMaintenance !== props.appMaintenance) {
      this.setState({ notes: props.appMaintenance.notes })
    }
  }

  activateMaintenance = () => {
    this.props.activateMaintenance({ notes: this.state.notes })
  }

  deactivateMaintenance = () => {
    this.props.deactivateMaintenance({ notes: this.state.notes })
  }

  render() {
    const { maintenance } = this.props
    let { notes } = this.state

    if (!maintenance) {
      return <Loader active inline='centered' />
    }

    if (notes === undefined) {
      notes = maintenance.notes
    }

    return (
      <div className='appMaintenanceComponent'>
        <h5 className='appMaintenanceStatus'>
          Current status:{' '}
          <span className={maintenance.value}>{maintenance.value}</span>
        </h5>

        <Form>
          <Form.Field>
            <h5>Maintenace date/time note</h5>

            <TextArea
              placeholder='from [starting date/time] to [ending date/time]'
              name='notes'
              onChange={(e, data) => this.setState({ notes: data.value })}
              value={notes}
            />
          </Form.Field>

          {maintenance.value === 'active' ? (
            <Button color='black' onClick={this.deactivateMaintenance}>
              Deactivate maintenance
            </Button>
          ) : (
            <Button color='black' onClick={this.activateMaintenance}>
              Activate maintenance
            </Button>
          )}
        </Form>
      </div>
    )
  }
}

const mapStateToProps = ({
  appMaintenance,
  activateAppMaintenance,
  deactivateAppMaintenance,
}) => ({
  maintenance: appMaintenance.result && appMaintenance.result.data,
  activateAppMaintenanceResult: activateAppMaintenance,
  deactivateAppMaintenanceResult: deactivateAppMaintenance,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMaintenance: () => dispatch({ type: 'FETCH_APP_MAINTENANCE_REQUESTED' }),
  activateMaintenance: (payload) =>
    dispatch({ type: 'ACTIVATE_APP_MAINTENANCE_REQUESTED', payload }),
  deactivateMaintenance: (payload) =>
    dispatch({ type: 'DEACTIVATE_APP_MAINTENANCE_REQUESTED', payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppMaintenance)
