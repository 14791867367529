const defaultState = {
  requesting: false,
  error: false,
  result: null,
  isCreate: false
};

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'FORGOT_PASSWORD_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: true
      }
    case 'FORGOT_PASSWORD_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true
      }
    case 'FORGOT_PASSWORD_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true
      }
    default:
      return state
  }
}
