import React from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'

const { Item, Menu } = Dropdown

const UsersActions = ({
  groupId,
  user,
  user: { _id: id },
  edit,
  invite,
  remove,
  logOut,
  enable,
  disable,
  selectUserForDeletion
}) => (
  <Dropdown text='Actions' className='userActionsButton' item>
    <Menu>
      <Item value={user} onClick={edit} content='Edit' />
      <Item value={id} onClick={invite} content='Send Invite' />
      <Item
        value={id}
        onClick={() => remove(groupId, id)}
        content='Remove from Group'
      />
      <Item value={id} onClick={logOut} content='Force Log Out' />
      {user.disabled ? (
        <Item value={id} onClick={enable} content='Enable' />
      ) : (
        <Item value={id} onClick={disable} content='Disable' />
      )}
      <Item
        value={user}
        onClick={() => selectUserForDeletion(user, groupId)}
        content='Delete'
      />
    </Menu>
  </Dropdown>
)

const mapDispatchToProps = dispatch => ({
  edit: (e, { value: user }) =>
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'editUser',
      params: { ...user }
    }),
  invite: (e, { value: userId }) =>
    dispatch({ type: 'INVITE_USER_REQUESTED', userId }),
  remove: (groupId, userId) =>
    dispatch({ type: 'DELETE_USER_FROM_GROUP_REQUESTED', groupId, userId }),
  logOut: (e, { value: userId }) =>
    dispatch({ type: 'FORCE_LOG_OUT_USER_REQUESTED', userId }),

  disable: (e, { value: id }) =>
    dispatch({
      type: 'UPDATE_USER_REQUESTED',
      id,
      payload: {
        disabled: true
      }
    }),
  enable: (e, { value: id }) =>
    dispatch({
      type: 'UPDATE_USER_REQUESTED',
      id,
      payload: {
        disabled: false
      }
    }),
  selectUserForDeletion: (user, groupId) =>
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'confirmDelete',
      params: {
        name: user.firstName + ' ' + user.lastName,
        item: user,
        url: '/groups/' + groupId,
        type: 'User'
      }
    })
})

export default connect(
  null,
  mapDispatchToProps
)(UsersActions)
