import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Header, Input, Popup } from 'semantic-ui-react'
import Button from '../common/Button'
import get from 'lodash/get'

import Prompt from './prompt'

class Importance extends Component {
  state = {
    factorImportance: {}
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    const { surveyResponse } = this.props

    if (surveyResponse !== prevProps.surveyResponse) {
      this.loadData()
    }
  }

  loadData = () => {
    const { survey, surveyResponse } = this.props

    const factorImportance = surveyResponse.factorImportance || {}

    if (survey.factors) {
      Object.keys(survey.factors).forEach(val => {
        if (!factorImportance[val]) {
          factorImportance[val] = {
            importance: 0
          }
        }
      })
    }
    if (surveyResponse.additionalFactors) {
      surveyResponse.additionalFactors.forEach(val => {
        if (!factorImportance[val]) {
          factorImportance[val] = {
            importance: 0
          }
        }
      })
    }

    this.setState({ factorImportance })
  }

  setFactorImportance = (factor, value) => {
    const { factorImportance } = this.state
    value = parseInt(value, 10)
    if (value < 0) return
    const totalAvailable =
      this.getTotalAvailable() + factorImportance[factor._id].importance
    if (totalAvailable - value >= 0) {
      const newImportance = Object.assign({}, factorImportance)
      newImportance[factor._id] = {
        importance: value
      }
      this.setState({ factorImportance: newImportance })
    }
  }

  getTotalAvailable = () => {
    const { factorImportance } = this.state
    let totalAvailable = 100
    Object.keys(factorImportance).forEach(key => {
      totalAvailable -= factorImportance[key].importance
    })
    return totalAvailable
  }

  handleSubmit = () => {
    const { factorImportance } = this.state
    const { saveImportance, surveyNavigation, saveTime } = this.props

    saveImportance(factorImportance)
    saveTime()
    surveyNavigation.navigateToNext()
  }

  validateImportance(totalAvailable) {
    const { surveyNavigation } = this.props

    if (totalAvailable === 0 && !surveyNavigation.checkIfCurrentStepIsDone()) {
      surveyNavigation.setCurrentStepToCompleted()
    } else if (!surveyNavigation.checkIfCurrentStepIsDone()) {
      surveyNavigation.setCurrentStepToPending()
    }
  }

  render() {
    const { sectors, surveyNavigation } = this.props
    const { factorImportance } = this.state

    let totalAvailable = this.getTotalAvailable()
    this.validateImportance(totalAvailable)

    const canGoNext = surveyNavigation.checkIfCurrentStepIsDoneOrCompleted() && totalAvailable === 0

    return (
      <div className='surveySectorComponent'>
        <Header as='h1' style={{ marginBottom: '56px' }}>
          Total Points Available{' '}
          <div className='availablePoints'>{totalAvailable}</div>
        </Header>

        {sectors.map(sector => (
          <div key={sector._id} className='importanceSector'>
            <Header as='h2'>{sector.name}</Header>
            {sector.dimensions
              .reduce((acum, dimension) => {
                return acum.concat(
                  dimension.factors.filter(factor => {
                    factor.dimension = dimension
                    return factorImportance[factor._id]
                  })
                )
              }, [])
              .map(factor => {
                const importance = factorImportance[factor._id].importance
                return (
                  <div key={factor._id} className='importanceFactor'>
                    <Input
                      className='importanceNumber'
                      type='Number'
                      value={importance}
                      min='0'
                      max='100'
                      onFocus={event => event.target.select()}
                      onChange={(e, data) =>
                        this.setFactorImportance(factor, data.value)
                      }
                    />
                    <Popup trigger={<label>{factor.name}</label>}>
                      <div className='surveyPopup'>
                        <Header>{factor.name}</Header>
                        <p>{factor.description}</p>
                        <div>
                          <div>Sector:</div>
                          <div>{sector.name}</div>
                        </div>
                        <div>
                          <div>Dimension:</div>
                          <div>{factor.dimension.name}</div>
                        </div>
                        <div>
                          <div>Rating Score:</div>
                          <div>
                            {!factor.rating || factor.rating < 1
                              ? 0
                              : factor.rating}{' '}
                            / {sector.compare ? '5' : '7'}
                          </div>
                        </div>
                        <div>
                          <div>Importance Score:</div>
                          <div>
                            {!importance || importance < 1 ? 0 : importance}
                          </div>
                        </div>
                      </div>
                    </Popup>
                  </div>
                )
              })}
          </div>
        ))}

        <Prompt>
          <div className='promptInstructions importance'>
            <div>
              {totalAvailable === 0 && (
                <span>You have completed this step.</span>
              )}
              {totalAvailable !== 0 && (
                <span>
                  Now let’s determine the relative importance of each factor in
                  your decisionmaking for treatments in this category, by
                  distributing 100 points across all of them.
                  <br />
                  You may choose to assign anywhere between 0 and 100 points to
                  any single factor. Total points assigned must add to 100.
                </span>
              )}
            </div>
            <div>
              {totalAvailable === 0
                ? 'When you’re ready, click Next to continue.'
                : 'Once you have completed this section, the Next button will be active and you can move on to the next step.'}
            </div>
          </div>
          <div className='buttonsContainer'>
            <Button
              color={canGoNext ? 'pink' : 'gray'}
              size='large'
              onClick={() => this.handleSubmit()}
              disabled={!canGoNext}
            >
              Next
            </Button>
          </div>
        </Prompt>
      </div>
    )
  }
}

const mapStateToProps = ({ survey, surveyResponse }) => ({
  survey: get(survey, 'result.data', {}),
  surveyResponse: get(surveyResponse, 'result.data', {})
})

export default connect(mapStateToProps)(Importance)
