import React from 'react'
import { Modal } from 'semantic-ui-react'

const TechnicalSupportModal = ({ onClose }) => (
  <Modal
    open={true}
    onClose={onClose}
    closeIcon
    closeOnDimmerClick
    closeOnEscape
    className='technicalSupportModal'
  >
    <Modal.Header>Technical Support</Modal.Header>
    <Modal.Content>
      <p>
        For technical support, you can reach us at{' '}
        <a href='mailto:VOLTsupport@payersciences.com'>
          VOLTsupport@payersciences.com
        </a>
        . Monday to Friday 9am to 5pm EST.
      </p>
    </Modal.Content>
  </Modal>
)

export default TechnicalSupportModal
