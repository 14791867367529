import React from 'react'

import Button from '../common/Button'

const ActionsBar = ({ onSave, onCancel, isSaving }) => (
  <div className='actionsBar'>
    <Button rounded onClick={onCancel} color='white'>
      Cancel
    </Button>
    <Button rounded shadowed onClick={onSave} disabled={isSaving}>{isSaving ? 'Saving...' : 'Save'}</Button>
  </div>
)

export default ActionsBar
