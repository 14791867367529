import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Message, Input, Button, Icon } from 'semantic-ui-react'
import get from 'lodash/get'
import { Loader } from 'semantic-ui-react'

import Logo from '../../assets/ps_logo.svg'

import './styles.scss'

class Login extends Component {
  state = {
    show: false,
  }

  UNSAFE_componentWillMount() {
    const { tokenValidation, verifyToken } = this.props

    if (!tokenValidation.result && !tokenValidation.requesting) {
      verifyToken()
    }

    this.setState({ show: true })
  }

  componentDidMount() {
    this.props.getMaintenance()
  }

  login = (e) => {
    e.preventDefault()

    this.props.resetValidation()

    this.props.login({
      email: this.state.email,
      password: this.state.password,
    })
  }

  renderMaintenance = (maintenance) => {
    if (maintenance && maintenance.value === 'active') {
      return (
        <div className='maintenance'>
          <h3>
            We're currently making updates to our system and you cannot log in
            <br /> {maintenance.notes}.<br />
            <br />
            Thank you.
          </h3>
        </div>
      )
    } else {
      return null
    }
  }

  renderLoading = () => {
    return (
      <div>
        <br />
        <br />
        <br />
        <Loader active size='large' inline='centered' />
      </div>
    )
  }

  render() {
    const { auth, goTo, maintenance, tokenValidation } = this.props

    if (!tokenValidation.error && tokenValidation.result) {
      return <Redirect to='/' />
    }

    // wait for validation response
    if (!this.state.show || tokenValidation.requesting) {
      return <>{this.renderLoading()}</>
    }

    return (
      <div className='loginContainer fullHeight'>
        <div className='loginComponent'>
          <br />
          <img
            src={Logo}
            style={{ cursor: 'pointer' }}
            onClick={() => goTo('/')}
            alt=''
          />
          <h1>Log In to Your Account</h1>
          {this.renderMaintenance(maintenance)}

          <form onSubmit={this.login}>
            <Input
              placeholder='Email'
              id='email'
              name='email'
              fluid
              onChange={(e, data) => this.setState({ email: data.value })}
            />
            <Input
              type={this.state.showPassword ? 'text' : 'password'}
              id='password'
              name='password'
              placeholder='Password'
              fluid
              onChange={(e, data) => this.setState({ password: data.value })}
              icon={
                <Icon
                  name='eye'
                  size='big'
                  link
                  onClick={() =>
                    this.setState({ showPassword: !this.state.showPassword })
                  }
                />
              }
            />
            {auth.error && (
              <Message color='red'>
                {get(
                  auth,
                  'result.response.data.msg',
                  'There was an unexpected error'
                )}
              </Message>
            )}
            <Button
              basic
              id='submitBtn'
              color='blue'
              loading={auth.requesting}
              size='huge'
            >
              Login
            </Button>
          </form>

          <div>
            <a href='/forgot-password'>Forgot password?</a>
          </div>

          <div className='copyright'>
            © {new Date().getFullYear()} PAYERSCIENCES, LLC. ALL RIGHTS
            RESERVED.
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, appMaintenance, tokenValidation }) => ({
  auth,
  maintenance: get(appMaintenance, 'result.data'),
  tokenValidation,
})

const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch({ type: 'LOGIN_REQUESTED', payload }),
  resetValidation: () => dispatch({ type: 'TOKEN_VALIDATION_RESET' }),
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
  getMaintenance: () => dispatch({ type: 'FETCH_APP_MAINTENANCE_REQUESTED' }),
  verifyToken: () => dispatch({ type: 'TOKEN_VALIDATION_REQUESTED' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
