import React from 'react'
import { Header } from 'semantic-ui-react'

import './MenuHeader.scss'

const MenuHeader = ({ title }) => {
  return (
    <div className='menuHeader'>
      <Header as='h4' className='.header'>
        {title}
      </Header>
    </div>
  )
}

export default MenuHeader
