import { get } from 'lodash';
import qs from 'query-string';
import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Header, Tab } from 'semantic-ui-react';

import './Entry.scss';

import { Description } from './Description';
import { IDescriptionProperties } from './Description.types';
import { IEntryFactor, IEntryProps, IEntryState } from './Entry.types';
import Tags from './Tags';

import { RoutePaths } from '../_routing/route-paths';
import { usePreviousProps } from '../common/use-previous-props/usePreviousProps';
import { Breadcrumb } from '../page-layout/breadcrumb/Breadcrumb';
import {
  IEntryCreatePayload,
  IEntryPayload
} from 'redux/types/inventories.types';
import { IInventoryEntryDto } from 'types/dto/inventory.dto';
import { IFactorDto } from 'types/dto/factor.dto';

const Entry: React.FC<IEntryProps> = props => {
  const {
    entryResult,
    match,
    goTo,
    inventoryResult,
    getSectors,
    entryAssets,
    entryAssetsResult,
    getAssets,
    updateEntry,
    createEntry,
    location
  } = props;
  const [state, setState] = useState<IEntryState>({
    descriptionProperties: {
      title: '',
      citation: '',
      value: ''
    },
    factors: {}
  });
  const previousProps = usePreviousProps(props);
  const [editorValue, setEditorValue] = useState<string>('');

  useEffect(() => {
    const { match, getEntry, location, getInventoryDetails, getAssets } = props;

    if (match.params.id) {
      getEntry(match.params.id);
      getAssets(match.params.id);
    }

    const search = qs.parse(location.search);
    getInventoryDetails((search as any).inventory);
  }, []);

  useEffect(() => {
    if (
      inventoryResult !== previousProps?.inventoryResult &&
      inventoryResult?.isFetch &&
      inventoryResult?.loaded
    ) {
      getSectors({ wheel: get(inventoryResult, 'result.data.wheel') });
    }

    if (entryResult !== previousProps?.entryResult && entryResult?.isFetch && entryResult?.loaded) {
      const entryData: IInventoryEntryDto = get(entryResult, 'result.data');
      const factors = entryData.factors || [];

      setEditorValue(entryData.description);
      setState({
        descriptionProperties: {
          title: entryData.title,
          citation: entryData.citation,
          value: entryData.description
        },
        factors: factors.reduce((factorsMap: IEntryFactor, factor: IFactorDto) => {
          factorsMap[factor._id] = true;
          return factorsMap;
        }, {})
      });
    }

    if (
      entryResult !== previousProps?.entry &&
      entryResult?.isCreate &&
      entryResult?.loaded &&
      !match.params.id
    ) {
      const entryData = get(entryResult, 'result.data');
      goTo(`/entry/${entryData._id}?inventory=${entryData.inventory}`);
    }

    if (
      (entryAssetsResult !== previousProps?.entryAssetsResult &&
        entryAssetsResult.isCreate &&
        entryAssetsResult.result &&
        entryAssetsResult.result.data) ||
      (entryAssetsResult !== previousProps?.entryAssetsResult &&
        entryAssetsResult.isDelete &&
        entryAssetsResult.result)
    ) {
      getAssets(match.params.id);
    }
  }, [entryResult, match, inventoryResult, entryAssetsResult]);

  const onSave = (descriptionProperties: IDescriptionProperties) => {
    const { factors } = state;
    const search = qs.parse(location.search);

    if (!descriptionProperties.title) {
      return {
        success: false,
        field: 'title'
      };
    }

    if (match.params.id) {
      updateEntry(match.params.id, {
        title: descriptionProperties.title,
        citation: descriptionProperties.citation,
        description: editorValue,
        factors: Object.keys(factors)
      });
    } else {
      createEntry({
        inventory: search.inventory,
        title: descriptionProperties.title,
        citation: descriptionProperties.citation ?? '',
        description: editorValue,
        factors: Object.keys(factors)
      });
    }
    setState({
      ...state,
      descriptionProperties
    });
    return { success: true };
  };

  const onCancel = () => {
    const search = qs.parse(location.search);
    goTo(`/inventory/${search.inventory}`);
  };

  const onFactorChanged = (factorId: string, selected: boolean) => {
    const { factors } = state;
    const newFactors = { ...factors };

    if (selected) {
      newFactors[factorId] = true;
    } else {
      delete newFactors[factorId];
    }

    setState({ ...state, factors: newFactors });
  };

  const onDescriptionChanged = ({ field, value }: {
    field: 'title' | 'value' | 'citation' | 'editorValue',
    value: string
  }) => {
    if (field === 'editorValue') {
      setEditorValue(value);
    } else {
      const { descriptionProperties } = state;
      const newDescription = {
        ...descriptionProperties,
        [field]: value
      };
      setState({ ...state, descriptionProperties: newDescription });
    }
  };

  const handleTabChangeFromDescription = (data: any) => {
    const tabMap = {
      DESCRIPTION: 0,
      TAGS: 1
    };
    // If tab switches to TAGS, then set Description Editor value
    if (data.activeIndex === tabMap.TAGS) {
      onDescriptionChanged({ field: 'value', value: editorValue });
    }
  };

  const { factors, descriptionProperties } = state;
  const search = qs.parse(location.search);

  const inventoryTitle = get(inventoryResult, 'result.data.title', 'Inventory');

  const panes = [
    {
      menuItem: 'Description',
      render: () => (
        <Description
          descriptionProperties={descriptionProperties}
          isSaving={props?.entryResult?.requesting}
          onChange={onDescriptionChanged}
          onSave={onSave}
          onCancel={onCancel}
        />
      )
    },
    {
      menuItem: 'Tags',
      render: () => (
        <Tags
          descriptionProperties={descriptionProperties}
          factors={factors}
          onFactorChanged={onFactorChanged}
          onSave={onSave}
          onCancel={onCancel}
        />
      )
    }
  ];

  /* NOTE: Removed as no longer needed for now.
    *       See PGPD-319 on JIRA for more details.
  if (match.params.id) {
    panes.push({
      menuItem: 'Files',
      render: () => (
        <Files entryAssets={entryAssets} entryId={match.params.id} />
      )
    })
  }
  */

  return (
    <div className='entryComponent'>
      <Breadcrumb
        nodes={[
          { label: 'Dashboard', route: RoutePaths.Home },
          { label: 'Value Asset Inventories', route: RoutePaths.Inventories },
          {
            label: inventoryTitle,
            route: `${RoutePaths.Inventory}/${search.inventory}`
          }
        ]}
      />

      <Header as='h1'>{descriptionProperties.title || 'New Data Entry'}</Header>

      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        onTabChange={(e, data) => {
          handleTabChangeFromDescription(data);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ inventoryEntry, inventory, assets }) => ({
  entryResult: inventoryEntry,
  entryAssetsResult: assets,
  entryAssets: get(assets, 'result.data'),
  inventoryResult: inventory
});

const mapDispatchToProps = (dispatch: Function) => ({
  getAssets: (objectId: string) =>
    dispatch({
      type: 'FETCH_ASSETS_REQUESTED',
      objectId,
      assetType: 'entry'
    }),
  getEntry: (id: string) =>
    dispatch({ type: 'FETCH_INVENTORY_ENTRY_REQUESTED', id }),
  getInventoryDetails: (id: string) =>
    dispatch({ type: 'FETCH_INVENTORY_DETAILS_REQUESTED', id }),
  getSectors: (query: any) =>
    dispatch({ type: 'FETCH_SECTORS_REQUESTED', query }),
  updateEntry: (id: string, payload: IEntryPayload) =>
    dispatch({ type: 'UPDATE_ENTRY_REQUESTED', id, payload }),
  createEntry: (payload: IEntryCreatePayload) =>
    dispatch({ type: 'CREATE_INVENTORY_ENTRY_REQUESTED', payload }),
  goTo: (url: string) => dispatch({ type: 'REDIRECT', url })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type EntryProps = ConnectedProps<typeof connector>;

export default connector(Entry);
