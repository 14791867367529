import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Checkbox, Header, Message } from 'semantic-ui-react'
import Button from '../common/Button'

import Prompt from './prompt'

class Completion extends Component {
  state = {
    agree: false
  }

  handleSubmit() {
    const { surveyNavigation, saveTime } = this.props

    saveTime()
    surveyNavigation.navigateToNext()
  }

  handleAgreed(data) {
    const { surveyNavigation } = this.props

    this.setState({ agree: data.checked })

    if (data.checked) {
      surveyNavigation.setCurrentStepToCompleted()
    } else {
      surveyNavigation.setCurrentStepToPending()
    }
  }

  render() {
    const { surveyNavigation, surveyResponse } = this.props

    return (
      <div>
        <div style={{ padding: '40px' }}>
          <Header as='h1'>Congratulations!</Header>

          <br />
          <p className='completionParagraph'>
            You have completed this survey. Your input is very valuable.
            <br />
            <strong>
              Click the checkbox and the submit button below to submit your
              responses.
            </strong>
          </p>

          <Message positive>
            <Message.Header>
              Please note that once you submit your answers you will not be able
              to edit them later.
            </Message.Header>
          </Message>

          <br />

          <Checkbox
            className='completionParagraph'
            onChange={(e, data) => this.handleAgreed(data)}
            label='I agree my responses have been thoughtfully considered, and reflect my perceptions based on prior knowledge and the product and / or disease information provided within this survey.'
          />

          <br />
          <br />
          <br />

          <Button
            size='big'
            color={surveyNavigation.checkIfCurrentStepIsDoneOrCompleted() ? 'pink' : 'gray'}
            onClick={() => this.handleSubmit()}
            disabled={
              surveyResponse.requesting ||
              !surveyNavigation.checkIfCurrentStepIsDoneOrCompleted()
            }
          >
            Submit
          </Button>
        </div>

        <Prompt>
          <div className='promptInstructions completion'>
            <div>Your final step is to submit your survey results.</div>
            <div />
          </div>
          <div className='buttonsContainer'>
            <Button
              color='gray'
              size='large'
              onClick={() => surveyNavigation.navigateToPrevious()}
            >
              Previous
            </Button>
          </div>
        </Prompt>
      </div>
    )
  }
}

const mapStateToProps = ({ surveyResponse }) => ({ surveyResponse })

export default connect(mapStateToProps)(Completion)
