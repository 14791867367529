import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Input, Button, Form } from 'semantic-ui-react'

import Notifications from '../home/notifications'
import Logo from '../../assets/ps_logo.svg'

import './styles.scss'

class ForgotPassword extends Component {
  state = {}

  sendEmail = () => {
    this.props.sendForgotPassword(this.state)
  }

  render() {
    const { goTo, forgotPassword } = this.props

    return (
      <div className='loginContainer'>
        <Notifications />
        <div className='forgotPasswordComponent'>
          <br />
          <img
            src={Logo}
            style={{ cursor: 'pointer' }}
            onClick={() => goTo('/login')}
            alt=''
          />
          <h1>Forgot password?</h1>
          <Form>
            <Input
              placeholder='Email'
              name='email'
              fluid
              onChange={(e, data) => this.setState({ email: data.value })}
            />
            <Button
              basic
              color='blue'
              size='huge'
              loading={forgotPassword.requesting}
              disabled={forgotPassword.requesting}
              onClick={this.sendEmail}
            >
              Send email
            </Button>
            <Button basic size='huge' onClick={() => goTo('/login')}>
              Cancel
            </Button>
          </Form>
          <div className='copyright'>
            © {new Date().getFullYear()} PAYERSCIENCES, LLC. ALL RIGHTS
            RESERVED.
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ forgotPassword }) => ({ forgotPassword })

const mapDispatchToProps = dispatch => {
  return {
    sendForgotPassword: payload =>
      dispatch({ type: 'FORGOT_PASSWORD_REQUESTED', payload }),
    goTo: url => dispatch({ type: 'REDIRECT', url })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword)
