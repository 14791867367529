import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import api from '../../services/api'
import { handleError } from './helpers/saga-helpers'

function* getAssets(action) {
  try {
    const result = yield call(api.getAssets, action.objectId, action.assetType)
    yield put({ type: 'FETCH_ASSETS_SUCCEEDED', payload: result })
  } catch (e) {
    yield handleError({ error: e, type: 'FETCH_ASSETS_FAILED' })
  }
}

function* createAsset(action) {
  try {
    const result = yield call(api.createAsset, action.payload, action.file)
    yield put({ type: 'CREATE_ASSET_SUCCEEDED', payload: result })
    yield put({
      type: 'SEND_NOTIFICATION',
      payload: {
        message: 'Asset successfully uploaded!',
        success: true
      }
    })
  } catch (e) {
    yield handleError({ error: e, type: 'CREATE_ASSET_FAILED' })
  }
}

function* updateAsset(action) {
  try {
    const result = yield call(api.updateAsset, action.id, action.payload)
    yield put({ type: 'UPDATE_ASSET_SUCCEEDED', payload: result })
  } catch (e) {
    yield handleError({ error: e, type: 'UPDATE_ASSET_FAILED' })
  }
}

function* deleteAsset(action) {
  try {
    const result = yield call(api.deleteAsset, action.id)
    yield put({ type: 'DELETE_ASSET_SUCCEEDED', payload: result })
  } catch (e) {
    yield handleError({ error: e, type: 'DELETE_ASSET_FAILED' })
  }
}

function* assets() {
  yield takeEvery('FETCH_ASSETS_REQUESTED', getAssets)
  yield takeLatest('CREATE_ASSET_REQUESTED', createAsset)
  yield takeLatest('UPDATE_ASSET_REQUESTED', updateAsset)
  yield takeLatest('DELETE_ASSET_REQUESTED', deleteAsset)
}

export default assets
