import { getInstance } from './servicesUtils'

const groupsApi = {
  getGroups: () => {
    return getInstance().get('/groups')
  },
  getGroupDetails: (id: string) => {
    return getInstance().get(`/groups/${id}`)
  },
  createGroup: payload => {
    return getInstance().post('/groups', payload)
  },
  updateGroup: (payload, id: string) => {
    return getInstance().put(`/groups/${id}`, payload)
  },
  deleteGroup: (id: string) => {
    return getInstance().delete(`/groups/${id}`)
  },
  addUserToGroup: (userIds, groupId) => {
    return getInstance().post(`/groups/${groupId}/users`, { userIds })
  },
  deleteUserFromGroup: (userId, groupId) => {
    return getInstance().delete(`/groups/${groupId}/users/${userId}`)
  }
}

export default groupsApi
