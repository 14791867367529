import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  Header,
  Divider,
  List,
  Button,
  Form,
  Input,
  Segment,
  Icon,
} from 'semantic-ui-react'

import get from 'lodash/get'
import './styles.scss'

class Account extends Component {
  state = {
    password: '',
    password2: '',
  }

  changePassword = () => {
    const { password, password2 } = this.state
    const { sendError, changePassword } = this.props

    if (password !== password2) {
      return sendError('Passwords do not match')
    }
    if (!password.length) {
      return sendError('Password cannot be empty')
    }

    changePassword({ password })
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { passwordChange } = props

    if (
      this.props.passwordChange !== passwordChange &&
      !passwordChange.error &&
      passwordChange.isCreate &&
      passwordChange.result
    ) {
      this.setState({
        password: '',
        password2: '',
      })
    }
  }

  render() {
    const { user } = this.props

    return (
      <div className='accountComponent'>
        <Header as='h1'>Account settings</Header>

        <br />
        <Divider />
        <br />

        <List size='big' relaxed='very'>
          <List.Item>
            <List.Icon name='mail' />
            <List.Content>{user.email}</List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name='user' />
            <List.Content>
              {user.firstName} {user.lastName}
            </List.Content>
          </List.Item>
        </List>

        <br />

        <Segment compact padded color='violet' textAlign='center'>
          <Form>
            <Form.Field>
              <label>NEW PASSWORD</label>
              <Input
                placeholder='New password'
                name='password'
                onChange={(e, data) => this.setState({ password: data.value })}
                type={this.state.showPassword ? 'text' : 'password'}
                icon={
                  <Icon
                    name='eye'
                    size='big'
                    link
                    onClick={() =>
                      this.setState({ showPassword: !this.state.showPassword })
                    }
                  />
                }
                value={this.state.password}
              />
            </Form.Field>
            <Form.Field>
              <label>REPEAT PASSWORD</label>
              <Input
                placeholder='Repeat password'
                name='password2'
                onChange={(e, data) => this.setState({ password2: data.value })}
                type={this.state.showPassword ? 'text' : 'password'}
                icon={
                  <Icon
                    name='eye'
                    size='big'
                    link
                    onClick={() =>
                      this.setState({ showPassword: !this.state.showPassword })
                    }
                  />
                }
                value={this.state.password2}
              />
            </Form.Field>
            <Button basic onClick={this.changePassword}>
              Change password
            </Button>
          </Form>
        </Segment>
      </div>
    )
  }
}

const mapStateToProps = ({ tokenValidation, passwordChange }) => ({
  user: get(tokenValidation, 'result.data', null),
  passwordChange,
})

const mapDispatchToProps = (dispatch) => ({
  sendError: (message) =>
    dispatch({
      type: 'SEND_NOTIFICATION',
      payload: {
        message,
        success: false,
      },
    }),
  changePassword: (payload) =>
    dispatch({ type: 'CHANGE_PASSWORD_REQUESTED', payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Account)
