import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Checkbox, Divider, Header } from 'semantic-ui-react'
import Button from '../common/Button'
import get from 'lodash/get'

import disclaimerData from './disclaimerData'
import Prompt from './prompt'

const Welcome = props => {
  const { acceptedTos, surveyNavigation, saveTime, user, survey, goTo } = props
  const [acceptedTerms, setAcceptedTerms] = useState(acceptedTos)
  const [readOnly, setReadOnly] = useState(false)

  useEffect(() => {
    if (surveyNavigation.checkIfCurrentStepIsDone()) {
      setAcceptedTerms(true)
      setReadOnly(true)
    }
  }, [])

  const handleAcceptance = data => {
    setAcceptedTerms(data.checked)
  }

  const handleSubmit = () => {
    saveTime({ acceptedTos: true })
  }

  const handleNext = () => {
    surveyNavigation.navigateToNext()
  }

  const getContent = content => {
    const variables = {
      '[First Name]': user.firstName,
      '[Last Name]': user.lastName,
      '[Due Date]': moment(survey.dueDate).format('MM/DD/YYYY'),
      '[Disease Name]': get(survey, 'diagnostic.inventory.indicationLabel', '')
    }

    return Object.keys(variables).reduce((acum, key) => {
      return acum.replace(key, variables[key], 'g')
    }, content)
  }

  let { disclaimer = [] } = survey

  if (!disclaimer.length) {
    disclaimer = disclaimerData
  }

  return (
    <div className='surveyWelcomeComponent'>
      {disclaimer.map((item, index) => (
        <div key={`disclaimer_${index}`} className='disclaimerElement'>
          <Header as={index === 0 || !item.content ? 'h1' : 'h2'}>
            {getContent(item.title)}
          </Header>
          {item.content.split('\n').map((content, cIndex) => (
            <p key={`content_${index}_${cIndex}`}>{getContent(content)}</p>
          ))}
          {index < disclaimer.length - 1 && item.content && <Divider />}
        </div>
      ))}
      <div>
        <Checkbox
          label='I have read and acknowledge the above'
          checked={acceptedTerms || acceptedTos}
          readOnly={readOnly || acceptedTos}
          onChange={(e, data) => handleAcceptance(data)}
        />
      </div>
      <Divider />
      <Button
        color='gray'
        size='big'
        onClick={() => goTo('/surveys')}
        style={{ marginRight: '10px' }}
        padded
      >
        Cancel
      </Button>

      <Button
        padded
        color='black'
        disabled={
          !acceptedTerms ||
          surveyNavigation.checkIfCurrentStepIsDoneOrCompleted()
        }
        onClick={() => handleSubmit()}
        size='big'
      >
        Submit
      </Button>

      {acceptedTerms &&
        !surveyNavigation.checkIfCurrentStepIsDoneOrCompleted() && (
          <Prompt>
            <div className='promptInstructions welcome notAcceptedTerms'>
              <div>Click submit.</div>
            </div>
          </Prompt>
        )}

      {surveyNavigation.checkIfCurrentStepIsDoneOrCompleted() && (
        <Prompt>
          <div className='promptInstructions welcome acceptedTerms'>
            <div>You have completed this step.</div>
            <div>When you’re ready, click Next to continue.</div>
          </div>
          <div className='buttonsContainer'>
            <Button color='pink' size='large' onClick={() => handleNext()}>
              Next
            </Button>
          </div>
        </Prompt>
      )}
    </div>
  )
}

const mapStateToProps = ({ tokenValidation, survey }) => ({
  user: get(tokenValidation, 'result.data', {}),
  survey: get(survey, 'result.data', {})
})

const mapDispatchToProps = dispatch => ({
  goTo: url => dispatch({ type: 'REDIRECT', url })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Welcome)
