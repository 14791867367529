import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import values from 'lodash/values'

import GroupList from './GroupList'
import { constructGroupHierarchy } from './GroupDetails/GroupUtils'
import TopBar from '../dashboard/TopBar'

import './styles.scss'

class GroupsPage extends Component {
  state = {
    selectedItem: null,
    search: '',
    includeArchived: false
  }

  componentDidMount() {
    const { getItems } = this.props
    getItems()
  }

  deselectItem = () => {
    this.setState({ selectedItem: null })
  }

  archiveItem = (event, { value }) => {
    const { archiveItem } = this.props

    archiveItem(value)
  }

  restoreItem = (event, { value }) => {
    const { restoreItem } = this.props
    restoreItem(value)
  }

  createSubGroup = (event, { value }) => {
    const { createSubGroup } = this.props
    createSubGroup(value)
  }

  addItem = (event, { value }) => {
    const { addItem } = this.props
    addItem(value)
  }

  manageItems = (event, { value }) => {
    const { manageItems } = this.props
    manageItems(value)
  }

  editItem = (event, { value }) => {
    const { editItem } = this.props
    editItem(value)
  }

  filter = items => {
    const { search, includeArchived } = this.state
    const filterWord = search || ''

    if (!items) return items

    const filtered = items
      .filter(i => includeArchived || !i.archived)
      .filter(i => i.name && i.name.includes(filterWord))

    return values(constructGroupHierarchy(filtered))
  }

  onSearch = search => {
    this.setState({ search })
  }

  toggleArchived = includeArchived => {
    this.setState({ includeArchived })
  }

  render() {
    const { items, selectForDeletion, openCreateGroupModal } = this.props
    const { search, includeArchived } = this.state
    const filteredItems = this.filter(items)

    return (
      <>
        <TopBar
          search={search}
          includeArchived={includeArchived}
          onSearch={this.onSearch}
          toggleArchived={this.toggleArchived}
          onNew={() => openCreateGroupModal()}
          newText='New Group'
        />
        <GroupList
          items={filteredItems}
          editItem={this.editItem}
          archiveItem={this.archiveItem}
          deleteItem={selectForDeletion}
          restoreItem={this.restoreItem}
          createSubGroup={this.createSubGroup}
          manageItems={this.manageItems}
          addItem={this.addItem}
        />
      </>
    )
  }
}

const mapStateToProps = ({ groups }) => ({
  items: get(groups, 'result.data', null)
})

const mapDispatchToProps = dispatch => ({
  editItem: id => dispatch({ type: 'REDIRECT', url: `/groups/${id}` }),
  addItem: id => dispatch({ type: 'REDIRECT', url: `/groups/${id}/new` }),
  manageItems: id => dispatch({ type: 'REDIRECT', url: `/groups/${id}/bulk` }),
  getItems: () => dispatch({ type: 'FETCH_GROUPS_REQUESTED' }),
  selectForDeletion: (e, { value }) =>
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'deleteGroup',
      params: { group: value }
    }),
  restoreItem: id => dispatch({ type: 'RESTORE_GROUP_REQUESTED', id }),
  archiveItem: id => dispatch({ type: 'ARCHIVE_GROUP_REQUESTED', id }),
  createSubGroup: id =>
    dispatch({ type: 'OPEN_MODAL', modal: 'createGroup', params: { id } }),
  openCreateGroupModal: () =>
    dispatch({ type: 'OPEN_MODAL', modal: 'createGroup' })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsPage)
