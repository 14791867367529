import React from 'react'
import { Modal } from 'semantic-ui-react'
import _ from 'lodash'

import { mapEntriesToFactorIds } from '../../inventory/inventoryUtils'
import EntryList from '../../inventory/EntryList'
import AddFactor from '../../admin-controls/sector-admin/addFactor'

const Modals = ({
  factor,
  dimension,
  sector,
  onSelectFactor,
  inventoryEntries,
  showEntriesModal,
  onCloseEntriesModal,
  showFactorModal,
  onCloseEditFactor
}) => {
  const entryMap = (inventoryEntries && inventoryEntries.reduce) ? inventoryEntries.reduce(mapEntriesToFactorIds, {}) : {};

  return (
    <>
      <Modal
        open={showEntriesModal}
        onClose={onCloseEntriesModal}
        centered={false}
        closeIcon={true}
        size='large'
      >
        <Modal.Header>Factor entries</Modal.Header>
        <Modal.Content scrolling>
          <EntryList
            factor={factor}
            dimension={dimension}
            entryMap={entryMap}
            onSelectFactor={onSelectFactor}
            inModal={true}
            hideBackButton
          />
        </Modal.Content>
      </Modal>
      <AddFactor
        onClose={onCloseEditFactor}
        show={showFactorModal}
        factor={factor}
        sector={sector}
      />
    </>
  )
}

export default Modals
