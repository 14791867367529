import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Input, Button, Form, Icon } from 'semantic-ui-react'
import qs from 'query-string'

import passwordHelpers from '../../lib/passwordHelpers'
import Notifications from '../home/notifications'
import Logo from '../../assets/ps_logo.svg'

import './styles.scss'

class ResetPasswordPage extends Component {
  state = {}

  UNSAFE_componentWillReceiveProps(props) {
    const { resetPasswordResult, goTo } = props

    if (
      this.props.resetPasswordResult !== resetPasswordResult &&
      resetPasswordResult.isCreate &&
      !resetPasswordResult.error &&
      resetPasswordResult.result
    ) {
      setTimeout(() => goTo('/login'), 5000)
    }
  }

  resetPassword = (e) => {
    e.preventDefault()

    if (this.state.password !== this.state.password2) {
      return this.props.sendError('The passwords do not match')
    }

    if (!this.state.password) {
      return this.props.sendError('The password is mandatory')
    }

    const passwordStrengthValidity = passwordHelpers.validatePasswordStrength(
      this.state.password
    )
    if (passwordStrengthValidity !== true) {
      return this.props.sendError(passwordStrengthValidity)
    }

    const token = qs.parse(this.props.location.search).token
    this.props.resetPassword({
      token,
      password: this.state.password,
    })
  }

  render() {
    const { goTo } = this.props

    return (
      <div>
        <Notifications />
        <div className='resetPasswordComponent'>
          <img
            src={Logo}
            style={{ cursor: 'pointer' }}
            onClick={() => goTo('/')}
            alt=''
            className='ps-logo'
          />

          <h1>Reset your password</h1>
          <Form>
            <Form.Field>
              <div className='required'>*REQUIRED</div>
              <label>NEW PASSWORD</label>
              <Input
                placeholder='New password'
                name='password'
                type={this.state.showPassword ? 'text' : 'password'}
                icon={
                  <Icon
                    name='eye'
                    size='big'
                    link
                    onClick={() =>
                      this.setState({
                        showPassword: !this.state.showPassword,
                      })
                    }
                  />
                }
                onChange={(e, data) => this.setState({ password: data.value })}
              />
            </Form.Field>
            <Form.Field>
              <div className='required'>*REQUIRED</div>
              <label>REPEAT PASSWORD</label>
              <Input
                placeholder='Repeat password'
                name='password2'
                type={this.state.showPasswordRepeat ? 'text' : 'password'}
                icon={
                  <Icon
                    name='eye'
                    size='big'
                    link
                    onClick={() =>
                      this.setState({
                        showPasswordRepeat: !this.state.showPasswordRepeat,
                      })
                    }
                  />
                }
                onChange={(e, data) => this.setState({ password2: data.value })}
              />
              <div className='passwordAlert'>
                Must have a special character, a number, upper and lowercase
                letters
              </div>
            </Form.Field>
            <Button basic color='blue' size='huge' onClick={this.resetPassword}>
              Reset password
            </Button>
          </Form>
          <div className='copyright'>
            © {new Date().getFullYear()} PAYERSCIENCES, LLC. ALL RIGHTS
            RESERVED.
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ resetPassword }) => ({
  resetPasswordResult: resetPassword,
})

const mapDispatchToProps = (dispatch) => ({
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
  resetPassword: (payload) =>
    dispatch({ type: 'RESET_PASSWORD_REQUESTED', payload }),
  sendError: (message) =>
    dispatch({
      type: 'SEND_NOTIFICATION',
      payload: {
        message,
        success: false,
      },
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage)
