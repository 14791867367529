import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import get from 'lodash/get'

import { Table, Dropdown, Confirm, Icon } from 'semantic-ui-react'

import UpdatedAt from './updatedAt'
import TopBar from './TopBar'
import ItemStatus from '../common/item-status'
import ContentLoader from '../common/content-loader'

class Diagnostics extends Component {
  state = {
    diagnostic: {},
    selectedId: '',
    search: '',
    includeArchived: false,
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { diagnosticResult, getDiagnostics, duplicateDiagnosticResult } =
      props

    const { search, includeArchived } = this.state

    if (
      this.props.diagnosticResult !== diagnosticResult &&
      diagnosticResult.isDelete &&
      diagnosticResult.result &&
      !diagnosticResult.error
    ) {
      getDiagnostics({ search, includeArchived })
    }

    if (
      this.props.duplicateDiagnosticResult.requesting &&
      !duplicateDiagnosticResult.requesting
    ) {
      getDiagnostics({ search, includeArchived })
    }
  }

  componentDidMount() {
    const { search, includeArchived, getDiagnostics, diagnostics } = this.props
    if (!diagnostics || search !== '') {
      getDiagnostics({ search, includeArchived })
    }
  }

  onSearch = (newSearch) => {
    const { search, includeArchived } = this.state
    const { getDiagnostics } = this.props

    if (newSearch !== search) {
      this.setState({ search: newSearch })
      getDiagnostics({ search: newSearch, includeArchived })
    }
  }

  toggleArchived = (checked) => {
    const { search, includeArchived } = this.state
    const { getDiagnostics } = this.props

    if (checked !== includeArchived) {
      this.setState({ includeArchived: checked })
      getDiagnostics({ search, includeArchived: checked })
    }
  }

  rowActionClick = (event, data) => {
    this.setState({ showArchiveConfirmation: true, diagnosticId: data.value })
  }

  finishAction = () => {
    const { diagnosticId } = this.state
    const { restore, archive, diagnostics } = this.props

    const selected = diagnostics.find((i) => i._id === diagnosticId)

    selected && selected.archived
      ? restore(selected._id)
      : archive(selected._id)

    this.setState({ showArchiveConfirmation: false })
  }

  componentDidUpdate(prevProps) {
    const { token } = this.props
    const { selectedId } = this.state

    if (token !== prevProps.token && token && selectedId) {
      this.form.submit()
    }
  }

  downloadCSV = (id) => {
    this.setState({ selectedId: id })
    this.props.getToken()
  }

  content = () => {
    const { diagnostics } = this.props
    const { diagnosticId } = this.state

    const selected =
      diagnostics && diagnostics.find((i) => i._id === diagnosticId)

    if (!selected) return {}

    return {
      header: (selected.archived ? 'Restore' : 'Archive') + ' diagnostic',
      content: (
        <div className='content'>
          Are you sure you want to {selected.archived ? 'restore' : 'archive'}{' '}
          <strong>{selected.title}</strong>?
        </div>
      ),
    }
  }

  render() {
    const {
      diagnostics,
      goTo,
      diagnosticResult,
      duplicateDiagnosticResult,
      token,
      user,
    } = this.props
    const { showArchiveConfirmation, selectedId, search, includeArchived } =
      this.state

    const { header, content } = this.content()

    return (
      <>
        <Confirm
          open={showArchiveConfirmation}
          header={header}
          content={content}
          onCancel={() => this.setState({ showArchiveConfirmation: false })}
          onConfirm={this.finishAction}
        />
        <TopBar
          search={search}
          includeArchived={includeArchived}
          onSearch={this.onSearch}
          toggleArchived={this.toggleArchived}
          onNew={() => goTo('/new-diagnostic')}
          newText='New Diagnostic'
          hideArchiveToggle={user.type === 'C'}
          hideNewButton={user.type === 'C'}
        />
        <form
          target='_blank'
          action={`/api/v1/diagnostic/${selectedId}/csv?token=${token}`}
          method='POST'
          ref={(el) => {
            this.form = el
            return null
          }}
        />
        <ContentLoader
          empty={!diagnostics.length}
          loading={
            diagnosticResult.requesting || duplicateDiagnosticResult.requesting
          }
        >
          <Table padded='very' basic='very' id='diagnosticsList'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Inventory</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Last Edited By</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {diagnostics.map((row) => (
                <Table.Row key={row._id}>
                  <Table.Cell className='tableTitle'>
                    <Link to={`/diagnostic/${row._id}`}>{row.title}</Link>
                  </Table.Cell>
                  <Table.Cell>{row.inventory.title}</Table.Cell>
                  <Table.Cell>
                    <ItemStatus archived={row.archived} />
                  </Table.Cell>
                  <Table.Cell>
                    {row.lastEditedBy &&
                      `${row.lastEditedBy.firstName} ${row.lastEditedBy.lastName}`}
                    <UpdatedAt timestamp={row.updatedAt} />
                  </Table.Cell>
                  <Table.Cell className='actionsCell'>
                    <Dropdown text='Actions' item>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => goTo(`/diagnostic/${row._id}`)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            window.open(`/diagnostic-preview/${row._id}`)
                          }
                        >
                          Preview
                          <Icon
                            name='external'
                            size='small'
                            className='voltActionMenuPreviewIcon'
                          />
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => this.downloadCSV(row._id)}
                        >
                          Export Data
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={this.rowActionClick}
                          value={row._id}
                        >
                          {row.archived ? 'Restore' : 'Archive'}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ContentLoader>
      </>
    )
  }
}

const mapStateToProps = ({
  diagnostic,
  duplicateDiagnostic,
  token,
  tokenValidation,
}) => ({
  diagnosticResult: diagnostic,
  diagnostics: get(diagnostic, 'fetchResult.data', []),
  duplicateDiagnosticResult: duplicateDiagnostic,
  token: get(token, 'result.data.token', ''),
  user: get(tokenValidation, 'result.data'),
})

const mapDispatchToProps = (dispatch) => {
  return {
    goTo: (url) => dispatch({ type: 'REDIRECT', url }),
    getDiagnostics: (query) =>
      dispatch({ type: 'FETCH_DIAGNOSTICS_REQUESTED', query }),
    archive: (id) => dispatch({ type: 'ARCHIVE_DIAGNOSTIC_REQUESTED', id }),
    restore: (id) => dispatch({ type: 'RESTORE_DIAGNOSTIC_REQUESTED', id }),
    duplicateDiagnostic: (id) =>
      dispatch({ type: 'DUPLICATE_DIAGNOSTIC_REQUESTED', id }),
    getToken: (payload) => dispatch({ type: 'GET_TOKEN_REQUESTED', payload }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Diagnostics)
