import React from 'react'
import { Divider, Header } from 'semantic-ui-react'
import { Table } from '../../common'

import UserListItem from './UserListItem'

const GroupUsers = ({ users, ...rest }) => {
  if (!users) return null

  return (
    <>
      {!users || !users.length ? (
        <Header as='h4'>
          There are no users in this group yet. Click "Add New" or "Change in
          Bulk" to add users.
        </Header>
      ) : (
        <>
          <Header as='h2'>All Group Members</Header>
          <Divider />
          <Table headings={['Full Name', 'Email', 'Role', 'Status', 'Actions']}>
            {users &&
              users.map(item => (
                <UserListItem key={item._id} item={item} {...rest} />
              ))}
          </Table>
        </>
      )}
    </>
  )
}

export default GroupUsers
