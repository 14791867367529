const defaultState = {
  logoVisible: true
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'HIDE_LOGO':
      return {
        logoVisible: false
      }
    case 'SHOW_LOGO':
      return {
        logoVisible: true
      }
    default:
      return state
  }
}
