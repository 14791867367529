import React from 'react'
import { Dropdown } from 'semantic-ui-react'

import './ActionButton.scss'

const options = highlighted => [
  {
    key: 'edit',
    icon: 'edit outline',
    text: 'Edit Factor',
    value: 'edit'
  },
  {
    key: 'view',
    icon: 'list alternate outline',
    text: 'View Asset Inventory',
    value: 'view'
  },
  {
    key: 'highlight',
    icon: 'star outline',
    text: highlighted ? 'Remove as Relevant Factor' : 'Show as Relevant Factor',
    value: 'highlighted'
  }
]

const ActionsButton = ({ handleOnChange, highlighted }) => (
  <Dropdown
    button
    className={'icon actionButton ' + highlighted}
    closeOnBlur
    closeOnChange
    closeOnEscape
    floating
    icon='angle down'
    labeled
    onChange={handleOnChange}
    options={options(highlighted)}
    selectOnBlur={false}
    text='Actions'
    value={null}
  />
)

export default ActionsButton
