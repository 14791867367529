import React, { Component } from 'react';
import { Divider, Checkbox } from 'semantic-ui-react';

class Sector extends Component {
  updateFactor = (checked, factor) => {
    const { factorsMap, onValueChange } = this.props;
    const newMap = Object.assign({}, factorsMap);
    if (checked) {
      newMap[factor._id] = true;
    } else {
      delete newMap[factor._id];
    }
    onValueChange({ field: 'factorsMap', value: newMap });
  }

  render() {
    const { sector, dimensionIndex, factorsMap } = this.props;
    const dimension = sector.dimensions[dimensionIndex];

    return (
      <div>
        <div className='factorsList'>
          <div className='factorsTitle'>FACTORS</div>
          <div>
            {dimension.factors.map(factor => (
              <div key={factor._id}>
                <Checkbox
                  label={factor.name}
                  checked={!!factorsMap[factor._id]}
                  onChange={(e, data) => this.updateFactor(data.checked, factor)} />
                <Divider />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Sector;
