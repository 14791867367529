import React from 'react';
import { Label } from 'semantic-ui-react';

import './styles.scss';

const VariableManager = ({ variables = [], onAddVariable }) => (
  <div className='variableManager'>
    <div className='variables'>
      {variables.map(variable => (
        <Label
          as='a'
          color='blue'
          key={variable}
          onClick={() => onAddVariable(variable)}
        >
          {variable}
        </Label>
      ))}
    </div>
  </div>
);

export default VariableManager;
