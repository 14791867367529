import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { Table, Message, Popup } from 'semantic-ui-react'

import Button from '../common/Button'

class Surveys extends Component {
  state = {
    showPopup: false,
    popupVisible: false
  }

  componentDidMount() {
    this.props.getSurveyInvites()

    if (!window.localStorage['firstLogin']) {
      this.setState({ showPopup: true, popupVisible: true })
      window.localStorage['firstLogin'] = true
    }
  }

  getSurveyStatus = survey => {
    if (survey.answered) {
      return 'Completed'
    }
    if (survey.started) {
      return 'In Progress'
    }
    return 'Not Started'
  }

  render() {
    const { surveys, goTo } = this.props

    if (!surveys.length) {
      return <Message>Your dashboard is empty.</Message>
    }

    const getSurveyButton = row => {
      return (
        <Button
          onClick={() => goTo(`/survey/${row.survey._id}`)}
          rounded
          padded
          shadowed
        >
          Open
        </Button>
      )
    }

    return (
      <Table padded='very' basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Sender</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {surveys.map((row, index) => (
            <Table.Row key={row._id}>
              <Table.Cell>{row.survey.title}</Table.Cell>
              <Table.Cell>{get(row, 'survey.sender.name', '')}</Table.Cell>
              <Table.Cell>{this.getSurveyStatus(row)}</Table.Cell>
              <Table.Cell>
                {!row.answered && this.state.showPopup && index === 0 && (
                  <Popup
                    open={this.state.popupVisible}
                    className='tutorialPopup'
                    inverted
                    trigger={getSurveyButton(row)}
                    position='bottom right'
                  >
                    <div className='message'>
                      Click the Open button to begin your survey.
                    </div>
                    <Button
                      primary
                      onClick={() => this.setState({ popupVisible: false })}
                    >
                      Done
                    </Button>
                  </Popup>
                )}
                {!row.answered &&
                  (!this.state.showPopup || index !== 0) &&
                  getSurveyButton(row)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )
  }
}

const mapStateToProps = ({ surveyInvites }) => ({
  surveys: get(surveyInvites, 'result.data', [])
})

const mapDispatchToProps = dispatch => ({
  goTo: url => dispatch({ type: 'REDIRECT', url }),
  getSurveyInvites: () => dispatch({ type: 'FETCH_SURVEY_INVITES_REQUESTED' })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Surveys)
