import { IDefaultReducerState } from "redux/types/reducer.types";

const defaultState: IDefaultReducerState = {
  requesting: false,
  error: false,
  result: null,
  isFetch: false,
  isCreate: false,
  isDelete: false,
  loaded: false,
};

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'FETCH_INVENTORY_ENTRY_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: false,
      };
    case 'FETCH_INVENTORY_ENTRY_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: true,
      };
    case 'FETCH_INVENTORY_ENTRY_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: false,
      };
    case 'FETCH_INVENTORY_ENTRIES_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: false,
      };
    case 'FETCH_INVENTORY_ENTRIES_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: true,
      };
    case 'FETCH_INVENTORY_ENTRIES_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isFetch: true,
        isCreate: false,
        isDelete: false,
        loaded: false,
      };
    case 'CREATE_INVENTORY_ENTRY_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: false,
      };
    case 'CREATE_INVENTORY_ENTRY_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: true,
      };
    case 'CREATE_INVENTORY_ENTRY_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: false,
      };
    case 'UPDATE_ENTRY_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: false,
      };
    case 'UPDATE_ENTRY_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: true,
      };
    case 'UPDATE_ENTRY_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isFetch: false,
        isCreate: true,
        isDelete: false,
        loaded: false,
      };
    default:
      return state;
  }
}
