const disclaimerData = [
  {
    title: 'Welcome [First Name]!',
    content: 'This survey will take about 1 hour to complete and you can do it at your own pace. \nFeel free to exit and come back within the time allotted to complete the survey. This survey is due on [Due Date].'
  },
  {
    title: 'Before you begin…',
    content: ''
  },
  {
    title: 'Adverse Events Reporting',
    content: 'This is a market research study and your responses will be kept anonymous, meaning that your responses will not be attributable to you specifically. \nShould any of your responses deliver specific  safety information related to the products mentioned in this study (i.e., safety information related to a particular patient experience), including adverse events, we will need to report this even if you have already reported it directly to the company or the regulatory authorities using the normal reporting processes. \nYou will be asked for consent to follow-up if an AE is reported or surfaces during the study, however you will not be terminated from the study should you choose not to consent.'
  },
  {
    title: 'Honor Code and Confidentiality - You agree to the following:',
    content: 'You consent to participate in the survey today. You understand that anything that you see within the survey is confidential, and you will not discuss it with anyone after the research has finished. You also understand that your participation in this research does not constitute an inducement to prescribe, supply, administer, recommend, buy or sell any medicine. \nThis is not a commercial communication or sales call. Your participation in this research will be anonymous, and you can withdraw at any time. \nYou will answer all requested survey prompts thoughtfully and to the best of your ability, using prior knowledge and materials provided within the survey.'
  }
];

export default disclaimerData;
