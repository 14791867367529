const defaultState = {
  requesting: false,
  error: false,
  result: null,
  isCreate: false
};

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'GET_RESET_LINK_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false
      };
    case 'GET_RESET_LINK_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false
      };
    case 'GET_RESET_LINK_FAILED':
      return {
        requesting: false,
        error: true,
        result: null,
        isCreate: false
      };
    default:
      return state;
  }
}
