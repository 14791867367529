import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loader, Header } from 'semantic-ui-react'
import get from 'lodash/get'

import Wheel from '../wheel'

class WheelPreview extends Component {
  state = {
    wheelTitle: ''
  }

  componentDidMount() {
    const { match, getWheel } = this.props
    const wheelId = match.params.wheelId

    if (wheelId === 'default') {
      this.setState({ wheelTitle: 'Default Wheel Preview' })
    } else {
      getWheel(wheelId)
    }

    this.getSectors(wheelId)
  }

  showLoading() {
    const { wheel, sectors } = this.props

    return !wheel || !sectors
  }

  getSectors = wheelId => {
    const { getSectors } = this.props
    const query = { all: true, wheel: wheelId }

    getSectors(query)
  }

  createPreviewSectors() {
    const { sectors } = this.props

    return sectors.map(sector => {
      const sec = { ...sector }
      sec.dimensions = sec.dimensions.map(dimension => {
        const dim = { ...dimension }
        dim.factors = dim.factors.filter(factor => factor.include)

        dim.factors.forEach(item => {
          item.score = null
          item.compareScore = null
          item.highlighted = false
        })

        return dim
      })
      return sec
    })
  }

  render() {
    const { wheel } = this.props

    const previewSectors = this.createPreviewSectors()

    if (this.showLoading()) {
      return <Loader active />
    }

    return (
      <div className='voltPreviewComponent'>
        <Header as='h1'>{wheel.title || this.state.wheelTitle}</Header>
        <Wheel preview sectors={previewSectors} />
      </div>
    )
  }
}

const mapStateToProps = ({ wheels, wheelSectors }) => ({
  wheel: get(wheels, 'result.data', {}),
  sectors: get(wheelSectors, 'all')
})

const mapDispatchToProps = dispatch => ({
  getWheel: id => dispatch({ type: 'FETCH_WHEEL_REQUESTED', id }),
  getSectors: query =>
    dispatch({ type: 'FETCH_WHEEL_SECTORS_REQUESTED', query })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WheelPreview)
