import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Menu, Divider } from 'semantic-ui-react'
import { get } from 'lodash'

import EntryPreview from '../inventory/entryPreview'
import Logo from '../../assets/volt_logo.svg'
import { getArray } from '../../lib/utils'
import Button from '../common/Button'
import ClosePreviewImage from '../../assets/volt_close_preview.png'

import './styles.scss'

class InventoryPreview extends Component {
  state = {
    inventoryEntries: {},
  }

  componentDidMount() {
    const { getSectors, getInventoryDetails, match, getInventoryEntries } =
      this.props
    getSectors({})
    getInventoryDetails(match.params.id)
    getInventoryEntries(match.params.id)
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.sectors !== this.props.sectors && props.sectors.length) {
      this.setState({ activeSector: props.sectors[0] })
    }
  }

  componentDidUpdate(prevProps) {
    const { inventory, getSectors, inventoryRequest, inventoryEntry } =
      this.props
    if (
      inventoryRequest !== prevProps.inventoryRequest &&
      !inventoryRequest.requesting &&
      inventoryRequest.result
    ) {
      getSectors({ wheel: inventory.wheel })
    }

    if (
      inventoryEntry !== prevProps.inventoryEntry &&
      inventoryEntry.isFetch &&
      inventoryEntry.loaded
    ) {
      this.loadEntryMap()
    }
  }

  loadEntryMap = () => {
    const { entries } = this.props
    const inventoryEntries = entries.reduce((entryMap, entry) => {
      entry.factors.forEach((factor) => {
        if (!entryMap[factor._id]) entryMap[factor._id] = []
        entryMap[factor._id].push(entry)
      })
      return entryMap
    }, {})
    this.setState({ inventoryEntries })
  }

  isInventoryPreviewPage() {
    const path = window.location.pathname

    if (path.startsWith('/inventory-preview')) {
      return true
    }

    return false
  }
  
  render() {
    const { sectors } = this.props
    const { activeSector, inventoryEntries } = this.state
    const isPreview = this.isInventoryPreviewPage()

    const factors = []
    if (activeSector) {
      activeSector.dimensions.forEach((dimension) => {
        dimension.factors.forEach((factor) => {
          factors.push(Object.assign({}, factor, { dimension }))
        })
      })
    }

    return (
      <div className='inventoryPreviewComponent'>
        <div className='headerComponent'>
          <div
            className={'voltLogo ' + (isPreview ? 'voltPreviewPage' : '')}
            onClick={() => !isPreview && this.goTo('/')}
          >
            <img src={Logo} alt='' />
          </div>

          <div className='btnMenu'>
            <Button
              shadowed
              rounded
              size='small'
              onClick={() => window.close()}
            >
              Close Preview
              <img
                alt=''
                src={ClosePreviewImage}
                className='voltPreviewCloseIcon'
              />
            </Button>
          </div>
        </div>
        <div className='inventoryContent'>
          <Menu attached='top' tabular>
            {sectors.map((sector) => (
              <Menu.Item
                key={sector._id}
                name={sector.name}
                active={activeSector === sector}
                onClick={() => this.setState({ activeSector: sector })}
              />
            ))}
          </Menu>
          <div className='sectorContent'>
            {factors.map((factor) => (
              <div className='factorContent' key={factor._id}>
                <div className='factorTitle'>
                  <div className='factorSection'>
                    <div>SECTOR</div>
                    <div>{activeSector.name}</div>
                  </div>
                  <div className='factorDivider' />
                  <div className='factorSection'>
                    <div>DIMENSION</div>
                    <div>{factor.dimension.name}</div>
                  </div>
                  <div className='factorDivider' />
                  <div className='factorSection'>
                    <div>FACTOR</div>
                    <div>{factor.name}</div>
                  </div>
                </div>
                <div
                  className={activeSector.compare ? 'comparatorContainer' : ''}
                >
                  {inventoryEntries[factor._id] &&
                    inventoryEntries[factor._id].map((entry) => (
                      <div className='customFactor' key={entry._id}>
                        <EntryPreview entry={entry} noLimit />
                      </div>
                    ))}
                </div>
                <Divider />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ inventory, sectors, inventoryEntry }) => ({
  inventoryRequest: inventory,
  inventory: get(inventory, 'result.data', {}),
  sectors: getArray(sectors, 'result.data', []),
  inventoryEntry,
  entries: getArray(inventoryEntry, 'result.data', []),
})

const mapDispatchToProps = (dispatch) => ({
  getSectors: (query) => dispatch({ type: 'FETCH_SECTORS_REQUESTED', query }),
  getInventoryDetails: (id) =>
    dispatch({ type: 'FETCH_INVENTORY_DETAILS_REQUESTED', id }),
  getInventoryEntries: (id) =>
    dispatch({ type: 'FETCH_INVENTORY_ENTRIES_REQUESTED', id }),
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
})

export default connect(mapStateToProps, mapDispatchToProps)(InventoryPreview)
