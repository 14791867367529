import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import activateAppMaintenance from './activateAppMaintenance';
import appMaintenance from './appMaintenance';
import appSettings from './appSettings';
import assets from './assets';
import auth from './auth';
import deactivateAppMaintenance from './deactivateAppMaintenance';
import diagnostic from './diagnostics';
import dimensions from './dimensions';
import duplicateDiagnostic from './duplicateDiagnostic';
import duplicateInventory from './duplicateInventory';
import duplicateSurvey from './duplicateSurvey';
import duplicateWheel from './duplicateWheel';
import emailList from './emailList';
import emailTemplates from './emailTemplates';
import factors from './factors';
import forceLogOutUser from './forceLogOutUser';
import forgotPassword from './forgotPassword';
import groups from './groups';
import inventory from './inventory';
import inventoryEntry from './inventoryEntry';
import inviteUser from './inviteUser';
import getResetLink from './getResetLink';
import loggedInUsers from './loggedInUsers';
import modals from './modalsReducer';
import multiFactors from './multiFactors';
import notifications from './notifications';
import passwordChange from './passwordChange';
import pslogo from './pslogo';
import redirect from './redirect';
import resetPassword from './resetPassword';
import sectors from './sectors';
import signUp from './signUp';
import survey from './surveys';
import surveysByDiagnostic from './surveysByDiagnostic';
import surveyInvites from './surveyInvites';
import surveyResponse from './surveyResponse';
import surveySender from './surveySender';
import surveyResponsesSummary from './surveyResponsesSummary';
import surveyTypes from './surveyTypes';
import token from './token';
import tokenValidation from './tokenValidation';
import updateEmailTemplates from './updateEmailTemplates';
import users from './users';
import wheels from './wheels';
import wheelSectors from './wheelSectors';
import diagnosticRelationRename from './diagnosticRelationRename';

export default history =>
  combineReducers({
    router: connectRouter(history),
    activateAppMaintenance,
    appMaintenance,
    appSettings,
    assets,
    auth,
    deactivateAppMaintenance,
    diagnostic,
    dimensions,
    duplicateDiagnostic,
    duplicateInventory,
    duplicateSurvey,
    duplicateWheel,
    emailList,
    emailTemplates,
    factors,
    forceLogOutUser,
    forgotPassword,
    groups,
    inventory,
    inventoryEntry,
    inviteUser,
    getResetLink,
    loggedInUsers,
    modals,
    multiFactors,
    notifications,
    passwordChange,
    pslogo,
    redirect,
    resetPassword,
    sectors,
    signUp,
    survey,
    surveysByDiagnostic,
    surveyInvites,
    surveyResponse,
    surveySender,
    surveyResponsesSummary,
    surveyTypes,
    token,
    tokenValidation,
    updateEmailTemplates,
    users,
    wheels,
    wheelSectors,
    diagnosticRelationRename
  });
