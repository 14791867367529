import React, { Component } from 'react'
import { Search } from 'semantic-ui-react'
import Button from '../../common/Button'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import escapeRegExp from 'lodash/escapeRegExp'

import './DiagnosticHeading.scss'

const initialState = { isLoading: false, results: [], value: '' }

const resultRenderer = ({ name, description }) => (
  <div class="message empty">
    <div class="header">{name}</div>
    <div class="description">{description}</div>
  </div>
)

class DiagnosticHeading extends Component {
  state = { ...initialState }

  componentDidUpdate(prevProps) {
    const { token } = this.props

    if (token !== prevProps.token && token) {
      this.form.submit()
    }
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value })

    setTimeout(() => {
      const { allFactors: source } = this.props

      if (value.length < 1) return this.setState(initialState)

      const re = new RegExp(escapeRegExp(value), 'i')
      const isMatch = result => re.test(result.name)

      this.setState({
        isLoading: false,
        results: filter(source, isMatch),
      })
    }, 300)
  }

  onFactorSelect = (e, { result: factor }) => {
    const { onFactorSelect } = this.props

    onFactorSelect(factor)
    this.setState({ value: '' })
  }

  downloadCSV = () => this.props.getToken()

  render() {
    const { id, token } = this.props
    const { results, value, isLoading } = this.state

    return (<>
      <form
        target='_blank'
        action={`/api/v1/diagnostic/${id}/csv?token=${token}`}
        method='POST'
        ref={el => {
          this.form = el
          return null
        }}
      />

      <div className='actionBar'>
        <Button className="largeButton" onClick={this.downloadCSV}>Export CSV</Button>
        <Search
          placeholder="Search"
          className='searchInput'
          loading={isLoading}
          value={value}
          onResultSelect={this.onFactorSelect}
          onSearchChange={debounce(this.handleSearchChange, 500, { leading: true })}
          results={results}
          resultRenderer={resultRenderer}
          noResultsMessage={"Change search query"}
          noResultsDescription={"For current search query, no factors are found."}
        />
      </div>
    </>
    )
  }
}




export default DiagnosticHeading