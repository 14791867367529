import React from 'react';
import styles from './Breadcrumb.module.scss';
import { IProps, BreadcrumbNode } from './Breadcrumb.types';
import { Link } from 'react-router-dom';

export class Breadcrumb extends React.PureComponent<IProps> {
  render () {
    const { nodes } = this.props;

    return (
      <div className={styles.breadcrumb} data-cy="breadcrumb">
        {nodes.map((node: BreadcrumbNode, index: number) => {
          return (
            <span key={`${node.label}-${node.route}`} className={styles.breadcrumbContainer}>
              <Link key={node.route} to={node.route} className={styles.breadcrumbNode}>
                {node.label}
              </Link>
              {index < nodes.length - 1 && <span className={styles.breadcrumbSeparator}>/</span>}
            </span>
          );
        })}
      </div>
    );
  }
}
