import { find, get, isEmpty, some } from 'lodash';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Icon, Form, Input, Divider, Dropdown } from 'semantic-ui-react';
import { v1 as uuidV1 } from 'uuid';

import { IInventoryDto } from 'types/dto/inventory.dto';

import { IInventoryFormState, IInventoryFormProps } from './inventory.types';

import { RoutePaths } from '../_routing/route-paths';
import Button from '../common/Button';
import DropdownMessage from '../common/DropdownMessage';

import { RootContext } from '../../contexts/RootContext.types';
import { getUpdatedWheels, getUpdatedWheelsWithInventory } from '../../helpers/wheels.helper'
import { isEmptyString } from '../../helpers/string.helper';
import { IComparatorDto } from '../../types/dto/comparator.dto';
import { IWheelDto } from '../../types/dto/wheel.dto';

const validatedFields = ['title', 'wheelId'];

const InventoryForm: React.FC<IInventoryFormProps> = props => {
  const [defaultWheelText, setDefaultWheelText] = useState('');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
  const [form, setForm] = useState<IInventoryFormState>({
    comparators: [],
    inventoryId: '',
    title: '',
    product: '',
    wheelId: '',
    comparatorsError: {},
    productError: {},
    titleError: {},
    wheelError: {}
  });

  const { wheelsWithInventories, setWheelsWithInventories } = useContext(RootContext);
  const { inventories, isNewInventory, inventoriesRequest, wheels, goTo } = props;

  useEffect(() => {
    if (!isEmpty(wheelsWithInventories)) {
      return;
    }
    const updatedWheels = getUpdatedWheels(wheels, inventories);
    setWheelsWithInventories(updatedWheels);
  }, [wheels, inventories, wheelsWithInventories]);

  const currentWheels = useMemo(() => {
    if (Array.isArray(inventories)) {
      return wheelsWithInventories;
    }
    return getUpdatedWheelsWithInventory(wheelsWithInventories, inventories);
  }, [inventories, wheelsWithInventories]);


  const setComparators = (comparators: IComparatorDto[]) => {
    setForm({ ...form, comparators });
  };

  const addComparator = () => {
    const { comparators } = form;
    const activeComparators = comparators?.filter(c => !c.deleted);
    const counter = isEmpty(activeComparators) ? 1 : activeComparators.length + 1;

    const newComparator: Partial<IComparatorDto> = {
      _id: uuidV1(),
      name: `Comparator ${counter}`,
      deleted: false,
      new: true
    };

    const extendedComparators = [
      ...(comparators || []),
      newComparator
    ] as IComparatorDto[];

    setComparators(extendedComparators);
  };

  // @TODO Fix the argument "inventory"; sometimes, it's a 
  // single IInventoryDto object and sometimes, it's an array of them
  // This is confusing and creates weird hacks to fix the issue
  // Additionally, when on creating New vs Existing Asset Inventories,
  // "wheelId" property sometimes shows up as "wheel" instead.
  // For now, hardcoding fix to handle that issue.
  const setUpForm = (inventory: any, wheels: IWheelDto[]) => {
    setForm({
      inventoryId: inventory._id,
      ...inventory,
      wheelId: inventory.wheel,
      comparatorsError: {},
      wheels
    });
  };

  const saveParameters = (evt: any) => {
    evt.preventDefault();
    const { onSave } = props;

    const fieldsError = validatedFields.reduce(
      (error, val) => updateForm(val, form[val]) || error,
      false
    );
    const comparatorsError = validateComparators();

    if (fieldsError || some(comparatorsError, true)) {
      return;
    }

    const { inventoryId, title, product, comparators, wheelId } = form;

    const inventoryParams = {
      title,
      product,
      comparators,
      wheel: wheelId
    };

    onSave(inventoryParams, inventoryId);
  };

  const validateComparators = () => {
    const errors = form?.comparators?.reduce((acc, comparator) => {
      if (!comparator || comparator.name === '') {
        return {
          ...acc,
          [comparator._id]: true
        };
      }
      return acc;
    }, {});

    updateForm('comparatorsError', errors);
    return errors;
  };

  // @TODO Fix this file so that we separate / fix how we get and update
  // form values
  const updateForm = (field: string, value: any): boolean => {
    const newState = { ...form };

    newState[field] = value;

    let hasError = false;

    if (validatedFields.includes(field)) {
      // validation - is empty or null
      hasError = value ? false : true;
      newState[`${field}Error`] = hasError;
    }

    if (field === 'wheelId') {
      const newWheelText = getSelectedWheelText(value);
      setDefaultWheelText(newWheelText);
    }

    setForm(newState);
    return hasError;
  };

  const updateComparator = (value: string, comparator: IComparatorDto) => {
    comparator.name = value;
    setComparators(comparators);
    validateComparators();
  };

  const deleteComparator = (evt: any, comparatorId: string) => {
    evt.stopPropagation();
    evt.preventDefault();

    const comparator = comparators.find(item => {
      return item._id === comparatorId;
    });

    if (comparator) {
      comparator.deleted = true;
    }

    setComparators(comparators);
  };

  const getSelectedWheelText = (currentWheelId: string) => {
    const found: IWheelDto = find(wheels, (wheel: IWheelDto) => {
      return wheel._id === currentWheelId;
    });
    if (!found) {
      return '';
    }
    return found.title;
  };

  const renderButtonMenu = () => (
    <div className='buttons'>
      <Button
        color='white'
        rounded
        disabled={inventoriesRequest.requesting}
        onClick={() => goTo(RoutePaths.Inventories)}
      >
        Cancel
      </Button>
      <Button
        rounded
        shadowed
        disabled={inventoriesRequest.requesting || saveButtonDisabled}
        onClick={saveParameters}
      >
        Save
      </Button>
    </div>
  );

  useEffect(() => {
    if (inventories && !Array.isArray(inventories)) {
      const currentWheelText = getSelectedWheelText((inventories as IInventoryDto)?.wheel as string);
      setDefaultWheelText(currentWheelText);
    }
  }, [inventories, wheelsWithInventories]);

  useEffect(() => {
    if (inventories && currentWheels) {
      setUpForm(inventories, currentWheels);
    }
    if (isNewInventory && currentWheels) {
      setUpForm({}, currentWheels);
    }
  }, [inventories, currentWheels]);

  useEffect(() => {
    if (isNewInventory) {
      addComparator();
    }
  }, []);


  if (!currentWheels || (!isNewInventory && !inventories)) {
    return null;
  };

  useEffect(() => {
    if (isEmptyString(form?.title) || isEmptyString(form?.wheelId) || isEmptyString(form?.product)) {
      setSaveButtonDisabled(true);
    } else {
      setSaveButtonDisabled(false);
    }
  }, [form]);

  const {
    comparators,
    comparatorsError,
    product,
    productError,
    title,
    titleError,
    wheelId,
    wheelError
  } = form;

  return (
    <div className='parameters'>
      <Form>
        {renderButtonMenu()}

        <Form.Field>
          <div className='fieldLabel'>
            <label>Title</label>
            <label>*REQUIRED</label>
          </div>
          <Input
            name='title'
            error={!isEmpty(titleError)}
            defaultValue={title}
            onChange={(e, data) => updateForm('title', data.value)}
          />
        </Form.Field>
        <Form.Field>
          <div className='fieldLabel'>
            <label>Wheel</label>
            <label>*REQUIRED</label>
          </div>
          <Dropdown
            fluid
            search
            selection
            text={defaultWheelText}
            value={wheelId}
            error={!isEmpty(wheelError)}
            onChange={(e, data) => updateForm('wheelId', data.value)}
            options={currentWheels.map((wheel: IWheelDto) => ({
              key: wheel._id,
              text: wheel.title,
              value: wheel._id,
              disabled: wheel.used
            }))}
          />
          <DropdownMessage items={currentWheels} type='inventory' parent='wheel' />
        </Form.Field>

        <Form.Field>
          <div className='fieldLabel'>
            <label>Product Name</label>
            <label>*REQUIRED</label>
          </div>
          <Input
            name='product'
            error={!isEmpty(productError)}
            defaultValue={product}
            required
            onChange={(e, data) => updateForm('product', data.value)}
          />
        </Form.Field>

        <Form.Field>
          <label>COMPARATORS</label>
        </Form.Field>

        {comparators &&
          comparators
            .filter(comparator => !comparator?.deleted)
            .map(comparator => (
              <Form.Field key={comparator?._id} className='comparatorField'>
                <div className='comparatorInput'>
                  <Input
                    placeholder={`Comparator`}
                    onChange={(e, data) =>
                      updateComparator(data.value, comparator)
                    }
                    defaultValue={comparator.name}
                    error={comparatorsError[comparator?._id]}
                  />
                </div>
                {comparators.length > 1 && (
                  <div>
                    <Button
                      rounded
                      icon='delete'
                      index={comparator._id}
                      className='deleteComparator'
                      onClick={e => deleteComparator(e, comparator?._id)}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </Form.Field>
            ))}

        <div style={{ clear: 'both' }} />

        <Button color='clear' onClick={addComparator} className='addComparator'>
          <Icon name='plus circle' color='green' size='large' />
          Add Comparator
        </Button>
        <Divider />
        <div style={{ marginTop: '38px' }}>{renderButtonMenu()}</div>
      </Form>
    </div>
  );
};

const mapStateToProps = ({ inventory: inventoriesRequest, wheels: wheelsRequest }) => {
  const inventories = get(inventoriesRequest, 'result.data');
  // @TODO After full changeover to ContextAPI, remove wheels from here
  const wheels = get(wheelsRequest, 'result.data', []);
  return {
    inventoriesRequest,
    inventories,
    wheels,
  }
};

const mapDispatchToProps = (dispatch: Function) => ({
  goTo: (url: string) => dispatch({ type: 'REDIRECT', url })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type InventoryFormProps = ConnectedProps<typeof connector>;

export default connector(InventoryForm);
