import React, { Component } from 'react'

import utils from './utils'
import constants from './constants'
import Dimension from './dimension'

class Sector extends Component {
  selectSector = () => {
    const { meta, onSectorSelect, sector } = this.props

    if (meta.selectableSectors) {
      onSectorSelect(sector._id)
    }
  }

  render() {
    const {
      sector,
      meta,
      selectedSector,
      onFactorSelect,
      selectedDimensions,
      onDimensionSelect,
      angle
    } = this.props

    const { preview } = meta
    const darkGrey = '#2A2B2E'

    const totalElements =
      sector.totalFactors + (meta.showDimensions ? sector.dimensions.length : 0)
    const sa = angle
    const ta =
      angle + meta.factorStep * (totalElements - (meta.showDimensions ? 0 : 1))
    const isMoreThanHalf = Math.abs(ta - sa) >= 180

    const cx = meta.width / 2,
      cy = meta.height / 2
    let startRadius = 196
    let n = [1, 2, 3, 4, 5]
    if (!sector.compare) {
      startRadius -= constants.scaleStep
      n = [1, 2, 3, 4, 5, 6, 7]
    }

    const tagAngle = ta + 1
    const tagFAngle = tagAngle + 10
    const shouldShowTags = !sector.compare
    const tags = [
      {
        sx: utils.calculateDX(cx, tagAngle, startRadius),
        sy: utils.calculateDY(cy, tagAngle, startRadius),
        tx: utils.calculateDX(cx, tagFAngle, startRadius),
        ty: utils.calculateDY(cy, tagFAngle, startRadius),
        radius: startRadius,
        tag: 'LOW'
      },
      {
        sx: utils.calculateDX(
          cx,
          tagAngle,
          startRadius + constants.scaleStep * 4
        ),
        sy: utils.calculateDY(
          cy,
          tagAngle,
          startRadius + constants.scaleStep * 4
        ),
        tx: utils.calculateDX(
          cx,
          tagFAngle,
          startRadius + constants.scaleStep * 4
        ),
        ty: utils.calculateDY(
          cy,
          tagFAngle,
          startRadius + constants.scaleStep * 4
        ),
        radius: startRadius + constants.scaleStep * 4,
        tag: 'MID'
      },
      {
        sx: utils.calculateDX(
          cx,
          tagAngle,
          startRadius + constants.scaleStep * 8
        ),
        sy: utils.calculateDY(
          cy,
          tagAngle,
          startRadius + constants.scaleStep * 8
        ),
        tx: utils.calculateDX(
          cx,
          tagFAngle,
          startRadius + constants.scaleStep * 8
        ),
        ty: utils.calculateDY(
          cy,
          tagFAngle,
          startRadius + constants.scaleStep * 8
        ),
        radius: startRadius + constants.scaleStep * 8,
        tag: 'HIGH'
      }
    ]

    function getArc(radius) {
      return {
        sx: utils.calculateDX(cx, sa, radius),
        sy: utils.calculateDY(cy, sa, radius),
        tx: utils.calculateDX(cx, ta, radius),
        ty: utils.calculateDY(cy, ta, radius)
      }
    }

    function getLine(cx, cy, ang, radius, n) {
      return {
        x1: utils.calculateDX(cx, ang, radius + constants.scaleStep),
        y1: utils.calculateDY(cy, ang, radius + constants.scaleStep),
        x2: utils.calculateDX(cx, ang, radius + n * constants.scaleStep),
        y2: utils.calculateDY(cy, ang, radius + n * constants.scaleStep)
      }
    }

    const disabled = '#B5B6B8'
    const highlighted = '#404144'
    let lineColor = '#d4cfcf'
    let textColor
    let opacity = 1
    if ((meta.filter === 'parities' && !sector.compare) || (sector.disabled && !preview)) {
      lineColor = disabled
      opacity = 0.3
    }
    
    if (sector.highlighted) {
      textColor = highlighted
    }

    let scaleColor = disabled
    if (meta.filter === 'detailed' && selectedSector === sector._id) {
      scaleColor = darkGrey
    }

    function createGrid() {
      let ang = angle
      const gridElements = sector.dimensions.reduce((acum, dimension) => {
        if (meta.showDimensions) {
          const { x1, y1, x2, y2 } = getLine(cx, cy, ang, startRadius, n.length)
          acum.push(
            <line
              key={`DL_${dimension._id}`}
              x1={x1}
              y1={y1}
              x2={x2}
              y2={y2}
              style={{ stroke: lineColor, strokeWidth: '1', opacity }}
            />
          )
          ang += meta.factorStep
        }

        return acum.concat(
          dimension.factors.map((factor, index) => {
            const { x1, y1, x2, y2 } = getLine(
              cx,
              cy,
              ang,
              startRadius,
              n.length
            )

            ang += meta.factorStep

            if (!x1 || !y1 || !x2 || !y2) return null

            return (
              <line
                key={`FL_${factor._id}`}
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                style={{ stroke: lineColor, strokeWidth: '1', opacity }}
              />
            )
          })
        )
      }, [])

      if (meta.showDimensions) {
        const { x1, y1, x2, y2 } = getLine(cx, cy, ang, startRadius, n.length)
        gridElements.push(
          <line
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            style={{ stroke: lineColor, strokeWidth: '1', opacity }}
          />
        )

        if (selectedSector === sector._id) {
          gridElements.push(
            <Dimension
              sector={sector}
              dimension={{ name: '', factors: [] }}
              radius={startRadius}
              angle={ang}
              meta={meta}
              selectedSector={selectedSector}
              onFactorSelect={onFactorSelect}
              selectedDimensions={selectedDimensions}
              onDimensionSelect={onDimensionSelect}
            />
          )
        }
      }

      return gridElements
    }

    function drawPath(options) {
      let sweep = 1
      if (options.straight === false) {
        options = Object.assign({}, options, {
          sx: options.tx,
          sy: options.ty,
          tx: options.sx,
          ty: options.sy
        })

        sweep = 0
      }

      options.style = options.style || {}
      options.style.opacity = opacity

      if (
        !options.sx ||
        !options.sy ||
        !options.radius ||
        !options.tx ||
        !options.ty
      )
        return null

      return (
        <path
          id={options.id}
          key={options.key}
          d={`M ${options.sx} ${options.sy} A ${options.radius} ${
            options.radius
          } 0 ${isMoreThanHalf && !options.avoidHalf ? '1' : '0'} ${sweep} ${options.tx} ${options.ty}`}
          stroke={options.stroke}
          style={options.style}
          fill={options.fill}
        />
      )
    }

    function drawScale(id, radius, color) {
      const { sx, sy, tx, ty } = getArc(radius)
      const l1 = getArc(radius - 3)
      const l2 = getArc(radius + 3)

      if (!l1.tx || !l1.ty) return null

      return [
        drawPath({
          sx,
          sy,
          radius,
          tx,
          ty,
          stroke: scaleColor,
          fill: 'transparent',
          key: `SL_${id}_${radius}`,
          style: { strokeWidth: '1.2', opacity }
        }),
        <line
          x1={l1.sx}
          y1={l1.sy}
          x2={l2.sx}
          y2={l2.sy}
          key={`SL1_${id}_${radius}`}
          style={{ stroke: scaleColor, strokeWidth: '1.2', opacity }}
        />,
        <line
          x1={l1.tx}
          y1={l1.ty}
          x2={l2.tx}
          y2={l2.ty}
          key={`SL2_${id}_${radius}`}
          style={{ stroke: scaleColor, strokeWidth: '1.2', opacity }}
        />
      ]
    }

    const getColor = () => {
      if (sector.disabled) return disabled
      if (meta.filter === 'parities' && !sector.compare) return disabled
      if (!meta.selectableSectors) return '#404144'
      if (sector._id === selectedSector) return '#4e4d4d'
      return '#d4cfcf'
    }

    const textPath = getArc(startRadius - constants.scaleStep)
    const textPathId = `SP_${sector._id}`
    const textRadius = startRadius - constants.scaleStep

    const gridRadiusStart = startRadius
    let dimensionAngle = angle

    let midAngle = angle + (sector.totalElements * meta.factorStep) / 2
    while (midAngle >= 360) midAngle -= 360
    while (midAngle < 0) midAngle += 360

    const straight = midAngle > 180

    const grid = createGrid()
    if (!grid.length) {
      return null
    }

    return (
      <g>
        {drawScale(sector._id, startRadius, getColor())}
        {grid}
        {n.map((val, index) => {
          startRadius += constants.scaleStep
          if ((sector.compare && val === 3) || (!sector.compare && val === 4)) {
            return null
          }
          const line = getArc(startRadius)

          if (!line.sx || !line.sy || !startRadius || !line.tx || !line.ty)
            return null

          return (
            <g key={`SL_${sector._id}_${index}`}>
              <path
                d={`M ${line.sx} ${
                  line.sy
                } A ${startRadius} ${startRadius} 0 ${isMoreThanHalf ? '1' : '0'} 1 ${line.tx} ${line.ty}`}
                stroke={lineColor}
                fill='transparent'
                opacity={opacity}
              />
            </g>
          )
        })}
        {drawScale(sector._id, startRadius + constants.scaleStep, getColor())}
        {sector.dimensions.map((dimension, index) => {
          const totalElements =
            dimension.factors.length + (meta.showDimensions ? 1 : 0)
          const elem = (
            <Dimension
              key={dimension._id}
              sector={sector}
              dimension={dimension}
              radius={gridRadiusStart}
              angle={dimensionAngle}
              meta={meta}
              selectedSector={selectedSector}
              onFactorSelect={onFactorSelect}
              selectedDimensions={selectedDimensions}
              onDimensionSelect={onDimensionSelect}
            />
          )
          dimensionAngle += totalElements * meta.factorStep
          return elem
        })}
        {shouldShowTags &&
          tags.map(tag => (
            <g key={tag.tag}>
              {drawPath({
                id: tag.tag,
                sx: tag.sx,
                sy: tag.sy,
                radius: tag.radius,
                tx: tag.tx,
                ty: tag.ty,
                stroke: 'transparent',
                fill: 'transparent',
                straight: !isMoreThanHalf,
                avoidHalf: true
              })}
              <text fill={darkGrey} className='sectorTag'>
                <textPath
                  href={`#${tag.tag}`}
                  style={{
                    fontFamily: 'Roboto, Arial'
                  }}
                >
                  {tag.tag}
                </textPath>
              </text>
            </g>
          ))}
        {drawPath({
          id: textPathId,
          sx: textPath.sx,
          sy: textPath.sy,
          radius: textRadius,
          tx: textPath.tx,
          ty: textPath.ty,
          stroke: 'transparent',
          fill: 'transparent',
          straight
        })}
        <text
          fill={textColor || getColor()}
          textAnchor='middle'
          className='sectorName'
        >
          <textPath
            onClick={this.selectSector}
            style={{
              cursor: meta.selectableSectors ? 'pointer' : 'default',
              fontWeight: sector.highlighted ? '900' : '400',
              fontFamily: 'Roboto, Arial'
            }}
            href={`#${textPathId}`}
            startOffset='50%'
          >
            {sector.name}
          </textPath>
        </text>
      </g>
    )
  }
}

export default Sector
