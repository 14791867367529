import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Table, Icon, Loader, Confirm } from 'semantic-ui-react'

import styles from './DataDuplication.module.scss'
import RenameModal from './RenameModal'

const DataDuplication = props => {
  const {
    getDiagnostics,
    diagnostics,
    wheels,
    getWheels,
    duplicateDiagnostic,
    diagnosticDuplication,
    wheelsRequest,
    diagnosticsRequest,
    deleteWheel
  } = props
  const [wheelMap, setWheelMap] = useState({})
  const [showRenameModal, setShowRenameModal] = useState(false)
  const [selectedDiagnostic, setSelectedDiagnostic] = useState(undefined)
  const [diagnosticToDuplicate, setDiagnosticToDuplicate] = useState(undefined)
  const [wheelToDelete, setWheelToDelete] = useState(undefined)

  const loadData = () => {
    getDiagnostics({})
    getWheels({})
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (!diagnosticDuplication.error && diagnosticDuplication.result) {
      loadData()
    }
  }, [diagnosticDuplication])

  useEffect(() => {
    if (!wheelsRequest.requesting && wheelsRequest.isDelete) {
      loadData()
    }
  }, [wheelsRequest])

  useEffect(() => {
    if (wheels.length > 0) {
      setWheelMap(_.keyBy(wheels, '_id'))
    }
  }, [wheels])

  const isDiagnosticLoading = diagnostic => {
    return (
      diagnosticDuplication.requesting &&
      diagnosticToDuplicate === diagnostic._id
    )
  }

  const deleteWheelRelation = () => {
    deleteWheel(wheelToDelete._id)
    setWheelToDelete(undefined)
  }

  return (
    <div>
      {selectedDiagnostic && (
        <RenameModal
          open={showRenameModal}
          onClose={() => setShowRenameModal(false)}
          diagnostic={selectedDiagnostic}
          inventory={selectedDiagnostic.inventory}
          wheel={wheelMap[selectedDiagnostic.inventory.wheel]}
          onUpdate={() => {
            loadData()
            setShowRenameModal(false)
          }}
        />
      )}
      <Confirm
        className={styles.confirmModal}
        header='Delete'
        content={
          <div className={styles.confirmContent}>
            <p>
              You're about to delete a set of wheel, inventory, and diagnostic.
              You cannot undo this action and deletion is permanent.
            </p>
            <p>Click Delete to continue or Cancel if you changed your mind.</p>
          </div>
        }
        confirmButton='Delete'
        cancelButton='Cancel'
        onCancel={() => setWheelToDelete(undefined)}
        open={!!wheelToDelete}
        onConfirm={() => deleteWheelRelation()}
      />
      <Loader
        active={wheelsRequest.requesting || diagnosticsRequest.requesting}
        inline='centered'
      />
      {wheels.length > 0 && diagnostics.length > 0 && (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Wheel</Table.HeaderCell>
              <Table.HeaderCell>Inventories</Table.HeaderCell>
              <Table.HeaderCell>Diagnostics</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
            {diagnostics.map(diagnostic => (
              <Table.Row key={diagnostic._id}>
                <Table.Cell>
                  {_.get(wheelMap, `${diagnostic.inventory.wheel}.title`)}
                </Table.Cell>
                <Table.Cell>{diagnostic.inventory.title}</Table.Cell>
                <Table.Cell>{diagnostic.title}</Table.Cell>
                <Table.Cell>
                  <div className={styles.tableButtons}>
                    <Icon
                      name='window restore'
                      circular
                      size='large'
                      title='Duplicate'
                      loading={isDiagnosticLoading(diagnostic)}
                      disabled={diagnosticDuplication.requesting}
                      onClick={() => {
                        if (!diagnosticDuplication.requesting) {
                          setDiagnosticToDuplicate(diagnostic._id)
                          duplicateDiagnostic(diagnostic._id)
                        }
                      }}
                    />
                    <Icon
                      name='edit'
                      circular
                      size='large'
                      onClick={() => {
                        setSelectedDiagnostic(diagnostic)
                        setShowRenameModal(true)
                      }}
                      title='Rename'
                    />
                    <Icon
                      name='trash'
                      circular
                      size='large'
                      onClick={() =>
                        setWheelToDelete(
                          _.get(wheelMap, `${diagnostic.inventory.wheel}`)
                        )
                      }
                      title='Delete'
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Header>
        </Table>
      )}
    </div>
  )
}

const mapStateToProps = ({ diagnostic, wheels, duplicateDiagnostic }) => {
  const wheelData = _.get(wheels, 'result.data', [])
  const diagnosticData = _.get(diagnostic, 'fetchResult.data', [])
  return {
    wheels: Array.isArray(wheelData) ? wheelData : [],
    diagnostics: Array.isArray(diagnosticData) ? diagnosticData : [],
    diagnosticDuplication: duplicateDiagnostic,
    wheelsRequest: wheels,
    diagnosticsRequest: diagnostic
  }
}

const mapDispatchToProps = dispatch => ({
  deleteWheel: id => dispatch({ type: 'DELETE_WHEEL_REQUESTED', id }),
  getWheels: query => dispatch({ type: 'FETCH_WHEELS_REQUESTED', query }),
  getDiagnostics: query =>
    dispatch({ type: 'FETCH_DIAGNOSTICS_REQUESTED', query }),
  duplicateDiagnostic: id =>
    dispatch({ type: 'DUPLICATE_DIAGNOSTIC_REQUESTED', id })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataDuplication)
