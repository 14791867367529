import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { Table, Dropdown, Confirm, Label } from 'semantic-ui-react';

import { getArray } from '../../lib/utils';
import UpdatedAt from './updatedAt';
import TopBar from './TopBar';
import ContentLoader from '../common/content-loader';
import ItemStatus from '../common/item-status';
import { USER_TYPES } from '../../types/user.types';

class Users extends Component {
  state = {
    userToDelete: null,
    search: ''
  };

  UNSAFE_componentWillReceiveProps(props) {
    const {
      getLoggedInUsers,
      loggedInUsersResult,
      forceLogOutUserResult
    } = props;

    if (forceLogOutUserResult.requesting && !loggedInUsersResult.requesting) {
      getLoggedInUsers();
    }
  }

  componentDidMount() {
    const { search } = this.props;
    this.props.getUsers({ search });
    this.props.getLoggedInUsers();
  }

  showDeleteUser = userToDelete => {
    this.setState({ userToDelete });
  };

  componentDidUpdate(prevProps) {
    const { userResult, search } = this.props;

    if (
      userResult !== prevProps.userResult &&
      !userResult.requesting &&
      userResult.isCreate
    ) {
      this.props.getUsers({ search });
    }
  }

  onSearch = newSearch => {
    const { search } = this.state;
    const { getUsers } = this.props;

    if (newSearch !== search) {
      this.setState({ search: newSearch });
      getUsers({ search: newSearch });
    }
  };

  render() {
    const {
      goTo,
      users,
      userResult,
      loggedInUsers,
      loggedInUsersResult,
      forceLogOutUser,
      forceLogOutUserResult,
      inviteUser,
      inviteUserResult,
      getResetLink,
      getResetLinkResult,
      deleteUser,
      toggleDisableUser
    } = this.props;
    const { userToDelete, search } = this.state;

    return (
      <>
        <Confirm
          open={!!userToDelete}
          header='Delete User'
          content={
            <div className='content'>
              Are you sure you want to delete{' '}
              <strong>{get(userToDelete, 'email')}</strong>?
            </div>
          }
          onCancel={() => this.setState({ userToDelete: null })}
          onConfirm={() => {
            deleteUser(userToDelete._id);
            this.setState({ userToDelete: null });
          }}
        />
        <TopBar
          search={search}
          hideArchiveToggle
          onSearch={this.onSearch}
          onNew={() => goTo('/user/new')}
          newText='New User'
        />
        <ContentLoader
          loading={
            userResult.requesting &&
            loggedInUsersResult.requesting &&
            forceLogOutUserResult.requesting &&
            inviteUserResult.requesting &&
            getResetLinkResult.requesting
          }
        >
          <Table padded='very' basic='very' id='usersList'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Last Edited By</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {users.map(row => (
                <Table.Row key={row._id}>
                  <Table.Cell className='tableTitle'>
                    <Link to={`/user/${row._id}`}>{row.email}</Link>
                  </Table.Cell>
                  <Table.Cell>{USER_TYPES[row.type]}</Table.Cell>
                  <Table.Cell>
                    <ItemStatus inactive={row.disabled} />
                    {loggedInUsers.includes(row._id) && (
                      <Label color='violet'>Logged in</Label>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {row.lastEditedBy &&
                      `${row.lastEditedBy.firstName} ${row.lastEditedBy.lastName}`}
                    <UpdatedAt timestamp={row.updatedAt} />
                  </Table.Cell>
                  <Table.Cell className='actionsCell'>
                    {
                      <Dropdown text='Actions' item>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => goTo(`/user/${row._id}`)}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => inviteUser(row._id)}>
                            Send Invite
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => getResetLink(row._id)}>
                            Copy Reset Link
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => forceLogOutUser(row._id)}
                          >
                            Force Logout
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => toggleDisableUser(row)}>
                            {row.disabled ? 'Activate' : 'Deactivate'} User
                          </Dropdown.Item>
                          <Dropdown.Item
                            className='voltActionDelete'
                            onClick={() => this.showDeleteUser(row)}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    }
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ContentLoader>
      </>
    );
  }
}

const mapStateToProps = ({
  users,
  loggedInUsers,
  forceLogOutUser,
  inviteUser,
  getResetLink
}) => ({
  userResult: users,
  users: getArray(users, 'result.data', []),
  loggedInUsersResult: loggedInUsers,
  loggedInUsers: getArray(loggedInUsers, 'result.data', []),
  forceLogOutUserResult: forceLogOutUser,
  inviteUserResult: inviteUser,
  getResetLinkResult: getResetLink,
  resetLink: get(getResetLink, 'result.data.resetLink', '')
});

const mapDispatchToProps = dispatch => ({
  goTo: url => dispatch({ type: 'REDIRECT', url }),
  getUsers: query => dispatch({ type: 'FETCH_USERS_REQUESTED', query }),
  getLoggedInUsers: () => dispatch({ type: 'FETCH_LOGGED_IN_USERS_REQUESTED' }),
  deleteUser: id =>
    dispatch({
      type: 'UPDATE_USER_REQUESTED',
      id,
      payload: {
        deleted: true
      }
    }),
  toggleDisableUser: user =>
    dispatch({
      type: 'UPDATE_USER_REQUESTED',
      id: user._id,
      payload: {
        disabled: !user.disabled
      }
    }),
  forceLogOutUser: userId =>
    dispatch({ type: 'FORCE_LOG_OUT_USER_REQUESTED', userId }),
  inviteUser: userId => dispatch({ type: 'INVITE_USER_REQUESTED', userId }),
  getResetLink: userId => dispatch({ type: 'GET_RESET_LINK_REQUESTED', userId })
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
