import React, { Component } from 'react'
import get from 'lodash/get'
import differenceBy from 'lodash/differenceBy'
import { connect } from 'react-redux'
import {
  Button,
  Form,
  Input,
  Dropdown,
  Divider,
  Header
} from 'semantic-ui-react'
import { USER_TYPES } from '../../../types/user.types'

const filterMembers = (users, group, allGroups) => {
  const childGroups = group.parent
    ? allGroups[group.parent._id].children
    : allGroups[group._id].children
  const parentMembers = group.parent
    ? allGroups[group.parent._id].members
    : group.members
  const allMembers = childGroups.reduce((a, i) => a.concat(i.members), [
    ...parentMembers
  ])

  return differenceBy(users, allMembers, '_id')
}
const getUserOptions = filteredUsers => {
  if (!filteredUsers || !filteredUsers.length) return []

  return filteredUsers.map(u => ({
    text: `${u.firstName} ${u.lastName}`,
    value: u._id
  }))
}

class NewUser extends Component {
  state = {
    firstName: '',
    lastName: '',
    type: '',
    email: ''
  }

  componentDidMount() {
    const { getUsers } = this.props
    getUsers()
  }

  backToGroupDetails = () => {
    const { goTo, id } = this.props
    goTo(`/groups/${id}`)
  }

  addUser = () => {
    const { userId } = this.state
    const { addUserToGroup, id } = this.props
    addUserToGroup(userId, id)
    this.backToGroupDetails()
  }

  createUser = () => {
    const { email, type, firstName, lastName } = this.state
    const { createUser, id } = this.props

    createUser({
      email,
      type,
      firstName,
      lastName,
      groupId: id
    })

    this.backToGroupDetails()
  }

  handleChange = (e, { type, name, checked, value }) => {
    const nextValue = type === 'checkbox' ? checked : value
    this.setState({ [name]: nextValue })
  }

  render() {
    const { users, group, allGroups } = this.props
    const { firstName, lastName, email, type, userId } = this.state

    const filteredUsers = filterMembers(users, group, allGroups)

    const userTypeOptions = Object.keys(USER_TYPES).map(key => {
      return {
        value: key,
        key,
        text: USER_TYPES[key]
      }
    })

    return (
      <>
        <Header as='h2'>Add Existing User to Group</Header>
        <Form>
          <Form.Field>
            <label htmlFor='userId'>USER</label>
            <Dropdown
              multiple
              placeholder='User to add'
              fluid
              search
              selection
              name='userId'
              onChange={this.handleChange}
              value={userId}
              options={getUserOptions(filteredUsers)}
            />
          </Form.Field>
          <div className='primaryActions'>
            <Button basic size='big' onClick={this.backToGroupDetails}>
              Cancel
            </Button>
            <Button
              basic
              color='blue'
              type='button'
              size='big'
              onClick={this.addUser}
              disabled={!userId}
            >
              Add
            </Button>
          </div>
        </Form>
        <Divider />
        <Header as='h2'>Create New User</Header>
        <Form>
          <Form.Field>
            <label htmlFor='firstName'>FIRST NAME</label>
            <Input
              placeholder='First Name'
              name='firstName'
              value={firstName}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor='lastName'>LAST NAME</label>
            <Input
              placeholder='Last Name'
              name='lastName'
              value={lastName}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor='email'>EMAIL</label>
            <Input
              placeholder='Email'
              name='email'
              onChange={this.handleChange}
              value={email}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor='type'>TYPE</label>
            <Dropdown
              placeholder='User Type'
              fluid
              search
              selection
              name='type'
              onChange={this.handleChange}
              value={type}
              options={userTypeOptions}
            />
          </Form.Field>
          <div className='primaryActions'>
            <Button basic size='big' onClick={this.backToGroupDetails}>
              Cancel
            </Button>
            <Button
              basic
              type='button'
              color='blue'
              size='big'
              onClick={this.createUser}
            >
              Create
            </Button>
          </div>
        </Form>
      </>
    )
  }
}

const mapStateToProps = ({ users }) => ({
  users: get(users, 'result.data', [])
})

const mapDispatchToProps = dispatch => {
  return {
    goTo: url => dispatch({ type: 'REDIRECT', url }),
    getUsers: () => dispatch({ type: 'FETCH_USERS_REQUESTED' }),
    addUserToGroup: (userIds, groupId) =>
      dispatch({ type: 'ADD_USER_GROUP_REQUESTED', groupId, userIds }),
    createUser: payload =>
      dispatch({ type: 'CREATE_GROUP_USER_REQUESTED', payload })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUser)
