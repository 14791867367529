import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

import { Table, Dropdown, Confirm, Icon } from 'semantic-ui-react';

import UpdatedAt from './updatedAt';
import TopBar from './TopBar';
import ItemStatus from '../common/item-status';
import ContentLoader from '../common/content-loader';

class Wheels extends Component {
  state = {
    wheel: {},
    search: '',
    includeArchived: false,
    selectedId: null
  };

  UNSAFE_componentWillReceiveProps(props) {
    const { getWheels, wheelsResult, duplicateWheelResult, history } = props;
    const { search, includeArchived } = this.state;

    if (
      this.props.wheelsResult !== wheelsResult &&
      wheelsResult.isDelete &&
      wheelsResult.result &&
      !wheelsResult.error
    ) {
      getWheels({ search, includeArchived });
    }

    if (
      this.props.duplicateWheelResult.requesting &&
      !duplicateWheelResult.requesting
    ) {
      getWheels({ search, includeArchived });
    }
  }

  componentDidMount() {
    const { search, includeArchived, getWheels } = this.props;
    getWheels({ search, includeArchived });
  }

  onSearch = newSearch => {
    const { search, includeArchived } = this.state;
    const { getWheels } = this.props;

    if (newSearch !== search) {
      this.setState({ search: newSearch });
      getWheels({ search: newSearch, includeArchived });
    }
  };

  toggleArchived = checked => {
    const { search, includeArchived } = this.state;
    const { getWheels } = this.props;

    if (checked !== includeArchived) {
      this.setState({ includeArchived: checked });
      getWheels({ search, includeArchived: checked });
    }
  };

  componentDidUpdate(prevProps) {
    const { token } = this.props;
    const { selectedId } = this.state;

    if (token !== prevProps.token && token && selectedId) {
      this.form.submit();
    }
  }

  downloadCSV = id => {
    this.setState({ selectedId: id });
    this.props.getToken();
  };

  rowActionClick = (event, data) => {
    this.setState({ showArchiveConfirmation: true, wheelId: data.value });
  };

  finishAction = () => {
    const { wheelId } = this.state;
    const { restore, archive, wheels } = this.props;

    const wheel = wheels.find(i => i._id === wheelId);

    wheel && wheel.archived ? restore(wheel._id) : archive(wheel._id);

    this.setState({ showArchiveConfirmation: false });
  };

  content = () => {
    const { wheels } = this.props;
    const { wheelId } = this.state;

    const selected = wheels && wheels.find(i => i._id === wheelId);

    if (!selected) return {};

    return {
      header: (selected.archived ? 'Restore' : 'Archive') + ' wheel',
      content: (
        <div className='content'>
          Are you sure you want to {selected.archived ? 'restore' : 'archive'}{' '}
          <strong>{selected.title}</strong>?
        </div>
      )
    };
  };

  render() {
    const {
      wheels,
      goTo,
      wheelsResult,
      duplicateWheelResult,
      token,
      user
    } = this.props;
    const {
      showArchiveConfirmation,
      search,
      includeArchived,
      selectedId
    } = this.state;
    const { header, content } = this.content();

    return (
      <>
        <Confirm
          open={showArchiveConfirmation}
          header={header}
          content={content}
          onCancel={() => this.setState({ showArchiveConfirmation: false })}
          onConfirm={this.finishAction}
        />
        <TopBar
          search={search}
          includeArchived={includeArchived}
          onSearch={this.onSearch}
          toggleArchived={this.toggleArchived}
          onNew={() => goTo('/new-wheel')}
          newText='New Wheel'
          hideArchiveToggle={user.type === 'C'}
          hideNewButton={user.type === 'C'}
        />
        <form
          target='_blank'
          action={`/api/v1/wheels/${selectedId}/csv?token=${token}`}
          method='POST'
          ref={el => {
            this.form = el;
            return null;
          }}
        />
        <ContentLoader
          loading={wheelsResult.requesting || duplicateWheelResult.requesting}
          empty={wheels && !wheels.length}
        >
          <Table padded='very' basic='very' id='wheelsList'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Last Edited By</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {wheels.map(row => (
                <Table.Row key={row._id}>
                  <Table.Cell className='tableTitle'>
                    <Link to={`/new-wheel/${row._id}`}>{row.title}</Link>
                  </Table.Cell>
                  <Table.Cell>
                    <ItemStatus archived={row.archived} />
                  </Table.Cell>
                  <Table.Cell>
                    {row.lastEditedBy &&
                      `${row.lastEditedBy.firstName} ${row.lastEditedBy.lastName}`}
                    <UpdatedAt timestamp={row.updatedAt} />
                  </Table.Cell>
                  <Table.Cell className='actionsCell'>
                    <Dropdown text='Actions' item>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => goTo(`/new-wheel/${row._id}`)}
                        >
                          Edit
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={() =>
                            window.open(`/wheel-preview/${row._id}`)
                          }
                        >
                          Preview
                          <Icon
                            name='external'
                            size='small'
                            className='voltActionMenuPreviewIcon'
                          />
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => this.downloadCSV(row._id)}
                        >
                          Export Data
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={this.rowActionClick}
                          value={row._id}
                        >
                          {row.archived ? 'Restore' : 'Archive'}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ContentLoader>
      </>
    );
  }
}

const mapStateToProps = ({
  tokenValidation,
  wheels,
  duplicateWheel,
  token
}) => ({
  wheelsResult: wheels,
  wheels:
    wheels.result && Array.isArray(wheels.result.data)
      ? wheels.result.data
      : [],
  duplicateWheelResult: duplicateWheel,
  token: get(token, 'result.data.token', ''),
  user: get(tokenValidation, 'result.data')
});

const mapDispatchToProps = dispatch => ({
  goTo: url => dispatch({ type: 'REDIRECT', url }),
  getWheels: query => dispatch({ type: 'FETCH_WHEELS_REQUESTED', query }),
  archive: id => dispatch({ type: 'ARCHIVE_WHEEL_REQUESTED', id }),
  restore: id => dispatch({ type: 'RESTORE_WHEEL_REQUESTED', id }),
  duplicateWheel: id => dispatch({ type: 'DUPLICATE_WHEEL_REQUESTED', id }),
  getToken: payload => dispatch({ type: 'GET_TOKEN_REQUESTED', payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(Wheels);
