import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Notifications from './notifications';
import './styles.scss';

import Account from '../account';
import { AdminControls } from '../admin-controls';
import Dashboard from '../dashboard';
import { DiagnosticAndSurveyPreview } from '../diagnostic-and-survey-preview';
import Diagnostic from '../DiagnosticDetails';
import NewDiagnostic from '../DiagnosticDetails/CreateDiagnostic';
import Entry from '../entry';
import { GroupDetails } from '../group';
import Header from '../header';
import Inventory from '../inventory/inventory';
import NewInventory from '../inventory/newInventory';
import Modals from '../modals';
import NewSurvey from '../new-survey';
import NewUser from '../new-user';
import NewWheel from '../new-wheel';
import { RoutePaths } from '../_routing/route-paths';
import Survey from '../survey';
import { WheelPreview } from '../wheel-preview';

export const Home: React.FC = () => (
  <div className='homeComponent'>
    <Header />
    <Notifications />
    <Modals />
    <div className='container'>
      <div className='homeContent'>
        <Switch>
          <Route path={RoutePaths.AdminControls} component={AdminControls} />
          <Route exact path={RoutePaths.Account} component={Account} />
          <Route exact path={RoutePaths.NewSurvey} component={NewSurvey} />
          <Route
            exact
            path={`${RoutePaths.NewSurvey}/:id`}
            component={NewSurvey}
          />
          <Route
            exact
            path={RoutePaths.NewInventory}
            component={NewInventory}
          />
          <Route path={`${RoutePaths.Inventory}/:id`} component={Inventory} />
          <Route path={`${RoutePaths.Entry}/:id`} component={Entry} />
          <Route path={RoutePaths.Entry} component={Entry} />
          <Route
            exact
            path={RoutePaths.NewDiagnostic}
            component={NewDiagnostic}
          />
          <Route
            path={`${RoutePaths.NewDiagnostic}/:id`}
            component={NewDiagnostic}
          />
          <Route
            path={`${RoutePaths.DiagnosticPreview}/:diagnosticId`}
            component={DiagnosticAndSurveyPreview}
          />
          <Route path={`${RoutePaths.Diagnostic}/:id`} component={Diagnostic} />
          <Route path={`${RoutePaths.Survey}/:id`} component={Survey} />
          <Route
            path={`${RoutePaths.SurveyPreview}/:surveyId`}
            component={DiagnosticAndSurveyPreview}
          />
          <Route exact path={RoutePaths.NewUser} component={NewUser} />
          <Route exact path={`${RoutePaths.User}/:id`} component={NewUser} />
          <Route exact path={RoutePaths.NewWheel} component={NewWheel} />
          <Route
            exact
            path={`${RoutePaths.NewWheel}/:id`}
            component={NewWheel}
          />
          <Route
            path={`${RoutePaths.WheelPreview}/:wheelId`}
            component={WheelPreview}
          />

          <Route path={`${RoutePaths.Groups}/:id`} component={GroupDetails} />
          <Route path={RoutePaths.Home} component={Dashboard} />
        </Switch>
      </div>
    </div>
  </div>
);
