import axios from 'axios';

import { IInventoryCreatePayload } from 'redux/types/inventories.types';

export const convertSurveyToSchemaSurvey = ({
  additionalCoverage,
  comparators,
  diagnostic,
  disabled,
  disclaimer,
  dueDate,
  email,
  emailList,
  factors,
  hideProductDiseaseInfo,
  hideAdditionalCoverage,
  hideBackgroundQuestions,
  hideDisclaimer,
  message,
  sender,
  subject,
  title
}) => {
  return {
    additionalCoverage,
    comparators,
    diagnostic: typeof diagnostic === 'object' ? diagnostic._id : diagnostic,
    disabled,
    disclaimer,
    dueDate,
    email,
    emailList,
    factors,
    hideProductDiseaseInfo,
    hideAdditionalCoverage,
    hideBackgroundQuestions,
    hideDisclaimer,
    message,
    sender: typeof sender === 'object' ? sender._id : sender,
    subject,
    title
  };
};

const getInstance = () => {
  return axios.create({
    baseURL: `${process.env.REACT_APP_API}/api/v1`,
    headers: {
      'x-access-token': window.localStorage.accessToken || ''
    }
  });
};

const api = {
  login: payload => {
    return getInstance().post('/auth', payload);
  },
  checkLogin: () => {
    return getInstance().post('/check-login');
  },
  logout: () => {
    return getInstance().post('/logout');
  },
  signUp: payload => {
    return getInstance().post('/sign-up', payload);
  },
  validateToken: () => {
    return getInstance().get('/me');
  },
  getSectors: query => {
    return getInstance().get('/sectors', { params: query });
  },
  createSector: payload => {
    return getInstance().post('/sectors', payload);
  },
  updateSector: (payload, id) => {
    return getInstance().put(`/sectors/${id}`, payload);
  },
  createAssetInventory: (payload: IInventoryCreatePayload) => {
    return getInstance().post('/inventory', payload);
  },
  getAssetInventories: query => {
    return getInstance().get('/inventory', { params: query });
  },
  getInventoryDetails: (id: string) => {
    return getInstance().get(`/inventory/${id}`);
  },
  updateInventory: (payload, id) => {
    return getInstance().put(`/inventory/${id}`, payload);
  },
  updateInventoryProps: (payload, id) => {
    return getInstance().post(`/inventory/${id}`, payload);
  },
  archiveInventory: id => {
    return getInstance().post(`/inventory/${id}/archive`);
  },
  restoreInventory: id => {
    return getInstance().put(`/inventory/${id}/restore`);
  },
  duplicateInventory: id => {
    return getInstance().post(`/inventory/${id}/duplicate`);
  },
  getInventoryEntry: (id: string) => {
    return getInstance().get(`/entries/${id}`);
  },
  getInventoryEntries: (inventoryId: string, query: { entryId: string }) => {
    return getInstance().get(`/inventory/${inventoryId}/entries`, {
      params: query
    });
  },
  createInventoryEntry: (payload: any) => {
    return getInstance().post(`/entries`, payload);
  },
  updateDiagnostic: (payload, id) => {
    return getInstance().put(`/diagnostic/${id}`, payload);
  },
  createDiagnostic: payload => {
    return getInstance().post('/diagnostic', payload);
  },
  archiveDiagnostic: id => {
    return getInstance().post(`/diagnostic/${id}/archive`);
  },
  restoreDiagnostic: id => {
    return getInstance().put(`/diagnostic/${id}/restore`);
  },
  getDiagnostics: query => {
    return getInstance().get('/diagnostic', { params: query });
  },
  getDiagnosticDetails: id => {
    return getInstance().get(`/diagnostic/${id}`);
  },
  getSurveysByDiagnostic: id => {
    return getInstance().get(`/diagnostic/${id}/surveys`);
  },
  duplicateDiagnostic: id => {
    return getInstance().post(`/diagnostic/${id}/duplicate`);
  },
  createSurvey: payload => {
    return getInstance().post('/survey', payload);
  },
  updateSurvey: (payload, id) => {
    return getInstance().put(`/survey/${id}`, payload);
  },
  updateSurveyProps: (payload, id) => {
    return getInstance().post(`/survey/${id}`, payload);
  },
  getSurveys: query => {
    return getInstance().get('/survey', { params: query });
  },
  getSurveyDetails: id => {
    return getInstance().get(`/survey/${id}`);
  },
  getSurveyInvites: query => {
    return getInstance().get('/survey-invites', { params: query });
  },
  updateSurveyInvite: (payload, id) => {
    return getInstance().put(`/survey-invites/${id}`, payload);
  },
  deleteSurveyInvite: id => {
    return getInstance().delete(`/survey-invites/${id}`);
  },
  submitSurvey: payload => {
    return getInstance().post('/survey-response', payload);
  },
  getSurveyResponse: (id, query) => {
    return getInstance().get(`/survey/${id}/survey-response`, {
      params: query
    });
  },
  archiveSurvey: id => {
    return getInstance().post(`/survey/${id}/archive`);
  },
  restoreSurvey: id => {
    return getInstance().put(`/survey/${id}/restore`);
  },
  duplicateSurvey: id => {
    return getInstance().post(`/survey/${id}/duplicate`);
  },
  getUsers: query => {
    return getInstance().get('/user', { params: query });
  },
  getLoggedInUsers: () => {
    return getInstance().get('/user/logged-in');
  },
  getSurveyResponsesSummary: id => {
    return getInstance().get(`/survey/${id}/responses-summary`);
  },
  getUserDetails: id => {
    return getInstance().get(`/user/${id}`);
  },
  createUser: payload => {
    return getInstance().post('/user', payload);
  },
  createUsers: payload => {
    return getInstance().post('/user/collection', payload);
  },
  getUserByToken: token => {
    return getInstance().get(`/user/by-token/${token}`);
  },
  updateUser: (payload, id) => {
    return getInstance().put(`/user/${id}`, payload);
  },
  forceLogOutUser: userId => {
    return getInstance().post(`/user/${userId}/force-log-out`);
  },
  inviteUser: userId => {
    return getInstance().post(`/user/${userId}/send-invite`);
  },
  getResetLink: userId => {
    return getInstance().get(`/user/${userId}/get-reset-link`);
  },
  createEmailList: payload => {
    return getInstance().post('/email-list', payload);
  },
  updateEmailList: (payload, id) => {
    return getInstance().put(`/email-list/${id}`, payload);
  },
  getEmailLists: () => {
    return getInstance().get('/email-list');
  },
  createSurveySender: payload => {
    return getInstance().post('/survey-sender', payload);
  },
  updateSurveySender: (payload, id) => {
    return getInstance().put(`/survey-sender/${id}`, payload);
  },
  getSurveySenders: () => {
    return getInstance().get('/survey-sender');
  },
  sendForgotPassword: payload => {
    return getInstance().post('/forgot-password', payload);
  },
  resetPassword: payload => {
    return getInstance().post('/reset-password', payload);
  },
  changePassword: payload => {
    return getInstance().post('/me/password', payload);
  },
  createDimension: payload => {
    return getInstance().post('/dimensions', payload);
  },
  updateDimension: (payload, id) => {
    return getInstance().put(`/dimensions/${id}`, payload);
  },
  deleteDimension: id => {
    return getInstance().delete(`/dimensions/${id}`);
  },
  createFactor: payload => {
    return getInstance().post('/factors', payload);
  },
  getFactor: id => {
    return getInstance().get(`/factors/${id}`);
  },
  updateFactor: (payload, id) => {
    return getInstance().put(`/factors/${id}`, payload);
  },
  deleteFactor: id => {
    return getInstance().delete(`/factors/${id}`);
  },
  createWheel: payload => {
    return getInstance().post('/wheels', payload);
  },
  deleteWheel: id => {
    return getInstance().delete(`/wheels/${id}`);
  },
  updateWheel: (payload, id) => {
    return getInstance().put(`/wheels/${id}`, payload);
  },
  getWheels: query => {
    return getInstance().get('/wheels', { params: query });
  },
  getWheel: id => {
    return getInstance().get(`/wheels/${id}`);
  },
  archiveWheel: id => {
    return getInstance().post(`/wheels/${id}/archive`);
  },
  restoreWheel: id => {
    return getInstance().put(`/wheels/${id}/restore`);
  },
  duplicateWheel: id => {
    return getInstance().post(`/wheels/${id}/duplicate`);
  },
  getAppMaintenance: () => {
    return getInstance().get(`/app/maintenance`);
  },
  getAppSettings: () => {
    return getInstance().get('/app/settings');
  },
  activateAppMaintenance: payload => {
    return getInstance().post(`app/activate-maintenance`, payload);
  },
  deactivateAppMaintenance: payload => {
    return getInstance().post(`app/deactivate-maintenance`, payload);
  },
  getEmailTemplates: () => {
    return getInstance().get(`/app/email-templates`);
  },
  updateEmailTemplates: payload => {
    return getInstance().post(`app/email-templates`, payload);
  },
  getToken: payload => {
    return getInstance().post('/token', payload);
  },
  getAssets: (objectId, type) => {
    return getInstance().get('/asset', { params: { objectId, type } });
  },
  createAsset: (payload, file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('survey', payload.objectId);
    formData.append('order', payload.order);
    formData.append('type', payload.type);
    formData.append('additional', JSON.stringify(payload.additional || {}));

    return getInstance().post('/asset', formData);
  },
  updateAsset: (assetId, payload) => {
    return getInstance().patch(`/asset/${assetId}`, payload);
  },
  deleteAsset: assetId => {
    return getInstance().delete(`/asset/${assetId}`);
  },
  deleteEntry: (inventoryId, factorId, entryIndex) => {
    return getInstance().delete(
      `/inventory/${inventoryId}/entries/${factorId}/${entryIndex}`
    );
  },
  updateEntry: (id, payload) => {
    return getInstance().put(`/entries/${id}`, payload);
  },
  updateFactors: payload => {
    return getInstance().put('/factors', payload);
  }
};

export default api;
