import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { Form, Input } from 'semantic-ui-react'

import Button from '../../common/Button'

const WheelSettings = ({ wheel, getWheel, wheelData, wheels, goTo, updateWheel }) => {
  const [title, setTitle] = useState('')

  useEffect(() => {
    getWheel(wheel)
  }, [wheel])

  useEffect(() => {
    setTitle(wheelData.title || '')
  }, [wheelData])

  const saveWheel = async () => {
    await updateWheel({ title }, wheel)
    getWheel(wheel)
  }

  return (
    <Form onSubmit={() => saveWheel()}>
      <Form.Field>
        <div className='fieldLabel'>
          <label>Title</label>
          <label>*REQUIRED</label>
        </div>
        <Input
          placeholder='Title'
          name='title'
          value={title}
          required
          onChange={(e, data) => setTitle(data.value)}
        />
      </Form.Field>

      <div className='buttonsContainer'>
        <Button
          color='white'
          rounded
          disabled={wheels.requesting}
          onClick={() => goTo('/wheels')}
        >
          Cancel
        </Button>
        <Button
          color='black'
          rounded
          shadowed
          disabled={wheels.requesting}
          type='submit'
        >
          Save
        </Button>
      </div>
    </Form>
  )
}

const mapStateToProps = ({ wheels }) => ({
  wheels,
  wheelData: get(wheels, 'result.data', {})
})

const mapDispatchToProps = dispatch => ({
  goTo: url => dispatch({ type: 'REDIRECT', url }),
  getWheel: id => dispatch({ type: 'FETCH_WHEEL_REQUESTED', id }),
  updateWheel: (payload, id) =>
    dispatch({ type: 'UPDATE_WHEEL_REQUESTED', payload, id })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WheelSettings)
