import React from 'react'
import { Label } from 'semantic-ui-react'

const ItemStatus = ({ archived, inactive }) => {
  if (archived) {
    return <Label color='red'>Archived</Label>
  }

  if (inactive) {
    return <Label color='black'>Inactive</Label>
  }

  return <Label color='green'>Active</Label>
}

export default ItemStatus
