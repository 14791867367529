import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Icon } from 'semantic-ui-react'

const shouldShowChildren = (selected, parentId, children) => {
  if (!children) {
    return false
  }

  if (selected._id === parentId) {
    return true
  }

  if (selected.parent && selected.parent._id === parentId) {
    return true
  }

  return false
}

const GroupNavigation = ({ selected, groups, onClick }) => (
  <>
    {Object.entries(groups).map(([id, { _id, name, children }]) => (
      <>
        <Menu.Item
          key={_id}
          className='parentMenuItem'
          name='group'
          onClick={onClick}
          active={selected._id === id}
        >
          <Link to={`/groups/${id}`}>
            <div className='menuItem'>
              <div className='group'>{name}</div>
              {!!children.length && (
                <Icon
                  name={`caret ${
                    selected._id === id ||
                    (selected.parent && selected.parent._id === id)
                      ? 'down'
                      : 'right'
                  }`}
                />
              )}
            </div>
          </Link>
        </Menu.Item>

        {shouldShowChildren(selected, id, children) &&
          children.map(({ _id: childId, name: childName }) => (
            <Menu.Item
              className='childMenuItem'
              key={childId}
              name='childGroup'
              onClick={onClick}
              active={selected._id === childId || selected.parent === childId}
            >
              <Link to={`/groups/${childId}`}>
                <div className='childGroup'>{childName}</div>
              </Link>
            </Menu.Item>
          ))}
      </>
    ))}
  </>
)

export default GroupNavigation
