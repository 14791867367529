import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Modal } from 'semantic-ui-react'

import Button from '../common/Button'

class SurveySender extends Component {
  state = {
    name: '',
  }

  saveSurveySender = () => {
    if (this.props.sender) {
      this.props.updateSurveySender(
        {
          name: this.state.name,
        },
        this.props.sender._id
      )
    } else {
      this.props.createSurveySender({
        name: this.state.name,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.show && !this.props.show) {
      if (props.sender) {
        this.setState({
          name: props.sender.name,
        })
      } else {
        this.setState({
          name: '',
        })
      }
    }

    if (
      props.surveySender !== this.props.surveySender &&
      props.surveySender.isCreate &&
      !props.surveySender.error
    ) {
      this.props.getSurveySenders()
      this.props.onClose()
    }
  }

  render() {
    const { onClose, show, sender } = this.props

    return (
      <Modal open={show} onClose={onClose} centered={false}>
        <Modal.Header>
          {sender ? 'Update' : 'Create new'} survey sender
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>NAME</label>
              <Input
                placeholder='Name'
                name='name'
                onChange={(e, data) => this.setState({ name: data.value })}
                value={this.state.name}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button rounded shadowed onClick={this.saveSurveySender}>
            Save
          </Button>
          <Button rounded color='white' onClick={onClose}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const mapStateToProps = ({ surveySender }) => ({ surveySender })

const mapDispatchToProps = (dispatch) => ({
  updateSurveySender: (payload, id) =>
    dispatch({ type: 'UPDATE_SURVEY_SENDER_REQUESTED', payload, id }),
  createSurveySender: (payload) =>
    dispatch({ type: 'CREATE_SURVEY_SENDER_REQUESTED', payload }),
  getSurveySenders: () => dispatch({ type: 'FETCH_SURVEY_SENDERS_REQUESTED' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SurveySender)
