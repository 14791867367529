import React, { Component } from 'react'
import { Icon, Header, Divider } from 'semantic-ui-react'

import './task-line.scss'

export default class TaskLine extends Component {
  showStepCircle(step, stepPosition) {
    const { surveyNavigation } = this.props

    let voltStepClass = ''

    if (step.status === 'done') {
      voltStepClass = 'done'
    } else if (step.status === 'completed') {
      voltStepClass = 'completed'
    } else if (surveyNavigation.latestStepPosition === stepPosition) {
      voltStepClass = 'current'
    }

    return (
      <div>
        <div className={`voltStepCircle ${voltStepClass}`}>
          {['completed', 'done'].includes(step.status) && <Icon name='check' />}
        </div>
        <div className={`voltStepName ${voltStepClass}`}>{step.name}</div>
      </div>
    )
  }

  render() {
    const { surveyNavigation } = this.props

    return (
      <div className='voltTaskLine'>
        <Header as='h3'>Your Task Line</Header>

        <div className='voltTaskLineComponent'>
          <div className='voltTaskLineNavigationLine' />

          {surveyNavigation.steps.map((step, stepPosition) => {
            return (
              step.appearInTaskLine && (
                <div className='step' key={stepPosition}>
                  {this.showStepCircle(step, stepPosition)}
                </div>
              )
            )
          })}
        </div>
        <Divider />
      </div>
    )
  }
}
