const defaultValuesForFactors = {
  Context: {
    rating: {
      question: 'How would you rate this disease/condition on this factor?',
      options: [
        '[One of the least prevalent/most rare (e.g., Gaucher Disease)]',
        '',
        '',
        'Midpoint',
        '',
        '',
        '[One of the most prevalent (e.g., diabetes)]'
      ]
    },
    competitors: {
      question: '',
      options: ['', '', '', '', '']
    }
  },
  Clinical: {
    rating: {
      question: '',
      options: ['', '', '', '', '']
    },
    competitors: {
      question:
        'How would you rate each treatment based on its performance or result on this factor?',
      options: [
        'Very unfavorable',
        '',
        'Neither favorable nor unfavorable',
        '',
        'Very favorable'
      ]
    }
  },
  'Health Economics+': {
    rating: {
      question: '',
      options: ['', '', '', '', '']
    },
    competitors: {
      question:
        'How would you rate each treatment based on its performance or result on this factor?',
      options: [
        'Very unfavorable',
        '',
        'Neither favorable nor unfavorable',
        '',
        'Very favorable'
      ]
    }
  },
  Influence: {
    rating: {
      question: '',
      options: ['', '', '', '', '']
    },
    competitors: {
      question:
        'How would you rate each treatment based on its performance or result on this factor?',
      options: ['Very Low', '', 'Midpoint', '', 'Very High']
    }
  },
  Cost: {
    rating: {
      question: '',
      options: ['', '', '', '', '']
    },
    competitors: {
      question:
        'How would you rate each treatment based on its performance or result on this factor?',
      options: [
        'Very unfavorable',
        '',
        'Neither favorable nor unfavorable',
        '',
        'Very favorable'
      ]
    }
  },
  default: {
    rating: {
      question: '',
      options: ['', '', '', '', '']
    },
    competitors: {
      question: '',
      options: ['', '', '', '', '']
    }
  }
}

export function getDefaultValueForFactor(sector, questionType, fieldType) {
  if (!defaultValuesForFactors[sector]) {
    sector = 'default'
  }

  return defaultValuesForFactors[sector][questionType][fieldType]
}
