const defaultState = {
  requesting: false,
  error: false,
  result: null,
  isCreate: false,
  isDelete: false,
};

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'FETCH_SURVEY_INVITES_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false,
        isDelete: false,
      }
    case 'FETCH_SURVEY_INVITES_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false,
        isDelete: false,
      }
    case 'FETCH_SURVEY_INVITES_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false,
        isDelete: false,
      }
    case 'UPDATE_SURVEY_INVITE_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: true,
        isDelete: false
      }
    case 'UPDATE_SURVEY_INVITE_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true,
        isDelete: false
      }
    case 'UPDATE_SURVEY_INVITE_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true,
        isDelete: false
      }
    case 'DELETE_SURVEY_INVITE_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false,
        isDelete: false,
      }
    case 'DELETE_SURVEY_INVITE_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false,
        isDelete: true,
      }
    case 'DELETE_SURVEY_INVITE_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false,
        isDelete: true,
      }
    default:
      return state
  }
}
