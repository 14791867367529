import React, { useEffect, useReducer, useState } from 'react';
import { IWheelDto } from 'types/dto/wheel.dto';

import { RootContext } from './RootContext.types';

export const RootContextProvider: React.FC<{ children: any }> = ({ children }) => { 
  const [wheelsWithInventories, setWheelsWithInventories] = useState<IWheelDto[]>([]);

  const value = {
    wheelsWithInventories,
    setWheelsWithInventories
  };

  return (
    <RootContext.Provider value={value}>
      { children }
    </RootContext.Provider>
  );
};