import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'

import Sector from './sector'
import constants from './constants'

class Wheel extends Component {
  width = 1050
  height = 1050
  interval = null

  state = {
    selectedSector: null,
    angle: 0,
    totalSectors: 0,
    totalDimensions: 0,
    totalFactors: 0,
    totalElements: 0,
    sectorPadding: 0,
    meta: {},
    showPopup: false,
    selectedFactor: null,
    selectedDimensions: {}
  }

  selectSector = id => {
    if (this.state.selectedSector === id) {
      return this.setState({
        selectedSector: id
      })
    }
    return this.setState({
      selectedSector: id,
      selectedDimensions: {}
    })
  }

  componentDidMount() {
    this.updateState({}, {})
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateState(prevProps, prevState)
  }

  getSectorCenter = (sector, meta) => {
    return ((sector.totalElements - 1) / 2) * meta.factorStep
  }

  updateState = (prevProps, prevState) => {
    const {
      sectors,
      selectableSectors,
      showDimensions,
      filter,
      comparator,
      hidePopup,
      factorMap,
      preview
    } = this.props
    const { selectedSector } = this.state

    if (
      prevProps.sectors !== sectors ||
      prevProps.selectableSectors !== selectableSectors ||
      prevProps.showDimensions !== showDimensions ||
      prevState.selectedSector !== selectedSector ||
      prevProps.filter !== filter ||
      prevProps.comparator !== comparator ||
      prevProps.factorMap !== factorMap
    ) {
      const newState = {
        meta: {
          width: this.width,
          height: this.height,
          selectableSectors,
          showDimensions,
          factorStep: constants.factorStep,
          filter,
          comparator,
          hidePopup,
          factorMap: factorMap || {},
          preview,
          selectedSector: selectableSectors ? this.state.selectedSector : null
        },
        selectedSector: selectableSectors ? this.state.selectedSector : null,
        totalElements: 0,
        totalDimensions: 0,
        totalFactors: 0,
        totalSectors: 0,
        sectorPadding: 0
      }

      sectors.forEach(sector => {
        sector.totalFactors = 0

        newState.totalDimensions += sector.dimensions.length
        sector.dimensions.forEach(dimension => {
          newState.totalFactors += dimension.factors.length
          sector.totalFactors += dimension.factors.length
        })

        sector.totalElements =
          sector.totalFactors +
          (showDimensions ? sector.dimensions.length + 1 : 0)
        newState.totalElements += sector.totalElements

        if (sector.totalElements) {
          newState.totalSectors++
        }
      })

      let spaceLeft = 360 - newState.totalElements * newState.meta.factorStep
      if (newState.totalSectors) {
        newState.sectorPadding = spaceLeft / newState.totalSectors
      }

      if (sectors.length) {
        while (newState.sectorPadding > 8) {
          newState.meta.factorStep += 0.1
          spaceLeft = 360 - newState.totalElements * newState.meta.factorStep
          newState.sectorPadding = spaceLeft / newState.totalSectors
        }

        while (newState.sectorPadding < 1) {
          newState.meta.factorStep -= 0.1
          spaceLeft = 360 - newState.totalElements * newState.meta.factorStep
          newState.sectorPadding = spaceLeft / newState.totalSectors
          if (newState.meta.factorStep <= 1) break
        }

        if (selectedSector) {
          if (this.interval) {
            clearInterval(this.interval)
          }
          this.interval = setInterval(this.updateAngle, 50)
        } else {
          newState.angle = -90 - this.getSectorCenter(sectors[0], newState.meta)
          if (newState.angle < 0) {
            newState.angle += 360
          }
          if (newState.angle >= 360) {
            newState.angle -= 360
          }
        }
      }

      this.setState(newState)
    }
  }

  updateAngle = () => {
    const { sectors } = this.props
    const { selectedSector, meta, sectorPadding } = this.state
    let { angle } = this.state

    if (!selectedSector) {
      return clearInterval(this.interval)
    }

    let found = false
    let targetAngle = sectors.reduce((acum, sector) => {
      if (found) return acum

      if (sector._id !== selectedSector) {
        return acum - sector.totalElements * meta.factorStep - sectorPadding
      }

      found = true

      return acum - ((sector.totalElements - 1) / 2) * meta.factorStep
    }, 0)

    while (targetAngle >= 360) targetAngle -= 360
    while (targetAngle < 0) targetAngle += 360

    let speed = 20
    const leftDistance = angle + 360 - targetAngle
    const rightDistance = targetAngle - angle
    if (leftDistance < rightDistance) speed = -speed

    angle += speed
    if (
      (speed < 0 && angle < targetAngle) ||
      (speed > 0 && angle > targetAngle)
    )
      angle = targetAngle

    if (angle === targetAngle) {
      clearInterval(this.interval)
    }

    if (angle < 0) {
      angle += 360
    }
    if (angle >= 360) {
      angle -= 360
    }

    this.setState({ angle })
  }

  calculatePopupPosition(event) {
    const contentWidth = 1156
    const windowWidth = window.innerWidth
    const widthMargin = (windowWidth - contentWidth) / 2

    const pageX = event.pageX - widthMargin
    let widthOffset
    if (pageX < contentWidth / 2) {
      widthOffset = -50
    } else {
      widthOffset = -350
    }

    return {
      popupX: pageX + widthOffset,
      popupY: event.pageY - 50
    }
  }

  toggleFactor = (factor, event) => {
    const { hidePopup } = this.props
    if (hidePopup) return

    if (factor) {
      const { popupX, popupY } = this.calculatePopupPosition(event)

      return this.setState({
        showPopup: true,
        selectedFactor: factor,
        popupX,
        popupY
      })
    }

    this.setState({ showPopup: false })
  }

  selectDimension = (dimension, selected) => {
    const newSelection = Object.assign({}, this.state.selectedDimensions)
    newSelection[dimension._id] = selected
    this.setState({ selectedDimensions: newSelection })
  }

  render() {
    const { sectors, showDimensions, showTags, onSvgChange } = this.props
    const { meta, sectorPadding, showPopup, selectedFactor } = this.state
    let { angle } = this.state

    return (
      <div>
        <svg ref={onSvgChange} width={this.width} height={this.height}>
          <circle
            cx='50%'
            cy='50%'
            r='250'
            stroke='#adadad'
            strokeWidth='1'
            strokeDasharray='3'
            fill='none'
          />
          {sectors.map(sector => {
            const elem = (
              <Sector
                sector={sector}
                angle={angle}
                key={sector._id}
                meta={meta}
                onSectorSelect={this.selectSector}
                selectedSector={this.state.selectedSector}
                selectedDimensions={this.state.selectedDimensions}
                onFactorSelect={this.toggleFactor}
                onDimensionSelect={this.selectDimension}
                showTags={showTags}
              />
            )

            const sectorElements =
              sector.totalFactors +
              (showDimensions ? sector.dimensions.length + 1 : 0)

            if (sectorElements) {
              angle += meta.factorStep * sectorElements + sectorPadding
            }

            return elem
          })}
        </svg>
        <Segment
          style={{
            visibility: showPopup ? 'visible' : 'hidden',
            position: 'absolute',
            top: this.state.popupY,
            left: this.state.popupX,
            zIndex: 200
          }}
          className='factorPopup'
        >
          {selectedFactor && (
            <div>
              <div className='title'>{selectedFactor.name}</div>
              <div className='description'>{selectedFactor.description}</div>
              <div className='label'>Sector:</div>
              <div className='value'>
                {selectedFactor.sector && selectedFactor.sector.name}
              </div>
              <div className='label'>Dimension:</div>
              <div className='value'>{selectedFactor.dimension.name}</div>
              <div className='label'>Rating Score:</div>
              <div className='value'>
                {selectedFactor.rating} /{' '}
                {selectedFactor.sector && selectedFactor.sector.compare
                  ? '5'
                  : '7'}
              </div>
              <div className='label'>Total Responses:</div>
              <div className='value'>{selectedFactor.count}</div>
            </div>
          )}
        </Segment>
      </div>
    )
  }
}

export default Wheel
