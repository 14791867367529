import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Dropdown,
  Checkbox,
  Divider,
  Message,
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { getArray } from '../../lib/utils';

class Parameters extends Component {
  state = {};

  isSurveyExisting() {
    const surveyId = this.props.surveyId;

    if (surveyId) {
      return true;
    }

    return false;
  }

  render() {
    const {
      comparators,
      diagnostic,
      diagnostics,
      dueDate,
      hideBackgroundQuestions,
      onValueChange,
      selectDiagnostic,
      survey,
      title,
      updateComparator,
    } = this.props;

    const errors = {};

    if (
      survey.error &&
      survey.result.response &&
      survey.result.response.data.validation
    ) {
      survey.result.response.data.validation.keys.forEach(
        (key) => (errors[key] = true)
      );
    }

    return (
      <Form>
        <Form.Field>
          <label>TITLE</label>
          <Input
            placeholder='Title'
            name='title'
            onChange={(e, data) =>
              onValueChange({ field: 'title', value: data.value })
            }
            value={title}
            error={errors.title}
          />
        </Form.Field>
        <Form.Field>
          <label>DUE DATE</label>
          <DateInput
            className='dueDateInput'
            name='dueDate'
            placeholder='Due date'
            value={dueDate && moment(dueDate).format('MM/DD/YYYY')}
            iconPosition='left'
            error={errors.title}
            dateFormat='MM/DD/YYYY'
            onChange={(e, data) => {
              if (!moment(data.value).isValid()) {
                return onValueChange({
                  field: 'dueDate',
                  value: moment().format(),
                });
              }
              onValueChange({ field: 'dueDate', value: data.value });
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>SELECT EXISTING VALUE DIAGNOSTIC</label>

          <Dropdown
            placeholder='Select existing value diagnostic'
            fluid
            search
            selection
            onChange={(e, data) => selectDiagnostic(data.value)}
            value={diagnostic && diagnostic._id}
            error={errors.diagnostic}
            options={diagnostics.map((row) => ({
              text: row.title,
              value: row._id,
              key: row._id,
            }))}
            disabled={this.isSurveyExisting()}
          />
          <Message>
            <Message.Header className='light'>
              <strong>Note:</strong> you <strong>cannot</strong> change the
              diagnostic used in this survey once you sent it to respondents.
            </Message.Header>

            <p>
              <strong>
                If you need to change the diagnostic used in this survey, you
                must create a new survey.
              </strong>
              <br />
              For additional help, contact{' '}
              <a href='mailto:VOLTSupport@payersciences.com'>
                VOLT support
              </a>{' '}
              and we can provide guidance on how to organize your project.
            </p>
          </Message>
        </Form.Field>

        {diagnostic && (
          <Form.Field>
            <label>SELECT COMPARATORS</label>
            {diagnostic.inventory.comparators
              .filter(
                (comparator) =>
                  !comparator.deleted || !!comparators.includes(comparator._id)
              )
              .map((comparator, index) => (
                <div key={index}>
                  <Checkbox
                    label={comparator.name}
                    checked={!!comparators.includes(comparator._id)}
                    onChange={(e, data) =>
                      updateComparator(data.checked, comparator._id)
                    }
                    disabled={this.isSurveyExisting()}
                  />
                  <Divider />
                </div>
              ))}
          </Form.Field>
        )}
        <Form.Field>
          <label>BACKGROUND QUESTIONS</label>
          <Checkbox
            checked={hideBackgroundQuestions}
            onChange={(event, { checked }) =>
              onValueChange({
                field: 'hideBackgroundQuestions',
                value: checked,
              })
            }
            label='Do not include background questions in this survey'
          />
        </Form.Field>
      </Form>
    );
  }
}

const mapStateToProps = ({ survey, diagnostic }) => ({
  survey,
  diagnostics: getArray(diagnostic, 'fetchResult.data', []),
});

export default connect(mapStateToProps)(Parameters);
