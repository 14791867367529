import React, { useMemo, useState } from 'react'
import { Checkbox, Input, Table } from 'semantic-ui-react'
import _, { isEmpty } from 'lodash'
import './styles.scss'
import { TABS } from '../../types/dashboard.types'

export const PermissionsTable = ({
  diagnostics,
  wheels,
  diagnosticIds,
  setDiagnosticIds,
  enabledTabs
}) => {
  const [search, setSearch] = useState('')

  const wheelMap = useMemo(() => {
    if (wheels.length > 0) {
      return _.keyBy(wheels, '_id')
    }
    return {}
  }, [wheels])

  const allTabsDisabled = useMemo(() => {
    return _.isEmpty(enabledTabs);
  }, [enabledTabs]);

  const allDiagnosticsChecked = useMemo(() => {
    if (allTabsDisabled) return false
    if (isEmpty(diagnosticIds)) return false
    for (const diagnostic of diagnostics) {
      if (!diagnosticIds.includes(diagnostic._id)) return false
    }
    return true
  }, [diagnostics, diagnosticIds, allTabsDisabled])

  const someDiagnosticChecked = useMemo(() => {
    if (allTabsDisabled) return false
    if (isEmpty(diagnosticIds) || allDiagnosticsChecked) return false
    for (const diagnostic of diagnostics) {
      if (diagnosticIds.includes(diagnostic._id)) return true
    }
    return false
  }, [diagnostics, diagnosticIds, allDiagnosticsChecked, allTabsDisabled])

  const filterDiagnostics = diagnostic => {
    const searchLowerCase = search.toLowerCase()
    const wheelTitle = _.get(
      wheelMap,
      `${diagnostic.inventory.wheel}.title`,
      ''
    ).toLowerCase()

    if (wheelTitle.includes(searchLowerCase)) return true
    if (diagnostic.inventory.title.toLowerCase().includes(searchLowerCase))
      return true
    return diagnostic.title.toLowerCase().includes(searchLowerCase)
  }

  const getCellClass = (tab, enabled) => {
    if (isEmpty(enabledTabs)) return 'grayText'
    if (!enabledTabs.includes(tab)) return 'grayText'
    if (enabled) return 'greenText'
    return ''
  }

  return (
    <div className='permissionsContainer'>
      <div className='permissionSearchContainer'>
        <Input
          icon='search'
          placeholder='Search...'
          className='searchInput'
          onChange={event => {
            setSearch(event.target.value)
          }}
          value={search}
        />
      </div>
      <div className='permissionTableContainer'>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Checkbox
                  disabled={allTabsDisabled}
                  checked={allDiagnosticsChecked}
                  indeterminate={someDiagnosticChecked}
                  onChange={() => {
                    if (allDiagnosticsChecked) return setDiagnosticIds([])
                    return setDiagnosticIds(diagnostics.map(diag => diag._id))
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className={getCellClass(TABS.WHEELS, false)}>
                {TABS.WHEELS}
              </Table.HeaderCell>
              <Table.HeaderCell
                className={getCellClass(TABS.INVENTORIES, false)}
              >
                {TABS.INVENTORIES}
              </Table.HeaderCell>
              <Table.HeaderCell
                className={getCellClass(TABS.DIAGNOSTICS, false)}
              >
                {TABS.DIAGNOSTICS}
              </Table.HeaderCell>
            </Table.Row>
            {diagnostics.filter(filterDiagnostics).map(diagnostic => {
              const diagnosticIdsArray = _.cloneDeep(diagnosticIds) || []
              return (
                <Table.Row key={diagnostic._id}>
                  <Table.Cell>
                    <Checkbox
                      disabled={allTabsDisabled}
                      checked={diagnosticIdsArray.includes(diagnostic._id) && !allTabsDisabled}
                      onChange={() => {
                        const diagnosticIndex = diagnosticIdsArray.indexOf(
                          diagnostic._id
                        )
                        if (diagnosticIndex >= 0) {
                          diagnosticIdsArray.splice(diagnosticIndex, 1)
                          return setDiagnosticIds(diagnosticIdsArray)
                        }
                        setDiagnosticIds([
                          ...diagnosticIdsArray,
                          diagnostic._id
                        ])
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell
                    className={getCellClass(
                      TABS.WHEELS,
                      diagnosticIdsArray.includes(diagnostic._id)
                    )}
                  >
                    {_.get(wheelMap, `${diagnostic.inventory.wheel}.title`)}
                  </Table.Cell>
                  <Table.Cell
                    className={getCellClass(
                      TABS.INVENTORIES,
                      diagnosticIdsArray.includes(diagnostic._id)
                    )}
                  >
                    {diagnostic.inventory.title}
                  </Table.Cell>
                  <Table.Cell
                    className={getCellClass(
                      TABS.DIAGNOSTICS,
                      diagnosticIdsArray.includes(diagnostic._id)
                    )}
                  >
                    {diagnostic.title}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Header>
        </Table>
      </div>
    </div>
  )
}
