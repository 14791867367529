import React from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'

const { Item, Menu } = Dropdown

const GroupActions = ({
  group,
  group: { _id: id },
  group: { parent, archived },
  add,
  manage,
  child,
  restore,
  archive,
  onDelete
}) => (
  <Dropdown text='Actions' className='groupActionsButton' item>
    <Menu>
      <Item value={id} disabled={parent} onClick={child} content='Add Group' />
      <Item value={id} onClick={add} content='Add User' />
      <Item value={id} onClick={manage} content='Import / Export Users' />
      {archived ? (
        <Item value={id} onClick={restore} content='Restore' />
      ) : (
        <Item value={id} onClick={archive} content='Archive' />
      )}
      <Item value={group} onClick={onDelete} content='Delete' />
    </Menu>
  </Dropdown>
)

const mapDispatchToProps = dispatch => ({
  add: (e, { value: id }) =>
    dispatch({ type: 'REDIRECT', url: `/groups/${id}/new` }),
  manage: (e, { value: id }) =>
    dispatch({ type: 'REDIRECT', url: `/groups/${id}/bulk` }),
  restore: (e, { value: id }) =>
    dispatch({ type: 'RESTORE_GROUP_REQUESTED', id }),
  archive: (e, { value: id }) =>
    dispatch({ type: 'ARCHIVE_GROUP_REQUESTED', id }),
  child: (e, { value: id }) =>
    dispatch({ type: 'OPEN_MODAL', modal: 'createGroup', params: { id } })
})

export default connect(
  null,
  mapDispatchToProps
)(GroupActions)
