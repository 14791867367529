import React from 'react'
import { Button } from 'semantic-ui-react'

import './styles.scss'

const Carousel = ({ id = 'carousel', activeSlide, onChange, slides }) => (
  <div className='carouselComponent'>
    <Button
      circular
      size='large'
      color='black'
      icon='chevron left'
      disabled={activeSlide === 1}
      onClick={() => onChange({ slide: activeSlide - 1 })}
      className='carouselArrow left shadowed'
    />
    <Button
      circular
      size='large'
      icon='chevron right'
      color='black'
      disabled={activeSlide >= slides.length}
      onClick={() => onChange({ slide: activeSlide + 1 })}
      className='carouselArrow right shadowed'
    />

    {slides[activeSlide - 1]}

    <div className='carouselSteps'>
      {slides.map((slide, index) => (
        <Button
          key={`${id}_step_${index}`}
          className='carouselButton'
          color={activeSlide === index + 1 ? 'black' : 'gray'}
          onClick={() => onChange({ slide: index + 1 })}
          circular
        />
      ))}
    </div>
  </div>
)

export default Carousel
