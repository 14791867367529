import React, { useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import _ from 'lodash'

import productKey from './assets/key_product_x.svg'
import comparatorKey from './assets/key_comparator.svg'
import additionalFactorKey from './assets/key_additional_factor.svg'
import './keys.scss'

const shouldShowProductKey = (sectors, surveyNavigation, surveyResponse) => {
  const step = surveyNavigation.getCurrentStepSectorSubStep()

  const lastSector = surveyNavigation.getLatestSectorIndex()
  if (lastSector === 0) return false

  let s, d, f, dimensions, factors
  for (s = 0; s < sectors.length; s++) {
    dimensions = sectors[s].dimensions
    for (d = 0; d < dimensions.length; d++) {
      factors = dimensions[d].factors
      for (f = 0; f < factors.length; f++) {
        if (
          step !== 'otherfactors' &&
          factors[f].rating &&
          factors[f].rating >= 0 &&
          sectors[s].compare
        ) {
          return true
        }
        if (
          step === 'otherfactors' &&
          surveyResponse.factorImportance &&
          surveyResponse.factorImportance[factors[f]._id] &&
          !factors[f].highlighted &&
          factors[f].rating &&
          factors[f].rating >= 0
        ) {
          return true
        }
      }
    }
  }

  return false
}

const shouldShowComparatorKey = (sectors, surveyNavigation, surveyResponse) => {
  const step = surveyNavigation.getCurrentStepSectorSubStep()

  const lastSector = surveyNavigation.getLatestSectorIndex()
  if (lastSector === 0) return false

  let s, d, f, dimensions, factors
  for (s = 0; s < sectors.length; s++) {
    dimensions = sectors[s].dimensions
    for (d = 0; d < dimensions.length; d++) {
      factors = dimensions[d].factors
      for (f = 0; f < factors.length; f++) {
        if (
          step !== 'otherfactors' &&
          factors[f].rating &&
          factors[f].rating >= 0 &&
          sectors[s].compare
        ) {
          return true
        }
        if (
          step === 'otherfactors' &&
          surveyResponse.factorImportance &&
          surveyResponse.factorImportance[factors[f]._id] &&
          !factors[f].highlighted &&
          factors[f].rating &&
          factors[f].rating >= 0 &&
          sectors[s].compare
        ) {
          return true
        }
      }
    }
  }

  return false
}

const shouldShowRelevantKey = surveyNavigation => {
  const subStep = surveyNavigation.getCurrentStepSectorSubStep()
  const lastSector = surveyNavigation.getLatestSectorIndex()

  if (lastSector === 0) return false

  if (subStep === 'otherfactors' || subStep === 'summary') {
    return false
  }

  return lastSector > 0
}

const Keys = ({ survey, surveyNavigation, sectors, surveyResponse, onComparatorChange }) => {
  const comparators = _.get(survey, 'diagnostic.inventory.comparators', [])
  const [selectedComparator, setSelectedComparator] = useState(_.get(comparators, '[0]._id'))

  return (
    <div className='keysComponent'>
      <div>Key</div>
      {shouldShowProductKey(sectors, surveyNavigation, surveyResponse) && (
        <div>
          <img src={productKey} alt='Product Key' />{' '}
          {survey.diagnostic.inventory.product}
        </div>
      )}
      {shouldShowComparatorKey(sectors, surveyNavigation, surveyResponse) && (
        <div>
          <img src={comparatorKey} alt='Comparator Key' /> {comparators.length > 1 ? (
            <Dropdown
              placeholder='Select a comparator'
              selection
              options={comparators.map(comparator => ({
                key: comparator._id,
                value: comparator._id,
                text: comparator.name
              }))}
              value={selectedComparator}
              onChange={(e, data) => {
                setSelectedComparator(data.value)
                onComparatorChange(data.value)
              }}
            />
          ) : comparators[selectedComparator].name}
        </div>
      )}
      {shouldShowRelevantKey(surveyNavigation) && (
        <div>
          <img src={additionalFactorKey} alt='Additional Factor Key' /> You
          consider relevant
        </div>
      )}
    </div>
  )
}

export default Keys
