import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Form } from 'semantic-ui-react'

import Button from '../../common/Button'
import styles from './DataDuplication.module.scss'

const RenameModal = props => {
  const {
    open,
    onClose,
    diagnostic,
    inventory,
    wheel,
    updateDiagnosticRelationNames,
    diagnosticRelationRename,
    onUpdate
  } = props

  const [wheelTitle, setWheelTitle] = useState(wheel.title)
  const [inventoryTitle, setInventoryTitle] = useState(inventory.title)
  const [diagnosticTitle, setDiagnosticTitle] = useState(diagnostic.title)

  useEffect(() => {
    if (diagnosticRelationRename.success && onUpdate) {
      onUpdate()
    }
  }, [diagnosticRelationRename])

  useEffect(() => {
    setWheelTitle(wheel.title)
  }, [wheel])

  useEffect(() => {
    setInventoryTitle(inventory.title)
  }, [inventory])

  useEffect(() => {
    setDiagnosticTitle(diagnostic.title)
  }, [diagnostic])

  const save = () => {
    updateDiagnosticRelationNames({
      wheel: {
        _id: wheel._id,
        title: wheelTitle
      },
      inventory: {
        _id: inventory._id,
        product: inventory.product,
        audience: inventory.audience,
        indicatedPopulation: inventory.indicatedPopulation,
        indicationLabel: inventory.indicationLabel,
        category: inventory.category,
        drugClass: inventory.drugClass,
        comparators: inventory.comparators,
        customFactors: inventory.customFactors,
        wheel: inventory.wheel,
        title: inventoryTitle
      },
      diagnostic: {
        _id: diagnostic._id,
        inventory: inventory._id,
        factors: diagnostic.factors,
        title: diagnosticTitle
      }
    })
  }

  return (
    <Modal open={open} onClose={onClose} size='small' closeIcon>
      <Modal.Header>Rename</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            fluid
            label='Wheel'
            placeholder='Wheel Title'
            value={wheelTitle}
            onChange={(e, data) => setWheelTitle(data.value)}
          />
          <Form.Input
            fluid
            label='Inventory'
            placeholder='Inventory Title'
            value={inventoryTitle}
            onChange={(e, data) => setInventoryTitle(data.value)}
          />
          <Form.Input
            fluid
            label='Diagnostic'
            placeholder='Diagnostic Title'
            value={diagnosticTitle}
            onChange={(e, data) => setDiagnosticTitle(data.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions className={styles.modalButtons}>
        <Button onClick={save}>Save</Button>
        <Button color='white' onClick={onClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const mapStateToProps = ({ diagnosticRelationRename }) => ({
  diagnosticRelationRename
})

const mapDispatchToProps = dispatch => ({
  updateDiagnosticRelationNames: payload =>
    dispatch({ type: 'UPDATE_DIAGNOSTIC_RELATION_REQUESTED', payload })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenameModal)
