import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Dropdown, Input } from 'semantic-ui-react'
import get from 'lodash/get'

import Button from '../../common/Button'
import DropdownMessage from '../../common/DropdownMessage'
import './DiagnosticSettings.scss'

class DiagnosticSettings extends Component {
  state = {
    title: '',
    titleError: '',
    inventoryId: '',
    inventoryIdError: ''
  }

  componentDidMount() {
    const { getInventory, diagnostic, isNewDiagnostic } = this.props

    getInventory()

    if (diagnostic && !isNewDiagnostic) {
      this.setState({
        title: diagnostic.title,
        usedInSurvey: diagnostic.usedInSurvey,
        inventoryId: diagnostic.inventory._id
      })
    }
  }

  onInventoryChange = (e, data) => {
    this.setState({ inventoryId: data.value })
  }

  saveParameters = e => {
    e.preventDefault()

    const { inventoryId, title } = this.state
    const { diagnostic, updateDiagnostic, createDiagnostic, isNewDiagnostic } = this.props

    if (diagnostic && inventoryId && title && !isNewDiagnostic) {
      updateDiagnostic(
        {
          inventory: inventoryId,
          title
        },
        diagnostic._id
      )
    } else if (inventoryId && title && isNewDiagnostic) {
      createDiagnostic({ inventory: inventoryId, title })
    } else {
      this.setState({
        inventoryError: !inventoryId,
        titleError: !title
      })
    }
  }

  render() {
    const { goTo, inventories } = this.props

    const {
      title,
      titleError,
      inventoryId,
      inventoryIdError,
      usedInSurvey
    } = this.state

    if (!inventories || !inventories.length) return null

    return (
      <Form onSubmit={this.saveParameters} className='diagnosticSettings'>
        <Form.Field>
          <div className='fieldLabel'>
            <label>Title</label>
            <label>*REQUIRED</label>
          </div>
          <Input
            placeholder='Title'
            name='title'
            defaultValue={title}
            error={titleError}
            onChange={(e, data) => this.setState({ title: data.value })}
          />
        </Form.Field>
        <Form.Field>
          <div className='fieldLabel'>
            <label>Asset Inventory</label>
            <label>*REQUIRED</label>
          </div>
          <Dropdown
            placeholder='Select a Value Asset Inventory'
            fluid
            search
            selection
            disabled={usedInSurvey}
            error={inventoryIdError}
            onChange={this.onInventoryChange}
            options={
              inventories &&
              inventories.map(row => ({
                text: row.title,
                value: row._id,
                key: row._id,
                disabled: row.used
              }))
            }
            value={inventoryId}
          />
          <DropdownMessage items={inventories} />
        </Form.Field>
        <div className='buttons actions'>
          <Button rounded color='white' onClick={() => goTo('/diagnostics')}>
            Cancel
          </Button>
          <Button rounded shadowed onClick={this.saveParameters}>Save</Button>
        </div>
      </Form>
    )
  }
}

const mapStateToProps = ({ sectors, inventory, diagnostic }) => ({
  diagnostic: get(diagnostic, 'result.data', null),
  inventories: get(inventory, 'result.data', []),
  sectors: get(sectors, 'result.data', null)
})

const mapDispatchToProps = dispatch => ({
  goTo: url => dispatch({ type: 'REDIRECT', url }),
  getInventory: () => dispatch({ type: 'FETCH_INVENTORY_REQUESTED' }),
  createDiagnostic: payload =>
    dispatch({ type: 'CREATE_DIAGNOSTIC_REQUESTED', payload }),
  updateDiagnostic: (payload, id) =>
    dispatch({ type: 'UPDATE_DIAGNOSTIC_REQUESTED', payload, id })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiagnosticSettings)
