import React from 'react'
import PropTypes from 'prop-types'

import './Button.scss'
import { IButtonProps } from './Button.types'

const Button: React.FC<IButtonProps> = ({
  children,
  color = 'black',
  size = 'normal',
  onClick,
  className = '',
  disabled,
  rounded,
  padded,
  shadowed,
  style,
  icon,
  index,
}) => (
  <button
    style={style ? style : {}}
    className={`btn ${color} ${size} ${className} ${rounded ? 'rounded' : ''} ${
      padded ? 'padded' : ''
    } ${shadowed ? 'shadowed' : ''} ${disabled ? 'disabled' : ''}`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
)



export default Button
