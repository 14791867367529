import React from 'react'

import { Table } from '../common'
import GroupListRootItem from './GroupListRootItem'
import ExpiringLoader from './ExpiringLoader'

const GroupList = ({ items, ...rest }) => (
  <ExpiringLoader
    items={items}
    message='You have no groups yet. Click the Actions menu above to create a new group.'
  >
    <Table selectable headings={['Name', 'Status', 'Last Edited By', '']}>
      {items &&
        items.map(item => (
          <GroupListRootItem key={item._id} item={item} {...rest} />
        ))}
    </Table>
  </ExpiringLoader>
)

export default GroupList
