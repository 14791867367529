import _ from 'lodash'

export default class SurveyNavigation {
  constructor(
    updateSurveyNavigationState,
    completeSurvey,
    setupNewSectorAfterNavigation,
    saveLatestFulfilledStep,
    sectors,
    survey
  ) {
    this.updateSurveyNavigationState = updateSurveyNavigationState
    this.completeSurvey = completeSurvey
    this.setupNewSectorAfterNavigation = setupNewSectorAfterNavigation
    this.saveLatestFulfilledStep = saveLatestFulfilledStep
    this.sectors = sectors

    this.createNavigationSteps(survey)

    this.stepsCount = this.steps.length
    this.currentStepPosition = 0
    this.latestStepPosition = 0
    this.latestSectorPosition = 0
    this.currentStep = null
    this.setCurrentStepByCurrentStepPosition()
    this.hideAdditionalCoverage = survey.hideAdditionalCoverage
    this.hideBackgroundQuestions = survey.hideBackgroundQuestions
    this.hideDisclaimer = survey.hideDisclaimer
    this.hideProductDiseaseInfo = survey.hideProductDiseaseInfo
  }

  navigateToNextSubStepInSector() {
    if (this.currentStep.sectorSubStep === 'rating') {
      this.currentStep.sectorSubStep = 'additional'
      this.updateSurveyNavigationState()
    }

    const isSummary = this.currentStep.id === 'summary'
    const isInitialSubstep = this.currentStep.sectorSubStep === 'summary'
    const shouldSkipAdditionalCoverage = this.hideAdditionalCoverage

    if (isSummary && isInitialSubstep) {
      if (shouldSkipAdditionalCoverage) {
        this.navigateToNext()
      }
      this.currentStep.sectorSubStep = 'coverage'
      this.updateSurveyNavigationState()
    }
  }

  navigateToNext() {
    if (this.currentStepPosition === this.stepsCount - 1) {
      return this.completeSurvey()
    }

    if (this.currentStep.status === 'done') {
      this.currentStepPosition++
      this.updateStep()
    }
  }

  navigateToPrevious() {
    if (this.currentStepPosition > 0) {
      this.currentStepPosition--
      this.updateStep()
    }
  }

  setSectorSubStepToInitial() {
    const sectorSubStep = _.get(
      this.steps[this.currentStepPosition],
      'sectorSubStep',
      ''
    )

    if (sectorSubStep === 'additional') {
      this.steps[this.currentStepPosition].sectorSubStep = 'rating'
    }

    if (sectorSubStep === 'coverage') {
      this.steps[this.currentStepPosition].sectorSubStep = 'summary'
    }
  }

  setLatestStepPosition() {
    if (this.latestStepPosition < this.currentStepPosition) {
      this.latestStepPosition = this.currentStepPosition
    }
  }

  setLatestSectorIndex() {
    if (this.latestSectorPosition < this.currentStep.sectorIndex) {
      this.latestSectorPosition = this.currentStep.sectorIndex
    }
  }

  getLatestSectorIndex() {
    return this.latestSectorPosition
  }

  updateStep() {
    window.scrollTo(0, 0)
    this.setSectorSubStepToInitial()
    this.setCurrentStepByCurrentStepPosition()
    this.setLatestStepPosition()
    this.setLatestSectorIndex()

    const newSectorIndex = this.currentStep.sectorIndex || 0
    this.setupNewSectorAfterNavigation(newSectorIndex)

    this.updateSurveyNavigationState()
  }

  getCurrentStepId() {
    return this.currentStep.id
  }

  getCurrentStepSectorSubStep() {
    return this.currentStep.sectorSubStep
  }

  checkIfSector() {
    return this.currentStep.type === 'sector'
  }

  checkShouldHideAdditionalCoverage() {
    return this.hideAdditionalCoverage
  }

  checkIfCurrentStepIsDone() {
    return this.currentStep.status === 'done'
  }

  checkIfCurrentStepIsDoneOrCompleted() {
    return (
      this.currentStep.status === 'done' ||
      this.currentStep.status === 'completed'
    )
  }

  checkIfCurrentStepShouldShowTaskline() {
    return this.currentStep.showTaskLine
  }

  setCurrentStepToPending() {
    if (this.currentStep.status !== 'pending') {
      this.currentStep.status = 'pending'
      this.updateSurveyNavigationState()
    }
  }

  setCurrentStepToCompleted() {
    if (this.currentStep.status !== 'completed') {
      this.currentStep.status = 'completed'
      this.updateSurveyNavigationState()
    }
  }

  setCurrentStepToDone(additionalData) {
    if (this.currentStep.status !== 'done') {
      this.currentStep.status = 'done'
      this.saveLatestFulfilledStep(this.currentStep.id, additionalData)

      this.updateSurveyNavigationState()
    }
  }

  setCurrentStepByCurrentStepPosition() {
    if (this.currentStepPosition >= this.steps.length) {
      this.currentStepPosition = this.steps.length - 1
    }
    this.currentStep = this.steps[this.currentStepPosition]
  }

  setStepStatusesByLatestFullfiledStepId(stepId) {
    const latestFullfiledStepIndex = this.steps.findIndex(
      step => step.id === stepId
    )

    this.currentStepPosition = latestFullfiledStepIndex + 1
    this.latestStepPosition = this.currentStepPosition
    this.setCurrentStepByCurrentStepPosition()

    this.steps.forEach((step, index) => {
      if (index < this.currentStepPosition) {
        step.status = 'done'

        if (this.latestSectorPosition < step.sectorIndex) {
          this.latestSectorPosition = step.sectorIndex
        }
      }
    })
    this.updateStep()
  }

  createNavigationSteps(survey) {
    const {
      hideBackgroundQuestions,
      hideDisclaimer,
      hideProductDiseaseInfo
    } = survey

    this.steps = []

    if (!hideDisclaimer) {
      this.steps.push({
        id: 'welcome',
        name: 'Welcome',
        status: 'pending',
        showTaskLine: false,
        appearInTaskLine: false
      })
    }

    if (!hideBackgroundQuestions) {
      this.steps.push({
        id: 'background',
        name: 'Background',
        status: 'pending',
        showTaskLine: false,
        appearInTaskLine: false
      })
    }

    this.steps.push({
      id: 'introduction',
      name: 'Introduction',
      status: 'pending',
      showTaskLine: true,
      appearInTaskLine: true
    })

    if (!hideProductDiseaseInfo) {
      this.steps.push({
        id: 'product-and-disease-information',
        name: 'Product and Disease Information',
        status: 'pending',
        showTaskLine: true,
        appearInTaskLine: true
      })
    }

    this.sectors.forEach((sector, sectorIndex) => {
      this.steps.push({
        id: sector.name,
        sectorIndex,
        type: 'sector',
        sectorSubStep: 'rating',
        name: sector.name,
        status: 'pending',
        showTaskLine: true,
        appearInTaskLine: true
      })
    })

    this.steps.push({
      id: 'importance',
      name: 'Importance',
      status: 'pending',
      showTaskLine: true,
      appearInTaskLine: true
    })
    this.steps.push({
      id: 'otherfactors',
      type: 'sector',
      sectorSubStep: 'otherfactors',
      name: 'Other factors',
      status: 'pending',
      showTaskLine: true,
      appearInTaskLine: true,
      sectorIndex: 0
    })
    this.steps.push({
      id: 'summary',
      type: 'sector',
      sectorSubStep: 'summary',
      name: 'Summary',
      status: 'pending',
      showTaskLine: true,
      appearInTaskLine: true,
      sectorIndex: 0
    })
    this.steps.push({
      id: 'completion',
      name: 'Submit Results',
      status: 'pending',
      showTaskLine: true,
      appearInTaskLine: true
    })
  }
}
