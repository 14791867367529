import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loader, Segment, Modal, Header } from 'semantic-ui-react'
import Button from '../common/Button'
import get from 'lodash/get'

import Wheel from '../wheel'
import Factor from './factor'
import Prompt from './prompt'
import Keys from './keys'

import closeIcon from './assets/close_icon.svg'

class Sector extends Component {
  state = {
    showFactorModal: false,
    factors: [],
    accepted: false,
    totalTop5Rated: 0,
    totalImportant: 0,
    showPopup: false,
    factor: {},
    sector: {},
    dimension: {},
    previousSubStep: '',
    comparator: undefined
  }

  componentDidMount() {
    this.loadFactorMap({}, {})
  }

  componentDidUpdate(prevProps, prevState) {
    const { sector, sectorIndex } = this.props

    if (sector !== prevProps.sector) {
      this.setState({
        accepted: false,
        finished: false
      })

      if (sectorIndex > 1) {
        this.wheel.scrollIntoView({ behavior: 'smooth' })
      }
    }

    this.loadFactorMap(prevProps, prevState)
  }

  isFinished = () => {
    const { sector, surveyNavigation } = this.props

    let finished = true

    sector.dimensions.forEach(dimension => {
      dimension.factors.forEach(factor => {
        if (factor.highlighted && (!factor.rating || factor.rating === -1)) {
          finished = false
        }
      })
    })

    if (
      finished &&
      !surveyNavigation.checkIfCurrentStepIsDoneOrCompleted() &&
      surveyNavigation.getCurrentStepSectorSubStep() !== 'summary' &&
      surveyNavigation.getCurrentStepSectorSubStep() !== 'otherfactors'
    ) {
      surveyNavigation.setCurrentStepToCompleted()
    }

    return finished
  }

  showPopup = (sector, dimension, factor, event) => {
    if (event) {
      this.setState({
        showPopup: true,
        sector,
        dimension,
        factor,
        popupX: event.clientX + 20,
        popupY: event.clientY + 20
      })
    }
  }

  hidePopup = () => {
    this.setState({ showPopup: false })
  }

  setComparator = comparator => {
    this.setState({ comparator })
  }

  validateOtherFactorStep() {
    const hasAdditionalFactorsEntered = this.hasAdditionalFactorsEntered()
    const hasImportantAdditionalFactors = !!this.hasImportantAdditionalFactors()

    return hasAdditionalFactorsEntered || !hasImportantAdditionalFactors
  }

  loadFactorMap = (prevProps, prevState) => {
    const {
      surveyNavigation,
      sectors,
      sector,
      sectorIndex,
      updateFactorMap,
      setActiveFactor,
      surveyResponse
    } = this.props

    const { accepted, previousSubStep } = this.state
    const sectorSubStep = surveyNavigation.getCurrentStepSectorSubStep()

    if (
      prevProps.sectors !== sectors ||
      accepted !== prevState.accepted ||
      previousSubStep !== sectorSubStep ||
      (prevProps.surveyResponse !== surveyResponse &&
        !surveyResponse.isCreate &&
        surveyResponse.result) ||
      sector !== prevProps.sector
    ) {
      const factorMap = {}
      let importanceMap = {}
      let totalTop5Rated = 0
      let totalImportant = 0
      const top5 = {}

      if (sectorSubStep === 'otherfactors' && surveyResponse.factorImportance) {
        importanceMap = Object.assign({}, surveyResponse.factorImportance)
        sectors.forEach(sec => {
          sec.dimensions.forEach(dim => {
            dim.factors.forEach(fac => {
              if (
                fac.highlighted ||
                (importanceMap[fac._id] && !importanceMap[fac._id].importance)
              ) {
                delete importanceMap[fac._id]
              }
            })
          })
        })

        importanceMap = Object.keys(importanceMap)
          .map(key => ({
            _id: key,
            importance: surveyResponse.factorImportance[key].importance
          }))
          .sort((a, b) => {
            if (a.importance > b.importance) return -1
            if (a.importance < b.importance) return 1
            return 0
          })
          .reduce((acum, val, i) => {
            if (i < 5) {
              top5[val._id] = true
            }
            acum[val._id] = true
            return acum
          }, {})
      }

      sectors.forEach((sec, SectorIndexI) => {
        sec.dimensions.forEach((dim, dimensionIndex) => {
          dim.factors.forEach((factor, factorIndex) => {
            // add color to factor if rated and rating not -1 (unrated?)
            if (sectorSubStep === 'summary') {
              if (factor.rating && factor.rating !== -1) {
                factorMap[factor._id] = {
                  background: '#f83174',
                  text: '#fff',
                  onClick: () => {
                    this.setState({
                      showFactorModal: true,
                      factors: dim.factors
                    })
                    setActiveFactor({
                      sectorIndex: SectorIndexI,
                      dimensionIndex,
                      factorIndex
                    })
                  }
                }
              }
            } else if (sectorSubStep !== 'otherfactors') {
              if (factor.additionalFactor) {
                factorMap[factor._id] = {
                  active: true,
                  background: '#f1f2f6',
                  text: '#4e4d4d',
                  border: '#000',
                  strokeWidth: '2'
                }
              }

              if (factor.highlighted) {
                factorMap[factor._id] = {
                  highlighted: true,
                  background: '#000',
                  text: '#fff',
                  onClick: () => {
                    this.setState({
                      showFactorModal: true,
                      factors: dim.factors
                    })
                    setActiveFactor({
                      sectorIndex: SectorIndexI,
                      dimensionIndex,
                      factorIndex
                    })
                  }
                }

                // are all products rated for current factor
                const compareRated = factor.compare.reduce((acum, compare) => {
                  return acum && compare.rating && compare.rating !== '0'
                }, true)

                if (
                  factor.rating &&
                  factor.rating !== -1 &&
                  factor.rating !== '0' &&
                  compareRated
                ) {
                  factorMap[factor._id].background = '#f83174'
                  factorMap[factor._id].dot = '#f83174'
                }
              }
              if (
                sectorIndex === SectorIndexI &&
                sectorSubStep === 'additional' &&
                accepted &&
                !factor.highlighted
              ) {
                if (
                  !factorMap[factor._id] ||
                  (factorMap[factor._id] && factorMap[factor._id].hide)
                ) {
                  factorMap[factor._id] = {
                    active: false,
                    background: '#f1f2f6',
                    text: '#4e4d4d',
                    onClick: () => {
                      this.selectFactor(factor)
                    }
                  }
                } else {
                  factorMap[factor._id].onClick = () => {
                    this.selectFactor(factor)
                  }
                }
              } else if (!factor.highlighted && factorMap[factor._id]) {
                delete factorMap[factor._id].onClick
              }
            } else {
              if (importanceMap[factor._id] && !factor.highlighted) {
                totalImportant++
                factorMap[factor._id] = {
                  isTop5: top5[factor._id],
                  active: true,
                  background: '#000',
                  text: '#fff',
                  onClick: () => {
                    this.setState({
                      showFactorModal: true,
                      factors: dim.factors
                    })
                    setActiveFactor({
                      sectorIndex: SectorIndexI,
                      dimensionIndex,
                      factorIndex
                    })
                  }
                }

                if (factor.rating && factor.rating !== -1) {
                  if (top5[factor._id]) totalTop5Rated++
                  factorMap[factor._id] = {
                    isTop5: top5[factor._id],
                    background: '#f83174',
                    text: '#fff',
                    onClick: () => {
                      this.setState({
                        showFactorModal: true,
                        factors: dim.factors
                      })
                      setActiveFactor({
                        sectorIndex: SectorIndexI,
                        dimensionIndex,
                        factorIndex
                      })
                    }
                  }
                }
              }
              if (factor.highlighted) {
                factorMap[factor._id] = {
                  hide: true
                }
              }
            }

            if (sec.compare) {
              const shouldShow = factor.compare.reduce((acum, compare) => {
                return acum && compare.rating && compare.rating !== '0'
              }, factor.rating && factor.rating !== -1 && factor.rating !== '0')

              if (!factorMap[factor._id] || !factorMap[factor._id].hide) {
                factorMap[factor._id] = Object.assign(
                  {},
                  factorMap[factor._id],
                  { hide: !shouldShow }
                )
              }
            }

            factorMap[factor._id] = Object.assign({}, factorMap[factor._id], {
              onMouseOver: (event, data) =>
                this.showPopup(sec, dim, factor, event, data),
              onMouseOut: () => this.hidePopup()
            })
          })
        })
      })

      if (sectorSubStep === 'otherfactors') {
        if (this.validateOtherFactorStep()) {
          surveyNavigation.setCurrentStepToCompleted()
        } else {
          surveyNavigation.setCurrentStepToPending()
        }
        this.setState({ totalImportant, totalTop5Rated })
      }

      if (sectorSubStep !== previousSubStep) {
        this.setState({ previousSubStep: sectorSubStep })
      }

      updateFactorMap(factorMap)
    }
  }

  selectFactor = factor => {
    const { factorMap, updateFactorMap } = this.props
    const newFactorMap = Object.assign({}, factorMap)
    const fmap = newFactorMap[factor._id]
    if (fmap.active) {
      fmap.active = false
      fmap.border = ''
    } else {
      fmap.active = true
      fmap.border = '#000'
      fmap.strokeWidth = '2'
    }

    updateFactorMap(newFactorMap)
  }

  hasAdditionalFactors = () => {
    const { sector, factorMap } = this.props
    let total = 0
    sector.dimensions.forEach(dimension => {
      dimension.factors.forEach(factor => {
        if (factorMap[factor._id] && factorMap[factor._id].active) {
          total++
        }
      })
    })

    return total > 0
  }

  hasAdditionalFactorsEntered = () => {
    const { surveyResponse, factorMap } = this.props
    const { factors, factorImportance = {} } = surveyResponse

    const factorsMap =
      factors && factors.reduce((acc, val) => ({ ...acc, [val._id]: true }), {})

    return Object.keys(factorImportance)
      .filter(val => get(factorMap[val], 'isTop5', false))
      .reduce((acum, val) => acum && factorsMap[val], true)
  }

  hasImportantAdditionalFactors = () => {
    const { surveyResponse } = this.props
    const { additionalFactors = [], factorImportance = {} } = surveyResponse
    return additionalFactors.reduce(
      (acum, val) =>
        acum || (factorImportance[val] && factorImportance[val].importance),
      false
    )
  }

  saveAdditionalFactors = () => {
    const { saveAdditionalFactors } = this.props
    saveAdditionalFactors()

    this.handleSubmit()
  }

  getSectorText() {
    const { sector } = this.props

    if (sector.compare) {
      return `Let’s continue our survey with the next sector in our wheel: ${sector.name
        }.`
    }

    return `The first section of this survey is the ${sector.name} sector.`
  }

  handleSubmit() {
    const { surveyNavigation } = this.props
    surveyNavigation.setCurrentStepToDone()
    surveyNavigation.navigateToNext()
  }

  sendSummaryTime = () => {
    const { surveyNavigation, saveTime } = this.props
    saveTime()
    surveyNavigation.navigateToNextSubStepInSector()
  }

  closeModal = () => {
    this.setState({ showFactorModal: false })
  }

  render() {
    const {
      surveyNavigation,
      onSave,
      sectors,
      sector,
      sectorIndex,
      factorMap,
      survey,
      activeFactor,
      nextFactor,
      previousFactor,
      dimension,
      surveyResponse
    } = this.props

    const sectorSubStep = surveyNavigation.getCurrentStepSectorSubStep()

    const { showFactorModal, accepted, factor, comparator } = this.state
    const factors = sectors[sectorIndex].dimensions[dimension].factors

    const finished = this.isFinished()

    if (!sector) {
      return <Loader active inline='centered' />
    }

    const disease = get(survey, 'diagnostic.inventory.indicationLabel', ' ')
    const hasAdditionalFactors = this.hasAdditionalFactors()
    const hasAdditionalFactorsEntered = this.hasAdditionalFactorsEntered()
    const hasImportantAdditionalFactors = !!this.hasImportantAdditionalFactors()

    const importance = get(
      surveyResponse,
      `factorImportance[${factor._id}].importance`,
      null
    )

    return (
      <div className='surveySectorComponent'>
        <Segment
          style={{
            visibility: this.state.showPopup ? 'visible' : 'hidden',
            position: 'fixed',
            top: this.state.popupY,
            left: this.state.popupX,
            zIndex: 200
          }}
        >
          <div className='surveyPopup'>
            <Header>{factor.name}</Header>
            <p>{factor.description}</p>
            <div>
              <div>Sector:</div>
              <div>{factor.sector && factor.sector.name}</div>
            </div>
            <div>
              <div>Dimension:</div>
              <div>{factor.dimension && factor.dimension.name}</div>
            </div>
            <div>
              <div>Rating Score:</div>
              <div>
                {!factor.rating || factor.rating < 1 ? 0 : factor.rating} /{' '}
                {factor.sector && factor.sector.compare ? '5' : '7'}
              </div>
            </div>
            <div>
              <div>Importance Score:</div>
              <div>{!importance || importance < 1 ? 0 : importance}</div>
            </div>
          </div>
        </Segment>
        <div style={{ position: 'relative', marginBottom: '100px' }}>
          {sectorSubStep === 'otherfactors' &&
            !hasImportantAdditionalFactors && (
              <h4>
                Due to the responses in the previous screen, you have completed
                this step. Click the Next button to continue.
              </h4>
            )}

          {(sectorSubStep !== 'otherfactors' ||
            hasImportantAdditionalFactors) && (
              <div>
                <div className='diseaseName'>
                  <div>DISEASE</div>
                  <div>{disease}</div>

                  <Keys
                    survey={survey}
                    surveyResponse={surveyResponse}
                    surveyNavigation={surveyNavigation}
                    sectors={sectors}
                    onComparatorChange={this.setComparator}
                  />
                </div>

                <Wheel
                  sectors={sectors}
                  hidePopup={true}
                  factorMap={factorMap}
                  order='none'
                  showTags
                  ratingsCalculated
                  showChildComponents
                  compare={comparator}
                >
                  <Modal
                    open={showFactorModal}
                    closeOnDimmerClick={true}
                    onClose={this.closeModal}
                    closeIcon={false}
                    size='fullscreen'
                  >
                    <Modal.Content scrolling>
                      <div className='volt-close-modal' onClick={this.closeModal}>
                        <div className='volt-close-modal-text'>Close Window</div>
                        <img
                          src={closeIcon}
                          className='volt-close-icon'
                          alt='Close'
                        />
                      </div>

                      {activeFactor > -1 && (
                        <Segment basic padded>
                          <Factor
                            disease={disease}
                            sectors={sectors}
                            sector={sector}
                            onSave={onSave}
                            compare={sector.compare}
                            factor={factors[activeFactor]}
                            index={0}
                            nextFactor={nextFactor}
                            previousFactor={previousFactor}
                            factorMap={factorMap}
                          />
                        </Segment>
                      )}
                    </Modal.Content>
                  </Modal>
                </Wheel>
                <div ref={el => (this.wheel = el)} />
              </div>
            )}
        </div>

        {sectorSubStep === 'rating' && (
          <Prompt>
            {finished ? (
              <div className='promptInstructions sector rating finished'>
                <div>You have completed this step.</div>
                <div>When you’re ready, click Next to continue.</div>
              </div>
            ) : (
                <div className='promptInstructions sector rating not-finished'>
                  <div>{this.getSectorText()}</div>
                  <div>
                    Click on a highlighted factor to begin rating.
                  <br />
                  You must complete all highlighted factors above before you can
                  continue.
                </div>
                  <div>
                    Once you have completed this section, the Next button will be
                    active and you can move on to the next step.
                </div>
                </div>
              )}
            <div className='buttonsContainer'>
              <Button
                size='large'
                color={finished ? 'pink' : 'gray'}
                disabled={!finished}
                onClick={() => {
                  surveyNavigation.navigateToNextSubStepInSector()
                }}
              >
                Next
              </Button>
            </div>
          </Prompt>
        )}

        {sectorSubStep === 'additional' && !accepted && (
          <Prompt>
            <div className='promptInstructions sector additional not-accepted'>
              <div className='important'>
                You have rated all pre-selected factors in this sector. Now
                let’s try something else…
              </div>
              <div className='bold'>
                Take a moment to consider the other factors in this sector. Roll
                over any factor to see how it’s defined.
                <br />
                Are there any other factors in this sector you would consider
                relevant to your decision making for whether and how you choose
                to cover {disease} treatments?
              </div>
            </div>
            <div className='buttonsContainer' style={{ textAlign: 'right' }}>
              <Button
                color='pink'
                onClick={() => this.setState({ accepted: true })}
              >
                Yes
              </Button>
              <Button color='gray' onClick={() => this.handleSubmit()}>
                No
              </Button>
            </div>
          </Prompt>
        )}

        {sectorSubStep === 'additional' && accepted && (
          <Prompt>
            <div className='promptInstructions sector additional accepted'>
              <div>Click on an un-highlighted factor to select.</div>
              <div>
                Once you have completed this section, the Next button will be
                active and you can move on to the next step.
              </div>
            </div>
            <div className='buttonsContainer'>
              <Button
                size='large'
                disabled={!hasAdditionalFactors}
                color={hasAdditionalFactors ? 'pink' : 'gray'}
                onClick={() => this.saveAdditionalFactors()}
              >
                Next
              </Button>
            </div>
          </Prompt>
        )}

        {sectorSubStep === 'otherfactors' && (
          <Prompt>
            {(hasAdditionalFactorsEntered ||
              !hasImportantAdditionalFactors) && (
                <div className='promptInstructions sector otherfactors'>
                  <div>You have completed this step.</div>
                  <div>When you’re ready, click Next to continue.</div>
                </div>
              )}
            {!hasAdditionalFactorsEntered && hasImportantAdditionalFactors && (
              <div className='promptInstructions sector otherfactors'>
                <div>
                  Some of the additional factors you selected throughout this
                  survey seem very relevant in your decision-making process.
                </div>
                <div>Click on a highlighted factor to rate.</div>
                <div>
                  Once you have completed this section, the Next button will be
                  active and you can move on to the next step.
                </div>
              </div>
            )}

            <div className='buttonsContainer'>
              <Button
                size='large'
                color={
                  hasAdditionalFactorsEntered || !hasImportantAdditionalFactors
                    ? 'pink'
                    : 'gray'
                }
                disabled={
                  !hasAdditionalFactorsEntered && hasImportantAdditionalFactors
                }
                onClick={() => this.handleSubmit()}
              >
                Next
              </Button>
            </div>
          </Prompt>
        )}

        {sectorSubStep === 'summary' && (
          <Prompt>
            <div className='promptInstructions sector summary'>
              <div>
                Here’s a summary of all your ratings. Feel free to click on any
                pink factor if you wish to make any changes to your ratings.
              </div>
              <div>When you’re ready, click Next to continue.</div>
            </div>
            <div className='buttonsContainer'>
              <Button
                size='large'
                color='pink'
                onClick={() => this.sendSummaryTime()}
              >
                Next
              </Button>
            </div>
          </Prompt>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ surveyResponse }) => ({
  surveyResponse: get(surveyResponse, 'result.data', {})
})

export default connect(mapStateToProps)(Sector)
