import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import {
  Form,
  Input,
  Modal,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'
import './styles.scss'

import Button from '../common/Button'

class CreateGroupModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      groupName: null,
      isSubGroup: !!props.id,
      parent: props.id || null,
      error: {}
    }
  }

  componentDidMount = () => {
    const { getGroups } = this.props
    getGroups()
  }

  handleChange = (e, { type, name, checked, value }) => {
    const nextValue = type === 'checkbox' ? checked : value
    this.setState({ [name]: nextValue })
  }

  validate = () => {
    const { groups } = this.props
    const { groupName } = this.state

    const error = {
      groupName: ''
    }

    if (groupName === '') {
      error.groupName += "Name shouldn't be empty. "
    }

    if (groups.filter(g => g.name === groupName).length) {
      error.groupName += 'Name should be unique. '
    }

    this.setState({ error })

    return !error.groupName
  }

  onCheckboxChange = (e, { name, checked }) => {
    const { parent } = this.state

    this.setState({
      [name]: checked,
      parent: checked ? parent : null
    })
  }

  create = () => {
    const { groupName, parent } = this.state
    const { createGroup, onClose } = this.props

    const isValid = this.validate()

    if (!isValid) {
      return
    }

    createGroup({ name: groupName, parent })
    onClose()
  }

  render() {
    const { onClose, groups } = this.props
    const { name, isSubGroup, parent, error } = this.state

    const options = groups
      .filter(g => g.parent === null)
      .filter(g => !g.archived)
      .map(g => ({ text: g.name, value: g._id }))

    return (
      <Modal
        scrolling
        open={true}
        size='tiny'
        onClose={onClose}
        className='createGroup'
      >
        <Modal.Header>New Group</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description />
          <Form>
            <Form.Field>
              <label htmlFor='groupName'>
                {isSubGroup ? 'SUBGROUP NAME' : 'GROUP NAME'}
              </label>
              <Input
                id='groupName'
                placeholder={isSubGroup ? 'Subgroup Name' : 'Group Name'}
                name='groupName'
                value={name}
                onChange={this.handleChange}
                error={error['groupName']}
              />
              {error.groupName && (
                <span>
                  <i>{error.groupName}</i>
                </span>
              )}
            </Form.Field>
            {options && !!options.length && (
              <Form.Field>
                <Checkbox
                  label='Is it a subgroup?'
                  checked={isSubGroup}
                  name='isSubGroup'
                  onClick={this.onCheckboxChange}
                />
              </Form.Field>
            )}
            {isSubGroup && (
              <Form.Field>
                <label htmlFor='parent'>PARENT GROUP</label>
                <Dropdown
                  id='parent'
                  clearable
                  selection
                  name='parent'
                  value={parent}
                  placeholder='Parent Group Name'
                  options={options}
                  onChange={this.handleChange}
                />
                <div style={{ height: '220px' }} />
              </Form.Field>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button rounded color='white' onClick={onClose}>
            Cancel
          </Button>
          <Button rounded shadowed onClick={this.create}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const mapStateToProps = ({ groups }) => ({
  groups: get(groups, 'result.data', [])
})

const mapDispatchToProps = dispatch => ({
  createGroup: payload => dispatch({ type: 'CREATE_GROUP_REQUESTED', payload }),
  getGroups: () => dispatch({ type: 'FETCH_GROUPS_REQUESTED' })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateGroupModal)
