import React, { useEffect } from 'react'
import api from '../../services/api'

export const CheckLogin = () => {
  useEffect(() => {
    setInterval(() => {
      api.checkLogin()
    }, 25000)
  }, [])

  return <></>
}
