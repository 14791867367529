import React from 'react'

import Logo from './Logo'
import { NavigateToTop } from './NavigateToTop'

export default ({ appVersion = 'v0.0.0' }) => (
  <div className='appData'>
    <NavigateToTop />
    <div className='appVersion'>{appVersion}</div>
    <Logo />
  </div>
)
