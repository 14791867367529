import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import history from './history';
import createRootReducer from './reducers';

import {
  adminSaga,
  assetsSaga,
  authSaga,
  diagnosticsSaga,
  factorsSaga,
  groupsSaga,
  inventoriesSaga,
  redirectSaga,
  surveysByDiagnosticSaga,
  surveysSaga,
  tokenValidationSaga,
} from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), sagaMiddleware];

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: middlewares,
});

sagaMiddleware.run(adminSaga);
sagaMiddleware.run(assetsSaga);
sagaMiddleware.run(authSaga);
sagaMiddleware.run(diagnosticsSaga);
sagaMiddleware.run(factorsSaga);
sagaMiddleware.run(groupsSaga);
sagaMiddleware.run(inventoriesSaga);
sagaMiddleware.run(redirectSaga);
sagaMiddleware.run(surveysByDiagnosticSaga);
sagaMiddleware.run(surveysSaga);
sagaMiddleware.run(tokenValidationSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
