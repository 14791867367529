import { Component } from 'react'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import { Menu, Header, Tab, Label } from 'semantic-ui-react'

import FactorDetails from './FactorDetails'
import SideNavigation from './SideNavigation'

import './DiagnosticDetails.scss'
import DiagnosticSettings from './DiagnosticSettings'
import DiagnosticHeading from './DiagnosticHeading'

import { RoutePaths } from '../_routing/route-paths'
import { Breadcrumb } from '../page-layout/breadcrumb/Breadcrumb'

const isEmpty = (item) => !item || !item.length
const compressDimension = (acc, dimension) => acc.concat(dimension.factors)
const compressSector = (acc, sector) =>
  acc.concat(sector.dimensions.reduce(compressDimension, []))
const extractFactors = (sectors) => sectors.reduce(compressSector, [])

const Content = ({ children }) => (
  <div className='diagnosticDetails'>{children}</div>
)

class DiagnosticDetails extends Component {
  state = {
    selected: {},
    allFactors: [],
  }

  componentDidMount() {
    const { getDiagnosticDetails, match } = this.props
    getDiagnosticDetails(match.params.id)
  }

  componentDidUpdate(prevProps) {
    const { diagnostic, getSectors, sectors } = this.props

    if (diagnostic && diagnostic !== prevProps.diagnostic) {
      getSectors({ wheel: diagnostic.inventory.wheel })
    }

    if (!isEmpty(sectors) && sectors !== prevProps.sectors) {
      const allFactors = extractFactors(sectors)

      this.setState({
        selected: this.getFirstAvailableFactor(sectors),
        allFactors,
      })
    }
  }

  getFirstAvailableFactor = (sectors) => {
    for (const sector of sectors) {
      for (const dimension of sector.dimensions) {
        if (dimension.factors.length) {
          return {
            sector: sector._id,
            dimension: dimension._id,
            factor: dimension.factors[0]._id,
          }
        }
      }
    }
    return { sector: undefined, dimension: undefined, factor: undefined }
  }

  onFactorClick = ({ factorId }) => this.selectFactor(factorId)

  onFactorSelect = (factor) => {
    const { sectors } = this.props

    const dimensionId = factor.dimension
    const sector = sectors.find((s) =>
      s.dimensions.find((d) => d._id === dimensionId)
    )

    const sectorIndex = indexOf(
      sectors.map((s) => s._id),
      sector._id
    )

    this.setState({
      selected: {
        sector: sector._id,
        dimension: dimensionId,
        factor: factor._id,
      },
      activeTab: sectorIndex,
    })
  }

  selectSector = (sector) => {
    this.setState({
      selected: this.getFirstAvailableFactor([sector]),
      activeTab: undefined,
    })
  }

  selectDimension = (dimension) =>
    this.setState({
      selected: {
        ...this.state.selected,
        dimension,
      },
    })

  selectFactor = (factor) =>
    this.setState({
      selected: {
        ...this.state.selected,
        factor,
      },
    })

  selectFromLeftNavigation = (dimensionId, factorId) => {
    this.setState({
      selected: {
        ...this.state.selected,
        dimension: dimensionId,
        factor: factorId,
      },
    })
  }

  getTab = ({ label, sector, onClick }) => {
    const factors = sector.dimensions.reduce(
      (sum, dimension) => sum + dimension.factors.length,
      0
    )

    return (
      <Menu.Item onClick={onClick} key={get(sector, '_id')}>
        {label}
        <Label color={factors ? 'black' : 'grey'}>{factors}</Label>
      </Menu.Item>
    )
  }

  render() {
    const { diagnostic, sectors, token, getToken, getSectors, goTo } =
      this.props
    const { activeTab, selected, allFactors } = this.state

    if (isEmpty(sectors) || !selected.sector) return null

    const sector = sectors.find((s) => s._id === selected.sector)

    // handle situation when inventory and subsequently the wheel is changed on settings screen
    if (!sector) {
      getSectors({ wheel: diagnostic.inventory.wheel })
      return null
    }

    const dimension = sector.dimensions.find(
      (d) => d._id === selected.dimension
    )
    const factorIndex = indexOf(
      dimension.factors.map((f) => f._id),
      selected.factor
    )

    return (
      <Content>
        <Breadcrumb
          nodes={[
            { label: 'Dashboard', route: RoutePaths.Home },
            { label: 'Diagnostics', route: RoutePaths.Diagnostics },
          ]}
        />
        <Header as='h1'>{diagnostic.title}</Header>
        <Tab
          activeIndex={activeTab}
          menu={{ secondary: true, pointing: true }}
          panes={sectors
            .map((sector) => ({
              menuItem: this.getTab({
                onClick: () => this.selectSector(sector),
                label: sector.name,
                sector,
              }),
              render: () => (
                <>
                  <DiagnosticHeading
                    id={diagnostic._id}
                    token={token}
                    getToken={getToken}
                    allFactors={allFactors}
                    onSearchChange={this.select}
                    onFactorSelect={this.onFactorSelect}
                  />
                  <div className='diagnosticDetailsContent'>
                    <SideNavigation
                      id={diagnostic._id}
                      goTo={goTo}
                      sector={sectors.find((s) => s._id === selected.sector)}
                      selected={selected}
                      select={this.selectFromLeftNavigation}
                    />
                    <FactorDetails
                      sector={sector}
                      dimension={dimension}
                      shouldCompare={sector.compare}
                      factorId={selected.factor}
                      factorIndex={factorIndex}
                    />
                  </div>
                </>
              ),
            }))
            .concat([
              {
                menuItem: <Menu.Item key='settings'>Settings</Menu.Item>,
                render: () => <DiagnosticSettings />,
              },
            ])}
        />
      </Content>
    )
  }
}

export default DiagnosticDetails
