import get from 'lodash/get'
import _ from 'lodash';
import { keyBy, orderBy } from 'lodash'

const MIDDLE_RATING_FOR_CONTEXT = 4

/**
 * Function removes factors that are parities, avg, or midpoint if summary is selected
 * @param {Array} sectors - sectors produced by translateFactorsDataForWheel
 * @param {String} filter - sector review, parities etc.
 * @param {String} comparatorFilter - product we are comparing to
 * @returns sectors to display
 **/

const filterParitiesAndMidpoint = (sectors, filter, comparatorFilter) => {
  // exit early if filter is not set to summaries
  if (filter !== 'summaries') {
    return sectors
  }

  return sectors.reduce((sectors, sector) => {
    const newSector = {
      ...sector,
      dimensions: sector.dimensions.reduce((dimensions, item) => {
        const dimensionCopy = {
          ...item,
          factors: item.factors.reduce((factors, factor) => {
            const ratings =
              factor.compareScore &&
              Object.keys(factor.compareScore).map(item => ({
                comparator: item,
                value: factor.compareScore[item]
              }))

            if (ratings && ratings.length) {
              if (comparatorFilter) {
                const comparison = ratings.find(
                  item => item.comparator === comparatorFilter
                )
                // if parities don't show
                if ((comparison && comparison.value !== factor.score) || !comparison) {
                  factors.push(factor)
                }
              } else {
                const avg = calculateAverageRatingForComparators(ratings)
                // don't show parities
                if (avg !== factor.score) {
                  factors.push(factor)
                }
              }
            } else if (!sector.compare) {
              // only for context section,
              if (factor.score !== MIDDLE_RATING_FOR_CONTEXT) {
                factors.push(factor)
              }
            }

            return factors
          }, [])
        }

        // if dimension has at least one factor push
        if (dimensionCopy.factors.length) {
          dimensions.push(dimensionCopy)
        }

        return dimensions
      }, [])
    }

    if (newSector.dimensions.length) {
      sectors.push(newSector)
    }

    return sectors
  }, [])
}

const calculateAverageRatingForComparators = ratings => {
  return Math.floor(
    ratings.reduce((sum, item) => sum + item.value, 0) / ratings.length
  )
}

/**
 * Return Sectors that are transformed that Wheel can display them, if needed new ratings are calculated
 * @function translateFactorsDataForWheel
 * @param {Object} survey - all data related to survey, including diagnostic data and internal survey
 * @param {Object} surveySummary - summary of all external survey data
 * @returns {Array} of Sectors
 **/
const translateFactorsDataForWheel = (survey, surveySummary) => {
  // all ratings from surveySummary
  const surveyRatingsMap = keyBy(surveySummary, '_id')

  let factors
  if (survey.type === 'external') {
    factors = get(survey, 'diagnostic.factors')
  } else if (survey.type === 'internal') {
    factors = get(survey, 'factors')
  }

  const sectorsMap = factors.reduce((sectors, item) => {
    // all of them have include value set to true ???
    if (!item.include) {
      return sectors
    }

    const factor = get(item, '_id')
    const dimension = factor.dimension
    if (!dimension) {
      return sectors
    }
    const sector = dimension.sector

    const dimensionId = dimension._id
    const sectorId = sector._id

    // set dimensionMap object
    if (!sectors[sectorId]) {
      sectors[sectorId] = {
        ...sector,
        dimensionsMap: {}
      }
    }

    // set factors array for each dimensionId
    if (!sectors[sectorId].dimensionsMap[dimensionId]) {
      sectors[sectorId].dimensionsMap[dimensionId] = {
        ...dimension,
        factors: []
      }
    }

    const ratedFactor =
      survey.type === 'internal'
        ? { ...factor }
        : setExternalSurveyRating(surveyRatingsMap, factor)

    if (!_.find(sectors[sectorId].dimensionsMap[dimensionId].factors, {name: ratedFactor.name})) {
      sectors[sectorId].dimensionsMap[dimensionId].factors.push(ratedFactor)
    }

    return sectors
  }, {})

  const sectors = Object.keys(sectorsMap).map(sectorKey => {
    sectorsMap[sectorKey].dimensions = Object.keys(
      sectorsMap[sectorKey].dimensionsMap
    ).map(dimensionKey => {
      return sectorsMap[sectorKey].dimensionsMap[dimensionKey]
    })
    delete sectorsMap[sectorKey].dimensionsMap
    return sectorsMap[sectorKey]
  })

  return orderBy(sectors, ['order'], ['asc']);
}

/**
 * Recalculates ratings for factor
 * @function setExternalSurveyRating
 * @param {Object} surveyRatingsMap - external survey data mapped by factor
 * @param {Object} factor - factor details with diagnostic data
 * @returns {Array} of Sectors
 **/

const setExternalSurveyRating = (surveyRatingsMap, factor) => {
  const factorRating = surveyRatingsMap[factor._id]

  return {
    ...factor,
    count: factorRating ? factorRating.count : 0,
    score: factorRating ? Math.floor(factorRating.rating) : 0,
    compareScore: factorRating ? compare(factorRating.compare) : {}
  }
}

const compare = comparators => {
  const compareScore = {}

  comparators.forEach(item => {
    if (item => item.rating !== null) {
      compareScore[item._id] = item.rating
    }
  })

  return compareScore
}

const getComparators = survey => {
  if (survey.type === 'internal') {
    return get(survey, 'inventory.comparators', [])
      .filter(comparator => !comparator.deleted)
      .map(comparator => comparator._id)
  } else if (survey.type === 'external') {
    return get(survey, 'comparators', [])
  }

  return []
}

export {
  getComparators,
  filterParitiesAndMidpoint,
  translateFactorsDataForWheel as loadSectors
}
