const defaultState = {
  requesting: false,
  error: false,
  result: null,
  isCreate: false,
  isDelete: false
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'FETCH_WHEELS_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false
      }
    case 'FETCH_WHEELS_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false
      }
    case 'FETCH_WHEELS_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false
      }
    case 'FETCH_WHEEL_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false
      }
    case 'FETCH_WHEEL_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false
      }
    case 'FETCH_WHEEL_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false
      }
    case 'CREATE_WHEEL_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: true
      }
    case 'CREATE_WHEEL_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true
      }
    case 'CREATE_WHEEL_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true
      }
    case 'UPDATE_WHEEL_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: true
      }
    case 'UPDATE_WHEEL_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true
      }
    case 'UPDATE_WHEEL_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true
      }
    case 'ARCHIVE_WHEEL_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false,
        isDelete: true
      }
    case 'ARCHIVE_WHEEL_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false,
        isDelete: true
      }
    case 'ARCHIVE_WHEEL_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false,
        isDelete: true
      }
    case 'RESTORE_WHEEL_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        isCreate: false,
        isDelete: false
      }
    case 'RESTORE_WHEEL_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true,
        isDelete: true
      }
    case 'RESTORE_WHEEL_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true,
        isDelete: true
      }
    case 'DELETE_WHEEL_REQUESTED':
      return {
        ...state,
        requesting: true,
        error: false,
        isCreate: false,
        isDelete: true
      }
    case 'DELETE_WHEEL_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false,
        isDelete: true
      }
    case 'DELETE_WHEEL_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false,
        isDelete: true
      }
    default:
      return state
  }
}
