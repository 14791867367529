export enum RoutePaths {
  Account = '/account',
  AdminControls = '/admin-controls',
  DiagnosticPreview = '/diagnostic-preview',
  Diagnostic = '/diagnostic',
  Diagnostics = '/diagnostics',
  Dimension = '/dimension',
  Entry = '/entry',
  ForgotPassword = '/forgot-password',
  Groups = '/groups',
  Home = '/',
  Inventories = '/inventories',
  Inventory = '/inventory',
  InventoryPreview = '/inventory-preview',
  Login = '/login',
  NewDiagnostic = '/new-diagnostic',
  NewInventory = '/new-inventory',
  NewSurvey = '/new-survey',
  NewUser = '/user/new',
  NewWheel = '/new-wheel',
  NotFound = '/404',
  ResetPassword = '/reset-password',
  Sector = '/sector',
  SignUp = '/sign-up',
  SurveyPreview = '/survey-preview',
  Survey = '/survey',
  Surveys = '/surveys',
  User = '/user',
  Users = '/users',
  WheelPreview = '/wheel-preview',
  Wheels = '/wheels'
};
