import React from 'react'
import { connect } from 'react-redux'
import { Tab, Label, Menu } from 'semantic-ui-react'
import { get } from 'lodash'

import ActionsBar from './ActionsBar'
import Dimension from './Dimension'

const Tags = (props) => {
  const { sectors, factors, onFactorChanged, onSave, onCancel, descriptionProperties } = props

  const getTotalFactors = sector => {
    const { factors } = props;

    return sector.dimensions.reduce((totalDimensionFactors, dimension) => {
      return (
        totalDimensionFactors +
        dimension.factors.reduce((totalFactors, factor) => {
          return totalFactors + (factors[factor._id] ? 1 : 0)
        }, 0)
      )
    }, 0)
  }

  const getMenuItem = sector => {
    const totalFactors = getTotalFactors(sector)

    return (
      <Menu.Item key={sector._id}>
        {sector.name}
        <Label color={totalFactors ? 'black' : 'grey'}>{totalFactors}</Label>
      </Menu.Item>
    )
  }

  return (
    <div className='tagsComponent'>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={sectors.map(sector => ({
          menuItem: getMenuItem(sector),
          render: () => (
            <div key={sector._id}>
              {sector.dimensions.map(dimension => (
                <Dimension
                  key={dimension._id}
                  factors={factors}
                  dimension={dimension}
                  onFactorChanged={onFactorChanged}
                />
              ))}
            </div>
          )
        }))}
      />

      <ActionsBar onSave={() => onSave(descriptionProperties)} onCancel={onCancel} />
    </div>
  );
}

const mapStateToProps = ({ sectors }) => ({
  sectors: get(sectors, 'result.data', [])
})

export default connect(mapStateToProps)(Tags)
