import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { Table, Dropdown } from 'semantic-ui-react'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'

import EntryPreview from './entryPreview'
import UpdatedAt from '../dashboard/updatedAt'
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal'
import Button from '../common/Button'
import BackArrow from './assets/arrow_button_active.svg'
import ContentLoader from '../common/content-loader'

class EntryList extends Component {
  state = {
    showArchiveConfirmation: false,
    entryId: undefined,
    inventoryEntries: [],
    selectedEntries: {},
    selectAll: false,
  }

  componentDidMount() {
    this.setEntries({})
  }

  componentDidUpdate(prevProps) {
    this.setEntries(prevProps)
  }

  setEntries = (prevProps) => {
    const { inventoryEntries } = this.props

    const entries = get(inventoryEntries, 'result.data')

    if (
      inventoryEntries !== prevProps.inventoryEntries &&
      Array.isArray(entries)
    ) {
      this.setState({ inventoryEntries: entries })
    }
  }

  getEntries = () => {
    const { factor, search, entryMap } = this.props
    const { inventoryEntries } = this.state
    let result

    if (!factor) {
      result = inventoryEntries
    } else {
      result = entryMap[factor._id] || []
    }

    if (search) {
      return result.filter((item) => {
        const { title, citation, description } = item
        const titleValue =
          title && title.toUpperCase().includes(search.toUpperCase())
        const citationValue =
          citation && citation.toUpperCase().includes(search.toUpperCase())
        const descValue =
          description &&
          this.htmlToText(description)
            .toUpperCase()
            .includes(search.toUpperCase())
        return titleValue || citationValue || descValue
      })
    }

    return result
  }

  rowActionClick = (event, data) => {
    this.setState({ showArchiveConfirmation: true, entryId: data.value })
  }

  finishAction = () => {
    const { entryId } = this.state
    const { updateEntry } = this.props
    updateEntry(entryId, { deleted: true })
  }

  getHighlightedText = (text, search) => {
    const STRING_MARGIN = 100
    const uppercaseSearch = search.toUpperCase()
    const uppercaseText = text.toUpperCase()
    const startOfMatch = uppercaseText.indexOf(uppercaseSearch)
    const endOfMatch = startOfMatch + uppercaseSearch.length
    const startOfSubstring = Math.max(0, startOfMatch - STRING_MARGIN)
    const endOfSubstring = Math.min(
      uppercaseText.length,
      endOfMatch + STRING_MARGIN
    )
    const newText =
      (startOfSubstring > 0 ? '...' : '') +
      text.substring(startOfSubstring, endOfSubstring) +
      (endOfSubstring < uppercaseText.length ? '...' : '')
    return (
      <Highlighter
        searchWords={[search]}
        autoEscape={true}
        textToHighlight={newText}
      />
    )
  }

  htmlToText = (htmlContent) => {
    const span = document.createElement('span')
    span.innerHTML = htmlContent
    return span.textContent || span.innerText
  }

  showHighlightedText = (entry, search) => {
    const uppercaseSearch = search.toUpperCase()
    const clearedDescription = this.htmlToText(entry.description)
    if (entry.title.toUpperCase().includes(uppercaseSearch)) {
      return this.getHighlightedText(entry.title, search)
    } else if (entry.citation.toUpperCase().includes(uppercaseSearch)) {
      return this.getHighlightedText(entry.citation, search)
    } else if (clearedDescription.toUpperCase().includes(uppercaseSearch)) {
      return this.getHighlightedText(clearedDescription, search)
    }
    return ''
  }

  render() {
    const {
      dimension,
      duplicateEntry,
      factor,
      goTo,
      hideBackButton,
      onSelectFactor = () => true,
      inModal,
      goToEntry,
      inventoryEntries,
      hideRationale,
      search,
    } = this.props
    const { showArchiveConfirmation } = this.state

    const entries = this.getEntries()

    return (
      <ContentLoader
        loading={inventoryEntries.isFetch && !inventoryEntries.loaded}
      >
        <div className='entryList'>
          {showArchiveConfirmation && (
            <ConfirmDeleteModal
              onClose={() => this.setState({ showArchiveConfirmation: false })}
              name='the entry'
              type='entry'
              onDelete={this.finishAction}
            />
          )}

          {factor && dimension && (
            <div className='factorContainer'>
              {!hideBackButton && (
                <div>
                  <Button
                    className='entryBack'
                    color='clear'
                    onClick={() => onSelectFactor({})}
                  >
                    <img src={BackArrow} alt='Back' className='voltBackArrow' />
                    Back to Full List
                  </Button>
                </div>
              )}
              <div
                className={inModal ? 'dimensionName inModal' : 'dimensionName'}
              >
                {dimension.name}
              </div>
              <div className={inModal ? 'factorName inModal' : 'factorName'}>
                {factor.name}
              </div>
            </div>
          )}
          {entries.length ? (
            <Table basic='very' padded>
              <Table.Header>
                <Table.Row verticalAlign='top'>
                  {!inModal ? (
                    <>
                      <Table.HeaderCell width={10}>Entry</Table.HeaderCell>
                      <Table.HeaderCell>Last Edit By</Table.HeaderCell>
                      <Table.HeaderCell />
                    </>
                  ) : (
                    <Table.HeaderCell>Entry</Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {entries.map((entry) => {
                  return inModal ? (
                    <Table.Row>
                      <Table.Cell>
                        <EntryPreview entry={entry} />
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row key={entry._id} verticalAlign='top'>
                      <Table.Cell className='entryTitleContainer'>
                        <div
                          className={'entryTitle'}
                          onClick={() => goToEntry(entry)}
                        >
                          {entry.title}
                        </div>

                        {!_.isEmpty(search) && (
                          <div className={'entrySearch'}>
                            {this.showHighlightedText(entry, search)}
                          </div>
                        )}
                      </Table.Cell>

                      <Table.Cell>
                        {entry.lastEditedBy &&
                          `${entry.lastEditedBy.firstName} ${entry.lastEditedBy.lastName}`}
                        <UpdatedAt timestamp={entry.updatedAt} />
                      </Table.Cell>
                      <Table.Cell>
                        <Dropdown text='Actions' item>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                goTo(
                                  `/entry/${entry._id}?inventory=${entry.inventory}`
                                )
                              }
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => duplicateEntry(entry)}
                            >
                              Duplicate
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={this.rowActionClick}
                              value={entry._id}
                            >
                              {entry.deleted ? 'Restore' : 'Delete'}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          ) : (
            <div className='emptyEntries'>
              This factor has no data entries yet.
            </div>
          )}
          {!hideRationale && factor && factor.rationale && (
            <div className='rationale'>
              <div>
                <strong>Rationale</strong>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: factor.rationale.replace(/\n/g, '<br />'),
                }}
              />
            </div>
          )}
        </div>
      </ContentLoader>
    )
  }
}

const mapStateToProps = ({ inventoryEntry }) => ({
  inventoryEntries: inventoryEntry,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteEntry: (id) =>
    dispatch({
      type: 'DELETE_ENTRY_REQUESTED',
      id,
    }),
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
  goToEntry: (entry) =>
    dispatch({
      type: 'REDIRECT',
      url: `/entry/${entry._id}?inventory=${entry.inventory}`,
    }),
  duplicateEntry: ({ inventory, title, citation, data, factors }) =>
    dispatch({
      type: 'CREATE_INVENTORY_ENTRY_REQUESTED',
      payload: {
        inventory,
        title: `${title} copy`,
        citation,
        data,
        factors,
      },
    }),
  updateEntry: (id, payload) =>
    dispatch({
      type: 'UPDATE_ENTRY_REQUESTED',
      id,
      payload,
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(EntryList)
