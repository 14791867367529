import React from 'react'
import { Header } from 'semantic-ui-react'

import Surveys from './viewerSurveys'

const ViewerDashboard: React.FC = () => {
  return (
    <>
      <Header as='h1'>Dashboard</Header>
      <br />
      <Surveys />
    </>
  );
};

export default ViewerDashboard
