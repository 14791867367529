import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Modal } from 'semantic-ui-react'

import Button from '../../common/Button'

class AddSector extends Component {
  state = {}

  saveSector = () => {
    const { wheel } = this.props

    if (this.props.sector) {
      this.props.updateSector(
        {
          name: this.state.name,
          order: this.state.order,
        },
        this.props.sector._id
      )
    } else {
      this.props.createSector({
        name: this.state.name,
        order: this.state.order,
        compare: true,
        wheel,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.show && !this.props.show) {
      if (props.sector) {
        this.setState({
          name: props.sector.name,
          order: props.sector.order,
        })
      } else {
        this.setState({
          name: '',
          order: 1,
        })
      }
    }

    if (
      props.sectors !== this.props.sectors &&
      props.sectors.isCreate &&
      !props.sectors.error &&
      props.sectors.result
    ) {
      this.props.getSectors({ wheel: props.wheel })
      this.props.onClose()
    }
  }

  render() {
    const { onClose, show, sector } = this.props

    return (
      <Modal open={show} onClose={onClose} centered={false}>
        <Modal.Header>{sector ? 'Edit' : 'Create new'} sector</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>NAME</label>
              <Input
                placeholder='Name'
                name='name'
                onChange={(e, data) => this.setState({ name: data.value })}
                value={this.state.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Order</label>
              <Input
                placeholder='Order'
                name='order'
                onChange={(e, data) => this.setState({ order: data.value })}
                value={this.state.order}
                type='number'
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button rounded shadowed onClick={this.saveSector}>
            Save
          </Button>
          <Button rounded color='white' onClick={onClose}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const mapStateToProps = ({ sectors }) => ({ sectors })

const mapDispatchToProps = (dispatch) => {
  return {
    getSectors: (query) => dispatch({ type: 'FETCH_SECTORS_REQUESTED', query }),
    updateSector: (payload, id) =>
      dispatch({ type: 'UPDATE_SECTOR_REQUESTED', payload, id }),
    createSector: (payload) =>
      dispatch({ type: 'CREATE_SECTOR_REQUESTED', payload }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSector)
