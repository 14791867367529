import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loader, Input, Button, Icon, Form } from 'semantic-ui-react'
import qs from 'query-string'
import passwordHelpers from '../../lib/passwordHelpers'

import Notifications from '../home/notifications'
import Logo from '../../assets/ps_logo.svg'
import './styles.scss'

class SignUp extends Component {
  state = {
    firstName: '',
    lastName: '',
    password: '',
  }

  componentDidMount() {
    const token = qs.parse(this.props.location.search).token
    this.props.getUserByToken(token)
    this.setState({ token })
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { usersResult, goTo } = props

    if (props.usersResult.error) {
      this.props.sendError('The token is invalid')
      return setTimeout(() => goTo('/login'), 5000)
    }

    if (
      this.props.usersResult.requesting &&
      !usersResult.requesting &&
      !usersResult.error
    ) {
      this.setState({
        email: usersResult.result.data.email,
      })
    }

    if (props.signUpResult.error) {
      this.props.sendError(props.signUpResult.result.response.data.message)
    }
  }

  submit(e) {
    e.preventDefault()

    if (!this.state.firstName) {
      return this.props.sendError('The first name is mandatory')
    }

    if (!this.state.lastName) {
      return this.props.sendError('The last name is mandatory')
    }

    if (!this.state.password) {
      return this.props.sendError('The password is mandatory')
    }

    const passwordStrengthValidity = passwordHelpers.validatePasswordStrength(
      this.state.password
    )
    if (passwordStrengthValidity !== true) {
      return this.props.sendError(passwordStrengthValidity)
    }

    this.props.signUp({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      password: this.state.password,
      token: this.state.token,
    })
  }

  render() {
    const { goTo, usersResult, signUpResult } = this.props
    const showLoading =
      usersResult.requesting || usersResult.error || signUpResult.requesting

    return (
      <div>
        <Notifications />
        <div className='signUpComponent'>
          <img
            src={Logo}
            style={{ cursor: 'pointer' }}
            onClick={() => goTo('/')}
            alt=''
            className='ps-logo'
          />

          {showLoading ? (
            <Loader active />
          ) : (
            <div>
              <div className='info'>
                Before we begin, we need you to set up a password for your
                account. Once set up, you can change it at anytime.
              </div>

              <Form>
                <Form.Field>
                  <div className='required'>*REQUIRED</div>
                  <label>EMAIL</label>
                  <Input
                    placeholder='Email'
                    name='email'
                    value={this.state.email}
                    readOnly
                  />
                </Form.Field>
                <Form.Field>
                  <div className='required'>*REQUIRED</div>
                  <label>YOUR FIRST NAME</label>
                  <Input
                    placeholder='First Name'
                    name='firstName'
                    value={this.state.firstName}
                    onChange={(e, data) =>
                      this.setState({ firstName: data.value })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <div className='required'>*REQUIRED</div>
                  <label>YOUR LAST NAME</label>
                  <Input
                    placeholder='Last Name'
                    name='lastName'
                    value={this.state.lastName}
                    onChange={(e, data) =>
                      this.setState({ lastName: data.value })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <div className='required'>*REQUIRED</div>
                  <label>NEW PASSWORD</label>
                  <Input
                    placeholder='New password'
                    name='password'
                    onChange={(e, data) =>
                      this.setState({ password: data.value })
                    }
                    value={this.state.password}
                    type={this.state.showPassword ? 'text' : 'password'}
                    icon={
                      <Icon
                        name='eye'
                        size='big'
                        link
                        onClick={() =>
                          this.setState({
                            showPassword: !this.state.showPassword,
                          })
                        }
                      />
                    }
                  />
                  <div className='passwordAlert'>
                    Must have a special character, a number, upper and lowercase
                    letters
                  </div>
                </Form.Field>
              </Form>

              <div className='loginButton'>
                <Button
                  basic
                  color='blue'
                  size='big'
                  onClick={(e) => this.submit(e)}
                >
                  Login
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ signUp, users }) => ({
  signUpResult: signUp,
  usersResult: users,
})

const mapDispatchToProps = (dispatch) => ({
  signUp: (payload) => dispatch({ type: 'SIGNUP_REQUESTED', payload }),
  goTo: (url) => dispatch({ type: 'REDIRECT', url }),
  getUserByToken: (token) =>
    dispatch({ type: 'FETCH_USER_BY_TOKEN_REQUESTED', token }),
  sendError: (message) =>
    dispatch({
      type: 'SEND_NOTIFICATION',
      payload: {
        message,
        success: false,
      },
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
