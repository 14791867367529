import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Confirm } from 'semantic-ui-react'

class ConfirmDeleteModal extends Component {
  remove = () => {
    const { remove, item, type, url, onClose, onDelete } = this.props
    if (onDelete) {
      onDelete()
    } else {
      remove(item, type, url)
    }
    onClose()
  }

  render() {
    const { name, type, onClose } = this.props

    return (
      <Confirm
        open={true}
        header={'Delete ' + type}
        content={
          <div className='modalContent'>
            Are you sure you want to delete <strong>{name}</strong>?
          </div>
        }
        onCancel={onClose}
        onConfirm={this.remove}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  remove: (item, type, url) => {
    dispatch({
      type: 'DELETE_' + type.toUpperCase() + '_REQUESTED',
      id: item._id
    })
    if (url) dispatch({ type: 'REDIRECT', url })
  }
})

export default connect(
  null,
  mapDispatchToProps
)(ConfirmDeleteModal)
