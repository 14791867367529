const defaultState = {
  requesting: false,
  error: false,
  result: null,
  isCreate: false
}

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'FETCH_USERS_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false
      }
    case 'FETCH_USERS_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false
      }
    case 'FETCH_USERS_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false
      }
    case 'FETCH_USER_DETAILS_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false
      }
    case 'FETCH_USER_DETAILS_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false
      }
    case 'FETCH_USER_DETAILS_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false
      }
    case 'FETCH_USER_BY_TOKEN_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: false
      }
    case 'FETCH_USER_BY_TOKEN_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: false
      }
    case 'FETCH_USER_BY_TOKEN_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: false
      }
    case 'CREATE_USER_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: true
      }
    case 'CREATE_USER_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: [...state.result, action.payload],
        isCreate: true
      }
    case 'CREATE_USER_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true
      }
    case 'CREATE_GROUP_USER_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: true
      }
    case 'CREATE_GROUP_USER_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true
      }
    case 'CREATE_GROUP_USER_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true
      }
    case 'UPDATE_USER_REQUESTED':
      return {
        requesting: true,
        error: false,
        result: null,
        isCreate: true
      }
    case 'UPDATE_USER_SUCCEEDED':
      return {
        requesting: false,
        error: false,
        result: action.payload,
        isCreate: true
      }
    case 'UPDATE_USER_FAILED':
      return {
        requesting: false,
        error: true,
        result: action.payload,
        isCreate: true
      }
    default:
      return state
  }
}
