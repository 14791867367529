const defaultState = {
  requesting: false,
  error: false,
  result: null,
  isCreate: false,
  isDelete: false
}

export default function counter(state = defaultState, action) {
  switch (action.type) {
    case 'FETCH_GROUPS_REQUESTED':
      return {
        ...state,
        requesting: true
      }
    case 'FETCH_GROUPS_SUCCEEDED':
      return {
        ...defaultState,
        result: action.payload
      }
    case 'FETCH_GROUPS_FAILED':
      return {
        ...defaultState,
        error: true,
        result: action.payload
      }
    case 'FETCH_GROUP_DETAILS_REQUESTED':
      return {
        ...defaultState,
        requesting: true
      }
    case 'FETCH_GROUP_DETAILS_SUCCEEDED':
      return {
        ...defaultState,
        result: action.payload
      }
    case 'FETCH_GROUP_DETAILS_FAILED':
      return {
        ...defaultState,
        error: true,
        result: action.payload
      }
    case 'CREATE_GROUP_REQUESTED':
      return {
        ...state,
        requesting: true,
        isCreate: true
      }
    case 'CREATE_GROUP_SUCCEEDED':
      return {
        ...defaultState,
        result: action.payload,
        isCreate: true
      }
    case 'UPDATE_GROUP_REQUESTED':
      return {
        ...state,
        requesting: true,
        isCreate: true
      }
    case 'UPDATE_GROUP_SUCCEEDED':
      return {
        ...defaultState,
        result: action.payload
      }
    case 'UPDATE_GROUP_FAILED':
      return {
        ...defaultState,
        error: true,
        result: action.payload
      }
    case 'DELETE_GROUP_REQUESTED':
      return {
        ...defaultState,
        result: state.result,
        requesting: true,
        isDelete: true
      }
    case 'DELETE_GROUP_SUCCEEDED':
      return {
        ...defaultState,
        result: action.payload,
        isDelete: true
      }
    case 'DELETE_GROUP_FAILED':
      return {
        ...defaultState,
        error: true,
        result: state.result,
        isDelete: true
      }
    default:
      return state
  }
}
