import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon, Menu, Popup } from 'semantic-ui-react'
import { get } from 'lodash'

import Logo from '../../assets/volt_logo.svg'
import ClosePreviewImage from '../../assets/volt_close_preview.png'
import Button from '../common/Button'

import './styles.scss'

class Header extends Component {
  state = {
    menuVisible: false
  }

  goTo = url => {
    const { redirect } = this.props
    this.setState({ menuVisible: false }, () => redirect(url))
  }

  openTechnicalSupport = () => {
    this.props.openTechnicalSupport()
  }

  isPreviewPage() {
    const path = window.location.pathname

    if (
      path.startsWith('/survey-preview') ||
      path.startsWith('/diagnostic-preview') ||
      path.startsWith('/wheel-preview')
    ) {
      return true
    }

    return false
  }

  render() {
    const { user, openTechnicalSupport, logout } = this.props
    const isPreview = this.isPreviewPage()

    return (
      <div className='headerContainer'>
        <div className='container'>
          <div className='headerComponent'>
            <div className='logoContainer'>
              <div
                className={'voltLogo ' + (isPreview ? 'voltPreviewPage' : '')}
                onClick={() => !isPreview && this.goTo('/')}
              >
                <img src={Logo} alt='' />
              </div>
            </div>

            {isPreview ? (
              <Button
                shadowed
                rounded
                size='small'
                onClick={() => window.close()}
              >
                Close Preview
                <img
                  alt=''
                  src={ClosePreviewImage}
                  className='voltPreviewCloseIcon'
                />
              </Button>
            ) : (
                <Popup
                  className='mainMenu'
                  basic
                  hoverable
                  offset='-120px,0'
                  hideOnScroll
                  on={['click']}
                  trigger={
                    <div className='menuButton'>
                      <span>Menu</span>
                      <Icon name='angle down' />
                    </div>
                  }
                >
                  <Popup.Content>
                    <Menu text vertical>
                      <Menu.Item name='home' onClick={() => this.goTo('/')}>
                        Dashboard
                    </Menu.Item>
                      <Menu.Item
                        name='accountSettings'
                        onClick={() => this.goTo('/account')}
                      >
                        Change Password
                    </Menu.Item>
                      {user.type === 'A' && (
                        <Menu.Item
                          name='maintenance'
                          onClick={() => this.goTo('/admin-controls')}
                        >
                          Admin Panel
                        </Menu.Item>
                      )}
                      <Menu.Item name='support' onClick={openTechnicalSupport}>
                        Technical Support
                    </Menu.Item>
                      <Menu.Item name='logout' onClick={logout}>
                        Logout
                    </Menu.Item>
                    </Menu>
                  </Popup.Content>
                </Popup>
              )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ tokenValidation }) => ({
  user: get(tokenValidation, 'result.data')
})

const mapDispatchToProps = dispatch => ({
  redirect: url => dispatch({ type: 'REDIRECT', url }),
  logout: () => dispatch({ type: 'LOGOUT' }),
  openTechnicalSupport: () =>
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'technicalSupport'
    })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
