import React, { Component } from 'react'
import { CSVDownload } from 'react-csv'
import { Header, Button } from 'semantic-ui-react'
import CsvParse from './CsvParse'

const objectToArray = (acc, i) => {
  acc.push([i.firstName, i.lastName, i.email, i.type])
  return acc
}

const keys = ['firstName', 'lastName', 'email', 'type']

class BulkUsers extends Component {
  state = {
    shouldDownload: false
  }

  membersToCSV = () => {
    const { members } = this.props
    return members.reduce(objectToArray, [keys])
  }

  download = () => {
    this.setState({ shouldDownload: true })
  }

  upload = data => {
    const { createUsers, id } = this.props
    const users = data.map(i => ({ ...i, groupId: id }))
    createUsers(users)
  }

  uploadCSV = () => {
    this.fileHandle.click()
  }

  render() {
    const { shouldDownload } = this.state
    return (
      <>
        <Header as='h2'>Import or Export users </Header>
        <p>
          When exporting make sure that you have your popup/ads blocker turned
          off for this site or paused. If you have it turned on, please refresh
          the page and try again.
        </p>
        <p>
          When importing data make sure to use exported files as templates
          before uploading the real file. Accounts with duplicate or invalid
          emails won&apos;t be created. All fields are required.
        </p>
        <Button.Group>
          <Button onClick={this.download}>Export</Button>
          <Button.Or />
          <CsvParse
            keys={keys}
            onDataUploaded={this.upload}
            render={onChange => (
              <Button htmlFor='csvUpload' positive onClick={this.uploadCSV}>
                Import
                <input
                  id='csvUpload'
                  ref={r => {
                    this.fileHandle = r
                  }}
                  style={{ display: 'none' }}
                  type='file'
                  onChange={onChange}
                />
              </Button>
            )}
          />
        </Button.Group>
        {shouldDownload && (
          <CSVDownload
            data={this.membersToCSV()}
            target='_blank'
            enclosingCharacter=''
          />
        )}
      </>
    )
  }
}

export default BulkUsers
