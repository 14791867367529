import React from 'react'
import moment from 'moment'

const UpdatedAt = ({ timestamp }) => {
  if (!timestamp) return null

  return (
    <span
      style={{
        width: '100%',
        fontSize: 10,
        fonWeight: 'bold',
        display: 'inline-block'
      }}
    >
      {moment(timestamp).format('LLL')}
    </span>
  )
}

export default UpdatedAt
