import React, { Component } from 'react'
import get from 'lodash/get'
import { UnratedContainer, ComparatorContainer, RatingContainer } from './Comparator'

import './CompareFactor.scss'

class CompareFactor extends Component {
  handleFactorRating = (e, data) => {
    const { updateFactor, factor } = this.props

    const value = parseInt(data.value, 10)

    updateFactor({ score: value }, factor._id)
    factor.score = value
  }

  render() {
    const { factor, competitorsQuestion, diagnostic, comparators, comparison, changeComparator } = this.props
    const score = factor.score || 0
    const productName = get(diagnostic, 'inventory.product')
    const allComparators = diagnostic.inventory.comparators
    const comparatorList = Object.keys(comparators)

    return (
      <div className='compareFactor'>
        <div className='title'>{competitorsQuestion}</div>
        <div className='note'>DRAG AND DROP EACH PRODUCT BELOW IN THE RATING SCALE</div>
        <UnratedContainer
          item={{ number: '0' }}
          changeComparator={changeComparator}
          handleFactorRating={this.handleFactorRating}
        >
          {comparatorList
            .filter(c => !comparators[c] || comparators[c].value === 0)
            .map(c => (
              <ComparatorContainer
                compare={comparators[c]}
                key={c}
                allComparatorsFromInventory={allComparators}
              />
            ))}
          {score <= 0 && (
            <ComparatorContainer
              compare={{
                description: productName,
                comparator: productName,
                isProduct: true
              }}
              allComparatorsFromInventory={allComparators}
            />
          )}
        </UnratedContainer>

        <div className='sectionBrake'>RATING SCALE</div>
        <div className='legendSection'>
          {comparison.map((item) => (
            <div className='legendItem'>
              <div className='comparisonNumber'>{item.number}</div>
              <div className='comparisonLabel'>{item.label}</div>
            </div>))}
        </div>
        <div className='comparison'>
          {comparison.map((item) => (
            <div key={item.number} className='comparisonItem'>
              <RatingContainer
                item={item}
                changeComparator={changeComparator}
                handleFactorRating={this.handleFactorRating}
              >
                {comparatorList
                  .filter(c => comparators[c].value.toString() === item.number)
                  .map(c => (
                    <ComparatorContainer
                      compare={comparators[c]}
                      key={c}
                      allComparatorsFromInventory={allComparators}
                    />
                  ))}
                {score.toString() === item.number && (
                  <ComparatorContainer
                    compare={{
                      description: productName,
                      comparator: productName,
                      isProduct: true
                    }}
                    allComparatorsFromInventory={allComparators}
                  />
                )}
              </RatingContainer>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default CompareFactor