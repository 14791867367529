import React, { Component } from 'react'

import constants from './constants'
import utils from './utils'

const getEscapedFactorName = name => {
  const MAX_CHAR = 27;
  const trimmedText = name.replace(/\t/g, '').trim();
  if (trimmedText.length > MAX_CHAR) {
    return trimmedText.substring(0, MAX_CHAR-2) + '...';
  }
  return trimmedText;
};

class Factor extends Component {
  state = {
    hover: false
  }

  colors = {
    7: {
      '-1': '#fff',
      1: '#BB5F03',
      2: '#F97E06',
      3: '#FA9937',
      4: '#84BBEC',
      5: '#5BA4E6',
      6: '#328DE0',
      7: '#266AA8'
    },
    5: {
      '-5': '#BB5F03',
      '-4': '#BB5F03',
      '-3': '#F97E06',
      '-2': '#FA9937',
      '-1': '#FBB26A',
      0: '#9A9B9F',
      1: '#84BBEC',
      2: '#5BA4E6',
      3: '#4799E3',
      4: '#328DE0',
      5: '#266AA8'
    },
    parity: '#9A9B9F',
    comparator: '#4e4d4d',
    gray: '#f1f2f6',
    disabled: '#B5B6B8',
    highlighted: '#f83174',
    darkGray: '#2A2B2E'
  }

  getRectPath = options => {
    const { sector, factor, radius, meta, angle } = this.props
    const ctx = document.createElement('canvas').getContext('2d')
    ctx.font = '12px Roboto'

    return utils.getRectPath({
      sector,
      id: `FT_${factor._id}`,
      angle,
      radius,
      meta,
      stroke: options.stroke,
      fill: options.fill,
      strokeWidth: options.strokeWidth,
      style: {
        cursor: 'pointer'
      },
      onMouseOver: event => this.selectFactor(event),
      onMouseOut: () => this.deselectFactor(),
      onClick: () => this.handleClick(factor),
      width: ctx.measureText(getEscapedFactorName(factor.name)).width
    })
  }

  getColors = rating => {
    const { sector, factor, meta, dimensionSelected } = this.props
    const { preview } = meta

    const colorKey = sector.compare ? 5 : 7
    const colors = {
      fill: '#fff',
      stroke: '#DDD',
      fillHover: '#fff',
      strokeHover: this.colors.comparator,
      text: this.colors.comparator,
      textHover: this.colors.comparator,
      opacity: 1,
      dotHover: this.colors[colorKey][rating],
      squareHover: this.colors.comparator
    }

    const factorMap = meta.factorMap[factor._id]
    if (!meta.filter) {
      colors.dot = this.colors[colorKey][rating]
      colors.square = this.colors.comparator
      colors.fill = this.colors.gray
      colors.stroke = this.colors.gray
      colors.fillHover = this.colors[colorKey][rating]
      colors.strokeHover = this.colors.darkGray
      colors.textHover = '#fff'

      if (factorMap && factorMap.background && !factorMap.highlighted) {
        colors.dot = factorMap.dot || factorMap.background
        colors.fill = factorMap.background
        colors.stroke = factorMap.border || factorMap.background
        colors.strokeWidth = factorMap.strokeWidth
        colors.fillHover = factorMap.background
        colors.strokeHover = factorMap.background
        colors.text = factorMap.text
        colors.textHover = factorMap.text
      }
      if (factorMap && factorMap.highlighted) {
        if (factorMap.dot) {
          colors.dot = factorMap.dot
        }
        colors.fill = this.colors.highlighted
        colors.text = '#fff'
        colors.fillHover = this.colors.highlighted
        colors.textHover = '#fff'
      }
    } else {
      colors.strokeHover = this.colors.comparator
      colors.fillHover = this.colors[colorKey][rating]
      colors.textHover = '#fff'
      if (!rating && !sector.compare) {
        colors.textHover = this.colors.comparator
      }

      if (meta.filter === 'advantages') {
        if (
          (sector.compare && rating <= 0) ||
          (!sector.compare && rating < 4)
        ) {
          colors.dot = this.colors.parity
          colors.square = this.colors.parity
        } else {
          colors.dot = this.colors[colorKey][rating]
          colors.square = this.colors.comparator
          colors.fill = this.colors[colorKey][rating]
          colors.stroke = this.colors[colorKey][rating]
          colors.text = '#fff'
        }
      }
      if (meta.filter === 'disadvantages') {
        if (
          (sector.compare && rating >= 0) ||
          (!sector.compare && rating >= 4) ||
          !factor.rating
        ) {
          colors.dot = this.colors.parity
          colors.square = this.colors.parity
        } else {
          colors.dot = this.colors[colorKey][rating]
          colors.square = this.colors.comparator
          colors.fill = this.colors[colorKey][rating]
          colors.stroke = this.colors[colorKey][rating]
          colors.text = '#fff'
        }
      }
      if (meta.filter === 'parities') {
        if (!sector.compare || (rating === 0 && factor.compareAvg === 0)) {
          colors.dot = this.colors.disabled
          colors.square = this.colors.disabled
          colors.text = this.colors.disabled
        } else if (sector.compare && rating !== 0) {
          colors.dot = this.colors.parity
          colors.square = this.colors.square
        } else {
          colors.dot = this.colors.parity
          colors.square = this.colors.square
          colors.fill = this.colors[colorKey][rating]
          colors.stroke = colors.fill
          colors.text = '#fff'
        }
      }
      if (meta.filter === 'summaries') {
        colors.dot = this.colors[colorKey][rating]
        colors.square = this.colors.comparator
        colors.fill = this.colors[colorKey][rating]
        colors.stroke = this.colors[colorKey][rating]
        colors.text = '#fff'

        if (!rating && !sector.compare) {
          colors.text = this.colors.comparator
        }
      }
      if (meta.filter === 'detailed') {
        if (dimensionSelected) {
          colors.dot = this.colors[colorKey][rating]
          colors.square = this.colors.comparator
          colors.fill = this.colors[colorKey][rating]
          if (rating) {
            colors.stroke = this.colors[colorKey][rating]
          }
          colors.text = '#fff'
        } else if (meta.selectedSector === sector._id) {
          colors.dot = this.colors.parity
          colors.square = this.colors.parity
          colors.fill = this.colors.gray
          colors.stroke = this.colors.gray
          colors.fillHover = this.colors[colorKey][rating]
          colors.textHover = '#fff'
        } else {
          colors.dot = this.colors.parity
          colors.square = this.colors.parity
        }
      }

      if (factor.compareAvg === 0 && sector.compare && rating && !meta.filter) {
        colors.dot = this.colors.darkGray
        colors.fill = this.colors.darkGray
        colors.text = '#fff'
        colors.fillHover = this.colors.darkGray
      }
    }

    if ((rating === 0 && factor.rating === 0) || factor.disabled) {
      colors.dot = this.colors.disabled
      colors.square = this.colors.disabled
      colors.text = this.colors.disabled
      colors.fillHover = this.colors.gray
      colors.strokeHover = this.colors.darkGray
      colors.textHover = this.colors.darkGray
      colors.fill = '#fff'
    }

    if (preview) {
      if (!factorMap || !factorMap.highlighted) {
        colors.fill = colors.dot
      }
      colors.text = '#fff'
    }

    if (factor.highlighted) {
      colors.opacity = 1
    }

    return colors
  }

  selectFactor = event => {
    const { meta } = this.props
    const factor = meta.factorMap[this.props.factor._id]
    if (factor && factor.onMouseOver) factor.onMouseOver(event)

    if (meta.hidePopup) return

    this.setState({ hover: true })
    this.props.onFactorSelect(this.props.factor, event)
  }

  deselectFactor = event => {
    const { meta } = this.props
    const factor = meta.factorMap[this.props.factor._id]
    if (factor && factor.onMouseOut) factor.onMouseOut(event)

    if (meta.hidePopup) return

    this.setState({ hover: false })
    this.props.onFactorSelect(null, event)
  }

  handleClick = factor => {
    const { meta } = this.props
    const factorMap = meta.factorMap[factor._id]
    if (factorMap && factorMap.onClick) {
      factorMap.onClick()
    }
  }

  render() {
    const { sector, factor, radius, meta } = this.props
    const { hover } = this.state
    let { angle } = this.props

    const cx = meta.width / 2
    const cy = meta.height / 2

    const x = utils.calculateDX(
      cx,
      angle,
      radius + factor.rating * constants.scaleStep
    )
    const y = utils.calculateDY(
      cy,
      angle,
      radius + factor.rating * constants.scaleStep
    )

    const compareAvg = factor.compareAvg

    const rating = factor.realRating
    const colors = this.getColors(rating)

    let rx, ry, cmx, cmy
    const compareWidth = 2.5,
      compareAngle = 0.8
    if (sector.compare) {
      rx =
        utils.calculateDX(
          cx,
          angle,
          radius + compareAvg * constants.scaleStep
        ) || 0
      ry =
        utils.calculateDY(
          cy,
          angle,
          radius + compareAvg * constants.scaleStep
        ) || 0
      cmx = utils.calculateDX(
        cx,
        angle - compareAngle,
        radius + compareAvg * constants.scaleStep
      )
      cmy = utils.calculateDY(
        cy,
        angle - compareAngle,
        radius + compareAvg * constants.scaleStep
      )
    }

    while (angle >= 360) angle -= 360
    while (angle < 0) angle += 360
    const straight = angle < 270 && angle > 90 ? 1 : 0

    let showRating = true
    if (meta.factorMap[factor._id] && meta.factorMap[factor._id].hide) {
      showRating = false
    }

    if (!x || !y) return null
    return (
      <g>
        {factor.rating > 0 && showRating && (
          <circle
            cx={x}
            cy={y}
            r={hover ? '6' : '4.5'}
            stroke={hover ? colors.dotHover : colors.dot}
            strokeWidth='1'
            fill={hover ? colors.dotHover : colors.dot}
            className='factorScore'
            onMouseOver={this.selectFactor}
            onMouseOut={this.deselectFactor}
            onClick={() => this.handleClick(factor)}
            style={{ opacity: colors.opacity }}
          />
        )}
        {sector.compare && compareAvg > 0 && (
          <g>
            {factor.rating > 0 && showRating && (
              <line
                x1={rx}
                y1={ry}
                x2={x}
                y2={y}
                style={{
                  stroke: hover ? colors.dotHover : colors.dot,
                  strokeWidth: '1',
                  opacity: colors.opacity
                }}
              />
            )}
            {showRating && (
              <rect
                x={cmx}
                y={cmy}
                width={compareWidth * 2}
                height={compareWidth * 2}
                fill={hover ? colors.squareHover : colors.square}
                transform={`rotate(${angle + 45},${cmx},${cmy})`}
                style={{ opacity: colors.opacity }}
              />
            )}
          </g>
        )}
        {this.getRectPath({
          stroke: hover ? colors.strokeHover : colors.stroke,
          fill: hover ? colors.fillHover : colors.fill,
          strokeWidth: hover ? 2 : colors.strokeWidth
        })}
        <text
          onMouseOver={this.selectFactor}
          onMouseOut={this.deselectFactor}
          onClick={() => this.handleClick(factor)}
          textAnchor={straight ? 'end' : ''}
          fill={hover ? colors.textHover : colors.text}
          style={{ opacity: colors.opacity }}
          className='factorName'
        >
          <textPath
            startOffset={straight ? '100%' : '0%'}
            href={`#FT_${factor._id}`}
            style={{
              opacity: colors.opacity,
              fontFamily: 'Roboto, Arial'
            }}
          >
            {getEscapedFactorName(factor.name)}
          </textPath>
        </text>
      </g>
    )
  }
}

export default Factor
