import React from 'react'
import moment from 'moment'

const LastUpdated = ({ item }) => {
  if (!item) return null

  return (
    <>
      {item.lastEditedBy &&
        `${item.lastEditedBy.firstName} ${item.lastEditedBy.lastName}`}
      <span
        style={{
          width: '100%',
          fontSize: 10,
          fonWeight: 'bold',
          display: 'inline-block'
        }}
      >
        {moment(item.updatedAt).format('LLL')}
      </span>
    </>
  )
}

export default LastUpdated
